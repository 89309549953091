import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import withStyles from '@material-ui/core/styles/withStyles'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import 'react-table/react-table.css'
import {
    CATALOG_ICON,
    DELETE_ICON,
    CATEGORY_ICON,
    SECONDARY_HEADER_HEIGHT,
} from '../../../Constants'
import Typography from '../../../fhg/components/Typography'
import SortIcon from '@material-ui/icons/Sort'

const styles = (theme) => ({
    banner: {
        width: '100%',

        '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)':
            {
                display: '-ms-flexbox',
            },
        '@supports not (-ms-high-contrast: none)': {
            display: 'flex',
        },
        position: 'relative',
        backgroundColor: theme.palette.environment.light.level0.base,
        justifyContent: 'space-between',
        alignItems: 'center',
        height: SECONDARY_HEADER_HEIGHT,
        borderBottom: `1px solid ${theme.palette.environment.light.level2.accent}`,
    },
    titleStyle: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontWeight: 'bold !important',
    },
    linkButton: {
        composes: 'no-print',
        zIndex: 1001,
        margin: theme.spacing(1),
        color: '#4f2682 !important', //'#5D788F',
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(0.5, 0.5),
        },
    },
    linkLabel: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        color: '#4f2682 !important',
    },
    middleSection: {
        position: 'absolute',
        transform: 'translateX(-50%)',
        left: '50%',
        '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)':
            {
                top: 0,
            },
    },
    imageStyle: {
        '@supports not (-ms-high-contrast: none)': {
            mask: `url(${CATALOG_ICON}) no-repeat`,
            maskSize: 'cover',
            backgroundColor: '#4f2682 !important',
        },
        '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)':
            {
                opacity: 0.5,
                background: `url(${CATALOG_ICON}) no-repeat`,
                backgroundSize: 'cover',
            },
        width: 14,
        height: 16,
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    deleteImageStyle: {
        '@supports not (-ms-high-contrast: none)': {
            mask: `url(${DELETE_ICON}) no-repeat`,
            maskSize: 'cover',
            backgroundColor: '#4f2682 !important',
        },
        '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)':
            {
                opacity: 0.5,
                background: `url(${DELETE_ICON}) no-repeat #4f2682`,
                backgroundSize: 'cover',
            },
        width: 14,
        height: 16,
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    categoryImageStyle: {
        '@supports not (-ms-high-contrast: none)': {
            mask: `url(${CATEGORY_ICON}) no-repeat`,
            maskSize: 'cover',
            backgroundColor: '#4f2682 !important',
        },
        '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)':
            {
                opacity: 0.5,
                background: `url(${CATEGORY_ICON}) no-repeat`,
                backgroundSize: 'cover',
            },
        width: 14,
        height: 16,
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    cancelButtonStyle: {
        color: theme.palette.button.minimal.cancel, //'#878e94',
    },
    cancelTextStyle: {
        fontWeight: 400,
    },
})

/**
 * Navigation header for assets.
 */
class PhotoHeader extends Component {
    static propTypes = {
        classes: PropTypes.any,
        onBack: PropTypes.func,
        evaluation: PropTypes.any,
        thumbnailsSelected: PropTypes.number,
    }

    render() {
        const {
            classes,
            evaluation,
            onBack,
            thumbnailsSelected,
            onCancel,
            onDelete,
            onCategorize,
            onSort,
        } = this.props
        return (
            <Grid item className={classes.banner}>
                <Grid item>
                    <Button
                        className={classes.linkButton}
                        variant={'text'}
                        onClick={onBack}
                        disabled={!evaluation || !evaluation.uri}
                        disableRipple
                    >
                        <span className={classes.imageStyle} />
                        <Typography
                            className={classes.linkLabel}
                            variant="button"
                            color={'inherit'}
                            id={'equipmentDetail.back.button'}
                        />
                    </Button>
                </Grid>
                <Grid
                    container
                    item
                    alignItems={'center'}
                    justifyContent={'flex-end'}
                >
                    <Typography
                        className={`${classes.titleStyle} subtle-text-small-bold`}
                        id={'equipmentDetail.selected.title'}
                        values={{ count: thumbnailsSelected }}
                    />
                    <Button
                        className={classes.linkButton}
                        variant={'text'}
                        onClick={onSort}
                        disableRipple
                    >
                        <SortIcon />
                        <Typography
                            className={classes.linkLabel}
                            variant="button"
                            color={'inherit'}
                            id={'equipmentDetail.sort.button'}
                        />
                    </Button>
                    <Button
                        className={classes.linkButton}
                        variant={'text'}
                        onClick={onDelete}
                        disableRipple
                    >
                        <span className={classes.deleteImageStyle} />
                        <Typography
                            className={classes.linkLabel}
                            variant="button"
                            color={'inherit'}
                            id={'equipmentDetail.delete.button'}
                        />
                    </Button>
                    <Button
                        className={classes.linkButton}
                        variant={'text'}
                        onClick={onCategorize}
                        disableRipple
                    >
                        <span className={classes.categoryImageStyle} />
                        <Typography
                            className={classes.linkLabel}
                            variant="button"
                            color={'inherit'}
                            id={'equipmentDetail.recategorize.button'}
                        />
                    </Button>
                    <Button
                        className={`${classes.linkButton} ${classes.cancelButtonStyle}`}
                        variant={'text'}
                        onClick={onCancel}
                        disabled={!evaluation || !evaluation.uri}
                        disableRipple
                    >
                        <Typography
                            className={`${classes.linkLabel} ${classes.cancelTextStyle}`}
                            variant="button"
                            color={'inherit'}
                            id={'cancel.button'}
                        />
                    </Button>
                </Grid>
            </Grid>
        )
    }
}

export default withStyles(styles)(PhotoHeader)
