import React, { useState, useEffect } from 'react'
import Modal from '@mui/material/Modal'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import SearchIcon from '@material-ui/icons/Search'
import Checkbox from '@material-ui/core/Checkbox'
import Button from '@mui/material/Button'
import { makeStyles } from '@material-ui/core/styles'
import { Auth } from 'aws-amplify'
import { isAdminOnly, isMultipleOrganization } from '../../../Utils/ServerUtil'
import { saveCatalogSharing } from '../../../Utils/SubmitUtil'
import Divider from '@material-ui/core/Divider'
import { SHARE_USER } from '../../../Constants'
import ContentLoader from 'react-content-loader'

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        outline: 'none',
        position: 'relative',
        width: 600,
        backgroundColor: theme.palette.background.paper,
        borderRadius: theme.spacing(2),
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: theme.spacing(0.5),
    },
    searchInput: {
        marginLeft: theme.spacing(1),
        flex: 1,
        backgroundColor: '#ede8f3',
        borderRadius: '50px',
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 2), // Add padding to the left and right
        marginBottom: theme.spacing(2), // Add padding at the bottom
        //borderBottom: '2px solid black', // Add border color at the bottom
    },
    listContainer: {
        maxHeight: 500,
        overflowY: 'auto',
        height: 600,
    },
    listItem: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: theme.spacing(1),
    },
    footer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: theme.spacing(2),
        borderTop: '0.5px solid #ccc',
        //boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
    },
    shareCounter: {
        display: 'flex',
        alignItems: 'center',
        color: '#4f2682',
    },
    checkboxStyle: {
        color: '#4f2682 !important',
        backgroundColor: `${theme.palette.environment.light.level0.base} !important`,
    },
    checkedStyle: {
        color: '#4f2682 !important',
    },
    icon: {
        width: 40,
        height: 40,
        marginRight: theme.spacing(1),
    },
    nameContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: theme.spacing(2),
    },
    emailText: {
        fontSize: '0.8rem',
        display: 'block',
        color: '#acacad',
    },
}))

const ShareModal = ({
    open,
    eval_id,
    evaluation,
    handleClose,
    handlesShowSuccessModal,
    handleLoading,
}) => {
    const classes = useStyles()
    const [searchValue, setSearchValue] = useState('')
    const [names, setNames] = useState([])
    const [selectedEmployees, setSelectedEmployees] = useState([])
    const [selectedEmployeeIds, setSelectedEmployeeIds] = useState([])
    const [isShareButtonDisabled, setIsShareButtonDisabled] = useState(true)
    const [filteredEmployees, setFilteredEmployees] = useState([])
    const [sharedCatalog, setSharedCatalog] = useState([])
    const [loading, setLoading] = useState(true)

    const shareCount = selectedEmployees.length

    useEffect(() => {
        // Fetch employees data
        fetchEmployeesData()
    }, [])

    useEffect(() => {
        const sharedData = async () => {
            let jwtToken
            let tokenRefreshError = false

            try {
                const session = await Auth.currentSession()
                jwtToken = session.idToken.jwtToken
            } catch (e) {
                try {
                    const session = await Auth.currentSession()
                    jwtToken = session.idToken.jwtToken
                } catch (e) {
                    //console.log("Error refreshing token", e);
                    tokenRefreshError = true
                    // Allow to go through without jwtToken for server requests not requiring an authenticated user.
                }
            }

            const headers = {
                authorization: jwtToken,
                accept: 'application/json',
            }
            // This adds a header for selected organization_ID or _Key which is stored in local storage
            if (isAdminOnly() || isMultipleOrganization()) {
                const organization =
                    localStorage['hw.' + window.btoa('organization')]
                if (organization && window.atob(organization)) {
                    headers['HW-Organization'] = window.atob(organization)
                }
            }
            const options = {
                method: 'GET',
                headers: headers,
            }

            const url = `${process.env.REACT_APP_API_ENDPOINT}/evaluations/${evaluation?.evaluation?.eval_id}`

            // console.log("url====> ",url);
            try {
                const response = await fetch(url, options)
                if (response.ok) {
                    const data = await response.json()

                    // Update user_id to key in the fetched data
                    const updatedData =
                        data?.evaluation?.shared_with_users?.map((emp) => ({
                            ...emp,
                            key: emp.user_id,
                        }))

                    // Set the updated shared catalog data
                    setSharedCatalog(updatedData)

                    // Update selectedEmployeeIds with the updated key values
                    if (updatedData) {
                        const sharedEmployeeKeys = updatedData.map(
                            (emp) => emp.key
                        )
                        setSelectedEmployeeIds((prevState) => [
                            ...prevState,
                            ...sharedEmployeeKeys,
                        ])

                        console.log('~~~~~~~~~~~~~~~~~~~~~~~', updatedData)
                    }
                } else {
                    // Handle the error if the response is not ok
                    console.log('Request failed with status:', response.status)
                    return null
                }
            } catch (error) {
                // Handle any network or other errors
                console.error('Error:', error)
            } finally {
                setLoading(false)
            }
        }
        sharedData()
    }, [])

    useEffect(() => {
        // Filter employees based on search query
        const filtered = names.filter((employee) =>
            employee.name.toLowerCase().includes(searchValue.toLowerCase())
        )
        setFilteredEmployees(filtered)
    }, [searchValue, names])

    const handleSearchChange = (event) => {
        setSearchValue(event.target.value)
    }

    useEffect(() => {
        // Check if any checkbox is selected to enable/disable the Share button
        setIsShareButtonDisabled(selectedEmployees.length === 0)
    }, [selectedEmployees])

    useEffect(() => {
        // Initialize selected employees based on sharedCatalog
        initializeSelectedEmployees()
    }, [sharedCatalog])

    const initializeSelectedEmployees = () => {
        const initialSelectedEmployees = sharedCatalog.map((employee) => ({
            ...employee,
            checked: true,
        }))
        setSelectedEmployees(initialSelectedEmployees)
        setSelectedEmployeeIds(initialSelectedEmployees.map((emp) => emp.key))
    }

    const handleCheckboxChange = (event, employee) => {
        const isChecked = event.target.checked

        if (isChecked) {
            // Check if the employee is already in the selectedEmployees state
            const isAlreadySelected = selectedEmployees.some(
                (emp) => emp.key === employee.key
            )

            if (!isAlreadySelected) {
                // Add the selected employee to the array with checked property
                setSelectedEmployees((prevState) => [
                    ...prevState,
                    { ...employee, checked: true },
                ])
                setSelectedEmployeeIds((prevState) => [
                    ...prevState,
                    employee.key,
                ])
            }
        } else {
            // Remove the unselected employee from the array
            setSelectedEmployees((prevState) =>
                prevState.filter((emp) => emp.key !== employee.key)
            )
            setSelectedEmployeeIds((prevState) =>
                prevState.filter((id) => id !== employee.key)
            )
        }
    }

    const handleShareCatalog = () => {
        // Access the selected names and IDs from selectedEmployees array
        selectedEmployees.forEach((employee) => {
            const { key, name } = employee
            console.log(`Selected ID: ${key}, Name: ${name}`)
            // Add your logic to share the catalog with these employees
            console.log('SELECTED EMPLOYEEEEEE===========>', selectedEmployees)
        })

        selectedEmployeeIds.forEach((id) => {
            console.log('Selected ID==============:', selectedEmployeeIds)
            // Add your logic to share the catalog with these employee IDs
        })

        // Clear the selected employees array if needed
        //setSelectedEmployees([]);

        handleLoading(true)
        handleShareCatalogToEndpoint()
        handleClose()
    }

    // Employees call
    const fetchEmployeesData = async () => {
        let jwtToken
        let tokenRefreshError = false

        try {
            const session = await Auth.currentSession()
            jwtToken = session.idToken.jwtToken
        } catch (e) {
            try {
                const session = await Auth.currentSession()
                jwtToken = session.idToken.jwtToken
            } catch (e) {
                console.log('Error refreshing token', e)
                tokenRefreshError = true
                // Allow to go through without jwtToken for server requests not requiring an authenticated user.
            }
        }

        const headers = {
            authorization: jwtToken,
            accept: 'application/json',
        }
        // This adds a header for selected organization_ID or _Key which is stored in local storage
        if (isAdminOnly() || isMultipleOrganization()) {
            const organization =
                localStorage['hw.' + window.btoa('organization')]
            if (organization && window.atob(organization)) {
                headers['HW-Organization'] = window.atob(organization)
            }
        }
        const options = {
            method: 'GET',
            headers: headers,
        }

        const url = `${process.env.REACT_APP_API_ENDPOINT_V3}/all_users`

        try {
            // Make the API call
            const response = await fetch(url, options)

            // Check if the response is successful (status code 2xx)
            if (response.ok) {
                // Parse the JSON response
                const employeeData = await response.json()
                console.log('=========Employeeeees API response:', employeeData)
                // Handle the data as needed

                // Create an empty array to store names
                const EMPLOYEE_NAME = []

                // Loop through the employeesData and extract names
                employeeData.forEach((employee) => {
                    EMPLOYEE_NAME.push(employee.name)
                })

                console.log(
                    'EMPLOYEES_NAMES ================================++>>>>>>>>>>>>:',
                    EMPLOYEE_NAME
                )
                // Filter out the primary_contact_id from the employeeData
                const filteredEmployeeData = employeeData.filter(
                    (employee) =>
                        employee.key !== evaluation?.primary_contact_id
                )

                //console.log("Filtered Employee Data:", filteredEmployeeData);

                // Set the filtered employee data in the state
                setNames(filteredEmployeeData)
            } else {
                console.error(
                    'API request failed with status:',
                    response.status
                )
                // Handle the error as needed
            }
        } catch (error) {
            console.error('Error occurred during API request:', error)
            // Handle the error as needed
        }
    }

    const employessData = {
        user_keys: selectedEmployeeIds.map((id) => `${id}`),
    }

    const handleShareCatalogToEndpoint = async () => {
        try {
            await saveCatalogSharing(employessData, eval_id)
            console.log('SUCESSSSSSSSSSSSSSSS')
            handleLoading(false)
            handlesShowSuccessModal(true)
        } catch (e) {
            console.log('Error Please============>> Error:', e)
        }
    }

    useEffect(() => {
        console.log('TEST DATA TO SEND TO IMS', employessData)
    }, [evaluation, employessData])

    useEffect(() => {
        console.log('Eval!!!!!!!!!~~~~~~~~~~~~~~~~~~~~~~', evaluation)
    }, [])

    return (
        <Modal
            className={classes.modal}
            open={open}
            onClose={handleClose}
            closeAfterTransition
        >
            <Paper className={classes.paper}>
                <div className={classes.header}>
                    {/* <Typography variant="h6">Select Names</Typography> */}
                </div>
                <div className={classes.searchInput}>
                    <IconButton>
                        <SearchIcon
                            style={{
                                backgroundColor: '#4f2682',
                                borderRadius: '50%',
                                padding: 10,
                                color: 'white',
                                fontSize: 20,
                            }}
                        />
                    </IconButton>
                    <input
                        type="text"
                        placeholder="Search names..."
                        value={searchValue}
                        onChange={handleSearchChange}
                        style={{
                            border: 'none',
                            outline: 'none',
                            width: '100%',
                            backgroundColor: '#ede8f3',
                            fontSize: '15px',
                        }}
                    />
                </div>
                <div className={classes.listContainer}>
                    {loading ? (
                        <ContentLoader
                            viewBox="0 0 400 150"
                            height={400}
                            width={600}
                        >
                            <circle cx="10" cy="20" r="8" />
                            <rect
                                x="25"
                                y="15"
                                rx="5"
                                ry="5"
                                width="220"
                                height="10"
                            />
                            <circle cx="10" cy="50" r="8" />
                            <rect
                                x="25"
                                y="45"
                                rx="5"
                                ry="5"
                                width="220"
                                height="10"
                            />
                            <circle cx="10" cy="80" r="8" />
                            <rect
                                x="25"
                                y="75"
                                rx="5"
                                ry="5"
                                width="220"
                                height="10"
                            />
                            <circle cx="10" cy="110" r="8" />
                            <rect
                                x="25"
                                y="105"
                                rx="5"
                                ry="5"
                                width="220"
                                height="10"
                            />
                            <circle cx="10" cy="20" r="8" />
                            <rect
                                x="25"
                                y="15"
                                rx="5"
                                ry="5"
                                width="220"
                                height="10"
                            />
                            <circle cx="10" cy="50" r="8" />
                            <rect
                                x="25"
                                y="45"
                                rx="5"
                                ry="5"
                                width="220"
                                height="10"
                            />
                            <circle cx="10" cy="80" r="8" />
                            <rect
                                x="25"
                                y="75"
                                rx="5"
                                ry="5"
                                width="220"
                                height="10"
                            />
                            <circle cx="10" cy="110" r="8" />
                            <rect
                                x="25"
                                y="105"
                                rx="5"
                                ry="5"
                                width="220"
                                height="10"
                            />
                            <circle cx="10" cy="20" r="8" />
                            <rect
                                x="25"
                                y="15"
                                rx="5"
                                ry="5"
                                width="220"
                                height="10"
                            />
                            <circle cx="10" cy="50" r="8" />
                            <rect
                                x="25"
                                y="45"
                                rx="5"
                                ry="5"
                                width="220"
                                height="10"
                            />
                            <circle cx="10" cy="80" r="8" />
                            <rect
                                x="25"
                                y="75"
                                rx="5"
                                ry="5"
                                width="220"
                                height="10"
                            />
                            <circle cx="10" cy="110" r="8" />
                            <rect
                                x="25"
                                y="105"
                                rx="5"
                                ry="5"
                                width="220"
                                height="10"
                            />
                        </ContentLoader>
                    ) : (
                        <>
                            {/* Display initially shared employees at the top */}
                            {selectedEmployees.map((employee) => (
                                <div
                                    key={employee.key}
                                    className={classes.listItem}
                                >
                                    <Checkbox
                                        classes={{
                                            checked: classes.checkedStyle,
                                        }}
                                        className={classes.checkboxStyle}
                                        checked={employee.checked}
                                        onChange={(event) =>
                                            handleCheckboxChange(
                                                event,
                                                employee
                                            )
                                        }
                                    />
                                    <div className={classes.nameContainer}>
                                        <Typography>{employee.name}</Typography>
                                        <Typography
                                            className={classes.emailText}
                                        >
                                            {employee.email}
                                        </Typography>
                                    </div>
                                </div>
                            ))}
                            {/* Divider line between initially shared and remaining employees */}
                            {!isShareButtonDisabled && (
                                <Divider className={classes.divider} />
                            )}
                            {/* Display remaining employees */}
                            {filteredEmployees
                                .filter(
                                    (employee) =>
                                        !selectedEmployees.some(
                                            (emp) => emp.key === employee.key
                                        )
                                )
                                .map((employee) => (
                                    <div
                                        key={employee.key}
                                        className={classes.listItem}
                                    >
                                        <Checkbox
                                            color="secondary"
                                            checked={selectedEmployeeIds.includes(
                                                employee.key
                                            )}
                                            onChange={(event) =>
                                                handleCheckboxChange(
                                                    event,
                                                    employee
                                                )
                                            }
                                        />
                                        <div className={classes.nameContainer}>
                                            <Typography>
                                                {employee.name}
                                            </Typography>
                                            <Typography
                                                className={classes.emailText}
                                            >
                                                {employee.email}
                                            </Typography>
                                        </div>
                                    </div>
                                ))}
                        </>
                    )}
                </div>
                <div className={classes.footer}>
                    <div className={classes.shareCounter}>
                        <img
                            src={SHARE_USER}
                            alt="Share With"
                            className={classes.icon}
                        />
                        <div>
                            <div
                                style={{
                                    color: 'grey',
                                    fontSize: '15px',
                                    fontWeight: 'bold',
                                }}
                            >
                                Share with
                            </div>
                            <div
                                style={{ color: 'grey', fontSize: '12px' }}
                            >{`${shareCount} employees selected`}</div>
                        </div>
                    </div>
                    <Button
                        disabled={isShareButtonDisabled}
                        style={{
                            backgroundColor: isShareButtonDisabled
                                ? '#ccc'
                                : '#4f2682',
                            width: '150px',
                            color: 'white',
                        }}
                        onClick={handleShareCatalog}
                        className={
                            isShareButtonDisabled ? classes.disabledButton : ''
                        }
                    >
                        Share Catalog
                    </Button>
                </div>
            </Paper>
        </Modal>
    )
}

export default ShareModal
