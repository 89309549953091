import React from 'react';
import { withStyles } from '@material-ui/core/styles';
const styles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(3)
  },
  svg: {
    width: 120,
    height: 120,
    marginBottom: theme.spacing(2)
  },
  message: {
    marginTop: theme.spacing(2),
    color: '#666',
    fontSize: '14px',
    fontWeight: 500
  }
});

const NoDataSVG = ({ classes, searchActive }) => {
  return (
    <div className={classes.container}>
      <svg 
        className={classes.svg} 
        viewBox="0 0 100 100" 
        xmlns="http://www.w3.org/2000/svg"
      >
        {/* Stylized empty table/document */}
        <rect x="20" y="20" width="60" height="60" rx="2" fill="#f5f5f5" stroke="#ccc" strokeWidth="2" />
        
        {/* Header line */}
        <line x1="20" y1="30" x2="80" y2="30" stroke="#ccc" strokeWidth="2" />
        
        {/* Table lines */}
        <line x1="35" y1="40" x2="65" y2="40" stroke="#eee" strokeWidth="1.5" strokeDasharray="2" />
        <line x1="35" y1="50" x2="65" y2="50" stroke="#eee" strokeWidth="1.5" strokeDasharray="2" />
        <line x1="35" y1="60" x2="65" y2="60" stroke="#eee" strokeWidth="1.5" strokeDasharray="2" />
        <line x1="35" y1="70" x2="65" y2="70" stroke="#eee" strokeWidth="1.5" strokeDasharray="2" />
        
        {/* Circular "no data" symbol */}
        <circle cx="50" cy="50" r="15" fill="none" stroke="#999" strokeWidth="2" />
        <line x1="40" y1="50" x2="60" y2="50" stroke="#999" strokeWidth="2" />
      </svg>
      
      <div className={classes.message}>
        {searchActive ? "No catalogs found matching your search" : "No data available"}
      </div>
    </div>
  );
};

export default withStyles(styles)(NoDataSVG);