import React from 'react'
import RadioGroup from '@material-ui/core/RadioGroup'
import FTRadioItem, { RadioItemProps } from './FTRadioItem'
import { makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles<Theme>((theme) => ({
    radioGroup: {
        display: 'flex',
        flexDirection: 'row',
        marginLeft: 16,
    },
}))
interface Props {
    name?: string
    onChange: () => void
    defaultValue?: any[]
    value?: any[]
    items: RadioItemProps[]
}

const FTRadioGroup: React.FC<Props> = ({
    name,
    defaultValue,
    value,
    items,
    onChange,
}) => {
    const classes = useStyles()
    return (
        <RadioGroup
            name={name}
            defaultValue={defaultValue}
            value={value}
            onChange={onChange}
            className={classes.radioGroup}
        >
            {items.map((item) => (
                <FTRadioItem
                    key={item.value}
                    value={item.value}
                    title={item.title}
                    tooltipTitle={item.tooltipTitle}
                />
            ))}
        </RadioGroup>
    )
}

export { FTRadioGroup }
