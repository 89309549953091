// import React, { useContext } from 'react';
// import { IMSContext } from './IMSformCategtorySelect';

// const withSelectedCategory = (WrappedComponent) => {
//   return (props) => {
//     const { selectedCategory } = useContext(IMSContext);
//     return <WrappedComponent {...props} selectedCategory={selectedCategory} />;
//   };
// };

// export default withSelectedCategory;

import React, { useContext } from 'react'
import { IsTabClickedContext } from './AssetPropertiesDrawer'

const withIsTabClicked = (WrappedComponent) => {
    return (props) => {
        const { isTabClicked } = useContext(IsTabClickedContext)
        return <WrappedComponent {...props} isTabClicked={isTabClicked} />
    }
}

export default withIsTabClicked
