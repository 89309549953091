import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import withStyles from '@material-ui/core/styles/withStyles'
import PropTypes from 'prop-types'
import React, { Component, Fragment } from 'react'
import FHGTypography from '../../../fhg/components/Typography'

const styles = (theme) => ({
    titleStyle: {
        margin: theme.spacing(1, 2, 0.5, 2),
    },
    inputStyle: {
        margin: theme.spacing(0, 0, 2, 1),
    },
    selectStyle: {
        '& svg': {
            fill: theme.palette.environment.light.level3.base, //'#E6E3DC'
        },
    },
    select: {
        background: 'transparent',
        '&:focus': {
            background: 'transparent',
        },
    },
    itemStyle: {
        color: `${theme.palette.content.default} !important`,
    },
    linkButton: {
        composes: 'no-print',
        zIndex: 1001,
        marginLeft: theme.spacing(1),
        color: theme.palette.button.standard.secondary, //'#5D788F',
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(0.5, 0.5),
        },
    },
    linkLabel: {
        color: '#4f2682 !important',
    },
    menuItemStyle: {
        paddingLeft: 16,
    },
})

export const HEAVYWORTH_VALUATION_TYPE = 'HeavyWorth'
export const INTERNAL_VALUATION_TYPE = 'Internal'
export const PROFILE_VALUATION_TYPE = 'Profile'
export const SELF_VALUATION_TYPE = 'Self'
export const NONE_VALUATION_TYPE = 'None'

export const valuationTypeOptions = {
    [HEAVYWORTH_VALUATION_TYPE]: 'valuationType.heavyWorth.label',
    [INTERNAL_VALUATION_TYPE]: 'valuationType.internal.label',
    [PROFILE_VALUATION_TYPE]: 'valuationType.profile.label',
    [SELF_VALUATION_TYPE]: 'valuationType.self.label',
    [NONE_VALUATION_TYPE]: 'valuationType.none.label',
}

/**
 * Valuation Type menu component to select the valuation type for the selected assets.
 */
class ValuationTypeMenu extends Component {
    static propTypes = {
        onSelectValuationType: PropTypes.func.isRequired, //Callback when a valuation type is selected.
    }

    constructor(props, context) {
        super(props, context)

        this.state = {
            isMenuOpen: props.open,
            select: undefined,
        }
    }

    /**
     * Close the menu.
     */
    handleClose = () => {
        this.setState({ anchorEl: null, isMenuOpen: false })
    }

    /**
     * Called when the user presses a key. If the escape key is pressed, close the edit for subpackage. If the enter key
     * is pressed enter the newly created supbackage.
     *
     * @param event The key down event.
     */
    handleKeydown = (event) => {
        event.stopPropagation()

        if (event.key === 'Escape') {
            event.preventDefault()
            this.handleClose()
        }
    }

    /**
     * Handle the change to the selected menu item.
     *
     * @param valuationType The subpackage for the menu item.
     * @return {function(...[*]=)} The function for the menu item.
     */
    handleChange = (valuationType) => (event) => {
        const { onSelectValuationType } = this.props
        this.setState({ anchorEl: null, isMenuOpen: false }, () => {
            onSelectValuationType && onSelectValuationType(valuationType)
        })
    }

    /**
     * When the Subpackage menu button is clicked, open the menu.
     *
     * @param event The click event.
     */
    onClick = (event) => {
        event.preventDefault()
        event.stopPropagation()
        this.setState({ isMenuOpen: true, anchorEl: event.currentTarget })
    }

    render() {
        const { classes, ...attributes } = this.props
        const { isMenuOpen, anchorEl } = this.state

        return (
            <Fragment>
                <Button
                    className={classes.linkButton}
                    variant={'text'}
                    onClick={this.onClick}
                    disableRipple
                >
                    <FHGTypography
                        className={classes.linkLabel}
                        variant="button"
                        color={'inherit'}
                        id={'valuationType.placeholder.label'}
                    />
                </Button>
                <Menu
                    open={isMenuOpen}
                    className={classes.selectStyle}
                    onClose={this.handleClose}
                    anchorEl={anchorEl}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                    MenuListProps={{ disablePadding: true }}
                    {...attributes}
                >
                    <FHGTypography
                        className={`nonessential-title-caps ${classes.titleStyle}`}
                        id={'valuationType.title'}
                    />
                    {Object.entries(valuationTypeOptions).map(
                        ([key, value]) => (
                            <MenuItem
                                className={classes.menuItemStyle}
                                value={key}
                                onClick={this.handleChange(key)}
                            >
                                <FHGTypography
                                    className={`label-text ${classes.itemStyle}`}
                                    id={value}
                                    values={{
                                        b: (...chunks) => <b>{chunks}</b>,
                                    }}
                                />
                            </MenuItem>
                        )
                    )}
                </Menu>
            </Fragment>
        )
    }
}

export default withStyles(styles)(ValuationTypeMenu)
