import FormControl from '@material-ui/core/FormControl'
import Input from '@material-ui/core/Input'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import makeStyles from '@material-ui/core/styles/makeStyles'
import React, { useState, useEffect } from 'react'
import FHGTypography from '../../../fhg/components/Typography'

export const ALL_SUBPACKAGE_FILTER = 'All Subpackages'

const useStyles = makeStyles(
    (theme) => ({
        titleStyle: {
            fontSize: '14px !important',
            fontWeight: 500,
            lineHeight: '17px',
            textAlign: 'center',
            color: theme.palette.content.default,
            // color: theme.palette.secondary.contrastText,
            textTransform: 'none',
            whiteSpace: 'nowrap',
            [theme.breakpoints.down('xs')]: {
                whiteSpace: 'wrap',
            },
        },
        titleStyle2: {
            margin: theme.spacing(1, 2, 0.5, 2),
        },
        formControl: {
            color: 'inherit',
            backgroundColor: 'transparent',
            '&:focus': {
                backgroundColor: 'transparent',
            },
        },
        select: {
            background: 'transparent',
            '&:focus': {
                background: 'transparent',
            },
        },
        selectStyle: {
            // color: 'blue',
            color: theme.palette.content.default,
            '& svg': {
                fill: theme.palette.content.default,
            },
        },
        menuText: {
            color: `${theme.palette.content.default} !important`,
            display: 'inline',
            whiteSpace: 'normal',
        },
    }),
    'SubpackageFilterMenuStyles'
)

/**
 * SubpackageMenu component to show the existing subpackages for the catalog..
 */
export default function SubPackageFilterMenu({ subpackages = [], onFilter }) {
    const classes = useStyles()

    const [selectedSubpackage, setSelectedSubpackage] = useState(
        localStorage.subpackage &&
            subpackages.indexOf(localStorage.subpackage) >= 0
            ? localStorage.subpackage
            : ALL_SUBPACKAGE_FILTER
    )

    useEffect(() => {
        if (localStorage.subpackage && subpackages.length > 0) {
            setSelectedSubpackage(
                localStorage.subpackage &&
                    subpackages.indexOf(localStorage.subpackage) >= 0
                    ? localStorage.subpackage
                    : ALL_SUBPACKAGE_FILTER
            )
        }
    }, [subpackages])

    /**
     * Handle changes to the organization.
     * @param event The change event.
     */
    const handleChange = (event) => {
        const value = event.target.value
        setSelectedSubpackage(value)
        localStorage.subpackage = value
        onFilter && onFilter(value)
    }

    return (
        <FormControl className={classes.formControl}>
            <Select
                value={selectedSubpackage || ''}
                className={classes.selectStyle}
                classes={{ select: classes.select }}
                displayEmpty={true}
                // renderValue={() => (
                //    <Grid container wrap={'nowrap'} direction={'row'} alignItems={'center'}>
                //       <FHGTypography variant={'button'} className={classes.titleStyle}>
                //          {selectedSubpackage || ALL_SUBPACKAGE_FILTER}
                //       </FHGTypography>
                //    </Grid>
                // )}
                onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                }}
                onChange={handleChange}
                input={
                    <Input
                        name="selectedSubpackage"
                        disableUnderline={true}
                        style={{ marginRight: 8, fontSize: 18 }}
                    />
                }
            >
                <FHGTypography
                    className={`nonessential-title-caps ${classes.titleStyle2}`}
                    id={'subpackage.title'}
                />
                <MenuItem value={ALL_SUBPACKAGE_FILTER}>
                    <FHGTypography
                        className={classes.menuText}
                        color="inherit"
                        variant="body1"
                        id={'subpackage.all.label'}
                    />
                </MenuItem>
                {subpackages.map((subpackage) => (
                    <MenuItem value={subpackage}>
                        <FHGTypography
                            className={classes.menuText}
                            color="inherit"
                            variant="body1"
                        >
                            {subpackage}
                        </FHGTypography>
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}
