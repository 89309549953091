module.exports = {
    'equipmentList.final.estimate.label': 'Purple Wave Value:',
    // eslint-disable-next-line
    'equipmentList.total.label': 'Purple Wave Value Total ',
    'equipmentList.heavyworthValue.label': 'Purple Wave Value',
    'equipmentListEstimate.finalEstimate.label': 'Purple Wave Value',
    'equipmentList.heavyworthValue.column': 'Field Tool',
    'login.title': 'Sign in to PW Field Tool.',
    'admin.deleteOrganization.text': 'Delete {orgName} from Purple Wave',
    'emailSubscribe.subscribe.note':
        'Purple Wave sends out email updates when we release new features. Typically this is\nonce every couple of weeks.',
    'login.supportInstructions':
        'Please call Purple Wave Customer Service at 866-608-9283 or email auction@purplewave.com for all login or bidding questions.',

    // Valuation Types
    'valuationType.heavyWorth.label': 'Purple Wave Value (FMV)',
    'valuationType.heavyWorth.short.label': '<b>Purple Wave</b>',

    // Customer Inventory
    'customerInventory.notSentToPurpleWave.status': 'Not Sent to PurpleWave',
}
