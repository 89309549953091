import { Tooltip } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import makeStyles from '@material-ui/core/styles/makeStyles'
import classNames from 'classnames'
import { find } from 'lodash'
import debounce from 'lodash/debounce'
import get from 'lodash/get'
import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef, useMemo } from 'react'
import { useIntl } from 'react-intl'
import 'react-table/react-table.css'
import {
    ESTIMATE_DEBOUNCE_MILLIS,
    THUMBNAIL_SIZE,
    SAVE_EVALUATION,
    SUCCESS_DECORATION_DURATION,
} from '../../../Constants'
import Grid from '../../../fhg/components/Grid'
import Typography from '../../../fhg/components/Typography'
import Feature from '../../../fhg/security/Feature'
import { formatMessage } from '../../../fhg/utils/Utils'
import {
    requestForServer,
    SEE_ESTIMATES,
    getUserData,
} from '../../../Utils/ServerUtil'
import EditableEstimate from '../components/EditableEstimate'
import ErrorSnackbar from '../components/ErrorSnackbar'
import FormattedTextFieldXL from '../components/FormattedTextFieldXL'
import OtherEstimates from '../components/OtherEstimates'
import { fontStyle } from '../components/Styles'
import { getYourEstimate, getConsensusEstimate } from '../EquipmentUtil'

/**
 * Component to show the equipment detail.
 */
const useStyles = makeStyles(
    (theme) => ({
        root: {
            height: '100%',
            backgroundColor: theme.palette.environment.light.level1.base,
            [theme.breakpoints.up('md')]: {
                overflow: 'hidden',
            },
            [theme.breakpoints.down('sm')]: {
                display: 'block',
            },
        },
        mainGrid: {
            flex: '1 1',
            overflow: 'hidden',
            padding: `0 ${theme.spacing(2)}px`,
            backgroundColor: theme.palette.environment.light.level0.base,
        },
        innerGrid: {
            flex: '1 1 auto',
            overflow: 'auto',
        },
        propertyGrid: {
            flex: '0 0 auto',
            overflow: 'auto',
        },
        description: {
            backgroundColor: theme.palette.environment.light.level1.base,
            padding: `0 ${theme.spacing(2)}px`,
            marginLeft: 0,
            [theme.breakpoints.down('xs')]: {
                fontSize: '20px  !important',
                lineHeight: '24px  !important',
            },
        },
        properties: {
            display: 'flex',
            flex: '0 0 auto',
            justifyContent: 'space-between',
        },
        containerFlex: {
            display: 'flex',
            flexDirection: 'column',
        },
        textFieldRoot: {
            padding: 0,
            'label + &': {
                marginTop: theme.spacing(1.5),
            },
            '&.saved:after': {
                color: 'white',
                animation: `$successText ${SUCCESS_DECORATION_DURATION / 1000}s ease-in-out`,
                content: '"Saved"',
                fontSize: `${theme.size.font['item-title']}rem !important`,
                zIndex: 1001,
                position: 'absolute',
                left: theme.spacing(0.5),
                bottom: theme.spacing(1),
            },
        },
        textFieldInput: {
            color: `${theme.palette.text.secondary} !important`,
        },
        estimateBox: {
            flex: '0 0 auto',
            backgroundColor: theme.palette.environment.light.level2.base,
            height: THUMBNAIL_SIZE,
            padding: theme.spacing(2),
            margin: `0 ${theme.spacing(-2)}px`,
        },
        textFieldInputSaved: {
            '&:focus': {
                transition: theme.transitions.create(['border'], {
                    duration: theme.transitions.duration.short,
                }),
                border: `1px solid ${theme.palette.button.standard.success} !important`,
                '&:focus': {
                    border: `1px solid ${theme.palette.button.standard.success} !important`,
                },
            },
        },
        '@keyframes successText': {
            '30%': {
                color: theme.palette.button.standard.success,
            },
            to: {
                color: 'white',
            },
        },
        smallProperty: {
            margin: `0 0 ${theme.spacing(4)}px 0`,
            padding: '0 0px',
        },
        smallPropertyTop: {
            margin: `${theme.spacing(1)}px 0`,
            padding: '0 0px',
        },
        formControl: {
            transform: 'none',
            outline: 'none',
        },
        textInput: {
            outline: 'none',
        },
        topProperty: {
            paddingTop: theme.spacing(2),
        },
        linkLabel: {
            color: '#4f2682 !important', //'#5D788F',
            fontSize: 14,
            padding: theme.spacing(1),
            fontWeight: 'bold',
            whiteSpace: 'nowrap',
        },
        smallTextAllCaps: {
            color: `${theme.palette.content.nonessential} !important`,
            fontSize: `${theme.size.font['item-title-caps']}rem !important`,
            lineHeight: `${theme.size.lineheight['item-title-caps']}rem !important`,
            letterSpacing: `${theme.size.letterspacing['item-title-caps']}rem !important`,
            fontWeight: 'bold !important',
            textTransform: 'uppercase',
        },
        formattedText: {
            minWidth: 92,
            maxWidth: 114,
            '@media print': {
                maxWidth: 110,
            },
            '& input': {
                ...fontStyle(theme, 'numbers', undefined, 0),
            },
        },
        tooltipStyle: {
            marginTop: '4px !important ',
        },
    }),
    { name: 'EquipmentListDetailEstimateStyles' }
)

EquipmentListDetailEstimate.propTypes = {
    evaluation: PropTypes.object.isRequired,
    canEdit: PropTypes.bool, //Indicates if the Estimate can be edited in the panel.
    onRefresh: PropTypes.func,
    showSaved: PropTypes.bool,
}

/**
 * The component to show and edit the estimates and estimate notes.
 */
export default function EquipmentListDetailEstimate({
    evaluation = { estimates: [], item: {} },
    evalItem = {},
    onRefresh,
}) {
    const classes = useStyles()
    const intl = useIntl()

    const [notes, setNotes] = useState(get(evalItem, 'notes', '').trim())

    const [openEstimates, setOpenEstimates] = useState(false)

    const [showError, setShowError] = useState(false)
    const [errorId, setErrorId] = useState()

    useEffect(() => {
        return () => {
            handleNotesSubmit.flush()
        }
    }, [])

    useEffect(() => {
        if (notes !== get(evalItem, 'notes', '').trim()) {
            handleNotesSubmit(notes)
        }
    }, [notes])

    /**
     * Close the error snackbar.
     */
    const handleErrorClose = () => {
        setShowError(false)
    }

    const viewEstimates = () => {
        setOpenEstimates(true)
    }

    /**
     * When the notes change update the state and save changes.
     * @param target
     */
    const onNotesChange = ({ target }) => {
        setNotes(target.value)
    }

    /**
     * Submits the notes changes.
     */
    const handleNotesSubmit = useRef(
        debounce(async (notes) => {
            await saveEvaluation('notes', notes || ' ')
        }, ESTIMATE_DEBOUNCE_MILLIS)
    ).current

    /**
     * Save the value to the key property in the evaluation to the server.
     *
     * @param key The name of the property.
     * @param value The new value of the property to be saved.
     * @param estimateType The type of estimate that is being saved.
     * @return {Promise<void>} The promise for the completion of the save.
     */
    const saveEvaluation = async (key, value, estimateType) => {
        const update = { [key]: value }
        if (estimateType) {
            update['estimate_type_name'] = estimateType.name
        }
        try {
            onRefresh && onRefresh(true, false)
            evalItem[key] = value

            await requestForServer(
                SAVE_EVALUATION.format({
                    uri: evaluation.uri,
                    itemId: get(evalItem, 'item.item_id'),
                }),
                'put',
                update
            )
            handleAfterSave(false, false)
        } catch (e) {
            handleAfterSave(false, true)
            setShowError(true)
            setErrorId('equipmentList.save.error')
        }
    }

    /**
     * Save the value to the key property in the evaluation to the server.
     *
     * @param isSaving Indicates if the item is being saved.
     * @param isError Indicates if an error has occurred saving the item.
     */
    const handleAfterSave = (isSaving, isError) => {
        onRefresh && onRefresh(isSaving, isError)
    }

    const onBlur = (debounceFunction) => () => {
        debounceFunction.flush()
    }

    const estimateTypes = getUserData().estimateTypes
    const estimate =
        useMemo(
            () =>
                find(get(evalItem, 'estimates', []), {
                    user_uuid: getUserData().uuid,
                }),
            [evalItem]
        ) || {}

    return (
        <Grid
            container
            direction={'column'}
            className={classes.root}
            wrap={'nowrap'}
        >
            {showError && (
                <ErrorSnackbar
                    open={showError}
                    onClose={handleErrorClose}
                    errorId={errorId}
                />
            )}
            <Grid
                container
                className={classes.mainGrid}
                direction={'column'}
                spacing={0}
                justify={'space-between'}
                wrap={'nowrap'}
            >
                <Grid
                    container
                    direction={'column'}
                    spacing={0}
                    className={classes.innerGrid}
                >
                    <Grid
                        container
                        direction={'column'}
                        spacing={0}
                        className={classes.propertyGrid}
                        fullWidth={false}
                    >
                        {estimateTypes &&
                            estimateTypes.length > 0 &&
                            estimateTypes.map((estimateType, index) => {
                                if (estimateType.active) {
                                    const estimate = getYourEstimate(
                                        evalItem,
                                        estimateType
                                    ).value
                                    const estimateName =
                                        'Your' + estimateType.name
                                    const consensusName = estimateType.name
                                    return (
                                        <Grid
                                            container
                                            direction={'column'}
                                            fullWidth={false}
                                            className={classNames(
                                                classes.properties,
                                                classes.topProperty
                                            )}
                                            spacing={0}
                                        >
                                            {estimateType.enable_workflow_columns && (
                                                <Grid
                                                    container
                                                    className={
                                                        classes.smallPropertyTop
                                                    }
                                                    direction={'column'}
                                                    spacing={0}
                                                    fullWidth={false}
                                                >
                                                    <Grid item>
                                                        <Tooltip
                                                            title={
                                                                estimateType.tooltip ||
                                                                ''
                                                            }
                                                            placement={
                                                                'bottom-start'
                                                            }
                                                            classes={{
                                                                tooltip:
                                                                    classes.tooltipStyle,
                                                            }}
                                                        >
                                                            <div>
                                                                <Typography
                                                                    color={
                                                                        'textSecondary'
                                                                    }
                                                                    className={
                                                                        classes.smallTextAllCaps
                                                                    }
                                                                    id={
                                                                        'equipmentListEstimate.yourEstimate.label'
                                                                    }
                                                                    values={
                                                                        estimateType
                                                                    }
                                                                >
                                                                    Your
                                                                    Estimate
                                                                </Typography>
                                                            </div>
                                                        </Tooltip>
                                                    </Grid>
                                                    <Grid item>
                                                        <EditableEstimate
                                                            key={
                                                                evalItem.item_id +
                                                                estimateName
                                                            }
                                                            estimateType={
                                                                estimateType
                                                            }
                                                            inputName={`${estimateName} ${index}`}
                                                            value={estimate}
                                                            evalItem={evalItem}
                                                            intl={intl}
                                                            isConsensus={false}
                                                            evaluation={
                                                                evaluation
                                                            }
                                                            onSave={
                                                                handleAfterSave
                                                            }
                                                            disabled={
                                                                !evalItem.PK
                                                            }
                                                            marginRight={'auto'}
                                                            allowButton={false}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            )}
                                            <Grid
                                                container
                                                className={
                                                    classes.smallPropertyTop
                                                }
                                                direction={'column'}
                                                spacing={0}
                                            >
                                                <Grid item>
                                                    <Tooltip
                                                        title={
                                                            estimateType.tooltip ||
                                                            ''
                                                        }
                                                        placement={
                                                            'bottom-start'
                                                        }
                                                        classes={{
                                                            tooltip:
                                                                classes.tooltipStyle,
                                                        }}
                                                    >
                                                        <div>
                                                            <Typography
                                                                color={
                                                                    'textSecondary'
                                                                }
                                                                className={
                                                                    classes.smallTextAllCaps
                                                                }
                                                            >
                                                                {
                                                                    estimateType.display_name
                                                                }
                                                            </Typography>
                                                        </div>
                                                    </Tooltip>
                                                </Grid>
                                                <Grid item>
                                                    <EditableEstimate
                                                        key={
                                                            'consensus' +
                                                            evalItem.item_id +
                                                            estimateName
                                                        }
                                                        estimateType={
                                                            estimateType
                                                        }
                                                        inputName={`${consensusName} ${index}`}
                                                        value={
                                                            getConsensusEstimate(
                                                                evalItem,
                                                                estimateType
                                                            ).value || 0
                                                        }
                                                        evalItem={evalItem}
                                                        intl={intl}
                                                        isConsensus={true}
                                                        evaluation={evaluation}
                                                        onSave={handleAfterSave}
                                                        disabled={!evalItem.PK}
                                                        marginRight={'auto'}
                                                        allowButton={false}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )
                                }
                            })}
                        <Feature feature={SEE_ESTIMATES}>
                            <Grid item className={classes.smallPropertyTop}>
                                <Typography
                                    color={'textSecondary'}
                                    className={classes.smallTextAllCaps}
                                    id={
                                        'equipmentListEstimate.otherEstimate.label'
                                    }
                                >
                                    Other Estimates
                                </Typography>
                                {!openEstimates ? (
                                    <Button
                                        className={classes.linkLabel}
                                        variant={'text'}
                                        onClick={viewEstimates}
                                        color="secondary"
                                        disableRipple={true}
                                        disabled={!estimate || !evalItem.PK}
                                    >
                                        <Typography
                                            color="inherit"
                                            variant="button"
                                            id={
                                                'equipmentListEstimate.viewOther.button'
                                            }
                                        >
                                            View Other Estimates
                                        </Typography>
                                    </Button>
                                ) : (
                                    <OtherEstimates
                                        average={evalItem.mean_estimate}
                                        estimates={evalItem.estimates}
                                    />
                                )}
                            </Grid>
                        </Feature>
                        <Grid
                            container
                            className={classes.smallProperty}
                            direction={'column'}
                            spacing={0}
                        >
                            <Typography
                                color={'textSecondary'}
                                className={classes.smallTextAllCaps}
                                id={
                                    'equipmentListEstimate.evaluationNotes.label'
                                }
                            >
                                Evaluation Notes
                            </Typography>
                            <FormattedTextFieldXL
                                value={notes}
                                onChange={onNotesChange}
                                onBlur={onBlur(handleNotesSubmit)}
                                multiline={true}
                                rows={4}
                                disabled={!evalItem.PK}
                                placeholder={formatMessage(
                                    intl,
                                    'equipmentDetail.none.label',
                                    'None'
                                )}
                                inputProps={{
                                    className: classNames(
                                        'default-text-small',
                                        classes.textFieldInput
                                    ),
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}
