import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import withStyles from '@material-ui/core/styles/withStyles'
import MoreVerticalIcon from '@material-ui/icons/MoreVertOutlined'
import PropTypes from 'prop-types'
import React, { Fragment, PureComponent } from 'react'
import Typography from '../../../fhg/components/Typography'
import PhotoCategorySelect from './PhotoCategorySelect'
import NotFeature from '../../../fhg/security/NotFeature'
import { CUSTOMER_ENV } from '../../../Utils/ServerUtil'
import get from 'lodash/get'
import { reSumbmitImageToIms } from '../../../Utils/SubmitUtil'
import Alert from '@mui/material/Alert'
import Snackbar from '@mui/material/Snackbar'
import CircularProgress from '@material-ui/core/CircularProgress'
import { posthogCapture } from '../../../PostHogFunction'
import eventCapture from '../../../eventList'

export const MOVE_FIRST = 'first'
export const MOVE_PREVIOUS = 'previous'
export const MOVE_NEXT = 'next'
export const MOVE_LAST = 'last'

const styles = (theme) => ({
    moreColor: {
        color: 'black',
    },
    spinnerMargin: {
        marginLeft: theme.spacing(0.5),
    },
    moreIconStyle: {
        backgroundColor: 'rgba(255, 255, 255, 0.4)',
        '@supports not (-ms-high-contrast: none)': {
            filter: 'drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.2))',
            '-webkit-filter': 'drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.2))',
        },
        padding: theme.spacing(0.5),
        width: 40,
        height: 40,
        marginRight: theme.spacing(1),
        marginTop: theme.spacing(1) - 2,
        '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.6)',
        },
    },
})

/**
 * The More Menu for thumbnail photos.
 */
class PhotoMoreMenu extends PureComponent {
    static propTypes = {
        onDeletePhoto: PropTypes.func,
        onRefresh: PropTypes.func,
        onMovePhoto: PropTypes.func,
        isLast: PropTypes.bool,
        isFirst: PropTypes.bool,
        onSortAllPhotos: PropTypes.func,
        evalItem: PropTypes.object,
        index: PropTypes.object,
    }

    state = {
        anchorEl: undefined,
        showCategorySelect: false,
        successAlertVisible: false,
        errorAlertVisible: false,
        isExporting: false,
    }

    /**
     * When the More Menu icon is clicked, open the menu.
     * @param event The click event.
     */
    onClick = (event) => {
        event.preventDefault()
        event.stopPropagation()
        this.setState({ anchorEl: event.currentTarget })
    }

    /**
     * When the More Menu is closed.
     */
    onClose = () => {
        this.setState({ anchorEl: null })
    }

    /**
     * When the delete photo menu item is selected, call the prop to delete the photo.
     */
    handleDeletePhoto = (event) => {
        event.preventDefault()
        event.stopPropagation()
        this.setState({ anchorEl: null }, this.props.onDeletePhoto)
    }

    /**
     * When a move photo menu item is selected, call the prop to move the photo.
     */
    handleMovePhoto = (action) => (event) => {
        event.preventDefault()
        event.stopPropagation()
        this.setState({ anchorEl: null }, () => this.props.onMovePhoto(action))
    }

    /**
     * When a sort all photos menu item is selected, call the prop to sort the photos.
     */
    handleSortAllPhotos = (event) => {
        event.preventDefault()
        event.stopPropagation()
        this.setState({ anchorEl: null }, this.props.onSortAllPhotos)
    }

    handleImageResend = async () => {
        this.setState({ isExporting: true })
        const { evalItem, index } = this.props
        const itemId = get(evalItem, 'item_id', get(evalItem, 'PK'))
        const imageId = get(
            evalItem,
            `image_data[${index}].image_id`,
            get(evalItem, `image_data[${index}].original_filename`)
        )
        posthogCapture(eventCapture.RESUBMIT_IMAGE)
        try {
            await reSumbmitImageToIms(itemId, imageId)
            this.setState({ successAlertVisible: true })
        } catch (e) {
            alert('ITEM RESUBMIT ERROR ERROR')
            this.setState({ errorAlertVisible: true })
        } finally {
            this.setState({ isExporting: false })
        }
    }

    handleCategorizePhoto = (event) => {
        this.setState({ anchorEl: null, showCategorySelect: true })
    }

    closeCategorizePhoto = (event) => {
        this.setState({ showCategorySelect: false })
    }

    render() {
        const { classes, image, onRefresh, isFirst, isLast } = this.props
        const {
            anchorEl,
            showCategorySelect,
            successAlertVisible,
            errorAlertVisible,
            isExporting,
        } = this.state

        const open = Boolean(anchorEl)

        return (
            <Fragment>
                <div>
                    {/* Show the Snackbar for success */}
                    <Snackbar
                        open={successAlertVisible}
                        autoHideDuration={10000}
                        onClose={() =>
                            this.setState({ successAlertVisible: false })
                        }
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    >
                        <Alert severity="success" sx={{ width: '100%' }}>
                            Image Successfully Resubmited to IMS!
                        </Alert>
                    </Snackbar>
                    {/* Show the Snackbar for error */}
                    <Snackbar
                        open={errorAlertVisible}
                        autoHideDuration={10000}
                        onClose={() =>
                            this.setState({ errorAlertVisible: false })
                        }
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    >
                        <Alert severity="error" sx={{ width: '100%' }}>
                            ImageResubmition Failed, kindly check your internet
                            or refresh the page!
                        </Alert>
                    </Snackbar>
                    {isExporting && (
                        <CircularProgress
                            className={classes.spinnerMargin}
                            size={50}
                            thickness={5}
                        />
                    )}
                </div>
                {showCategorySelect && (
                    <PhotoCategorySelect
                        onClose={this.closeCategorizePhoto}
                        images={image}
                        onRefresh={onRefresh}
                    />
                )}
                <IconButton
                    className={`${classes.moreIconStyle} no-print`}
                    aria-label="More"
                    aria-owns={open ? 'long-menu' : null}
                    aria-haspopup="true"
                    onClick={this.onClick}
                    style={{ zIndex: 1002, color: 'black' }}
                >
                    <MoreVerticalIcon />
                </IconButton>
                <Menu
                    id="long-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={this.onClose}
                >
                    <MenuItem onClick={this.handleImageResend}>
                        <Typography
                            color="inherit"
                            variant="body1"
                            id={'photoDetail.resendImageToIMS.menuItem'}
                        />
                    </MenuItem>

                    <MenuItem onClick={this.handleDeletePhoto}>
                        <Typography
                            color="inherit"
                            variant="body1"
                            id={'photoDetail.delete.menuItem'}
                        />
                    </MenuItem>
                    <MenuItem onClick={this.handleCategorizePhoto}>
                        <Typography
                            color="inherit"
                            variant="body1"
                            id={'photoDetail.changeCategory.menuItem'}
                        />
                    </MenuItem>
                    <MenuItem
                        onClick={this.handleMovePhoto(MOVE_FIRST)}
                        disabled={isFirst}
                    >
                        <Typography
                            color="inherit"
                            variant="body1"
                            id={'photoDetail.moveFirst.menuItem'}
                        />
                    </MenuItem>
                    <MenuItem
                        onClick={this.handleMovePhoto(MOVE_PREVIOUS)}
                        disabled={isFirst}
                    >
                        <Typography
                            color="inherit"
                            variant="body1"
                            id={'photoDetail.movePrevious.menuItem'}
                        />
                    </MenuItem>
                    <MenuItem
                        onClick={this.handleMovePhoto(MOVE_NEXT)}
                        disabled={isLast}
                    >
                        <Typography
                            color="inherit"
                            variant="body1"
                            id={'photoDetail.moveNext.menuItem'}
                        />
                    </MenuItem>
                    <MenuItem
                        onClick={this.handleMovePhoto(MOVE_LAST)}
                        disabled={isLast}
                    >
                        <Typography
                            color="inherit"
                            variant="body1"
                            id={'photoDetail.moveLast.menuItem'}
                        />
                    </MenuItem>
                    <NotFeature feature={CUSTOMER_ENV}>
                        <MenuItem onClick={this.handleSortAllPhotos}>
                            <Typography
                                color="inherit"
                                variant="body1"
                                id={'photoDetail.sortAllPhotos.menuItem'}
                            />
                        </MenuItem>
                    </NotFeature>
                </Menu>
            </Fragment>
        )
    }
}

export default withStyles(styles)(PhotoMoreMenu)
