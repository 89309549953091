import React from 'react'
import {
    Modal,
    Box,
    Typography,
    IconButton,
    Chip,
    Divider,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
} from '@mui/material'
import {
    Close as CloseIcon,
    Star as StarIcon,
    BugReport as BugIcon,
    NewReleases as NewReleasesIcon,
} from '@mui/icons-material'
import { styled } from '@mui/material/styles'

const StyledModal = styled(Modal)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
})

const ModalContent = styled(Box)(({ theme }) => ({
    position: 'relative',
    backgroundColor: '#ffffff',
    borderRadius: '16px',
    outline: 'none',
    padding: theme.spacing(4),
    maxWidth: '600px',
    width: '90%',
    maxHeight: '90vh',
    overflow: 'auto',
    boxShadow: '0 8px 32px rgba(0, 0, 0, 0.1)',
    '&::-webkit-scrollbar': {
        width: '8px',
    },
    '&::-webkit-scrollbar-track': {
        background: '#f1f1f1',
        borderRadius: '4px',
    },
    '&::-webkit-scrollbar-thumb': {
        background: '#888',
        borderRadius: '4px',
    },
}))

const VersionChip = styled(Chip)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    fontWeight: 'bold',
    fontSize: '0.9rem',
    height: '28px',
}))

const Feature = styled(ListItem)(({ theme }) => ({
    backgroundColor: 'rgba(0, 0, 0, 0.02)',
    borderRadius: '8px',
    marginBottom: theme.spacing(1),
    transition: 'all 0.2s ease',
    '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
        transform: 'translateX(4px)',
    },
}))

interface ReleaseNotesModalProps {
    open: boolean
    onClose: () => void
}

const ReleaseNotesModal: React.FC<ReleaseNotesModalProps> = ({
    open,
    onClose,
}) => {
    return (
        <StyledModal
            open={open}
            onClose={onClose}
            BackdropProps={{
                style: { backgroundColor: 'rgba(0, 0, 0, 0.6)' },
            }}
        >
            <ModalContent>
                {/* Close Button */}
                <IconButton
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: 'grey.500',
                    }}
                    onClick={onClose}
                >
                    <CloseIcon />
                </IconButton>

                {/* Header */}
                <Box
                    sx={{
                        mb: 3,
                        display: 'flex',
                        alignItems: 'center',
                        gap: 2,
                    }}
                >
                    <NewReleasesIcon sx={{ fontSize: 32, color: '#4a287d' }} />
                    <Typography
                        variant="h5"
                        component="h2"
                        sx={{ fontWeight: 600 }}
                    >
                        What's New
                    </Typography>
                </Box>

                {/* Version Info */}
                <Box
                    sx={{
                        mb: 3,
                        display: 'flex',
                        alignItems: 'center',
                        gap: 2,
                    }}
                >
                    <VersionChip
                        label="Web Application: 1.57.0"
                        sx={{ backgroundColor: '#4a287d' }}
                    />
                    <Typography
                        variant="caption"
                        sx={{ color: 'text.secondary' }}
                    >
                        Released: {new Date().toLocaleDateString()}
                    </Typography>
                </Box>

                <Divider sx={{ my: 2 }} />

                {/* Features List */}
                <List sx={{ py: 0 }}>
                    <Feature>
                        <ListItemIcon>
                            <StarIcon sx={{ color: '#FFB400' }} />
                        </ListItemIcon>
                        <ListItemText
                            primary="Enhanced Asset Display"
                            secondary="Display per asset the Year | Make | Model from the form should display in the fields available so quick glance at the assets displays what has been filled out on the form"
                            primaryTypographyProps={{ fontWeight: 700 }}
                            secondaryTypographyProps={{
                                sx: {
                                    color: 'text.secondary',
                                    fontSize: '0.875rem',
                                    mt: 0.5,
                                },
                            }}
                        />
                    </Feature>

                    <Feature>
                        <ListItemIcon>
                            <StarIcon sx={{ color: '#FFB400' }} />
                        </ListItemIcon>
                        <ListItemText
                            primary="Required Fields Indicator"
                            secondary="Required Fields have an indicator next to the field so that it is easily notable which fields need to be filled out before a save/submit can be done"
                            primaryTypographyProps={{ fontWeight: 700 }}
                            secondaryTypographyProps={{
                                sx: {
                                    color: 'text.secondary',
                                    fontSize: '0.875rem',
                                    mt: 0.5,
                                },
                            }}
                        />
                    </Feature>

                    <Feature>
                        <ListItemIcon>
                            <BugIcon sx={{ color: '#F44336' }} />
                        </ListItemIcon>
                        <ListItemText
                            primary="Technical Improvements"
                            secondary="Fixed some bugs and UI across the Web App"
                            primaryTypographyProps={{ fontWeight: 700 }}
                            secondaryTypographyProps={{
                                sx: {
                                    color: 'text.secondary',
                                    fontSize: '0.875rem',
                                    mt: 0.5,
                                },
                            }}
                        />
                    </Feature>
                </List>

                {/* Footer */}
                <Box
                    sx={{
                        mt: 3,
                        pt: 2,
                        borderTop: '1px solid',
                        borderColor: 'divider',
                    }}
                >
                    <Typography
                        variant="body2"
                        color="text.secondary"
                        align="center"
                    >
                        Thank you for using our application. For support,
                        contact our team.
                    </Typography>
                </Box>
            </ModalContent>
        </StyledModal>
    )
}

export default ReleaseNotesModal
