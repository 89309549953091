import React, { useState, useEffect, useCallback } from 'react'
import { Auth } from 'aws-amplify'
import { isAdminOnly, isMultipleOrganization } from '../../../Utils/ServerUtil'
import { FTCheckbox } from '../../FTCheckbox'
import Menu from '@material-ui/core/Menu'
import { List, Divider } from '@mui/material'
import eventCapture from '../../../eventList'
import { posthogCapture } from '../../../PostHogFunction'
import { makeStyles } from '@material-ui/core/styles'
import NotFeature from '../../../fhg/security/NotFeature'
import { CUSTOMER_ENV } from '../../../Utils/ServerUtil'
import { FTButtonIconText } from '../../buttons'
import { FTFilterIcon } from '../../icons'
import { useEmployeesStore } from '../../zustandState/ZustandStore'
import { useMenuStore } from '../../zustandState/ZustandStore'

const useStyles = makeStyles((theme) => ({
    mouseTracker: {
        '&:focus': {
            outline: 'none',
        },
    },
}))

function EmployeesFilterMenu() {
    const classes = useStyles()
    const { SelectedEmployeesState, setSelectedEmployeesState } = useEmployeesStore()
    const { MenuClosedState, setMenuClosedState } = useMenuStore()
    
    const [EMPLOYEES_NAMES, setEMPLOYEES_NAMES] = useState([])
    const [selectedEmployees, setSelectedEmployees] = useState([])
    const [selectAllEmployees, setSelectAllEmployees] = useState(false)
    const [menuOpen, setMenuOpen] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null)
    const [isInitialLoad, setIsInitialLoad] = useState(true)

    // Fetch employee names on component mount only
    useEffect(() => {
        fetchEmployeeNames()
    }, [])

    // Load stored selected employees from localStorage on mount
    useEffect(() => {
        const savedEmployees = localStorage.getItem('selectedEmployees')
        if (savedEmployees) {
            const parsedEmployees = JSON.parse(savedEmployees)
            setSelectedEmployees(parsedEmployees)
            
            // Set selectAllEmployees based on if all employees are selected
            if (EMPLOYEES_NAMES.length > 0 && parsedEmployees.length === EMPLOYEES_NAMES.length) {
                setSelectAllEmployees(true)
            }
        }
        setIsInitialLoad(false)
    }, [EMPLOYEES_NAMES])

    // Sync with Zustand state when local state changes
    useEffect(() => {
        if (!isInitialLoad) {
            setSelectedEmployeesState(selectedEmployees)
        }
    }, [selectedEmployees, setSelectedEmployeesState, isInitialLoad])

    // Update menu closed state
    useEffect(() => {
        setMenuClosedState(!menuOpen)
    }, [menuOpen, setMenuClosedState])

    // Handle menu toggle
    const handleEmployeeMenuToggle = (event) => {
        if (menuOpen) {
            // Menu is closing - this is where you'd handle any action needed on close
            setAnchorEl(null)
            
            // Save to localStorage when menu closes
            localStorage.setItem('selectedEmployees', JSON.stringify(selectedEmployees))
            
            // Log the event
            console.log('Menu closed with selected employees:', selectedEmployees)
        } else {
            // Menu is opening
            setAnchorEl(event.currentTarget)
        }
        setMenuOpen(!menuOpen)
    }

    // Handle selection of individual employee
    const handleEmployeesSelect = useCallback((employeeName) => {
        posthogCapture(eventCapture.FILTERED_BY_EMPLOYEES)

        setSelectedEmployees((prevSelectedEmployees) => {
            const updatedEmployees = prevSelectedEmployees.includes(employeeName)
                ? prevSelectedEmployees.filter((name) => name !== employeeName)
                : [...prevSelectedEmployees, employeeName]
            
            // Update selectAll state based on selection
            if (updatedEmployees.length === EMPLOYEES_NAMES.length) {
                setSelectAllEmployees(true)
            } else {
                setSelectAllEmployees(false)
            }
            
            return updatedEmployees
        })
    }, [EMPLOYEES_NAMES.length])

    // Handle select all checkbox
    const handleSelectAllChange = useCallback(() => {
        const newSelectAllEmployees = !selectAllEmployees
        
        if (newSelectAllEmployees) {
            // Select all employees
            setSelectedEmployees([...EMPLOYEES_NAMES])
        } else {
            // Deselect all employees
            setSelectedEmployees([])
        }
        
        setSelectAllEmployees(newSelectAllEmployees)
    }, [selectAllEmployees, EMPLOYEES_NAMES])

    // Fetch employee names list
    const fetchEmployeeNames = async () => {
        let jwtToken
        
        try {
            const session = await Auth.currentSession()
            jwtToken = session.idToken.jwtToken
        } catch (e) {
            try {
                const session = await Auth.currentSession()
                jwtToken = session.idToken.jwtToken
            } catch (e) {
                console.log('Error refreshing token', e)
                return; // Exit if we can't get a token
            }
        }

        const headers = {
            authorization: jwtToken,
            accept: 'application/json',
        }
        
        if (isAdminOnly() || isMultipleOrganization()) {
            const organization = localStorage.getItem('hw.' + window.btoa('organization'))
            if (organization && window.atob(organization)) {
                headers['HW-Organization'] = window.atob(organization)
            }
        }
        
        const options = {
            method: 'GET',
            headers: headers,
        }

        const url = `${process.env.REACT_APP_API_ENDPOINT_V3}/employees`

        try {
            const response = await fetch(url, options)

            if (response.ok) {
                const employeeData = await response.json()
                const employeeNames = employeeData.map(employee => employee.name);
                
                setEMPLOYEES_NAMES(employeeNames)
                console.log('Employee names loaded:', employeeNames.length)
            } else {
                console.error('API request failed with status:', response.status)
            }
        } catch (error) {
            console.error('Error occurred during API request:', error)
        }
    }

    return (
        <div>
            <NotFeature feature={CUSTOMER_ENV}>
                <FTButtonIconText
                    id="employee-filter-button"
                    title="evalDashboard.employees.column"
                    buttonAction={handleEmployeeMenuToggle}
                    icon={
                        <FTFilterIcon
                            activeFilter={selectedEmployees.length > 0 && selectedEmployees.length < EMPLOYEES_NAMES.length}
                        />
                    }
                />
                <Menu
                    anchorEl={anchorEl}
                    open={menuOpen}
                    onClose={handleEmployeeMenuToggle}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    getContentAnchorEl={null}
                >
                    <List className={classes.mouseTracker}>
                        {EMPLOYEES_NAMES.map((employee) => (
                            <FTCheckbox
                                key={employee}
                                value={employee}
                                onClick={() => handleEmployeesSelect(employee)}
                                checked={selectedEmployees.includes(employee)}
                            />
                        ))}
                    </List>
                    <Divider />
                    <FTCheckbox
                        key="Select All"
                        value="Select All"
                        onClick={handleSelectAllChange}
                        checked={selectAllEmployees}
                    />
                </Menu>
            </NotFeature>
        </div>
    )
}

export default EmployeesFilterMenu