export class PromiseCollection {
    /**
     * Create a new PromiseCollection.
     *
     * @param promise An array of promises, a single promise or undefined.
     */
    constructor(promise = []) {
        this.promises = Array.isArray(promise) ? promise : [promise]
    }

    /**
     * Add promise(s) to the collection.
     *
     * @param promise An array of promises or a single promise.
     */
    add = (promise) => {
        this.promises.push(promise)
    }

    /**
     * Get a promise for the whole collection. The collection can be modified dynamically. The behavior is essentially
     * the same as Promise.all, except new promises can be added dynamically.
     *
     * @return {Promise} The Promise completed when all the promises have completed (see Promise.all).
     */
    all = () => {
        const _start = () => {
            const count = this.promises.length
            return Promise.all(this.promises).then(() => {
                if (this.promises.length > count) {
                    return _start()
                } else {
                    return undefined
                }
            })
        }

        return _start()
    }
}
