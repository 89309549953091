import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { isFeatureEnabled } from '../../Utils/ServerUtil'

/**
 * A component that compares the feature to the feature_flags of the user. If the user doesn't have the feature, the
 * children will be displayed and not otherwise.
 **/
export default function NotFeature({ feature, children }) {
    const [hasFeature, setHasFeature] = useState(false)

    /**
     * When the feature changes update whether the children can be displayed.
     */
    useEffect(() => {
        setHasFeature(isFeatureEnabled(feature))
    }, [feature])

    return !hasFeature ? children : null
}

NotFeature.propTypes = {
    feature: PropTypes.string.isRequired, //Name of the feature to check.
}
