import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import CardMedia from '@mui/material/CardMedia'
import Card_1 from './PhoneCard'
import Card_2 from './EmailCard'
import Card_3 from './SlackCard'

const useStyles = makeStyles((theme) => ({
    mainView: {
        flexGrow: 1,
        background: 'linear-gradient(to top, #fbf1fc, #FFFFFF)',
        minWidth: '100vh',
    },
    root: {
        flexGrow: 1,
        maxWidth: 1000,
        margin: '0 auto',
        marginTop: theme.spacing(5),
    },
    topSection: {
        display: 'flex',
        alignItems: 'center',
    },
    paragraph: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        textAlign: 'left',
        fontSize: '1rem',
        lineHeight: '1.5',
    },
    midSection: {
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(10),
        display: 'flex', // Add display: flex
        justifyContent: 'flex-end', // Move content to the right
        alignItems: 'center', // Center vertically
    },
    header: {
        textAlign: 'left',
        marginTop: theme.spacing(3),
    },
    contactOption: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: theme.spacing(2),
    },
    icon: {
        marginRight: theme.spacing(1),
        fontSize: '1.5rem',
    },
    emailLink: {
        textDecoration: 'none',
        color: theme.palette.primary.main,
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    imageContainer: {
        maxWidth: 400,
        maxHeight: 400,
    },
}))

const Support = () => {
    const classes = useStyles()

    return (
        <div className={classes.mainView}>
            <div className={classes.root}>
                <Grid container spacing={3}>
                    <Grid item xs={7} className={classes.topSection}>
                        <header className={classes.header}>
                            <Typography variant="h2">Support</Typography>
                            <Typography
                                variant="body1"
                                className={classes.paragraph}
                            >
                                Please feel free to phone, email, or join our
                                Slack channel below for all of your field tool
                                support needs.
                            </Typography>
                            <Typography
                                variant="body1"
                                className={classes.paragraph}
                            >
                                Live chat is offered! by pressing the
                                bottom-right chat icon.
                            </Typography>
                        </header>
                    </Grid>
                    <Grid item xs={5} className={classes.midSection}>
                        <div className={classes.imageContainer}>
                            <CardMedia
                                component="img"
                                image="./img/support.svg"
                                alt="Support Image"
                            />
                        </div>
                    </Grid>
                    <Grid item xs={4}>
                        <Card_1 />
                    </Grid>
                    <Grid item xs={5}>
                        <Card_2 />
                    </Grid>
                    <Grid item xs={3}>
                        <Card_3 />
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}

export default Support
