import React, { Fragment, PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import { getUserData } from '../../Utils/ServerUtil'

/**
 * Component which only shows children if the user has privilege to see the children.
 *
 */
export default class WithPrivilege extends PureComponent {
    static propTypes = {
        requiresAdmin: PropTypes.bool, // Requires the admin privilege to see children.
        requiresPlatformAdmin: PropTypes.bool, // Requires the platform admin privilege to see children.
        redirectUrl: PropTypes.string, // If set will redirect users without privilege to this URL.
    }

    //Default to highest security if the developer forgets to set security.
    static defaultProps = {
        requiresAdmin: true,
        requiresPlatformAdmin: true,
    }

    constructor(props) {
        super(props)

        // Initialize state with a default value
        this.state = {
            hasPrivilege: false,
        }
    }

    /**
     * Any time the props change, recheck the privilege.
     *
     * @param props The new props.
     * @param state The existing state.
     * @return {{hasPrivilege: *}} The new state.
     */
    static getDerivedStateFromProps(props, state) {
        const { requiresAdmin, requiresPlatformAdmin } = props
        const { isAdmin, isPlatformAdmin } = getUserData()

        return {
            hasPrivilege:
                (requiresAdmin && isAdmin) ||
                (requiresPlatformAdmin && isPlatformAdmin),
        }
    }

    render() {
        const { children, redirectUrl } = this.props

        if (!this.state.hasPrivilege) {
            return redirectUrl ? <Redirect to={redirectUrl} /> : null
        }
        return <Fragment>{children}</Fragment>
    }
}

/**
 * An HOC component that restricts access to the wrapped component.
 *
 * */
export const withPrivilege = (options) => (WrappedComponent) => {
    class CustomizedWithPrivilege extends PureComponent {
        render() {
            return (
                <WithPrivilege {...options}>
                    <WrappedComponent {...this.props} />
                </WithPrivilege>
            )
        }
    }

    return CustomizedWithPrivilege
}
