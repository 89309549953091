import React from 'react'
import PropTypes from 'prop-types'
import { Redirect, Route } from 'react-router-dom'

export default function PrivilegeRoute({ hasPrivilege, ...rest }) {
    if (hasPrivilege && hasPrivilege()) {
        return <Route {...rest} />
    }
    return <Redirect to="/" />
}

PrivilegeRoute.propTypes = {
    hasPrivilege: PropTypes.func.isRequired, //Function that returns true if the user can access the route.
}
