// Main logo path.
export const LOGO = '/img/Monogram.svg'
export const LARGE_LOGO = '/img/purple_wave/ft-icon-color-white.png'
export const UP_ARROW = '/img/baseline-keyboard_arrow_up-24px.svg'
export const DOWN_ARROW = '/img/baseline-keyboard_arrow_down-24px.svg'
export const PIN_ICON = '/img/pin.svg'
export const PLACEHOLDER_IMAGE = '/img/placeholder.png'
export const PDF_IMAGE = '/img/pdf.jpg'
export const CATALOG_ICON = '/img/catalog.svg'
export const EMAIL_SMALL_ICON = '/img/email.svg'
export const CATEGORY_ICON = '/img/category.svg'
export const DELETE_ICON = '/img/delete.svg'
export const WARNING_ICON = '/img/warning.png'
//export const EMAIL_ICON = '/img/email graphic.png';
export const FT_LOGO = '/img/field tool icon.svg'
export const MAILBOX_ICON = '/img/mailbox graphic.png'
export const VIDEO_ICON = '/img/mp4.jpg'
export const EXCEL_ICON = '/img/xlsx.jpg'
export const CSV_ICON = '/img/csv.jpg'
export const SHARE_SUCCESS = '/img/shared-sucessfully.svg'
export const SHARE_USER = '/img/shareUser.svg'
export const NOT_SCHEDULED = '/img/not-scheduled.svg'
export const SCHEDULED = '/img/scheduled.svg'
export const SHARED = '/img/shareStatus.svg'
export const SHARE_LIVE = '/img/shareStatus_shared.svg'
export const IMS_FORM_IMG_PLACEHOLDER = '/img/image-icon.svg'

// Height of the Application bar.
export const APP_BAR_HEIGHT = 45

// Height of secondary header.
export const SECONDARY_HEADER_HEIGHT = 46

// Height of catalog header.
export const CATALOG_HEADER_HEIGHT = 60

// Time of user inactivity after last change before estimate is sent to server.
export const ESTIMATE_DEBOUNCE_MILLIS = 7000

// Time of user inactivity after last change to owner before request for owners is sent to server.
export const OWNER_DEBOUNCE_MILLIS = 300

export const MAX_CONTENT_WIDTH = 1200

export const CLICK_DELAY_FOR_SELECTION = 100

// When the snackbar shows the Subscribe message, it will close after this many milliseconds.
export const CLOSE_DELAY_FOR_SUBSCRIBE = 10000

// The size of the thumbnails in the Equipment Detail screen. Also determines the size of the Estimate box.
export const THUMBNAIL_SIZE = 150

// The size of the thumbnails in the Photo Upload screen.
export const THUMBNAIL_SIZE_SMALL = 70

// The large size of the thumbnails in the Photo Upload screen.
export const THUMBNAIL_SIZE_LARGE = 560

// The width of the properties sidebar on the Equipment Detail screen.
export const EQUIPMENT_DETAIL_PROPERTY_WIDTH = 350

// The width of the properties sidebar on the Equipment List screen.
export const EQUIPMENT_LIST_PROPERTY_WIDTH = 450

//Maximum image size for the uploaded photos.
//TODO leaving the limit in for now, but making size very large. Remove the limit if we don't need it anymore.
export const MAX_PHOTO_SIZE = 1000 * 10 * 1024 * 1024 // 10,000 MB.

//Time to wait for server to process images before refreshing after upload..
export const REFRESH_DELAY = 3000 // 3 seconds

// Time to display the success decoration in milliseconds.
export const SUCCESS_DECORATION_DURATION = 4000

export const GOOGLE_INIT_TIMEOUT = 10000

// Dates displayed to the user.
export const DATE_FORMAT = 'M/D/YYYY hh:mma'
export const DATE_ONLY_FORMAT = 'M/D/YYYY'
export const DATE_MISSING = 'N/A'

export const FIRST_VALID_YEAR = 1900

export const LAST_VALID_YEAR = new Date().getFullYear() + 1

// List of all image types sorted alphabetically.
// noinspection JSUnusedGlobalSymbols
export const IMAGE_TYPES = ['AMKT', 'BEXT', 'FVIN', 'GODO', 'HMET']

// Map image_view types to message keys.
export const IMAGE_VIEW_KEYS = {
    ID_STICKER: 'photoDetail.changeCategory.sticker',
    AMKT: 'photoDetail.changeCategory.profile',
    BEXT: 'photoDetail.changeCategory.external',
    INTERNAL: 'photoDetail.changeCategory.internal',
    METER_MILES: 'photoDetail.changeCategory.miles',
    METER_HOURS: 'photoDetail.changeCategory.hourMeter',
    ENGINE: 'photoDetail.changeCategory.engine',
    TIRES_TRACKS: 'photoDetail.changeCategory.trackTires',
    ADDITIONAL: 'photoDetail.changeCategory.additional',
    ID_VIN: 'photoDetail.changeCategory.vin',
    ID_SERIAL: 'photoDetail.changeCategory.serial',
    GODO: 'photoDetail.changeCategory.odometer',
    ID_INVENTORY: 'photoDetail.changeCategory.inventory',
    METER_KILOMETERS: 'photoDetail.changeCategory.kilomters',
    METER_ACRES: 'photoDetail.changeCategory.meterAcres',
    VIDEO: 'photpDetail.changeCategory.video',
    UNKNOWN: 'photoDetail.changeCategory.unknown',
    DOCUMENT: 'photoDetail.changeCategory.document',
}

// Map image_view types to message keys.
export const IMAGE_VIEW_CONVERT = {
    FVIN: 'ID_VIN',
    GODO: 'METER_MILES',
    HMET: 'METER_HOURS',
    METER_ENGINE_HOURS: 'METER_HOURS',
    METER_HOUR: 'METER_HOURS',
}

//export const SUPPORT_LINK = 'https://heavyworth.com/user-guide/web-app/';

// Maximum number of photos the user can upload at one time.
export const MAX_PHOTO_UPLOAD = 800

// Maximum number of photos before user is required to wait until photos are uploaded before continuing.
export const MAX_PHOTO_LIMIT = 100

// Number of photos to display in a page.
export const PHOTO_SUMMARY_PAGE_SIZE = 100

// The list of all workflow status values in order of the process.
export const STATUS_ORDER = [
    'capture_in_progress',
    'capture_complete',
    'sent_to_ims',
]

// The list of DEFAULT workflow status values used to initialize the filter the first time.
export const DEFAULT_STATUS_ORDER = [
    'capture_in_progress',
    'capture_complete',
    'sent_to_ims',
]

// The header height for the evaluation dashboard table. Must match rt-resizable-header-content in EvalDashboard.scss.
export const HEADER_HEIGHT_EVAL_TABLE = 20
export const HEADER_ICON_WIDTH_EVAL_TABLE = 20

// The increments to use for zooming the image. The minimum and maximum values are used to limit the wheel zooming.
export const SCALE_VALUES = [1, 1.25, 1.5, 2, 3, 4, 5, 7, 8, 12]

// The maximum margin of the image as a percentage of the parent bounds. This limits the image so it can't be dragged
// out of the visible area. So, a 0.75 (i.e. 75%) margin means the image can be moved left or right and up or down 75%
// of the parent.
export const MAX_MARGIN_ON_PAN = 0.75

// When the user resizes the window, the image margins need to be adjusted while zooming and panning.
export const ADJUST_IMAGE_MARGINS_DELAY = 200

// When the user is in zoom mode, the user can drag the image (i.e. panning) or click to zoom. This is the distance the
// mouse has to move to be a panning instead of zooming.
export const THRESHOLD_MOVE_CLICK = 3

// The debounce time before rotation changes get saved.
export const ROTATION_SAVE_DELAY = 2000

// Workaround AWS Network error issue. This is how close the errors need to occur to cause the auto logout.
export const NETWORK_ERROR_THRESHOLD = 5 * 60 * 1000 //5 minutes.

// Row height for Google
export const GOOGLE_SHEET_ROW_HEIGHT = 60

//Map MIME types to extensions. 'image/jpeg', 'image/png', 'image/webp'' +
export const MAP_MIME_EXT = {
    'image/jpeg': '.jpg',
    'image/png': '.png',
    'image/webp': '.webp',
    'image/gif': '.gif',
    'image/tiff': '.tiff',
    'image/bmp': '.bmp',
    'text/plain': '.txt',
    'application/pdf': '.pdf',
    'video/webm': '.webm',
    'video/mpeg': '.mpeg',
    'video/mp4': '.mp4',
    'video/x-msvideo': '.avi',
    'video/x-ms-wmv': '.wmv',
    'video/quicktime': '.mov',
}

export const MAP_REJECTED_MIME_TYPE = {
    'image/gif': 'GIF',
    'image/tiff': 'TIFF',
    'image/bmp': 'BMP',
    'image/svg+xml': 'SVG',
    'text/plain': 'Text',
    'text/csv': 'CSV',
}

export const MOVIE_EXTENSIONS = [
    '.ogv',
    '.webm',
    '.mpg',
    '.mpeg',
    '.mp4',
    '.avi',
    '.wmv',
    '.mov',
]
export const MOVIE_EXTENSIONS_TEXT = [
    'ogv',
    'webm',
    'mpg',
    'mpeg',
    'mp4',
    'avi',
    'wmv',
    'mov',
]
export const MOVIE_MIME_TYPES = [
    'video/ogg',
    'video/webm',
    'video/mpeg',
    'video/mp4',
    'video/x-msvideo',
    'video/x-ms-wmv',
    'video/quicktime',
]

export const VALUATION_REASONS =
    'Bankruptcy|Estate Planning|Financing|Inspection|Insurance|Liquidation|Litigation|Loss of Life|Retirement|Trade In'
export const VALUATION_REASONS_DEFAULTS = 'Financing'

export const CATALOG_ATTACHMENT_EXTENSIONS = ['.pdf', '.xls', '.xlsx', '.csv']
export const CATALOG_ATTACHMENT_EXTENSIONS_TEXT = ['pdf', 'xls', 'xlsx', 'csv']
export const CATALOG_ATTACHMENT_MIME_TYPES = [
    'text/csv',
    'application/pdf',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
]

//Image states
export const IMAGE_STATE_LOADED = 'is loaded'
export const IMAGE_STATE_PLACEHOLDER = 'placeholder'
export const IMAGE_STATE_NOT_LOADED = 'not loaded'
export const IMAGE_STATE_PROCESSING = 'processing'
export const IMAGE_STATE_ERROR = 'error'

export const PHOTO_STATE_ATTATCHED = 'attached'
export const PHOTO_STATE_UPLOADED = 'uploaded'
export const PHOTO_STATE_ERROR = 'error'
export const PHOTO_STATE_NOT_UPLOADED = 'uploading'

// Map
export const MAP_URL =
    'http://maps.google.com/maps?z=12&t=m&q=loc:{latitude}+{longitude}'

// Server URLs
// GET
//export const GET_USERS_EMAIL = '/users/search/email/{email}';
export const GET_ARCHIVED_EVALUATIONS = '/evaluations/all/archived'
export const GET_VERSION = '/clients/{domain}'
export const GET_ORGANIZATIONS = '/organizations'
export const GET_USERS = '/organizations/{orgId}/users'
export const GET_USERS_ALL = '/users'
export const GET_USER = '/users/{userId}'
export const GET_CATALOG_PDF =
    '/catalogs/{evaluationId}?images={isIncludePhotos}{sorting}'
export const GET_ASSET_PDF =
    '/items/{itemId}.pdf?images={isIncludePhotos}{sorting}'
export const GET_FILES_ONLY = '/evaluations/all/files'
export const GET_ORGANIZATION_USERS = '/organization_users'
export const GET_EVAL_SUBSCRIPTIONS = '/evaluations/{evalId}/subscriptions'
export const GET_SUBSCRIPTIONS = '/subscriptions'
export const GET_ASSET_SEARCH = '/search/assets?search={searchText}'
export const GET_IMAGES_ZIP = '/catalogs/{catalogId}/assets/{assetId}/images'
export const GET_CATALOG_SEARCH = '/packages?allNames={searchText}'
export const GET_USER_SESSION = '/user_session'
export const GET_USER_DETAILS = '/users/{userId}'

// PUT
export const SAVE_WORKFLOW_STATUS = '/evaluations/id/{id}/status/{status}'
export const ROTATE_IMAGE_URI = '/images/{uri}/rotate/{rotation}'
export const SAVE_EVALUATION = '{uri}/item/{itemId}'
export const CREATE_EVALUATION = '/evaluations/{evalId}'
export const SAVE_ITEMS_TO_EVALUATION = '/evaluations/{evalId}/items/{itemId}'
export const SAVE_EVALUATIONS = '/evaluations/{evalId}'
export const CREATE_UPDATE_ORGANIZATION = '/organizations/{orgId}'
export const UPDATE_USER = '/users/{userId}'
export const ADD_USER_TO_GROUP = '/organizations/{orgId}/users/{userId}'
export const UPDATE_ITEM = '/items/{itemId}'
export const UPDATE_LINK = '/items/{assetKey}/links/{linkKey}'
export const SAVE_IMS_FORM = '/items/{itemId}'
export const SAVE_CATALOG_SHARING = '/evaluations/{evalId}/share'

//PATCH
// export const UPDATE_IMAGES = '/items/{itemId}';
export const EDIT_USER = '/users/{userId}'
export const UPLOAD_IMAGE = '/items/{itemId}/images'

// POST
export const SAVE_ITEMS = '/items/'
export const SAVE_ESTIMATE = '{uri}/estimate'
export const CREATE_USER = '/users'
export const LOGIN_FAILED = '/support/authentication/failed'
export const PASSWORD_RESET = '/support/authentication/reset'
export const SUBSCRIBE = '/evaluations/{evalId}/subscriptions'
export const SUBSCRIBE_ADDITIONAL =
    '/evaluations/{evalId}/subscriptions/{userId}'
export const SUBSCRIPTIONS = '/subscriptions'
export const DROPBOX_SAVE = '/items/{itemId}/dropbox?category={category}'
export const SEND_TO_PURPLEWAVE = '/evaluations/{evalId}/purplewave'
export const CREATE_LINK = '/items/{assetKey}/links'
export const SUBMIT_TO_IMS = '/evaluations/{catalogId}/submit_to_ims'
export const RESUBMIT_CATALOG_TO_IMS =
    '/evaluations/{catalogId}/resubmit_to_ims'
export const RESUBMIT_ASSET_TO_IMS = '/items/{itemId}'
export const RESUBMIT_IMAGE_TO_IMS = '/items/{itemId}/images'
export const SUBMIT_FORM_TO_IMS = '/items/{itemId}/submit_form_to_ims'

// DEL
export const DELETE_EVAL_ITEM = '/evaluations/{evaluationID}/item/{itemId}'
export const DELETE_ITEM = '/items/{itemId}'
export const DELETE_USER = '/users/{userId}'
export const DELETE_ORGANIZATION = '/organizations/{orgId}'
export const DELETE_LINK = '/items/{assetKey}/links/{linkKey}'
export const DELETE_IMG = '/items/{itemId}/images/{imageId}'

// Client URLs
export const EDIT_ITEM = '/items/{itemId}'
export const EDIT_ITEM_PATH = '/items/:id?'
export const DEFAULT_LOCATION = '/evaluations/all/open/'
export const CATALOG_PATH = '/evaluations/:owner?'
export const GOOGLE_SHEETS_PATH =
    'https://docs.google.com/spreadsheets/d/{spreadSheetId}'
