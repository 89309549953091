import { isEmpty } from '@aws-amplify/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import Hidden from '@material-ui/core/Hidden'
import { makeStyles } from '@material-ui/core/styles'
import { find } from 'lodash'
import get from 'lodash/get'
import React, {
    useRef,
    useEffect,
    useState,
    useCallback,
    Fragment,
} from 'react'
import {
    Route,
    Switch,
    useParams,
    useRouteMatch,
    useHistory,
    useLocation,
} from 'react-router-dom'
import 'react-table/react-table.css'
import {
    DEFAULT_LOCATION,
    APP_BAR_HEIGHT,
    EDIT_ITEM_PATH,
    CATALOG_PATH,
    EQUIPMENT_LIST_PROPERTY_WIDTH,
} from '../../../Constants'
import DisplayError from '../../../fhg/components/DisplayError'
import Grid from '../../../fhg/components/Grid'
import useLazyRequestForServer from '../../../Utils/useLazyRequestForServer'
import '../dashboard/EvalDashboard.scss'
import AssetPropertiesDrawer from '../detail/AssetPropertiesDrawer'
import EquipmentDetailMain from '../detail/EquipmentDetailMain'
import EquipmentList from './EquipmentList'
import EquipmentListHeader from './EquipmentListHeader'
import NewItemDialog from './NewItemDialog'
import eventCapture from '../../../eventList'
import { posthogCapture } from '../../../PostHogFunction'
import { Auth } from 'aws-amplify'
import { isAdminOnly, isMultipleOrganization } from '../../../Utils/ServerUtil'
import { useEvaluationIdStore } from '../../zustandState/ZustandStore'

const useStyles = makeStyles((theme) => ({
    list: {
        height: '100%',
        width: '100%',
    },
    progress: {
        position: 'fixed',
        top: '50%',
        left: '50%',
        zIndex: 1001,
    },
    root: {
        height: `calc(100% - ${APP_BAR_HEIGHT + theme.spacing(2)}px) !important`,
        [theme.breakpoints.down('xs')]: {
            height: '100% !important',
        },
    },
    rootNoAppBar: {
        height: `100% !important`,
    },
    inner: {
        flex: '1 1',
        maxWidth: '100%',
        //maxHeight: "%100",
        overflow: 'auto',
        backgroundColor: theme.palette.environment.light.level2.base,
        // backgroundColor: 'green',
        '@media print': {
            display: 'block',
        },
    },
}))

/**
 * The component to show the equipment list. Handled requesting information from server so it only needs to be requested
 * once.
 */
export default function EquipmentListMain() {
    const classes = useStyles()
    const location = useLocation()
    const history = useHistory()
    const { id, owner } = useParams()
    let catalogMatch = useRouteMatch({
        path: CATALOG_PATH,
        strict: false,
        sensitive: false,
    })

    const tableRef = useRef()

    const evaluationId = useEvaluationIdStore((state) => state.evaluationId)

    const [evaluation, setEvaluation] = useState(null)

    const [cachedEvaluation, SetachedEvaluation] = useState(() => {
        const storedEvaluation = localStorage.getItem('evaluation')
        return storedEvaluation ? JSON.parse(storedEvaluation) : null
    })

    // Use useEffect to update localStorage whenever evaluation changes
    useEffect(() => {
        localStorage.setItem('evaluation', JSON.stringify(evaluation))
    }, [evaluation])
    const [evalItem, setEvalItem] = useState({})

    const [isDrawerOpen, setIsDrawerOpen] = useState(false)

    const [
        fetchCatalog,
        { error: errorCatalog, loading: loadingCatalog, data: dataCatalog },
    ] = useLazyRequestForServer()
    const [
        fetchAsset,
        { error: errorAsset, loading: isEvalItemLoading, data: dataAsset },
    ] = useLazyRequestForServer()

    const [doSetEvalItem, setDoSetEvalItem] = useState(false)
    const [doSetEvaluation, setDoSetEvaluation] = useState(false)

    const [isSaving, setIsSaving] = useState(false)
    const [savedTime, setSavedTime] = useState()
    const [isEstimateError, setIsEstimateError] = useState(false)
    const [evalID, setEvalID] = useState('')

    const isNew = get(location, 'state.isNew')
    const isZoomed = get(location, 'state.isZoomed')

    /**
     * Track the notification events for posthog.
     */
    useEffect(() => {
        if (location.search === '?from=notification') {
            //window?.posthog?.capture(eventCapture.NOTIFICATION_OPEN);
            posthogCapture(eventCapture.NOTIFICATION_OPEN)

            location.search = undefined
            history.replace(location)
        }
    }, [location.search, history, location])

    useEffect(() => {
        if (evaluationId === null) {
            setEvalID(cachedEvaluation?.evaluation?.eval_id)
        } else {
            setEvalID(evaluationId)
            newEvalData()
        }
    }, [])

    /**
     * If the uri owner/uuid changed, fetch the catalog.
     */
    useEffect(() => {
        if (owner) {
            const existingEvaluationUri =
                evaluation &&
                evaluation.uri &&
                evaluation.uri.replace &&
                evaluation.uri.replace('%7C', '')
            const newEvaluationUri =
                catalogMatch &&
                catalogMatch.url &&
                catalogMatch.url.replace('|', '')
            if (newEvaluationUri !== existingEvaluationUri) {
                fetchCatalog(catalogMatch.url)
                setEvalItem({})
            }
        }
        // eslint-disable-next-line
    }, [owner, evaluation, fetchCatalog])
    // }, [owner, catalogMatch, evaluation, fetchCatalog]);

    /**
     * When the catalog data is returned from the server, set the evaluation and the evalItem.
     */
    useEffect(() => {
        if (!isEmpty(dataCatalog)) {
            const evaluationData = Array.isArray(dataCatalog)
                ? dataCatalog[0]
                : dataCatalog
            setEvaluation(evaluationData)
            if (doSetEvalItem) {
                if (!!evaluationData) {
                    const evalItemFound = find(evaluationData.items, {
                        item_id: evalItem.item_id,
                    })
                    if (evalItemFound) {
                        setEvalItem(evalItemFound)
                    }
                }
                setDoSetEvalItem(false)
            }
        }
    }, [dataCatalog, doSetEvalItem, evalItem.item_id])

    const newEvalData = async () => {
        let jwtToken
        let tokenRefreshError = false

        try {
            const session = await Auth.currentSession()
            jwtToken = session.idToken.jwtToken
        } catch (e) {
            try {
                const session = await Auth.currentSession()
                jwtToken = session.idToken.jwtToken
            } catch (e) {
                //console.log("Error refreshing token", e);
                tokenRefreshError = true
                // Allow to go through without jwtToken for server requests not requiring an authenticated user.
            }
        }

        const headers = {
            authorization: jwtToken,
            accept: 'application/json',
        }
        // This adds a header for selected organization_ID or _Key which is stored in local storage
        if (isAdminOnly() || isMultipleOrganization()) {
            const organization =
                localStorage['hw.' + window.btoa('organization')]
            if (organization && window.atob(organization)) {
                headers['HW-Organization'] = window.atob(organization)
            }
        }
        const options = {
            method: 'GET',
            headers: headers,
        }

        const url = `${process.env.REACT_APP_API_ENDPOINT}/evaluations/${evalID}`

        // console.log("url====> ",url);
        try {
            const response = await fetch(url, options)
            if (response.ok) {
                const itemData = await response.json()
                console.log(
                    '--------------Fresh Eval Data---------------',
                    itemData
                )

                setEvaluation(itemData)
            } else {
                // Handle the error if the response is not ok
                console.log('Request failed with status:', response.status)
                return null
            }
        } catch (error) {
            // Handle any network or other errors
            console.error('Error:', error)
        }
    }

    useEffect(() => {
        newEvalData()
    }, [])

    useEffect(() => {
        console.log(
            'Eval data from Eqip list:',
            evaluation,
            'Eval data from Eqip list cached:',
            cachedEvaluation
        )

    }, [evaluation])

    useEffect(() => {
        if (id) {
            let foundAsset = false

            if (evaluation && Array.isArray(evaluation.items)) {
                const evalItem = evaluation.items.find(
                    (item) => get(item, 'item.item_id') === id
                )

                if (evalItem) {
                    foundAsset = true
                    setEvalItem(evalItem)
                }
            }

            // If the asset wasn't found, fetch it from the server
            if (!foundAsset) {
                setDoSetEvaluation(true)
                fetchAsset(`/items/${id}`)
            }
        }
    }, [id, fetchAsset, evaluation])

    /**
     * Load the evaluation from the server.
     */
    const loadEvaluation = useCallback(
        (uri, doSetEvalItem = false) => {
            setDoSetEvalItem(doSetEvalItem)
            return fetchCatalog(uri)
        },
        [fetchCatalog]
    )

    /**
     * When the asset is returned, set the evalItem. If the evaluation is needed, fetch the evaluation.
     */
    useEffect(() => {
        if (!isEmpty(dataAsset)) {
            const asset = Array.isArray(dataAsset) ? dataAsset[0] : dataAsset
            setEvalItem(asset)

            if (doSetEvaluation) {
                const evaluationUri = get(asset, 'open_evaluations[0].uri')
                if (evaluationUri) {
                    loadEvaluation(evaluationUri, true)
                    setDoSetEvaluation(false)
                } else {
                    console.log(
                        `The evaluation for the item couldn't be found. No open evaluations.`
                    )
                    if (!evaluation) {
                        console.log(
                            `The evaluation for the item couldn't be found. No open evaluations.`
                        )
                        //history.push(DEFAULT_LOCATION);
                    }
                }
            }
        }
    }, [dataAsset, doSetEvaluation, evaluation, history, loadEvaluation])

    // useEffect(() => {
    //    console.log(evaluation, "EVALUATION DATA =======>>>>>>");
    // })

    /**
     * When the data has changed for the item, force a refresh.
     *
     * @param item The item to refresh.
     * @param isRefreshList Is the item added or the profile image has changed, etc..
     */
    const onRefresh = useCallback(
        (item, isRefreshList) => {
            if (item && item.item_id && id === item.item_id) {
                setDoSetEvaluation(isRefreshList)
                return fetchAsset(`/items/${id}`)
            } else if (isRefreshList) {
                const uri = evaluation
                    ? evaluation.uri
                    : get(item, 'open_evaluations[0].uri')
                return fetchCatalog(uri)
            } else {
                return Promise.resolve()
            }
        },
        [id, evaluation, fetchAsset, fetchCatalog]
    )

    /**
     * Indicate the saving and saved time for estimates.
     *
     * @param isSaving Indicates if the estimates are being saved.
     * @param savedTime The time of the save estimates.
     */
    const handleSaving = (isSaving, savedTime) => {
        setIsSaving(isSaving)

        if (isSaving) {
            setTimeout(() => {
                if (isSaving) {
                    setIsSaving(false)
                }
            }, 6000)
        }

        setSavedTime(savedTime)
    }

    /**
     * When the user closes the sidebar, mark the sidebar closed.
     */
    const onDrawerClose = () => {
        setIsDrawerOpen(false)
    }

    /**
     * When the user opens the sidebar, mark the sidebar open.
     */
    const onDrawerOpen = () => {
        setIsDrawerOpen(true)
    }

    /**
     * When the detail is being refreshed, update the isSaving and isError.
     *
     * @param isSaving Indicates if the estimates are being saved.
     * @param isError Indicates if an error has occurred.
     */
    const onDetailRefresh = (isSaving, isError) => {
        setIsSaving(isSaving)
        setSavedTime(new Date())
        setIsEstimateError(isError)

        if (!isSaving && !isError) {
            onRefresh(undefined, true)
        }
    }

    return (
        <Fragment>
            {(errorAsset || errorCatalog) && (
                <DisplayError error={errorAsset || errorCatalog} />
            )}
            <Hidden xsDown>
                {evaluation && (
                    <EquipmentListHeader
                        evaluation={evaluation}
                        cachedEvaluation={cachedEvaluation}
                        allowExport={!isNew}
                        saving={isSaving}
                        savedTime={savedTime}
                        onDrawerOpen={onDrawerOpen}
                        isDrawerOpen={isDrawerOpen}
                        smBreakpoint={false}
                        tableRef={tableRef}
                        evalItem={evalItem}
                    />
                )}
            </Hidden>
            <Grid
                container
                className={isZoomed ? classes.rootNoAppBar : classes.root}
                name={'EquipmentListMain-root'}
                direction={'column'}
                wrap={'nowrap'}
                spacing={0}
            >
                {isEvalItemLoading && (
                    <CircularProgress className={classes.progress} />
                )}

                <Grid
                    container
                    className={classes.inner}
                    name={'EquipmentListMain-inner'}
                    direction={'row'}
                    wrap={'nowrap'}
                    spacing={0}
                >
                    <Switch>
                        <Route
                            exact
                            path={CATALOG_PATH}
                            render={(routeOptions) => {
                                if (
                                    get(
                                        routeOptions,
                                        'location.state.isNew'
                                    ) !== true
                                ) {
                                    return (
                                        //@TODO Remove innerRef when all EquipmentList HOCs support forwardRef.
                                        <EquipmentList
                                            tableRef={tableRef}
                                            evaluation={evaluation}
                                            onRefresh={loadEvaluation}
                                            isEstimateSaving={isSaving}
                                            isEstimateError={isEstimateError}
                                            isLoading={loadingCatalog}
                                            onSaving={handleSaving}
                                        />
                                    )
                                } else {
                                    return (
                                        <NewItemDialog
                                            key="NewItemDialog-New"
                                            evaluation={evaluation}
                                            onUpdate={onRefresh}
                                        />
                                    )
                                }
                            }}
                        />
                        <Route
                            exact
                            path={EDIT_ITEM_PATH}
                            render={(routeOptions) => {
                                if (
                                    get(
                                        routeOptions,
                                        'location.state.isEdit'
                                    ) === true
                                ) {
                                    return (
                                        <NewItemDialog
                                            evalItem={evalItem}
                                            evaluation={evaluation}
                                            onUpdate={onRefresh}
                                        />
                                    )
                                } else {
                                    return (
                                        <>
                                            <EquipmentDetailMain
                                                evalItem={evalItem}
                                                onRefresh={onRefresh}
                                                evaluation={evaluation}
                                                isLoading={isEvalItemLoading}
                                                isDrawerOpen={isDrawerOpen}
                                                onDrawerClose={onDrawerClose}
                                                onDrawerOpen={onDrawerOpen}
                                            />
                                            <AssetPropertiesDrawer
                                                evalItem={evalItem}
                                                evaluation={evaluation}
                                                breakpoint={'sm'}
                                                width={
                                                    EQUIPMENT_LIST_PROPERTY_WIDTH
                                                }
                                                showEquipmentTitle={false}
                                                onSaving={onDetailRefresh}
                                                isOpen={isDrawerOpen}
                                                onClose={onDrawerClose}
                                            />
                                        </>
                                    )
                                }
                            }}
                        />
                    </Switch>
                </Grid>
            </Grid>
        </Fragment>
    )
}
