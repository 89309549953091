module.exports = {
    // General
    refresh: 'Refresh',
    'date.missing': 'N/A',
    missing: 'N/A',
    'fetch.error':
        'Could not load data from server. Please refresh your browser. ({message})',
    'nav.admin.link': 'Admin',
    'nav.support.link': 'Support',
    'selectAll.label': 'Select All',
    'cancel.button': 'Cancel',
    'remove.button': 'Remove',
    'ok.button': 'Ok',
    'delete.button': 'Delete',
    'resetPassword.button': 'Reset Password',
    'delete.asset': 'Delete Item',
    'save.button': 'Save',
    'saveCreate.button': 'Save and Create Another',
    'saveEditNext.button': 'Save and Edit Next',
    'close.button': 'Close',

    'signOut.label': 'Sign out',

    //Updated times
    'time.fewMinutes.text': 'a few minutes ago',
    'time.minutes.text': '{minutes} minutes ago',
    'time.hours.text': 'a few hours ago',
    'time.today.text': 'at',
    'time.yesterday.text': 'yesterday',

    //Security
    logout: 'Log Out',

    // Evaluators Dashboard
    'evalDashboard.heading': 'Catalogs',
    'evalDashboard.catalog.label': 'Catalog',
    'evalDashboard.asset.label': 'Item',
    'evalDashboard.primaryContact.column': 'Primary Contact',
    'evalDashboard.equipmentOwner.column': 'Customer Name',
    'evalDashboard.equipmentOwnerId.column': 'Customer NO.',
    'evalDashboard.item.column': 'Items',
    'evalDashboard.photos.column': 'Images',
    'evalDashboard.videos.column': 'Videos',
    'evalDashboard.ims.count.column': 'IMS Count',
    'evalDashboard.dateCreated.column': 'Date Created',
    'evalDashboard.status.column': 'Status',
    'evalDashboard.share.column': 'Shared',
    'evalDashboard.employees.column': 'Employees',
    'evalDashboard.search.label': 'Search',
    'evalDashboard.fetch.error':
        'Could not load the catalog. Please refresh your browser. ({message})',
    'evalDashboard.consensus.title': 'Missing Estimates',
    'evalDashboard.consensus.submit': 'Finalize',
    'evalDashboard.consensus.error':
        'This catalog has items without an estimate. Finalize anyway?',
    'evalDashboard.loadOrganizations.error':
        'The organizations could not be loaded',
    'evalDashboard.new.label': 'New Catalog',

    'evalEquipmentList.heading': 'Item List',
    'evalEquipmentDetail.heading': 'Item Detail',

    //Equipment List
    'equipmentList.capture.label': 'Data Capture: {captureUsers}',
    'equipmentList.location.label': 'Location: {location}',
    'equipmentList.your.estimate.label': 'Your Estimate:',
    'equipmentList.final.estimate.label': 'HeavyWorth Value:',
    'equipmentList.estimate.label': 'Your Estimate:',
    'equipmentList.estimate.placeholder': '$10,000',
    'equipmentList.description.label': 'Item Type',
    'equipmentList.serialNo.label': 'Serial',
    'equipmentList.inventoryId.label': 'Inventory ID',
    'equipmentList.stickerId.label': 'Sticker ID',
    'equipmentList.vin.label': 'VIN',
    'equipmentList.uniqueId.label': 'Unique ID #',
    'equipmentList.saving.label': 'Saving...',
    'equipmentList.saved.label': 'Saved at {savedTime,time, short}',
    'equipmentList.save.error':
        'Could not save the estimate. Please refresh your browser.',
    'equipmentList.fetch.error':
        'Could not load captures. Please refresh your browser. ({message})',
    'equipmentList.export.button': 'Export',
    'equipmentList.exportCsv.menu': 'CSV',
    'equipmentList.exportPdf.menu': 'PDF',
    'equipmentList.exportPdfNoValues.menu': 'PDF (No Values)',
    'equipmentList.exportPdfPrimaryOnly.menu': 'PDF (Primary Value Only)',
    'equipmentList.exportSheets.menu': 'Google Sheets',
    'equipmentList.exportDropboxSaver.menu': 'Images to Dropbox',
    'equipmentList.submitToIms.menu': 'Submit to IMS',
    'equipmentList.exportZip.menu': 'Images to Zip File',
    'equipmentList.exportPdf.error': 'Could not download the PDF. ({message})',
    'equipmentList.exportSheets.error':
        'Could not export to Google Sheets. ({message})',
    'equipmentList.exportDropboxSaver.error':
        'Could not save to Dropbox. ({message})',
    'equipmentList.exportZip.error': 'Could not save to zip file. ({message})',
    'equipmentList.exportZip.text':
        'An email with the zip file will arrive shortly.',
    'equipmentList.submitToIms.text':
        'This catalog has been scheduled to be submitted to IMS.',
    'equipmentList.reSubmitToIms.text':
        'This catalog has been scheduled to be resubmitted to IMS.',
    'equipmentList.submitToIms.error':
        'Could not submit this catalog to IMS. ({message})',
    'equipmentList.reSubmitToIms.error':
        'Could not resubmit this catalog to IMS. ({message})',
    'equipmentList.exportPdf.text':
        'An email with the PDF file will arrive shortly.',
    'equipmentList.exportSheets.text': 'Exported Google Sheet - ',
    'equipmentList.exportSheetsFilename.text': 'HW Catalog - {customer_name}',

    'equipmentList.addNotes.button': 'Add Notes',
    'equipmentList.editNotes.button': 'Edit Notes',
    'equipmentList.edit.button': 'Edit',
    'equipmentList.allEstimates.label': 'All {display_name}s',
    'equipmentList.meanEstimates.label': '{display_name} Mean',
    'equipmentList.yourEstimates.label': 'Your {display_name}',
    // eslint-disable-next-line
    'equipmentList.total.label': 'Heavyworth Value Total ',
    'equipmentList.yourTotal.label': 'Estimate Total',
    'equipmentList.enterEstimate.label': 'Estimate',
    'equipmentList.heavyworthValue.label': 'HeavyWorth Value',

    'equipmentList.empty.label': 'No items found.',
    'equipmentList.newItem.label': 'New Item',
    'equipmentList.shareCatalog.label': 'Share Catalog',
    'equipmentList.resendCatalog.label': 'Resend Catalog To IMS',
    'equipmentList.notes.label': 'Catalog Notes',
    'equipmentList.details.label': 'Catalog Details',
    'equipmentList.equipment.label': 'Detail',
    'equipmentList.evaluation.label': 'Valuation',
    'equipmentList.IMSform.label': ' IMS Form',
    'equipmentList.IMSformLoadButton.label': ' Load Form',
    'equipmentList.IMSformSaveButton.label': 'Save Form',
    'equipmentList.IMSformSubmitButton.label': 'Submit Form To IMS',
    'equipmentList.IMSforResubmitButton.label': 'Resubmit Form To IMS',
    'equipmentList.ViewEstimates.button': 'View Estimates',
    'equipmentList.multiSelect.title':
        '{selectedCount} {selectedCount, plural, one {asset} other {assets}} selected',
    'equipmentListEstimate.yourEstimate.label': 'Your {display_name} Estimate',
    // eslint-disable-next-line
    'equipmentListEstimate.yourEstimate.value': '${estimate, number}',
    'equipmentListEstimate.finalEstimate.label': 'HeavyWorth Value',
    // eslint-disable-next-line
    'equipmentListEstimate.finalEstimate.value': '${consensus, number}',
    'equipmentListEstimate.viewOther.button': 'View Other Estimates',
    'equipmentListEstimate.evaluationNotes.label': 'Valuation Notes',
    'equipmentListEstimate.otherEstimate.label': 'Other Estimates',
    'equipmentList.removeItem.menuItem': 'Remove from catalog',
    'equipmentList.removeItem.error':
        'Could not remove the Item from the catalog. ({message})',
    'equipmentList.location.title': 'Location',
    'equipmentList.primaryContact.label': 'Primary Contact',
    'equipmentList.primaryContactCollaborators.label':
        ' + {count} {count, plural, one {Collaborator} other {Shared With}}',
    'equipmentList.primaryContactCollaboratorsOldEval.label': ' + Shared With',
    'equipmentList.removeItem.confirm.title':
        'Delete {itemCount, plural, one {Asset} other {Assets}}?',
    'equipmentList.removeItem.confirm':
        'Are you sure you want to delete the {itemCount, plural, one {asset} other {assets}} from the catalog?',
    'equipmentList.showNotes.title': 'Catalog Notes',
    'equipmentList.showDetails.title': 'Catalog Details',
    'equipmentList.subpackage.column': 'Sub-package',
    'equipmentList.valuationType.column': 'Valuation Type',
    'equipmentList.itemStatus.column': 'Item Status',
    'equipmentList.expectedImages.column': ' Expected Images',
    'equipmentList.expectedVideos.column': 'Expected Videos',
    'equipmentList.LotNumber.column': ' Copart Lot Number',
    'equipmentList.year.column': 'Year',
    'equipmentList.make.column': 'Make',
    'equipmentList.model.column': 'Model',
    'equipmentList.description.column': 'Item Type',
    'equipmentList.hours.column': 'Hours',
    'equipmentList.miles.column': 'Miles',
    'equipmentList.otherMeters.column': 'Other Meters',
    'equipmentList.serial.column': 'Serial',
    'equipmentList.vin.column': 'Vin',
    'equipmentList.sticker.column': 'Sticker',
    'equipmentList.imsForm.column': 'ims Form',
    'equipmentList.uniqueId.column': 'Unique ID #',
    'equipmentList.inventoryId.column': 'Inventory Id',
    'equipmentList.location.column': 'Location',
    'equipmentList.estimate.column': 'Estimate',
    'equipmentList.contract.column': 'Contract Price',
    'equipmentList.current.column': 'Current Bid',
    'equipmentList.otherEstimates.column': 'All Estimates',
    'equipmentList.mean.column': 'Mean Estimate',
    'equipmentList.created.column': 'Created',
    'equipmentList.updated.column': 'Updated',
    'equipmentList.heavyworthValue.column': 'HeavyWorth',
    'equipmentList.headerText.label':
        '{count} {count, plural, one {Item} other {Items}}',

    'equipmentListSort.title.label': 'Sort By',
    'equipmentListSort.updated.label': 'Updated',
    'equipmentListSort.created.label': 'Created',
    'equipmentListSort.location.label': 'Location',
    'equipmentListSort.serial.label': 'Serial',
    'equipmentListSort.vin.label': 'VIN',
    'equipmentListSort.inventoryId.label': 'Inventory ID',
    'equipmentListSort.stickerId.label': 'Sticker ID',
    'equipmentListSort.uniqueId.label': 'Unique ID #',
    'equipmentListSort.year.label': 'Year',
    'equipmentListSort.make.label': 'Make',
    'equipmentListSort.model.label': 'Model',
    'equipmentListSort.hours.label': 'Hours',
    'equipmentListSort.miles.label': 'Miles',
    'equipmentListSort.placeholder.label': 'Sort By',

    // Edit Evaluation Dialog
    'editEvaluation.main.title': 'Edit Catalog',
    'editEvaluation.save.error': "The changes couldn''t be saved. ({message})",
    'editEvaluation.newEvaluation.title': 'Catalog Setup',
    'editEvaluation.owner.title': 'Who owns this equipment?',
    'editEvaluation.ownerId.label': 'Customer Number (required)',
    'editEvaluation.opportunityId.label': 'Opportunity ID (required)',
    'editEvaluation.feeCode.label': 'Fee Code (required)',
    'editEvaluation.owner.label': 'Customer Name (required)',
    'editEvaluation.ownerEmail.label': 'Customer Email',
    'editEvaluation.primaryContact.label': 'Primary Contact (required)',
    'editEvaluation.primaryContact.info': 'Who is managing this catalog?',
    'editEvaluation.location.title': 'Where is this equipment?',
    'editEvaluation.location.label': 'Location (required)',
    'editEvaluation.notes.title': 'More Information',
    'editEvaluation.notes.label': 'Notes',
    'editEvaluation.evaluationNotes.label': 'Valuation Notes',
    'editEvaluation.valuationReasons.label': 'Valuation Reasons',
    'editEvaluation.collaborators.title': 'Shared With',
    'editEvaluation.collaborators.info':
        'Team members added to this catalog will receive email updates when the status changes.',
    'editEvaluation.collaborators.label': 'Invite people',
    'editEvaluation.collaborators.placeholder': 'Enter names (e.g. John Smith)',
    'editEvaluation.editPrimaryContact.button': 'Edit',
    'editEvaluation.maxFiles.error': 'Maximum of {maxPhotos} files per upload',
    'editEvaluation.import_requested.label':
        'Create Items from File Attachments',

    //New Item Dialog
    'newItem.main.title': 'Item Description',
    'newItem.meter.title': 'Service Meter Reading',
    'newItem.identification.title': 'Identification Numbers',
    'newItem.location.title': 'Item Location',
    'newItem.hours.label': 'Hours',
    'newItem.miles.label': 'Miles',
    'newItem.features.title': 'Features',
    'newItem.IMSForm.title': 'IMS Form',
    'newItem.problems.title': 'Problems',
    'newItem.notes.title': 'Notes',
    'newItem.addFeature.label': 'Add Feature',
    'newItem.addProblem.label': 'Add Problem',
    'newItem.addMeter.label': 'Add Meter',
    'newItem.valuationType.label': 'Valuation Type',
    'newItem.valuationType.missing': 'No Valuation Type',
    'newItem.year.label': 'Year',
    'newItem.make.label': 'Make',
    'newItem.model.label': 'Model',
    'newItem.description.label': 'Item Type',
    'newItem.location.label': 'Location (required)',
    'newItem.cancelConfirm.title': 'Delete This Item?',
    'newItem.cancelConfirm.text':
        "This item hasn''t been saved yet. Canceling will delete this item and all its images.",
    'newItem.cancelConfirm.label': 'Keep Working',
    'newItem.cancelDelete.label': 'Delete Item',
    'newItem.cancelConfirmPhotos.title': 'Remove Changes?',
    'newItem.cancelConfirmPhotos.text':
        "This item hasn''t been saved yet. Canceling will delete the uploaded images and remove the changes to the item.",
    'newItem.cancelDeletePhotos.label': 'Remove Changes',
    'newItem.maxPhotos.error': 'Maximum of {maxPhotos} imagess per upload',
    'newItem.photoSummary.label': 'Uploading {uploaded}/{total} {type}',
    'newItem.photoComplete.label': 'Uploaded {total} {type}',
    'newItem.largeThumbnail.label': 'Large images',

    //Equipment Detail
    'equipmentDetail.updatedBy.label': 'Updated {name}{time}',
    'equipmentDetail.capture.label': 'Data Capture',
    'equipmentDetail.main.title': '{index} / {total}',
    'equipmentDetail.serial.label': 'Serial',
    'equipmentDetail.vin.label': 'VIN',
    'equipmentDetail.inventoryId.label': 'Inventory ID',
    'equipmentDetail.stickerId.label': 'Sticker ID',
    'equipmentDetail.copartLotNumber.label': 'Copart Lot Number',
    'equipmentDetail.uniqueId.label': 'Unique ID #',
    'equipmentDetail.meters.label': 'Service Meters',
    'equipmentDetail.notes.label': 'Notes',
    'equipmentDetail.attachments.label': 'Attachments',
    'equipmentDetail.features.label': 'Features',
    'equipmentDetail.description.label': 'Item Description',
    'equipmentDetail.problems.label': 'Problems',
    'equipmentDetail.links.label': 'Links',
    'equipmentDetail.estimate.label': 'Estimate',
    'equipmentDetail.next.button': 'Next Item',
    'equipmentDetail.previous.button': 'Previous Item',
    'equipmentDetail.back.button': 'Catalog View',
    'equipmentDetail.assetDetails.button': 'Item Details',
    'equipmentDetail.delete.button': 'Delete',
    'equipmentDetail.sort.button': 'Sort All by Name',
    'equipmentDetail.selected.title':
        '{count} {count, plural, one {photo} other {photos}} selected',

    //Equipment Description
    'equipmentDetail.year.label': 'Year',
    'equipmentDetail.model.label': 'Model',
    'equipmentDetail.make.label': 'Make',
    'equipmentDetail.type.label': 'Item Type',

    'equipmentDetail.recategorize.button': 'Recategorize',
    'equipmentDetail.photo.error': 'Images must be less than {maxSize} MB',
    'equipmentDetail.file.error': 'Files must be less than {maxSize} MB',
    'equipmentDetail.photoType.error':
        'Images must be JPG, PNG or Webp. {types}',
    'equipmentDetail.photoType2.error':
        '{count} {count, plural, one {file is} other {files are}} {type}',
    'equipmentDetail.photoCount.text':
        '*Maximum of {maxPhotos} images per upload',
    'equipmentDetail.oneProfile.text': '*One profile image upload at at time.',
    'equipmentDetail.photoCount.error':
        'Image limit exceeded. Please remove images to stay under the {maxPhotos} image limit.',
    'equipmentDetail.leavePage.warning':
        'You have made changes, which have not been saved. Do you want to discard changes?',
    'equipmentDetail.save.error': "The changes couldn''t be saved. ({message})",
    'equipmentDetail.savePhotos.error':
        "The images couldn''t be saved. ({message})",
    'equipmentDetail.saveWhileUploading.info':
        'Saving this item will complete when images are finished uploading.',
    'equipmentDetail.fetch.error':
        'Could not load item information. Please refresh your browser.',
    'equipmentDetail.imageRotate.label': 'Rotate 90°',
    'equipmentDetail.imageZoom.label': 'Zoom',
    'equipmentDetail.none.label': 'None',
    'equipmentDetail.editAsset.button': 'Edit Item',
    'equipmentDetail.resendAssetToIMS.button': 'Resend Item To IMS',
    'equipmentDetail.editCatalog.button': 'Edit Catalog',
    'equipmentDetail.updatedBy.text': 'by {name} ',
    'equipmentDetail.imagesLoading.text':
        'Images are uploading. {count} of {total}\n Leaving this page will interrupt the uploading',

    // Edit Equipment Detail
    'equipmentEditDetail.photoVin.label': 'VIN #',
    'equipmentEditDetail.photoSerial.label': 'Serial',
    'equipmentEditDetail.photoSticker.label': 'Sticker ID',
    'equipmentEditDetail.photoProfile.label': 'Profile',
    'equipmentEditDetail.photoAdditional.label': 'Additional',
    'equipmentEditDetail.photoOdometer.label': 'Odometer',
    'equipmentEditDetail.photoMeter.label': 'Meter',
    'equipmentEditDetail.year.error':
        'Enter a year between {firstYear} and {lastYear}.',
    'equipmentEditDetail.ownerEmail.error': 'The email is not valid.',

    //Login
    'login.title': 'Sign in to HeavyWorth.',
    'login.email.label': 'Email',
    'login.password.label': 'Password',
    'login.signIn.button': 'Sign In',
    'login.reset.link': 'Reset your password',
    'login.forgot.label': 'Forgot password?',
    'login.codeSignIn.label': 'Sign in without typing password.',
    'login.codeSignIn.link': 'Email me a sign-in code',
    'login.error':
        "We didn''t recognize this email or password. Try again or click ''Reset your password.''",
    'login.success':
        'Success! Your password has been reset. You can now log in with your new password.',
    'login.supportInstructions': '',

    //Reset Password
    'reset.title': 'Reset Your Password',
    'reset.subheading':
        'Type your email below. We will email you a code that you can use in the next step to reset your password.',
    'reset.button.label': 'Reset Password',
    'reset.error':
        "We couldn''t reset your password. Enter your account email.",
    'submit.button.label': 'Set New Password',
    'submit.subheading':
        'Enter the code we sent to {email} and then type your new password.',
    'submit.code.label': 'Code',
    'submit.error': "We couldn''t use the code or set the new password.",

    'signInOptions.title': 'Want to skip the password?',
    'signInOptions.subtitle':
        'We will email you a code that instantly signs you in.',
    'signInOptions.email.label': 'Email me a sign-in code.',
    'signInOptions.signin.label': "I''ll sign in manually",

    'signInEmail.title': 'We’ll send a sign-in code to this email address.',
    'signInEmail.email.label': 'Email',
    'signInEmail.email.placeholder': 'Your email address',
    'signInEmail.email.button': 'Send',
    'signInEmail.emailMissing.error': 'Enter an email address',
    'signInEmail.emailInvalid.error': 'Enter a valid email address',

    'checkEmail.title': 'Check your email.',
    'checkEmail.subtitle': 'We sent a code to ',
    'checkEmail.subtitle2': '. Enter the code to instantly sign in.',
    'checkEmail.noEmail.text': 'Didn’t get an email? Contact us to get help:',
    'checkEmail.signin.label': 'Sign in manually',
    'checkEmail.code.label': 'Sign-in code (required)',
    'checkEmail.code.error': 'Enter the code from the email.',
    'checkEmail.invalidCode.error':
        'Sorry, that code isn’t right. Enter the code from the email here.',
    'checkEmail.code.placeholder': 'Enter the code from the email',

    //Workflow Status
    capture_in_progress: 'Capture in Progress',
    capture_complete: 'Capture Complete',
    sent_to_ims: 'Sent to IMS',

    //Admin
    'admin.organizations.title': 'Organizations',
    'admin.overview.label': 'Overview',
    'admin.settings.label': 'Settings',
    'admin.users.label': 'Users',
    'admin.newUser.label': 'New User',
    'admin.allOrganizations.label': 'All Organizations',
    'admin.administration.title': 'Administrator',
    'admin.deleteOrganization.title': 'Delete Organization',
    'admin.deleteOrganization.button': 'Delete Organization',
    'admin.deleteOrganization.text': 'Delete {orgName} from HeavyWorth',
    'admin.deleteOrganizationConfirm.title': 'Delete Organization?',
    'admin.deleteOrganizationConfirm.text':
        'Are you sure you want to delete {orgName}? This cannot be undone.',
    'admin.deleteOrganizationConfirm.error':
        'The organization could not be deleted.',

    'admin.name.column': 'Name',
    'admin.email.column': 'Email',
    'admin.organization.column': 'Organization',
    'admin.deleteUser.label': 'Delete User',

    'admin.editUser.label': 'Edit User',
    'admin.resetUserPassword.label': 'Reset Password',

    'admin.deleteUser.title': 'Delete User?',
    'admin.deleteUser.text':
        'Are you sure you want to delete {user}? This cannot be undone.',
    'admin.deleteUser.error': 'The user could not be deleted. ({message})',

    'admin.resetPassWord.title': 'Reset Password?',
    'admin.resetPassWord.text':
        'Are you sure you want to reset {user} password?',
    'admin.resetPassWord.error': 'The  user Password reset failed. ({message})',

    'newOrganization.main.title': 'New Organization',
    'newOrganization.orgName.label': 'Organization Name (required)',
    'newOrganization.admin.title': 'Administrator Information',
    'newOrganization.admin.info':
        'Who is the administrator for this organization?',
    'newOrganization.name.label': 'Name (required)',
    'newOrganization.email.label': 'Email (required)',
    'newOrganization.phone.label': 'Phone (required)',
    'newOrganization.submit.label': 'Create Organization',
    'newOrganization.save.error': "The changes couldn''t be saved. ({message})",
    'organizations.fetch.error':
        'Could not load organizations. Please refresh your browser. ({message})',

    'newUser.main.title': 'New User',
    'newUser.name.label': 'Name (required)',
    'newUser.email.label': 'Email (required)',
    'newUser.organization.label': 'Organization',
    'newUser.submit.label': 'Create User',
    'newUser.password.label': 'Password (required)',
    'newUser.save.error': "The changes couldn''t be saved. ({message})",
    'users.fetch.error':
        'Could not load users. Please refresh your browser. ({message})',

    'editUser.main.title': 'Edit User',
    'editUser.name.label': 'Name',
    'editUser.email.label': 'Email',
    'editUser.password.label': 'Password',
    'editUser.submit.label': 'Save User',

    // Photo Detail
    'photoDetail.delete.menuItem': 'Delete Image',
    'photoDetail.delete.confirm.title':
        'Delete {count, plural, one {Photo} other {Photos}}?',
    'photoDetail.delete.confirm':
        'Are you sure you want to delete the {count, plural, one {photo} other {photos}}?',
    'photoDetail.delete.error': 'The image could not be deleted ({message})',
    'photoDetail.changeCategory.error':
        'The image category could not be changed ({message})',
    'photoDetail.resendImageToIMS.menuItem': 'Resend Image To IMS',
    'photoDetail.changeCategory.menuItem': 'Change Image Category',
    'photoDetail.moveFirst.menuItem': 'Move First',
    'photoDetail.movePrevious.menuItem': 'Move Previous',
    'photoDetail.moveNext.menuItem': 'Move Next',
    'photoDetail.moveLast.menuItem': 'Move Last',
    'photoDetail.sortAllPhotos.menuItem': 'Sort All Images by Name',
    'photoDetail.changeCategory.title': 'Change Category',
    'photoDetail.selectCategory.title': 'Select Image Category: ',
    'photoDetail.photoCategory.title': 'Image Category',
    'photoDetail.changeCategory.submit': 'Done',
    'photoDetail.changeCategory.profile': 'Profile',
    'photoDetail.changeCategory.additional': 'Additional',
    'photoDetail.changeCategory.miles': 'Miles',
    'photoDetail.changeCategory.vin': 'VIN',
    'photoDetail.changeCategory.serial': 'Serial',
    'photoDetail.changeCategory.hourMeter': 'Hour Meter',
    'photoDetail.changeCategory.sticker': 'Sticker',
    'photoDetail.changeCategory.external': 'External',
    'photoDetail.changeCategory.internal': 'Internal',
    'photoDetail.changeCategory.engine': 'Engine',
    'photoDetail.changeCategory.trackTires': 'Tires / Tracks',
    'photoDetail.changeCategory.odometer': 'Odometer',
    'photoDetail.changeCategory.inventory': 'Inventory',
    'photoDetail.changeCategory.kilomters': 'Kilometers',
    'photoDetail.changeCategory.meterAcres': 'Acres',
    'photoDetail.changeCategory.unknown': 'Attachment',
    'photoDetail.changeCategory.document': 'Document',
    'photpDetail.changeCategory.video': 'Video',
    'photoDropzone.message.text': 'Dropbox blocked by your ad blocker',

    // Subscribe notifications
    'subscribe.turnOn.text':
        'Email notifications on. We’ll email\nyou when this valuation is finalized.',
    'subscribe.turnOff.text': 'Email notifications are off.',
    'subscribe.fetch.error': 'Subscriptions could not be loaded ({message}).',

    // Valuations
    'valuation.assetOwner.title': 'Customer Name',
    'valuation.download.button': 'Download',
    'valuation.fetch.error':
        'Could not load files. Please refresh your browser. ({message})',
    'valuation.download.error': 'Could not download the file. ({message})',
    'valuation.noCatalogs.title': 'No catalogs available',
    'valuation.noCatalogs.message':
        'To get help locating a catalog, contact support at ',
    'valuation.fetchValuation.error':
        'Catalog could not be loaded ({message}).',

    // Email subscribe
    'emailSubscribe.subscribe.checkbox': 'Email me about new features.',
    'emailSubscribe.subscribeMenu.checkbox': 'Email me about\nnew features.',
    'emailSubscribe.subscribe.title': 'Email Updates',
    'emailSubscribe.subscribe.note':
        'HeavyWorth sends out email updates when we release new features. Typically this is\nonce every couple of weeks.',
    'emailSubscribe.signOut.button': 'Sign Out',

    // Subpackages
    'subpackage.add.button': 'Add to Sub-package',
    'subpackage.clear.button': 'Clear Sub-package',
    'subpackage.new.button': 'Create New',
    'subpackage.title': 'Sub-packages',
    'subpackage.all.label': 'All Sub-packages',

    // Collaborators
    'collaborator.download.error':
        'Could not download the collaborators. ({message})',

    // Valuation Types
    'valuationType.heavyWorth.label': 'HeavyWorth Value (FMV)',
    'valuationType.internal.label': 'Internal Value',
    'valuationType.profile.label': 'Desktop Value',
    'valuationType.self.label': 'Owner/Representative Value',
    'valuationType.none.label': 'List Item/No Value',
    'valuationType.heavyWorth.short.label': '<b>HeavyWorth</b>',
    'valuationType.profile.short.label': 'Profile',
    'valuationType.self.short.label': 'Self',
    'valuationType.none.short.label': 'None',
    'valuationType.placeholder.label': 'Select a Valuation Option',
    'valuationType.title': 'Select a valuation option for this item:',

    // Customer Inventory
    'customerInventory.sentToPurpleWave.status': 'Sent to PurpleWave',
    'customerInventory.notSentToPurpleWave.status': 'Not Sent to PurpleWave',

    'showEmptyColumns.label': 'Show Empty Item Columns',

    // Edit Links
    'link.type.placeholder': 'details',
    'link.text.placeholder': 'Acme',
    'link.link.placeholder': 'https://www.acme.org',
    'link.edit.title': 'Edit Link',
    'link.add.title': 'Add Link',
    'link.type.label': 'Type (required)',
    'link.text.label': 'Text',
    'link.link.label': 'Link (required)',
    'link.delete.confirm.title': 'Delete Link?',
    'link.delete.confirm':
        'Are you sure you want to delete the link, "{link}"?',
    'link.delete.error': 'The link could not be deleted ({message})',
    'link.create.error': 'The link could not be created ({message})',
    'link.update.error': 'The link could not be updated ({message})',

    //Search Past Catalogs
    'search.assets.title': 'Items Search',
    'search.catalog.title': 'Catalogs Search',
    'search.go.button': 'Go to catalog',
    'search.noAssetsFound.text': 'No items found.',
    'search.noCatalogsFound.text': 'No catalogs found.',
    'search.noSearch.text': 'Enter a new search and click the search button.',
}
