import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import EditIcon from '@material-ui/icons/Edit'
import { Storage } from 'aws-amplify'
import {
    maxBy,
    pullAllBy,
    sortBy,
    sortedIndexBy,
    defer,
    differenceBy,
    map,
    countBy,
} from 'lodash'
import get from 'lodash/get'
import React, { Component, Fragment } from 'react'
import { injectIntl } from 'react-intl'
import { withRouter } from 'react-router-dom'
import { v4 as uuid } from 'uuid'
import {
    SAVE_EVALUATIONS,
    GET_ORGANIZATION_USERS,
    GET_EVAL_SUBSCRIPTIONS,
    SUBSCRIBE,
    MAX_PHOTO_SIZE,
    PHOTO_STATE_ATTATCHED,
    PHOTO_STATE_ERROR,
} from '../../../Constants'
import ModalDialog from '../../../fhg/components/dialog/ModalDialog'
import DisplayError from '../../../fhg/components/DisplayError'
import ReactSelect from '../../../fhg/components/ReactSelect'
import Typography from '../../../fhg/components/Typography'
import AllowGroup from '../../../fhg/security/AllowGroup'
import { removeOne, formatMessage, editChange } from '../../../fhg/utils/Utils'
import {
    requestForServer,
    getUserData,
    withRequest,
} from '../../../Utils/ServerUtil'
import FormattedTextField from '../components/FormattedTextField'
import { MAX_NEW_PHOTO_UPLOAD } from './NewItemDialog'
import PhotosDropZone from './PhotosDropZone'
import GridFHG from '../../../fhg/components/Grid'
import eventCapture from '../../../eventList'
import { posthogCapture } from '../../../PostHogFunction'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { FTInput } from '../../inputs'
import { FTDropdown, FTMultiDropdown } from '../../dropdowns'

const requestStyles = {
    progress: {
        position: 'absolute',
        marginLeft: '47%',
        top: 46,
    },
}

const styles = (theme) => ({
    contentStyle: {
        display: 'flex',
        padding: 0,
    },
    formStyle: {
        overflow: 'hidden',
        padding: theme.spacing(3, 3, 0),
    },
    fullWidthInput: {
        marginTop: `${theme.spacing(1)}px !important`,
        height: 30,
        width: '100% !important',
    },
    fullWidthInput2: {
        marginTop: `${theme.spacing(1)}px !important`,
        width: '100% !important',
    },
    spinnerMargin: {
        marginLeft: theme.spacing(0.5),
    },
    mediumTopPad: {
        marginTop: theme.spacing(1.5),
    },
    fullWidthStyle: {
        width: '100%',
        display: 'block',
    },
    actionStyle: {
        height: 60,
        margin: 0,
        padding: theme.spacing(0, 4.5),
        backgroundColor: theme.palette.environment.light.level1.base,
        borderTop: `1px solid ${theme.palette.environment.light.level1.accent}`,
    },
    largeUnrelatedPad: {
        padding: `${theme.spacing(4.5)}px 0 ${theme.spacing(1.5)}px`,
        display: 'block',
        width: '100%',
        marginBottom: '1rem',
    },
    largeRelatedPad: {
        padding: `0 0 ${theme.spacing(2)}px`,
    },
    smallRelatedPad: {
        padding: theme.spacing(3, 0, 1),
    },
    fatButtonStyle: {
        padding: '12px 30px !important',
        width: 'unset',
        height: 'unset',
    },
    titleStyle: {
        paddingLeft: theme.spacing(4.5),
    },
    checkboxStyle: {
        color: '#4f2682 !important',
    },
    collaboratorsButtonStyle: {
        composes: 'minimal-secondary-button',
        width: 130,
    },
    dropZoneStyle: {
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(2, 2, 2, 0),
        },
        [theme.breakpoints.down('sm')]: {
            margin: 0,
        },
        maxHeight: '100%',
    },
    editInfoStyle: {
        overflow: 'hidden',
        [theme.breakpoints.up('md')]: {
            borderRight: `1px solid ${theme.palette.divider} !important`,
            height: '100%',
        },
        [theme.breakpoints.down('sm')]: {
            height: 'auto !important',
        },
    },
    root: {
        overflow: 'hidden',
        [theme.breakpoints.up('md')]: {
            height: '100%',
        },
        [theme.breakpoints.down('sm')]: {
            overflow: 'auto',
        },
    },
})

// Define dummy opportunity data

/**
 * The Estimates notes dialog.
 */
class NewEvaluationDialog extends Component {
    constructor(props) {
        super(props)

        const primaryContactName = get(
            props,
            'evaluation.evaluation.primary_contact',
            getUserData().name
        )
        const primaryContactId = get(
            props,
            'evaluation.evaluation.primary_contact_id',
            getUserData().username
        )
        this.state = {
            ownerName: get(props, 'evaluation.evaluation.owner_name', ''),
            owner: get(props, 'evaluation.evaluation.owner', ''),
            customerNumber: get(props, 'evaluation.evaluation.owner_id', ''),
            opportunityId: get(
                props,
                'evaluation.evaluation.opportunity_id',
                ''
            ),
            fee_code: get(props, 'evaluation.evaluation.fee_code', ''),
            opportunityData: [],
            newLocation: get(props, 'evaluation.evaluation.location', ''),
            primaryContact: {
                label: primaryContactName,
                value: primaryContactId,
            },
            notes: get(props, 'evaluation.evaluation.notes', ''),
            evaluationNotes: get(
                props,
                'evaluation.evaluation.valuation_notes',
                ''
            ),
            import_requested: get(
                props,
                'evaluation.evaluation.import_requested',
                false
            ),
            collaborators: [],
            userOptions: [],
            isSaving: false,
            fileAttachments: get(props, 'evaluation.attachment_data') || [],
            newFileAttachments: [],
            valuation_reasons: map(
                get(
                    props,
                    'evaluation.evaluation.valuation_reasons',
                    getUserData().valuation_reason_default
                ),
                (reason) => ({ label: reason, value: reason })
            ),
            showFileError: false,
            selectedValues: [],
            submitted: false,
        }
    }

    componentDidMount() {
        const selectOptions = ReactSelect.createOptionsFromObject(
            this.props.data,
            'name',
            'user_id'
        )
        const userOptions = sortBy(selectOptions, 'label')
        pullAllBy(userOptions, [this.state.primaryContact], 'value')
        this.setState({ userOptions })

        if (get(this.props, 'evaluation.evaluation')) {
            defer(async () => {
                try {
                    const evalId = get(
                        this.props,
                        'evaluation.evaluation.eval_id',
                        get(this.props, 'evaluation.evaluation.PK')
                    )
                    if (evalId) {
                        //Get all the existing colloborators.
                        const result = await requestForServer(
                            GET_EVAL_SUBSCRIPTIONS.format({ evalId })
                        )

                        if (result.data && result.data.length > 0) {
                            const collaborators = []
                            const primaryContactId = get(
                                this.props,
                                'evaluation.evaluation.primary_contact_id',
                                getUserData().username
                            )

                            // Add the collaborators to the list. Make sure not to add the authenticated user as a
                            // collaborator.
                            for (const subscription of result.data) {
                                if (primaryContactId !== subscription.user_id) {
                                    collaborators.push({
                                        label: subscription.name,
                                        value: subscription.user_id,
                                    })
                                }
                            }
                            // Remove the collaborators from the list of users to select from.
                            const userOptions = differenceBy(
                                this.state.userOptions,
                                collaborators,
                                'value'
                            )
                            this.setState({ collaborators, userOptions })
                        }
                    }
                } catch (error) {
                    this.setState({
                        showError: true,
                        errorId: 'fetch.error',
                        ...error,
                    })
                    console.log(error)
                }
            })
        }
    }

    /**
     * Handle changes to the item.
     * @param target The target of the event that triggered the change.
     */
    handleChange = ({ target }) => {
        this.setState({ [target.name]: target.value, isChanged: true })
    }

    handleEdit = () => {
        this.setState({ isEditPrimaryContact: true })
    }

    handleRemove = (index) => () => {
        const collaborators = this.state.collaborators.slice(0)
        const userOptions = [...this.state.userOptions, collaborators[index]]
        removeOne(collaborators, index)
        this.setState({ collaborators, userOptions, isChanged: true })
    }

    /**
     * Handle changes to a react select component.
     * @param value The value of the react select.
     * @param name The name of the react select.
     */
    handleSelectChange = (value, name) => {
        if (name) {
            const sortedIndex = sortedIndexBy(
                this.state.userOptions,
                this.state[name],
                'value'
            )
            const userOptions = this.state.userOptions.slice(0)
            userOptions.splice(sortedIndex, 0, this.state[name])
            pullAllBy(userOptions, [value], 'value')
            this.setState(
                {
                    [name]: value,
                    isEditPrimaryContact: false,
                    userOptions,
                    isChanged: true,
                },
                this.onChange
            )
        } else if (value && value.preventDefault) {
            value.stopPropagation()
            value.preventDefault()
        }
    }

    handleReasonSelect = (selectedList, selectedItem) => {
        this.setState({
            selectedValues: selectedList,
        })
    }
    handleSingleSelect = (selected) => {
        this.setState({
            opportunityId: selected,
        })
    }

    handleCustomerNumberChange = (event) => {
        const { value } = event.target
        this.setState({ customerNumber: value, isChanged: true })
    }

    handleOpportunityNumberChange = (event) => {
        const { value } = event.target
        this.setState({ opportunityId: value, isChanged: true })
    }

    /**
     * Handle changes to a react select component.
     * @param value The value of the react select.
     * @param name The name of the react select.
     */
    handleSelectArrayChange = (value, name) => {
        if (name) {
            const list = [...this.state[name], value]
            const userOptions = pullAllBy(
                this.state.userOptions.slice(0),
                list,
                'value'
            )
            this.setState(
                {
                    userOptions,
                    [name]: [...this.state[name], value],
                    isChanged: true,
                },
                () => {
                    const elements = document.querySelectorAll(
                        `[name="collaboratorsInput"]`
                    )
                    if (elements && elements[0]) {
                        const inputElements =
                            elements[0].getElementsByTagName('input')
                        if (inputElements && inputElements.length > 0) {
                            inputElements[0].scrollIntoView &&
                                inputElements[0].scrollIntoView()
                            inputElements[0].focus({ preventScroll: false })
                        }
                    }

                    this.onChange && this.onChange()
                }
            )
        }
    }

    /**
     * Submits the changes to the item and photos.
     */
    onSubmit = async (event) => {
        event.preventDefault()

        this.setState({ submitted: true })

        const {
            isChanged,
            isSaving,
            owner,
            ownerName,
            customerNumber,
            newLocation,
            notes,
            primaryContact,
            collaborators,
            newFileAttachments,
            import_requested,
            valuation_reasons,
            evaluationNotes,
            opportunityId,
            fee_code,
        } = this.state
        const { location, history, onClose, evaluation } = this.props

        const isNew = !evaluation

        if (isChanged && !isSaving) {
            this.setState({ isSaving: true })
            try {
                const item = {}
                const evalId = isNew
                    ? uuid()
                    : encodeURIComponent(evaluation.evaluation.PK)
                item.owner = owner || undefined
                item.owner_name = ownerName || undefined
                item.owner_id = customerNumber || undefined
                item.opportunity_id = opportunityId || undefined
                item.fee_code = fee_code || undefined
                item.location = newLocation || undefined
                item.notes = notes || undefined
                item.primary_contact =
                    (primaryContact && primaryContact.label) || undefined
                item.primary_contact_id = primaryContact && primaryContact.value
                item.subscriptions = map(collaborators, 'value')
                item.import_requested = import_requested
                item.valuation_notes = evaluationNotes || undefined
                item.valuation_reasons =
                    valuation_reasons && valuation_reasons.length > 0
                        ? map(valuation_reasons, 'value')
                        : null
                if (isNew) {
                    item.subscriptions.push(item.primary_contact_id)
                    item.created = new Date().toISOString()
                    item.updated = item.created
                    item.initial_eval = evalId
                } else {
                    item.workflow_status = evaluation.evaluation.workflow_status
                    item.created = evaluation.evaluation.created
                    item.updated = new Date().toISOString()
                }

                const authUserInCollaborators =
                    item.subscriptions.indexOf(getUserData().username) >= 0
                const result = await requestForServer(
                    SAVE_EVALUATIONS.format({ evalId }),
                    'put',
                    item
                )
                if (!result.error) {
                    // window?.posthog?.capture(isNew ? eventCapture.SAVE_NEW_EVALUATION : eventCapture.SAVE_EVALUATION);
                    posthogCapture(
                        isNew
                            ? eventCapture.NEW_EVALUATION
                            : eventCapture.CURRENT_SAVE_EVALUATION
                    )
                    posthogCapture(eventCapture.CURRENT_SAVE_EVALUATION)

                    if (!isNew) {
                        evaluation.evaluation.owner_name = item.owner_name
                        evaluation.evaluation.owner = item.owner
                        evaluation.evaluation.opportunity_id =
                            item.opportunity_id
                        evaluation.evaluation.fee_code = item.fee_code
                        evaluation.evaluation.location = item.location
                        evaluation.evaluation.notes = item.notes
                        evaluation.evaluation.primary_contact =
                            item.primary_contact
                        evaluation.evaluation.primary_contact_id =
                            item.primary_contact_id
                        evaluation.evaluation.subscriptions = item.subscriptions
                        evaluation.evaluation.import_requested =
                            item.import_requested
                        evaluation.evaluation.valuation_reasons =
                            item.valuation_reasons
                        evaluation.evaluation.valuation_notes =
                            item.valuation_notes
                        if (authUserInCollaborators !== this.props.subscribed) {
                            await requestForServer(
                                SUBSCRIBE.format({ evalId }),
                                authUserInCollaborators ? 'post' : 'del'
                            )
                            evaluation.subscribed = authUserInCollaborators
                        }
                    }
                    await this.uploadFiles(
                        newFileAttachments,
                        get(result, 'data.eval_id')
                    )
                    if (!isNew) {
                        evaluation.attachment_data = [
                            ...(evaluation.attachment_data || []),
                            ...newFileAttachments,
                        ]
                    }

                    this.setState({ isChanged: false, isSaving: false })
                    if (isNew) {
                        history.replace(`/evaluations/${item.initial_eval}`, {
                            ...location.state,
                            isNewEval2: false,
                        })
                    }
                    onClose && onClose()
                } else {
                    this.setState({
                        isSaving: false,
                        showError: true,
                        errorId: 'editEvaluation.save.error',
                        ...result,
                    })
                }
                window.location.reload()
            } catch (error) {
                this.setState({
                    isSaving: false,
                    showError: true,
                    errorId: 'editEvaluation.save.error',
                    ...error,
                })
            }
        } else if (!isChanged) {
            onClose && onClose()
        }
    }

    getUserGroups = (value) => {
        return (
            <>
                <span>
                    We couldn't find anyone named <b>{value.inputValue}</b> at{' '}
                    {get(getUserData(), 'groups', []).join(', ') || 'N/A'}
                </span>
            </>
        )
    }

    /**
     * Upload all the files to the server.
     *
     * @param files Files to upload
     * @param uuid The uuid of the catalog to load the files to.
     * @return {Promise<void>} The promise for the files being uploaded.
     */
    uploadFiles = async (files = [], uuid) => {
        for (const file of files) {
            const imageKey = `${uuid}/${file.name}`

            try {
                const result = await Storage.put(imageKey, file, {
                    level: 'public',
                    contentType: file.type,
                })
                if (result && result.status === 400) {
                    file.__status = PHOTO_STATE_ERROR
                    console.log('Failed - ' + result.key)
                } else {
                    file.__status = PHOTO_STATE_ATTATCHED
                }
            } catch (error) {
                file.__status = PHOTO_STATE_ERROR
                console.log('Failed file upload - ' + error)
            }
        }
    }

    /**
     * Add the new files to the file attachments to upload for the catalog. The same error conditions are checked for
     * files as for photos for size and maximum to upload at a time.
     *
     * @param newFiles The new files to upload.
     */
    addFiles = (newFiles) => {
        const { files = [], newFileAttachments = [] } = this.state

        if (newFiles && newFiles.length > 0) {
            if (
                newFiles &&
                newFiles.length + files.length + newFileAttachments.length <=
                    MAX_NEW_PHOTO_UPLOAD
            ) {
                const maxSizeFile = maxBy(newFiles, 'size')
                if (maxSizeFile && maxSizeFile.size < MAX_PHOTO_SIZE) {
                    const sortedNewFiles = sortBy(newFiles, ['name'])
                    const sortedAllNewFileAttachments =
                        newFileAttachments.length > 0
                            ? sortBy(
                                  [...newFiles, ...newFileAttachments],
                                  ['name']
                              )
                            : sortedNewFiles
                    this.setState({
                        newFileAttachments: sortedAllNewFileAttachments,
                        showFileError: false,
                        errorId: undefined,
                        isChanged: true,
                    })
                } else {
                    this.setState({
                        errorId: 'equipmentDetail.file.error',
                        errorValues: { maxSize: MAX_PHOTO_SIZE / 1024 / 1024 },
                    })
                }
            } else {
                this.setState({
                    errorId: 'editEvaluation.maxFiles.error',
                    errorValues: { maxPhotos: MAX_NEW_PHOTO_UPLOAD },
                })
            }
        }
    }

    /**
     * When the list of files is changed, add the files to be uploaded.
     * @param event The event that changed the list of files.
     */
    handleFileChange = (event) => {
        this.addFiles(event.target.files)
    }

    /**
     * When the checkbox is changed, set the state.
     * @param event The event that changed the checkbox.
     */
    handleCheckboxChange = (event) => {
        event.stopPropagation()
        event.preventDefault()

        this.setState({ ...editChange(event), isChanged: true })
    }

    opportunityOptions = (data) => {
        return data.map((opportunity) => ({
            value: opportunity.id,
            label: opportunity.id,
        }))
    }

    locationsOptions = (locations) => {
        return locations.map((location) => ({
            value: location,
            label: location,
        }))
    }

    feeCodeToOptions = (data) => {
        return data.map((feeCode) => ({
            value: feeCode,
            label: feeCode,
        }))
    }

    locationsArray = [
        'A',
        'B',
        'C',
        'D',
        'E',
        'F',
        'G',
        'H',
        'I',
        'J',
        'K',
        'L',
        'M',
        'N',
        'O',
        'P',
        'Q',
        'R',
        'S',
        'T',
        'U',
        'V',
        'W',
        'X',
        'Y',
        'Z',
        'ZA',
        'ZB',
        'ZC',
        'ZD',
        'ZE',
        'ZF',
        'ZG',
        'ZH',
        'ZI',
        'ZJ',
        'ZK',
        'ZL',
        'ZM',
        'ZN',
        'ZO',
        'ZP',
        'ZQ',
        'ZR',
        'ZS',
        'ZT',
        'ZU',
        'ZV',
        'ZW',
        'ZX',
        'ZY',
        'ZZ',
    ]

    feeCodeArray = [
        'A - $100 Listing Fee',
        'B - $100 Listing Fee',
        'C - Listing Waived',
        'D - Listing Waived',
        'G - Listing Waived',
        'H - Listing Waived',
        'Z - Listing Waived',
        'B',
    ]

    render() {
        const { intl, classes, open, onClose, evaluation } = this.props
        const {
            fee_code,
            newLocation,
            isSaving,
            isEditPrimaryContact,
            showError,
            errorId,
            error,
            fileAttachments,
            newFileAttachments,
            showFileError,
            opportunityId,
            submitted,
        } = this.state
        const { processing = 0, uploading = 0 } = countBy(
            newFileAttachments,
            '__status'
        )
        const total = processing + uploading
        const remaining = total - uploading
        const isNew = !evaluation

        return (
            <ModalDialog
                open={open}
                onClose={onClose}
                onSubmit={this.onSubmit}
                titleKey={
                    !evaluation
                        ? 'editEvaluation.newEvaluation.title'
                        : 'editEvaluation.main.title'
                }
                classes={{
                    titleStyle: classes.titleStyle,
                    formStyle: classes.formStyle,
                    contentStyle: classes.contentStyle,
                }}
                submitKey={'save.button'}
                isSaving={isSaving}
                isForm={true}
                useCaptureKeydown={false}
                maxWidth={'md'}
                fullWidth
            >
                <Grid
                    container
                    spacing={4}
                    className={classes.root}
                    direction={'row'}
                >
                    <GridFHG
                        container
                        item
                        xs={12}
                        md={6}
                        className={classes.editInfoStyle}
                        isScrollable
                        fullWidth
                    >
                        {showError && (
                            <DisplayError error={error} errorId={errorId} />
                        )}
                        <Typography
                            className={`dark-level-3-heading`}
                            id={'editEvaluation.owner.title'}
                        />
                        <Typography
                            id="editEvaluation.owner.label"
                            className={`${classes.mediumTopPad} ${classes.fullWidthStyle} label-text`}
                        />
                        <FormattedTextField
                            name="ownerName"
                            //label="editEvaluation.owner.label"
                            onChange={this.handleChange}
                            placeholder="Doug Jones"
                            value={this.state.ownerName}
                            required
                            fullWidth
                            inputProps={{ className: classes.fullWidthInput }}
                            disabled={isSaving}
                            error={submitted && !this.state.ownerName}
                            helperText={
                                submitted &&
                                !this.state.ownerName &&
                                'Add owner name to create a catalog'
                            }
                        />
                        <Typography
                            id="editEvaluation.ownerId.label"
                            className={`${classes.mediumTopPad} ${classes.fullWidthStyle} label-text`}
                        />
                        <FormattedTextField
                            name="customerNumber"
                            required
                            fullWidth
                            value={this.state.customerNumber}
                            onChange={this.handleCustomerNumberChange}
                            margin="none"
                            placeholder={'e.g 1233445'}
                            inputProps={{ className: classes.fullWidthInput }}
                            disabled={isSaving}
                            format="number"
                            error={submitted && !this.state.customerNumber}
                            helperText={
                                submitted &&
                                !this.state.customerNumber &&
                                'Add customer number to create a catalog'
                            }
                        />
                        <FormControl fullWidth>
                            <Typography
                                id="editEvaluation.opportunityId.label"
                                className={`${classes.mediumTopPad} ${classes.fullWidthStyle} label-text`}
                            />
                            <FormattedTextField
                                name="opportunityId"
                                required
                                // fullWidth
                                value={opportunityId}
                                onChange={this.handleOpportunityNumberChange}
                                margin="none"
                                placeholder={'e.g 1233445'}
                                inputProps={{
                                    className: classes.fullWidthInput,
                                }}
                                disabled={isSaving}
                                format="number"
                                error={submitted && !opportunityId}
                                helperText={
                                    submitted &&
                                    !opportunityId &&
                                    'Add an opportunity to create a catalog'
                                }
                            />
                        </FormControl>
                        <FormControl fullWidth>
                            <Typography
                                id="editEvaluation.feeCode.label"
                                className={`${classes.mediumTopPad} ${classes.fullWidthStyle} label-text`}
                            />
                            <ReactSelect
                                name="fee_code"
                                value={
                                    fee_code
                                        ? { value: fee_code, label: fee_code }
                                        : null
                                }
                                onChange={(selectedOption) => {
                                    console.log(
                                        'Selected Fee Code:',
                                        selectedOption
                                    ) // Log the selected fee code
                                    this.setState(
                                        {
                                            fee_code: selectedOption.value,
                                            isChanged: true,
                                        },
                                        () => {
                                            console.log(
                                                'Updated feeCode:',
                                                this.state.fee_code
                                            ) // Log the updated fee code state
                                        }
                                    )
                                }}
                                options={this.feeCodeToOptions(
                                    this.feeCodeArray
                                )}
                                isMulti={false} // Ensure single selection
                                isSearchable
                                isDisabled={isSaving}
                                fullWidth
                                required
                                inputProps={{
                                    className: classes.fullWidthInput2,
                                }}
                                margin="none"
                                placeholder={'Select Fee Code'}
                                error={submitted && !fee_code}
                                helperText={
                                    submitted &&
                                    !fee_code &&
                                    'Add fee code to create a catalog'
                                }
                            />
                        </FormControl>
                        {/* <div style={{ margin: '12px 0' }}></div> */}
                        <AllowGroup group={'Customer'} allowIfInGroup={false}>
                            <Typography
                                id="editEvaluation.ownerEmail.label"
                                className={'label-text'}
                                style={{ marginTop: 16 }}
                                display={'block'}
                            >
                                Customer Email
                            </Typography>
                            <FormattedTextField
                                name="owner"
                                title="Owner Email"
                                type="email"
                                fullWidth
                                value={this.state.owner}
                                onChange={this.handleChange}
                                margin="none"
                                placeholder={'doug@mail.com'}
                                inputProps={{
                                    className: classes.fullWidthInput,
                                }}
                                disabled={isSaving}
                            />
                        </AllowGroup>
                        <Typography
                            id="editEvaluation.location.title"
                            variant={'inherit'}
                            className={`${classes.largeUnrelatedPad} dark-level-3-heading`}
                            display={'block'}
                        />

                        <Typography
                            className={`${classes.fullWidthStyle} label-text`}
                            id="editEvaluation.location.label"
                        />
                        <ReactSelect
                            name="newLocation"
                            value={
                                newLocation
                                    ? { value: newLocation, label: newLocation }
                                    : null
                            }
                            onChange={(selectedOption) => {
                                //console.log("Selected Location:", selectedOption); // Log the selected location
                                this.setState(
                                    {
                                        newLocation: selectedOption.value,
                                        isChanged: true,
                                    },
                                    () => {
                                        //console.log("Updated location:", this.state.newLocation); // Log the updated location state
                                    }
                                )
                            }}
                            options={this.locationsOptions(this.locationsArray)}
                            isMulti={false} // Ensure single selection
                            isSearchable
                            isDisabled={isSaving}
                            fullWidth
                            inputProps={{ className: classes.fullWidthInput2 }}
                            margin="none"
                            required
                            placeholder={'Manhattan, KS'}
                            error={submitted && !newLocation}
                            helperText={
                                submitted &&
                                !newLocation &&
                                'Add an opportunity to create a catalog'
                            }
                        />
                        <Typography
                            id="editEvaluation.notes.title"
                            variant={'inherit'}
                            className={`${classes.largeUnrelatedPad} dark-level-3-heading`}
                            display="block"
                        />

                        <Typography
                            className={`${classes.fullWidthStyle} label-text`}
                            id="editEvaluation.valuationReasons.label"
                        />

                        <ReactSelect
                            name={'valuation_reasons'}
                            inputProps={{ className: classes.fullWidthInput2 }}
                            value={this.state.valuation_reasons}
                            onChange={this.handleSelectChange}
                            options={getUserData().valuation_reasons}
                            isObject={false}
                            onRemove={this.handleReasonSelect}
                            onSelect={this.handleReasonSelect}
                            selectedValues={this.state.selectedValues}
                            disabled={isSaving}
                            fullWidth
                        />
                        <Typography
                            className={`${classes.fullWidthStyle} label-text`}
                            id="editEvaluation.notes.label"
                            style={{ marginTop: 16 }}
                        />
                        <FormattedTextField
                            name="notes"
                            fullWidth
                            value={this.state.notes}
                            onChange={this.handleChange}
                            margin="none"
                            rowsMax={6}
                            rows={2}
                            multiline={true}
                            inputProps={{ className: classes.fullWidthInput }}
                            disabled={isSaving}
                        />
                        {!isNew && (
                            <Typography
                                className={'label-text'}
                                display={'block'}
                                id="editEvaluation.evaluationNotes.label"
                                style={{ marginTop: 16 }}
                            />
                        )}
                        {!isNew && (
                            <FormattedTextField
                                name="evaluationNotes"
                                fullWidth
                                value={this.state.evaluationNotes}
                                onChange={this.handleChange}
                                margin="none"
                                rowsMax={6}
                                rows={2}
                                multiline={true}
                                inputProps={{
                                    className: classes.fullWidthInput,
                                }}
                                disabled={isSaving}
                            />
                        )}
                        <Typography
                            id="editEvaluation.primaryContact.info"
                            variant={'inherit'}
                            className={`${classes.largeUnrelatedPad} dark-level-3-heading`}
                            display={'block'}
                        />
                        <Grid item container spacing={2} alignItems={'center'}>
                            {isEditPrimaryContact ? (
                                <Grid
                                    item
                                    container
                                    className={classes.largeUnrelatedPad}
                                    direction={'column'}
                                    wrap="nowrap"
                                    spacing={1}
                                    style={{ height: '100%' }}
                                >
                                    <Grid item>
                                        <Typography
                                            className={'label-text'}
                                            display={'block'}
                                            id="editEvaluation.primaryContact.label"
                                        />
                                    </Grid>
                                    <Grid item>
                                        <ReactSelect
                                            name={'primaryContact'}
                                            value={this.state.primaryContact}
                                            onChange={this.handleSelectChange}
                                            options={this.state.userOptions}
                                            isMulti={false}
                                            autoFocus={true}
                                            escapeClearsValue={true}
                                            required
                                            isSearchable
                                            placeholder={'John Smith'}
                                            disabled={isSaving}
                                            fullWidth
                                            noOptionsMessage={
                                                this.getUserGroups
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            ) : (
                                <>
                                    <Grid
                                        item
                                        container
                                        alignItems={'center'}
                                        spacing={2}
                                    >
                                        <Grid item>
                                            <Typography
                                                className={'contrast-text'}
                                                display={'inline'}
                                            >
                                                {get(
                                                    this.state,
                                                    'primaryContact.label'
                                                )}
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                className={
                                                    'secondary-minimal-button-small no-print'
                                                }
                                                variant={'text'}
                                                style={{
                                                    height: 36,
                                                    marginLeft: -8,
                                                }}
                                                onClick={this.handleEdit}
                                                disableRipple={true}
                                            >
                                                <EditIcon
                                                    style={{
                                                        marginRight: 4,
                                                        fontSize: 18,
                                                    }}
                                                />
                                                <Typography
                                                    color="inherit"
                                                    variant="button"
                                                    id={
                                                        'editEvaluation.editPrimaryContact.button'
                                                    }
                                                />
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </>
                            )}
                        </Grid>
                        {/* <Typography
              id="editEvaluation.collaborators.title"
              variant={"inherit"}
              className={`${classes.smallRelatedPad} dark-level-3-heading`}
              display={"block"}
            />
            <Typography
              id="editEvaluation.collaborators.info"
              variant={"inherit"}
              className={`${classes.largeRelatedPad} label-text-small`}
              display={"block"}
            /> */}
                        {/* <Fragment>
              {this.state.collaborators.map((collaborator, index) => (
                <Grid
                  key={index + "gridKey"}
                  item
                  container
                  alignItems={"center"}
                  spacing={2}
                >
                  <Grid item>
                    <Typography className={"label-text"} display={"inline"}>
                      {get(this.state, `collaborators[${index}].label`)}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Button
                      className={"secondary-minimal-button-small no-print"}
                      variant={"text"}
                      style={{ width: 110, height: 36, marginLeft: -8 }}
                      onClick={this.handleRemove(index)}
                      disableRipple={true}
                    >
                      <Typography
                        color="inherit"
                        variant="button"
                        id={"remove.button"}
                      />
                    </Button>
                  </Grid>
                </Grid>
              ))}
            </Fragment> */}
                        {/* <GridFHG key={"gridKey"} item fullWidth>
              <Typography
                id="editEvaluation.collaborators.label"
                className={`${classes.mediumTopPad} label-text ${classes.fullWidthStyle}`}
              />
              <ReactSelect
                name={"collaborators"}
                value={this.state.collaborator}
                onChange={this.handleSelectArrayChange}
                inputProps={{
                  className: classes.fullWidthInput,
                  name: "collaboratorsInput",
                }}
                options={this.state.userOptions}
                isMulti={false}
                isSearchable
                escapeClearsValue
                placeholder={formatMessage(
                  intl,
                  "editEvaluation.collaborators.placeholder"
                )}
                disabled={isSaving}
                fullWidth
                maxMenuHeight={100}
                noOptionsMessage={this.getUserGroups}
              />
            </GridFHG> */}
                    </GridFHG>
                    <Grid
                        container
                        item
                        xs={12}
                        md={6}
                        className={classes.dropZoneStyle}
                        direction={'column'}
                        wrap={'nowrap'}
                    >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name={'import_requested'}
                                    color={'primary'}
                                    classes={{ checked: classes.checkedStyle }}
                                    className={classes.checkboxStyle}
                                    checked={this.state.import_requested}
                                    onChange={this.handleCheckboxChange}
                                    disableRipple
                                    value="import_requested"
                                />
                            }
                            label={
                                <Typography
                                    className={`default-text-small`}
                                    id={'editEvaluation.import_requested.label'}
                                />
                            }
                        />
                        <PhotosDropZone
                            onDrop={this.addFiles}
                            photos={fileAttachments}
                            onChange={this.handleFileChange}
                            uploadedPhotos={newFileAttachments}
                            isDisabled={isSaving}
                            showError={showFileError}
                            total={total}
                            remaining={remaining}
                            supportLargeThumbnails={false}
                            type={'files'}
                        />
                    </Grid>
                </Grid>
            </ModalDialog>
        )
    }
}

export default withStyles(requestStyles)(
    withRouter(
        withRequest({
            uri: GET_ORGANIZATION_USERS,
            showProgress: true,
            showOnLoad: false,
            errorMessageId: 'users.fetch.error',
        })(injectIntl(withStyles(styles)(NewEvaluationDialog)))
    )
)
