import { find, filter, sortBy } from 'lodash'
import compact from 'lodash/compact'
import { formatMessage } from '../../fhg/utils/Utils'
import { getUserData } from '../../Utils/ServerUtil'
import get from 'lodash/get'

/**
 * Create the description based on available properties.
 *
 * @param evalItem The evaluation item to create the description for.
 * @return {string} The description.
 */
export const getEquipmentDescription = (evalItem) => {
    if (evalItem) {
        const descriptionArray = [
            evalItem.year && evalItem.year !== 'unknown'
                ? evalItem.year
                : undefined,
            evalItem.make && evalItem.make !== 'unknown'
                ? evalItem.make
                : undefined,
            evalItem.model && evalItem.model !== 'unknown'
                ? evalItem.model
                : undefined,
            evalItem.description && evalItem.description !== 'unknown'
                ? evalItem.description
                : undefined,
        ]
        return compact(descriptionArray).join(' ')
    }
    return ''
}

export const getIdDescription = (intl, evalItem) => {
    const idArray = [
        evalItem.serial && evalItem.serial !== 'unknown'
            ? formatMessage(
                  intl,
                  'equipmentList.serialNo.label',
                  'Serial:',
                  evalItem
              )
            : undefined,
        evalItem.vin && evalItem.vin !== 'unknown'
            ? formatMessage(intl, 'equipmentList.vin.label', 'VIN:', evalItem)
            : undefined,
        evalItem.personal_id && evalItem.personal_id !== 'unknown'
            ? formatMessage(
                  intl,
                  'equipmentList.inventoryId.label',
                  'Inventory ID:',
                  evalItem
              )
            : undefined,
        evalItem.sticker_id && evalItem.sticker_id !== 'unknown'
            ? formatMessage(
                  intl,
                  'equipmentList.stickerId.label',
                  'Sticker ID:',
                  evalItem
              )
            : undefined,
    ]
    return compact(idArray)
}

export const getYourEstimate = (evalItem, estimateType) => {
    const estimates = get(evalItem, 'estimates', [])
    const user_uuid = getUserData().uuid
    const filterObject = { user_uuid, status: 'current|estimate' }
    if (estimateType) {
        filterObject.estimate_type_name = estimateType.name
    }
    const estimateFiltered = sortBy(filter(estimates, filterObject), 'modified')
    return estimateFiltered.length > 0
        ? estimateFiltered[estimateFiltered.length - 1]
        : {}
}

export const getEstimatesByType = (evalItem, estimateType) => {
    const estimates = get(evalItem, 'estimates', [])
    const filterObject = {
        estimate_type_name: estimateType.name,
        status: 'current|estimate',
    }
    return sortBy(filter(estimates, filterObject), 'modified')
}

export const getConsensusEstimate = (evalItem, estimateType) => {
    const consensusValues = get(evalItem, 'consensus_values', [])
    const consensusValuesFiltered = filter(consensusValues, {
        name: estimateType.name,
    })
    return consensusValuesFiltered.length > 0
        ? consensusValuesFiltered[consensusValuesFiltered.length - 1]
        : {}
}

export const getYourEstimateFromEvaluation = (evaluation, itemIndex) => {
    const evalItem = get(evaluation, `items[${itemIndex}]`)
    return evalItem ? getYourEstimate(evalItem) : {}
}

export const getMeterFromAsset = (asset, units) => {
    const meters = get(asset, 'item.meters', [])
    const hoursMeter = find(meters, { units })
    return get(hoursMeter, 'value')
}

export const getOtherMetersFromAsset = (asset) => {
    const meters = get(asset, 'item.meters', [])
    return filter(
        meters,
        (value) => value.units !== 'Hours' && value.units !== 'Miles'
    )
}

export const getMoreInformationFromAsset = (asset) => {
    const notes = get(asset, 'item.notes')
    const features = get(asset, 'item.features')
    const problems = get(asset, 'item.problems')
    return !!notes || !!features || !!problems ? 'Yes' : 'No'
}
