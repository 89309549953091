import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import {
    Popover,
    Typography,
    List,
    ListItem,
    ListItemText,
    Divider,
    Box,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = (theme) => ({
    popoverPaper: {
        padding: theme.spacing(2),
        maxWidth: 300,
        minWidth: 250,
    },
    listItem: {
        padding: theme.spacing(1, 0),
    },
    title: {
        fontWeight: 600,
        marginBottom: theme.spacing(1),
    },
    noUsers: {
        color: theme.palette.text.secondary,
        fontStyle: 'italic',
        padding: theme.spacing(1, 0),
    },
})

class CollaboratorPopover extends React.Component {
    static propTypes = {
        open: PropTypes.bool.isRequired,
        anchorEl: PropTypes.object,
        onClose: PropTypes.func.isRequired,
        evaluation: PropTypes.object,
        classes: PropTypes.object.isRequired,
    }

    render() {
        const { open, anchorEl, onClose, evaluation, classes } = this.props

        // Get shared users from evaluation
        const sharedWithUsers = get(
            evaluation,
            'evaluation.shared_with_users',
            []
        )

        // Get primary contact
        const primaryContact = get(
            evaluation,
            'evaluation.primary_contact',
            'N/A'
        )

        return (
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={onClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                classes={{
                    paper: classes.popoverPaper,
                }}
            >
                <Box>
                    <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                        Primary Contact:
                    </Typography>
                    <List disablePadding>
                        <ListItem className={classes.listItem} disableGutters>
                            <ListItemText primary={primaryContact} />
                        </ListItem>
                    </List>

                    <Divider style={{ margin: '8px 0' }} />

                    {/* Shared Users */}
                    <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                        Shared With:
                    </Typography>
                    {sharedWithUsers.length > 0 ? (
                        <List disablePadding>
                            {sharedWithUsers.map((user, index) => (
                                <ListItem
                                    key={index}
                                    className={classes.listItem}
                                    disableGutters
                                >
                                    <ListItemText
                                        primary={
                                            user.name ||
                                            user.username ||
                                            'Unknown User'
                                        }
                                    />
                                </ListItem>
                            ))}
                        </List>
                    ) : (
                        <Typography className={classes.noUsers}>
                            No additional collaborators
                        </Typography>
                    )}
                </Box>
            </Popover>
        )
    }
}

export default withStyles(styles)(CollaboratorPopover)
