/* eslint-disable react/prop-types, react/jsx-handler-names */

import Chip from '@material-ui/core/Chip'
import MenuItem from '@material-ui/core/MenuItem'
import NoSsr from '@material-ui/core/NoSsr'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core/styles'
import { emphasize } from '@material-ui/core/styles/colorManipulator'
import Typography from '@material-ui/core/Typography'
import CancelIcon from '@material-ui/icons/Cancel'
import classNames from 'classnames'
import { isEqual, get, debounce } from 'lodash'
import find from 'lodash/find'
import isObjectLike from 'lodash/isObjectLike'
import PropTypes from 'prop-types'
import React, { createRef } from 'react'
import Select from 'react-select'
import CreatableSelect from 'react-select/creatable'
import FormattedTextField from '../../components/eval/components/FormattedTextField'
import { hasValue } from '../utils/Utils'

const styles = (theme) => ({
    root: {
        flex: 1,
        // height: 60,
    },
    input: {
        display: 'flex',
        marginTop: '0 !important',
        // padding: 0,
        // minHeight: 40,
        // height: 'auto',
    },
    valueContainer: {
        display: 'flex',
        flex: 1,
        flexWrap: 'wrap',
        alignItems: 'center',
        overflow: 'hidden',
        width: '100%',
        alignSelf: 'center',
        '& p': {
            whiteSpace: 'nowrap',
            position: 'relative',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
        },
    },
    chip: {
        margin: theme.spacing(0.5, 0.25),
    },
    chipFocused: {
        backgroundColor: emphasize(
            theme.palette.type === 'light'
                ? theme.palette.grey[300]
                : theme.palette.grey[700],
            0.08
        ),
    },
    noOptionsMessage: {
        padding: theme.spacing(1, 2),
    },
    singleValue: {
        boxSizing: 'content-box',
        background: '0px center',
        border: 0,
        fontSize: 'inherit',
        outline: 0,
        padding: 0,
        marginLeft: theme.spacing(0.5),
        // marginRight: theme.spacing(0.5),
        // maxWidth: `calc(100% - ${theme.spacing(2)}px)`,
        // overflow: 'hidden',
        position: 'absolute',
        // textOverflow: 'ellipsis',
        // whiteSpace: 'nowrap',
        // top: '50%',
        // transform: 'translateY(-50%)',
        // fontSize: 16,
    },
    placeholder: {
        boxSizing: 'content-box',
        background: '0px center',
        border: 0,
        fontSize: 'inherit',
        outline: 0,
        padding: 0,
        // marginLeft: theme.spacing(0.5),
        // marginRight: theme.spacing(0.5),
        position: 'absolute',
        // top: '50%',
        // transform: 'translateY(-50%)',

        // left: 2,
        // fontSize: 16,
        opacity: 0.42,
    },
    paper: {
        position: 'absolute',
        zIndex: 10000,
        marginTop: theme.spacing(1),
        left: 0,
        width: '100%',
    },
    divider: {
        height: theme.spacing(2),
    },
    inputStyle: {
        boxSizing: 'content-box',
        width: 2,
        background: '0px center',
        border: 0,
        fontSize: 'inherit',
        opacity: 1,
        outline: 0,
        padding: 0,
        color: 'inherit',
    },
})

function NoOptionsMessage(props) {
    return (
        <Typography
            color="textSecondary"
            className={props.selectProps.classes.noOptionsMessage}
            {...props.innerProps}
        >
            {props.children}
        </Typography>
    )
}

function inputComponent({ inputRef, ...props }) {
    return <div ref={inputRef} {...props} />
}

function Control(props) {
    const inputProps = get(props, 'selectProps.textFieldProps.inputProps', {})
    const { className: inputPropsClassName, ...moreInputProps } = inputProps
    return (
        <FormattedTextField
            fullWidth
            margin={'none'}
            InputProps={{
                inputComponent,
                inputProps: {
                    className: `${props.selectProps.classes.input} ${inputPropsClassName}`,
                    inputRef: props.innerRef,
                    children: props.children,
                    ...moreInputProps,
                    ...props.innerProps,
                },
            }}
            {...props.selectProps.textFieldProps}
        />
    )
}

function Option(props) {
    return (
        <MenuItem
            buttonRef={props.innerRef}
            selected={props.isFocused}
            disableGutters
            component="div"
            style={{
                fontWeight: props.isSelected ? 500 : 400,
                zIndex: 1001,
                paddingLeft: 4,
                paddingRight: 4,
            }}
            {...props.innerProps}
        >
            <span style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                {props.children}
            </span>
        </MenuItem>
    )
}

function Placeholder(props) {
    return (
        <Typography
            color="textSecondary"
            display={'inline'}
            className={props.selectProps.classes.placeholder}
            {...props.innerProps}
        >
            {props.children}
        </Typography>
    )
}

function SingleValue(props) {
    return (
        <Typography
            display={'inline'}
            className={props.selectProps.classes.singleValue}
            {...props.innerProps}
        >
            {props.children}
        </Typography>
    )
}

function ValueContainer(props) {
    return (
        <div className={props.selectProps.classes.valueContainer}>
            {props.children}
        </div>
    )
}

function MultiValue(props) {
    return (
        <Chip
            tabIndex={-1}
            label={props.children}
            className={classNames(props.selectProps.classes.chip, {
                [props.selectProps.classes.chipFocused]: props.isFocused,
            })}
            onDelete={props.removeProps.onClick}
            deleteIcon={<CancelIcon {...props.removeProps} />}
        />
    )
}

function Menu(props) {
    return (
        <Paper
            id={'M'}
            square
            className={props.selectProps.classes.paper}
            {...props.innerProps}
        >
            {props.children}
        </Paper>
    )
}

const components = {
    Control,
    Menu,
    MultiValue,
    NoOptionsMessage,
    Option,
    Placeholder,
    SingleValue,
    ValueContainer,
}

class ReactSelect extends React.PureComponent {
    static propTypes = {
        classes: PropTypes.object.isRequired,
        theme: PropTypes.object.isRequired,
        isMulti: PropTypes.bool,
        isSearchable: PropTypes.bool,
    }
    static defaultProps = {
        isMulti: true,
        isSearchable: false,
    }

    constructor(props) {
        super(props)

        this.selectRef = createRef()
        this.state = {
            // editValue: null,
            options: this.mapOptions(props),
        }
        // if (props.defaultValue) {
        //    this.handleChange(props.defaultValue);
        // }
    }

    static createOptions(labelArray) {
        const results = []
        if (labelArray && labelArray.length > 0) {
            for (const labelArrayElement of labelArray) {
                results.push({
                    label: labelArrayElement,
                    value: labelArrayElement,
                })
            }
        }
        return results
    }

    static createOptionsFromLabels(labelArray, property) {
        const results = []
        if (labelArray && labelArray.length > 0) {
            for (const labelArrayElement of labelArray) {
                results.push({
                    label: labelArrayElement[property],
                    value: labelArrayElement[property],
                })
            }
        }
        return results
    }

    static createOptionsFromObject(objectArray, labelProperty, valueProperty) {
        const results = []
        if (objectArray && objectArray.length > 0) {
            for (const objectArrayElement of objectArray) {
                results.push({
                    label: objectArrayElement[labelProperty],
                    value: objectArrayElement[valueProperty],
                })
            }
        }
        return results
    }

    componentDidMount() {
        if (this.props.required) {
            setTimeout(() => {
                this.setRequired()
            }, 400)
        }
    }

    componentDidUpdate(prevProps) {
        if (
            prevProps.required !== this.props.required ||
            this.props.value !== prevProps.value
        ) {
            this.setRequired()
        }
    }

    setRequired = () => {
        if (this.props.name) {
            const elements = document.getElementsByName(this.props.name)
            if (elements && elements.length > 0) {
                const inputElement = elements[0].querySelector('input')
                if (inputElement) {
                    if (
                        this.props.required &&
                        !hasValue(this.props.value) &&
                        !hasValue(this.props.defaultValue)
                    ) {
                        inputElement.setAttribute('required', true)
                    } else {
                        inputElement.removeAttribute('required')
                    }
                } else {
                    console.log(
                        `Input Element couldn't be found for required. Name = ${this.props.name}`
                    )
                }
            }
        }
    }

    componentWillReceiveProps(nextProps) {
        if (!isEqual(nextProps.options, this.props.options)) {
            this.setState({ options: this.mapOptions(nextProps) })
        }
        // if (nextProps.defaultValue && this.state.editValue === null) {
        //    this.handleChange(nextProps.defaultValue);
        // }
    }

    mapOptions = (props) => {
        let options = []

        if (props.options && props.options.length > 0) {
            if (get(props, 'options[0].label') === undefined) {
                options = props.options.map(this.getMenuItem(props))
            } else {
                options = props.options
            }
        }
        return options
    }

    getMenuItem = (props) => (item) => {
        return {
            label: this.getLabel(props, item),
            value: this.getValue(props, item),
        }
    }

    getLabel = (props, item) => {
        if (typeof item === 'string') {
            return item
        }
        return props.getLabel
            ? props.getLabel(item)
            : item[props.labelKey || 'label']
    }

    getValue = (props, item) => {
        if (typeof item === 'string') {
            return item
        }
        return item[props.valueKey || 'value']
    }

    getDefaultValue = () => {
        const { defaultValue } = this.props
        const { isCleared } = this.state
        if (!isCleared) {
            if (isObjectLike(defaultValue)) {
                return defaultValue
            }
            return find(this.state.options, ['value', this.props.defaultValue])
        } else {
            return undefined
        }
    }

    handleChange = (value) => {
        if (value === null && this.props.isClearable) {
            this.setState({ isCleared: true })
        }
        // this.setState({editValue: value}, () => {
        const { onChange } = this.props
        onChange && onChange(value, this.props.name, this.props.isMulti)
        // });
    }

    handleScroll = debounce(
        (event) => {
            return get(event, 'target.parentElement.id') !== 'M'
        },
        100,
        { leading: true, maxWait: 200 }
    )

    render() {
        const {
            classes,
            theme,
            isClearable = false,
            autoFocus,
            menuIsOpen,
            menuPosition = 'fixed',
            disabled,
            label,
            placeholder,
            onCreateOption,
            getOptionLabel,
            getOptionValue,
            isMulti,
            escapeClearsValue,
            isCreatable = false,
            minMenuHeight,
            maxMenuHeight,
            fullWidth = false,
            defaultValue,
            closeMenuOnSelect,
            isSearchable,
            options: ignore,
            menuShouldScrollIntoView,
            captureMenuScroll,
            noOptionsMessage,
            ...textFieldProps
        } = this.props
        const { options } = this.state

        const selectStyles = {
            input: (base) => ({
                ...base,
                color: theme.palette.text.primary,
                '& input': {
                    font: 'inherit',
                },
            }),
        }
        const SelectComponent = isCreatable ? CreatableSelect : Select
        const usePlaceholder = placeholder || ''
        const useValue =
            this.props.value === undefined
                ? this.getDefaultValue() || ''
                : this.props.value
        return (
            <div className={classes.root}>
                <NoSsr>
                    <SelectComponent
                        menuIsOpen={menuIsOpen}
                        menuPosition={menuPosition}
                        closeMenuOnScroll={this.handleScroll}
                        closeMenuOnSelect={closeMenuOnSelect}
                        noOptionsMessage={noOptionsMessage}
                        isClearable={isClearable}
                        isSearchable={isSearchable}
                        autoFocus={autoFocus}
                        classes={classes}
                        styles={selectStyles}
                        isDisabled={disabled}
                        ref={this.selectRef}
                        textFieldProps={{
                            ...textFieldProps,
                            label,
                            disabled,
                            fullWidth,
                            InputLabelProps: {
                                shrink:
                                    !!usePlaceholder ||
                                    !!this.props.value ||
                                    !!defaultValue
                                        ? true
                                        : undefined,
                            },
                        }}
                        options={options}
                        getOptionLabel={getOptionLabel}
                        getOptionValue={getOptionValue}
                        components={components}
                        value={useValue}
                        onChange={this.handleChange}
                        onCreateOption={onCreateOption}
                        placeholder={usePlaceholder}
                        isMulti={isMulti}
                        escapeClearsValue={escapeClearsValue}
                        menuShouldScrollIntoView={menuShouldScrollIntoView}
                        captureMenuScroll={captureMenuScroll}
                        minMenuHeight={minMenuHeight}
                        maxMenuHeight={maxMenuHeight}
                    />
                </NoSsr>
            </div>
        )
    }
}

export default withStyles(styles, { withTheme: true })(ReactSelect)
