import Checkbox from '@material-ui/core/Checkbox'
import Divider from '@material-ui/core/Divider/Divider'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import withStyles from '@material-ui/core/styles/withStyles'
import { Auth } from 'aws-amplify'
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import FHGTypography from '../../../fhg/components/Typography'
import { getUserData } from '../../../Utils/ServerUtil'
import { submitSendEmails } from '../../../Utils/SubmitUtil'

const styles = (theme) => ({
    nameStyle: {
        color: theme.palette.environment.light.level3.base, //'#E6E3DC',
    },
    selectStyle: {
        '& svg': {
            fill: theme.palette.environment.light.level3.base, //'#E6E3DC'
        },
    },
    formPadding: {
        padding: theme.spacing(2, 1.5, 0),
        '& p': {
            '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)':
                {
                    margin: '0 0',
                },
            'margin-block-start': 0,
            'margin-block-end': 0,
            '-webkit-margin-start': 0,
            '-webkit-margin-end': 0,
            '-webkit-margin-before': 0,
            '-webkit-margin-after': 0,
        },
    },
    emailSpacing: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(3),
    },
    checkboxStyle: {
        marginTop: theme.spacing(-4) - 2,
        color: '#4f2682 !important',
        backgroundColor: `${theme.palette.environment.light.level0.base} !important`,
    },
    checkboxStyle2: {
        marginTop: theme.spacing(-3),
        color: '#4f2682 !important',
        backgroundColor: `${theme.palette.environment.light.level0.base} !important`,
    },
    checkedStyle: {
        color: '#4f2682 !important',
    },
    dividerStyle: {
        backgroundColor: theme.palette.environment.light.level0.accent,
    },
    checkboxPaddingStyle: {
        marginBottom: theme.spacing(3),
    },
})

/**
 * Log out the authenticated user and redirect to the login page.
 */
export const handleLogOut = (history, onStateChange) => {
    //TODO handle the error better?
    Auth.signOut()
        .then(() => {
            if (onStateChange) {
                onStateChange('signedOut')
            }
            window.Intercom('shutdown')
            window.Intercom('boot', {
                app_id: 'fl2igt9h',
            })
            history.replace(undefined, {})
        })
        .catch((err) => {
            console.log(err)
        })
}

/**
 * User menu component to handle preferences and security.
 */
class UserMenu extends Component {
    constructor(props, context) {
        super(props, context)

        this.state = {
            isAccountMenuOpen: false,
            //Assumes that EmailSubscribeCard has queried the server and set sendEmails before this is called.
            isSubscribe: getUserData().sendEmails,
        }
    }

    handleLogoutChange = (event) => {
        if (event.target.value === 'logout') {
            handleLogOut(this.props.history, this.props.onStateChange)
        }
    }

    handleMenu = () => {
        this.setState({ isAccountMenuOpen: true })
    }

    handleClose = () => {
        this.setState({ isAccountMenuOpen: false })
    }

    handleCheckboxChange = async (event) => {
        event.stopPropagation()
        event.preventDefault()

        if (event.target.name === 'isSubscribe') {
            submitSendEmails(event.target.checked)
        } else if (event.target.name === 'hideEmptyColumns') {
            localStorage.hideEmptyColumns =
                localStorage.hideEmptyColumns === 'true' ? 'false' : 'true'
            window.location.reload()
        }
    }

    render() {
        const { classes } = this.props
        const { isAccountMenuOpen } = this.state
        const userData = getUserData()
        const userName = userData.name
        const email = userData.email
        const sendEmails = userData.sendEmails

        return (
            <FormControl className={classes.formControl}>
                <Select
                    open={isAccountMenuOpen}
                    className={classes.selectStyle}
                    onClose={this.handleClose}
                    onOpen={this.handleMenu}
                    renderValue={() => (
                        <Grid
                            container
                            wrap={'nowrap'}
                            direction={'row'}
                            alignItems={'center'}
                        >
                            <FHGTypography
                                variant={'button'}
                                className={classes.nameStyle}
                            >
                                {userName}
                            </FHGTypography>
                        </Grid>
                    )}
                    MenuProps={{
                        MenuListProps: {
                            disablePadding: true,
                        },
                    }}
                    disableUnderline={true}
                    displayEmpty={true}
                    value={''}
                    onChange={this.handleLogoutChange}
                >
                    <Grid
                        container
                        direction={'column'}
                        className={classes.formPadding}
                    >
                        <FHGTypography className={'label-text'}>
                            {userName}
                        </FHGTypography>
                        <FHGTypography
                            className={`default-item-title ${classes.emailSpacing}`}
                        >
                            {email}
                        </FHGTypography>
                        {/* <FormControlLabel
                     control={
                        <Checkbox
                           name={'isSubscribe'}
                           color={'primary'}
                           classes={{checked: classes.checkedStyle}}
                           className={classes.checkboxStyle}
                           checked={sendEmails}
                           onChange={this.handleCheckboxChange}
                           value='isSubscribe'
                        />
                     }
                     label={<FHGTypography className={`default-label-small ${classes.checkboxPaddingStyle}`}
                                           id={'emailSubscribe.subscribeMenu.checkbox'} hasLineBreaks/>}
                  /> */}
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name={'hideEmptyColumns'}
                                    color={'primary'}
                                    classes={{ checked: classes.checkedStyle }}
                                    className={classes.checkboxStyle2}
                                    checked={
                                        localStorage.hideEmptyColumns !== 'true'
                                    }
                                    onChange={this.handleCheckboxChange}
                                    value="hideEmptyColumns"
                                />
                            }
                            label={
                                <FHGTypography
                                    className={`default-label-small ${classes.checkboxPaddingStyle}`}
                                    id={'showEmptyColumns.label'}
                                    hasLineBreaks
                                />
                            }
                        />
                    </Grid>
                    <Divider className={classes.dividerStyle} />
                    <MenuItem
                        value="logout"
                        component={Link}
                        to="/"
                        onClick={this.handleLogOut}
                    >
                        <FHGTypography
                            className="secondary-minimal-button-small"
                            id="emailSubscribe.signOut.button"
                        />
                    </MenuItem>
                </Select>
            </FormControl>
        )
    }
}

export default withStyles(styles)(UserMenu)
