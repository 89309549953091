import { Hidden, Select } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import Input from '@material-ui/core/Input'
import MenuItem from '@material-ui/core/MenuItem'
import Snackbar from '@material-ui/core/Snackbar'
import makeStyles from '@material-ui/core/styles/makeStyles'
import withStyles from '@material-ui/core/styles/withStyles'
import filter from 'lodash/filter'
import * as PropTypes from 'prop-types'
import React, { Component } from 'react'
import {
    PHOTO_STATE_ERROR,
    MAX_PHOTO_SIZE,
    WARNING_ICON,
    MOVIE_EXTENSIONS,
    MOVIE_MIME_TYPES,
    CATALOG_ATTACHMENT_MIME_TYPES,
    IMAGE_VIEW_KEYS,
} from '../../../Constants'
import FHGTypography from '../../../fhg/components/Typography'
import Typography from '../../../fhg/components/Typography'
import { detectIE } from '../../../fhg/utils/Utils'
import { SlideTransition } from '../components/ErrorSnackbar'
import { CloudUploadIcon2 } from './NewItemDialog'
import UploadPhotoSummary from './UploadPhotoSummary2'
import GridFHG from '../../../fhg/components/Grid'

const errorStyles = (theme) => ({
    snackbar: {
        position: 'sticky',
        left: 'auto',
        transform: 'unset',
    },
    snackbarContent: {
        backgroundColor: `${theme.palette.error.light} !important`,
    },
})

const useStyles = makeStyles(
    (theme) => ({
        titleStyle: {
            fontSize: '14px !important',
            fontWeight: 500,
            lineHeight: '17px',
            textAlign: 'center',
            color: theme.palette.content.default,
            // color: theme.palette.secondary.contrastText,
            textTransform: 'none',
            whiteSpace: 'nowrap',
            [theme.breakpoints.down('xs')]: {
                whiteSpace: 'wrap',
            },
        },
        titleStyle2: {
            margin: theme.spacing(1, 2, 0.5, 2),
        },
        formControl: {
            color: 'inherit',
            backgroundColor: 'transparent',
            '&:focus': {
                backgroundColor: 'transparent',
            },
        },
        select: {
            background: 'transparent',
            '&:focus': {
                background: 'transparent',
            },
        },
        selectStyle: {
            // color: 'blue',
            color: theme.palette.content.default,
            '& svg': {
                fill: theme.palette.content.default,
            },
        },
        menuText: {
            color: `${theme.palette.content.default} !important`,
            display: 'inline',
            whiteSpace: 'normal',
        },
    }),
    { name: 'SelectPhotoCategoryStyles' }
)

function SelectPhotoCategory({ value = 'BEXT', onChange }) {
    const classes = useStyles()

    return (
        <FormControl className={classes.formControl}>
            <Select
                value={value || ''}
                className={classes.selectStyle}
                classes={{ select: classes.select }}
                displayEmpty={true}
                onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                }}
                onChange={onChange}
                input={
                    <Input
                        name="selectedSubpackage"
                        disableUnderline={true}
                        style={{ marginRight: 8, fontSize: 18 }}
                    />
                }
            >
                <FHGTypography
                    className={`nonessential-title-caps ${classes.titleStyle2}`}
                    id={'photoDetail.photoCategory.title'}
                />
                {Object.entries(IMAGE_VIEW_KEYS).map(([key, value]) => (
                    <MenuItem key={key} value={key}>
                        <FHGTypography
                            className={classes.menuText}
                            color="inherit"
                            variant="body1"
                            id={value}
                        />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}

function PhotoErrorSnackbarFunction(props) {
    const { classes, open, onClose, onRetry } = props

    return (
        <Snackbar
            open={open}
            onClose={onClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            ContentProps={{
                'aria-describedby': 'snackbar-fab-message-id',
                className: classes.snackbarContent,
            }}
            TransitionComponent={SlideTransition}
            message={
                <span id="snackbar-fab-message-id">
                    Some photos could not be uploaded.
                </span>
            }
            action={
                <Button color="inherit" size="small" onClick={onRetry}>
                    Retry
                </Button>
            }
            className={classes.snackbar}
        />
    )
}

PhotoErrorSnackbarFunction.propTypes = {
    onRetry: PropTypes.func,
    onClose: PropTypes.func,
    open: PropTypes.bool,
}

const PhotoErrorSnackbar = withStyles(errorStyles)(PhotoErrorSnackbarFunction)

const styles = (theme) => ({
    baseStyle: {
        maxWidth: 400,
        minWidth: 200,
        margin: 'auto',
        height: 220,
        // maxWidth: '80%',
        border: `1px dashed ${theme.palette.content.subtle}`,
        [theme.breakpoints.down('xs')]: {
            border: 'none',
            height: 'auto',
        },
        borderRadius: 5,
        display: 'flex',
    },
    baseWithoutDragNDrop: {
        margin: 'auto',
        border: 'none',
        height: 'auto',
        display: 'flex',
    },
    activeStyle: {
        top: 157,
        width: '100%',
        height: 'calc(100% - 197px)',
        backgroundColor: `${theme.palette.button.standard.success} !important`,
        zIndex: 1001,
        padding: 20,
        position: 'absolute',
    },
    activeStyleInner: {
        border: `2px dashed white !important`,
        zIndex: 1001,
        width: 'calc(100% - 50px)',
        height: '100%',
    },
    thumbsContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: theme.spacing(2),
    },
    inputStyle: {
        display: 'none',
        width: 0,
        height: 0,
    },
    doneButton: {
        fontSize: 14,
        fontWeight: 'bold',
        whiteSpace: 'nowrap',

        tabIndex: 1,
        color: theme.palette.common.white,
        textTransform: 'none',
        borderRadius: 4,
        backgroundColor: '#4f2682 !important',
        padding: '8px 16px',

        '&:hover': {
            backgroundColor: '#7743b8 !important',
        },
        '&:disabled': {
            color: `${theme.palette.button.standard.label.disabled} !important`,
            backgroundColor: `${theme.palette.button.standard.disabled} !important`,
        },
    },
    standardDefaultStyle: {
        color: `${theme.palette.content.default} !important`,
        fontSize: 34.5,
        marginTop: -theme.spacing(2),
    },
    standardDefaultTextStyle: {
        composes: 'default-text-small',
        color: `${theme.palette.content.default} !important`,
    },
    warnTextStyle: {
        composes: 'default-text-small',
        color: `${theme.palette.button.minimal.warn} !important`,
        marginLeft: theme.spacing(1),
    },
    buttonFrame: {
        width: 'auto',
        flexDirection: 'column',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'row',
        },
    },
    menuText: {
        color: `${theme.palette.content.default} !important`,
        display: 'inline',
        whiteSpace: 'normal',
    },
})

class PhotosDropZone extends Component {
    constructor(props) {
        super(props)
        this.warnTextRef = React.createRef() // Create a ref for the warning element
    }

    static propTypes = {
        classes: PropTypes.array,
        onDropboxDrop: PropTypes.func,
        inputOpen: PropTypes.func,
        photos: PropTypes.array,
        isDisabled: PropTypes.bool,
        showError: PropTypes.bool,
        total: PropTypes.number,
        remaining: PropTypes.number,
        uploadedPhotos: PropTypes.array,
        onChange: PropTypes.func,
        supportLargeThumbnails: PropTypes.bool,
        type: PropTypes.oneOf(['images', 'files']),
        onPhotoCategoryChange: PropTypes.func,
    }

    static defaultProps = {
        supportLargeThumbnails: true,
        type: 'images',
    }

    state = {
        photoCategory: 'BEXT',
    }

    // Dropbox options. Limits types, sizes of photos, etc., Set success callback when photos are selected.
    options = {
        success: this.props.onDropboxDrop,
        linkType: 'direct',
        multiselect: true,
        extensions: [
            '.jpg',
            '.jpeg',
            '.png',
            '.webp',
            '.pdf',
            ...MOVIE_EXTENSIONS,
        ],
        folderselect: false,
        sizeLimit: MAX_PHOTO_SIZE,
    }

    componentDidMount() {
        if (window.Dropbox && this.props.onDropboxDrop) {
            this.dropBoxButton = window.Dropbox.createChooseButton(this.options)
            const warnElement = this.warnTextRef.current
            // Check if warnElement and its parent exist before removing it
            if (warnElement && warnElement.parentNode) {
                warnElement.parentNode.removeChild(warnElement)
            }

            if (!detectIE()) {
                document
                    .getElementById('dropboxContainer')
                    .appendChild(this.dropBoxButton)
            }
        }
    }

    /**
     * Handle the click of the select files button.
     *
     * @return {Function} The function that will input the type of photo.
     */
    handleOnPhotoClick = (event) => {
        event.preventDefault()
        event.stopPropagation()
        this.props.inputOpen && this.props.inputOpen()
    }

    handleRetry = () => {
        const { uploadedPhotos } = this.props
        const errorList = filter(uploadedPhotos, { status: PHOTO_STATE_ERROR })
        this.props.onDrop && this.props.onDrop(errorList)
    }

    handlePhotoCategoryChange = ({ target }) => {
        const { onPhotoCategoryChange } = this.props

        this.setState(
            { photoCategory: target.value },
            () => onPhotoCategoryChange && onPhotoCategoryChange(target.value)
        )
    }

    render() {
        const {
            classes,
            photos,
            isDisabled,
            showError,
            total,
            remaining,
            uploadedPhotos,
            inputOpen,
            onChange,
            onDropboxDrop,
            supportLargeThumbnails,
            type,
            onPhotoCategoryChange,
        } = this.props

        // inputOpen indicates drag and drop functionality.
        const allowDragAndDrop = !!inputOpen
        return (
            <Grid
                container
                direction="column"
                spacing={2}
                wrap="nowrap"
                style={{ flex: '1 1', overflow: 'hidden' }}
            >
                <Grid item>
                    <div
                        className={
                            allowDragAndDrop
                                ? classes.baseStyle
                                : classes.baseWithoutDragNDrop
                        }
                    >
                        <Grid
                            container
                            direction={'column'}
                            spacing={2}
                            wrap="nowrap"
                            justifyContent={'center'}
                            alignItems={'center'}
                            style={{ margin: 'auto' }}
                        >
                            <Grid
                                container
                                item
                                className={classes.buttonFrame}
                                direction={'column'}
                                spacing={2}
                                alignItems={'center'}
                            >
                                {onPhotoCategoryChange && (
                                    <Grid
                                        container
                                        item
                                        spacing={1}
                                        alignItems={'center'}
                                        justifyContent={'center'}
                                    >
                                        <Grid item>
                                            <Typography
                                                id={
                                                    'photoDetail.selectCategory.title'
                                                }
                                                className={classes.menuText}
                                                color="inherit"
                                                variant="body1"
                                            />
                                        </Grid>
                                        <Grid item>
                                            <SelectPhotoCategory
                                                value={this.state.photoCategory}
                                                onChange={
                                                    this
                                                        .handlePhotoCategoryChange
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                )}
                                <Grid item>
                                    <Button
                                        className={classes.doneButton}
                                        onClick={
                                            inputOpen
                                                ? this.handleOnPhotoClick
                                                : undefined
                                        }
                                        color={'secondary'}
                                        disabled={isDisabled}
                                        component={
                                            inputOpen ? 'button' : 'label'
                                        }
                                    >
                                        <Typography
                                            color="inherit"
                                            variant="button"
                                        >
                                            Select Files
                                        </Typography>
                                        {!allowDragAndDrop && (
                                            <input
                                                type="file"
                                                style={{ display: 'none' }}
                                                multiple
                                                onChange={onChange}
                                                accept={`${[
                                                    ...MOVIE_MIME_TYPES,
                                                    ...CATALOG_ATTACHMENT_MIME_TYPES,
                                                ]}`}
                                            />
                                        )}
                                    </Button>
                                </Grid>
                                {onDropboxDrop && (
                                    <Grid id={'dropboxContainer'} item>
                                        <Grid
                                            container
                                            id={'warnText'}
                                            ref={this.warnTextRef}
                                            direction={'row'}
                                            alignItems={'center'}
                                            spacing={1}
                                        >
                                            <img
                                                src={WARNING_ICON}
                                                height={21}
                                                alt=""
                                            />
                                            <Typography
                                                className={
                                                    classes.warnTextStyle
                                                }
                                                display={'inline'}
                                                id={
                                                    'photoDropzone.message.text'
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                )}
                            </Grid>
                            {allowDragAndDrop && (
                                <>
                                    <Hidden xsDown>
                                        <Grid
                                            item
                                            className={
                                                classes.standardDefaultTextStyle
                                            }
                                        >
                                            or drag and drop files here
                                        </Grid>
                                        <Grid item>
                                            <CloudUploadIcon2
                                                className={
                                                    classes.standardDefaultStyle
                                                }
                                            />
                                        </Grid>
                                    </Hidden>
                                </>
                            )}
                        </Grid>
                    </div>
                </Grid>
                <GridFHG item container fullWidth>
                    <PhotoErrorSnackbar
                        onRetry={this.handleRetry}
                        open={showError}
                    />
                    <UploadPhotoSummary
                        photos={photos}
                        uploadedPhotos={uploadedPhotos}
                        total={total}
                        remaining={remaining}
                        supportLargeThumbnails={supportLargeThumbnails}
                        type={type}
                    />
                </GridFHG>
            </Grid>
        )
    }
}

export default withStyles(styles)(PhotosDropZone)
