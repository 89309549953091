import React, { useEffect, useState } from 'react'
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    FormControl,
    Select,
    MenuItem,
    Grid,
} from '@material-ui/core'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import { IconButton } from '@material-ui/core'
import TextField from '@mui/material/TextField'
import Textarea from '@mui/joy/Textarea'
import CheckIcon from '@mui/icons-material/Check'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'
import ContentLoader from 'react-content-loader'
import Stack from '@mui/material/Stack'
import 'react-image-lightbox/style.css'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import CloseIcon from '@mui/icons-material/Close'
import { IMS_FORM_IMG_PLACEHOLDER } from '../../../Constants'
import InfoIcon from '@mui/icons-material/Info'
import Chip from '@mui/material/Chip'
import Divider from '@mui/material/Divider'
import LoadFormPreview from './LoadFormPreview'
import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import { Auth } from 'aws-amplify'
import { isAdminOnly, isMultipleOrganization } from '../../../Utils/ServerUtil'
import { saveIMSForms, submitItem } from '../../../Utils/SubmitUtil'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh'
import { savePopulatedForms } from '../../../Utils/SubmitUtil'

interface AutoPopulatedComponentCheck {
    is_auto_populated: boolean
    id: number // Strictly require a number
    component_response: any
}
interface FieldValue {
    value?: any
    [key: string]: any
}

interface FieldValues {
    [key: string]: FieldValue | any
}

interface ImsFormFieldsProps {
    handleSaveSuccess: () => void
    prevEvalItem: any
    handleIMSFormSave: () => Promise<void>
    setSaveForm: (value: boolean) => void
    setImsForm: (data: any) => void
    loadForm: boolean
    selectedCategory: any 
    successAlert: boolean
    handleSaveSuccessClose: () => void
    imsForm: any 
    error: string | null
    loading: boolean
    ims_forms_data: any 
    titleStyle?: {
        fontSize: string
        marginBottom: string
        color: string
    }
    internaUseValues: React.CSSProperties
    internaUseValues2: React.CSSProperties
    evaluation: any 
    nominationValue: string | number
    handleNominationChange: (
        event: React.ChangeEvent<{ value: unknown }>
    ) => void
    setFieldValues: (values: FieldValues) => void
    fieldValues: FieldValues
    handleFieldChange: (
        groupId: string | number,
        componentId: string | number,
        value: any,
        datatype: string
    ) => void
    newIMSData: any 
}

interface IComponent {
    id: React.Key | null | undefined
    control_type: string
    datatype: string
    label_display: any
    label: any
    prefix?: string | number | boolean | React.ReactElement | null
    suffix?: string | number | boolean | React.ReactElement | null
    component_response: any
    values?: any[]
    component_form?: string | any[]
    helper_text?: string | number | boolean | React.ReactElement | null
    is_auto_populated?: boolean
    photo_capture?: number
}

const ImsFormFields: React.FC<ImsFormFieldsProps> = ({
    loadForm,
    selectedCategory,
    successAlert,
    handleSaveSuccessClose,
    imsForm,
    error,
    loading,
    ims_forms_data,
    titleStyle,
    internaUseValues,
    internaUseValues2,
    evaluation,
    nominationValue,
    handleNominationChange,
    fieldValues,
    setFieldValues,
    handleFieldChange,
    newIMSData,
    handleIMSFormSave,
    setSaveForm,
    setImsForm,
    prevEvalItem,
    handleSaveSuccess,
}) => {
    const [lightboxImage, setLightboxImage] = useState<string | null>(null)
    const [lightboxOpen, setLightboxOpen] = useState<boolean>(false)
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
    const [populatedSuccess, setPopulatedSuccess] = useState<boolean>(false)
    const [populateError, setPopulateError] = useState<boolean>(false)
    const [vinInput, setVinInput] = useState<string>('')

    const populateFormAPI = async (itemId: string, vinNumber?: string) => {
        try {
            let jwtToken
            let tokenRefreshError = false

            try {
                const session = await Auth.currentSession()
                jwtToken = session.getIdToken().getJwtToken()
            } catch (e) {
                try {
                    const session = await Auth.currentSession()
                    jwtToken = session.getIdToken().getJwtToken()
                } catch (e) {
                    console.log('Error refreshing token', e)
                    tokenRefreshError = true
                }
            }

            // Create headers object
            const headers: Record<string, string> = {
                authorization: jwtToken || '',
                accept: 'application/json',
                'Content-Type': 'application/json',
            }

            if (isAdminOnly() || isMultipleOrganization()) {
                const organization =
                    localStorage['hw.' + window.btoa('organization')]
                if (organization && window.atob(organization)) {
                    headers['HW-Organization'] = window.atob(organization)
                }
            }

            // Filter out any undefined values
            const filteredHeaders: Record<string, string> = Object.fromEntries(
                Object.entries(headers).filter(
                    ([_, value]) => value !== undefined
                )
            )

            const url = `https://api.pwftdev.com/v1/items/${itemId}/populate_form`
            const options: RequestInit = {
                method: 'POST',
                headers: filteredHeaders,
                body: vinNumber
                    ? JSON.stringify({ vin: vinNumber })
                    : undefined,
            }

            const response = await fetch(url, options)
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`)
            }

            const data = await response.json()
            console.log('Form populated successfully:', data)
            return data
        } catch (error) {
            setPopulateError(true)
            console.error('Error populating form:', error)
            throw error
        }
    }

    const handlePopulateClose = () => {
        setPopulatedSuccess(false)
    }

    const handlePopulateErrorClose = () => {
        setPopulateError(false)
    }

    const shouldShowAutoPopulatedIndicator = (
        component: AutoPopulatedComponentCheck,
        fieldValues: FieldValues
    ): boolean => {
        const componentId = String(component.id)
        const fieldValue = fieldValues[componentId]

        if (component.id === null || component.id === undefined) {
            return false
        }
       
        return (
            component.is_auto_populated &&
            (!fieldValue ||
                fieldValue === component.component_response ||
                (typeof fieldValue === 'object' &&
                    fieldValue?.value === component.component_response))
        )
    }


    const AutoPopulateIndicator = () => (
        <FiberManualRecordIcon
            sx={{
                position: 'absolute',
                left: -30,
                top: '50%',
                transform: 'translateY(-50%)',
                color: '#209b2e',
                fontSize: '20px',
            }}
            titleAccess="Auto-populated from VIN"
        />
    )

    const handlePopulateFormToIMS = async () => {
        try {
            setSaveForm(true)
            if (!imsForm?.item?.vin) {
                setIsModalOpen(true)
            } else {
                const populateResponse = await populateFormAPI(
                    imsForm.item.item_id
                )
                if (populateResponse) {
                    const newFieldValues: FieldValues = {}
                    setPopulatedSuccess(true)

                    populateResponse.groups?.forEach((group: any) => {
                        group.components?.forEach((component: any) => {
                            newFieldValues[String(component.id)] = {
                                value: component.component_response,
                                is_auto_populated: true, // Set is_auto_populated flag
                            }
                        })
                    })

                    const updatedFormData = {
                        ...imsForm,
                        item: {
                            ...imsForm.item,
                            ims_forms_data: {
                                ...populateResponse,
                            },
                        },
                    }

                    console.log('Updated field values:', newFieldValues)
                    setFieldValues(newFieldValues) // Make sure setFieldValues accepts FieldValues type
                    setImsForm(updatedFormData)

                    console.log(
                        'Updated Form Data before save:',
                        updatedFormData
                    )

                    //    Save directly with the updated data
                    await savePopulatedForms(
                        imsForm.item.item_id,
                        updatedFormData
                    )
                }
            }
        } catch (error) {
            console.error('Error in form process:', error)
            setSaveForm(false)
        }
    }

    const handleModalSubmit = async () => {
        try {
            if (!vinInput.trim()) {
                alert('Please enter a VIN number')
                return
            }

            const populateResponse = await populateFormAPI(
                imsForm.item.item_id,
                vinInput
            )

            if (populateResponse) {

               
            const saveItemWithVIN = {
                ...imsForm.item, 
                vin: vinInput 
            };

            await submitItem(
                imsForm.item.item_id, 
                saveItemWithVIN,
                imsForm.item.owner,
                imsForm.item.owner_name, 
                imsForm.item, 
                null 
            );


                setIsModalOpen(false)
                setVinInput('')
                setPopulatedSuccess(true)
                const newFieldValues: FieldValues = {}

                populateResponse.groups?.forEach((group: any) => {
                    group.components?.forEach((component: any) => {
                        newFieldValues[String(component.id)] = {
                            value: component.component_response,
                            is_auto_populated: true, 
                        }
                    })
                })
        
                const updatedFormData = {
                    ...imsForm,
                    item: {
                        ...imsForm.item,
                        ims_forms_data: {
                            ...populateResponse,
                        },
                    },
                }
                console.log('Updated field values:', newFieldValues)
                setFieldValues(newFieldValues) 
                setImsForm(updatedFormData)

                console.log('Updated Form Data before save:', updatedFormData)

                await savePopulatedForms(imsForm.item.item_id, updatedFormData)
            }
        } catch (error) {
            console.error('Error submitting VIN:', error)
            setSaveForm(false)
        }
    }

    if (!loadForm && selectedCategory === null) {
        return (
            <div
                style={{
                    padding: '20px',
                    border: '1px solid #ccc',
                    borderRadius: '5px',
                    background: '#f9f9f9',
                    maxHeight: '95%',
                    overflowY: 'auto',
                    marginLeft: '20px',
                    marginRight: '20px',
                }}
            >
                <div>
                    <Snackbar
                        open={successAlert}
                        autoHideDuration={6000}
                        onClose={handleSaveSuccessClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                    >
                        <Alert
                            onClose={handleSaveSuccessClose}
                            severity="success"
                            variant="filled"
                            sx={{ width: '100%' }}
                        >
                            IMS form is saved successfully!
                        </Alert>
                    </Snackbar>
                </div>

                <LoadFormPreview />
            </div>
        )
    } else if (
        !imsForm?.item?.ims_forms_data?.id ||
        Object.keys(imsForm?.item?.ims_forms_data).length === 0
    ) {
        return (
            <div
                style={{
                    padding: '20px',
                    border: '1px solid #ccc',
                    borderRadius: '5px',
                    background: '#f9f9f9',
                    maxHeight: '95%',
                    overflowY: 'auto',
                    marginLeft: '20px',
                    marginRight: '20px',
                }}
            >
                <div>
                    <Snackbar
                        open={populateError}
                        autoHideDuration={6000}
                        onClose={handlePopulateErrorClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                    >
                        <Alert
                            onClose={handlePopulateClose}
                            severity="error"
                            variant="filled"
                            sx={{ width: '100%' }}
                        >
                            Wrong VIN number provided.
                        </Alert>
                    </Snackbar>
                    <Snackbar
                        open={populatedSuccess}
                        autoHideDuration={6000}
                        onClose={handlePopulateClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                    >
                        <Alert
                            onClose={handlePopulateClose}
                            severity="success"
                            variant="filled"
                            sx={{ width: '100%' }}
                        >
                            Form Populated Successfully
                        </Alert>
                    </Snackbar>
                    <Snackbar
                        open={successAlert}
                        autoHideDuration={6000}
                        onClose={handleSaveSuccessClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                    >
                        <Alert
                            onClose={handleSaveSuccessClose}
                            severity="success"
                            variant="filled"
                            sx={{ width: '100%' }}
                        >
                            IMS form is saved successfully!
                        </Alert>
                    </Snackbar>
                    <Dialog
                        open={isModalOpen}
                        onClose={() => setIsModalOpen(false)}
                        PaperProps={{
                            style: {
                                borderRadius: '12px',
                                padding: '16px',
                                maxWidth: '500px',
                                width: '100%',
                            },
                        }}
                    >
                        <DialogTitle
                            sx={{
                                color: '#4a287d',
                                fontWeight: 600,
                                fontSize: '1.5rem',
                                pb: 1,
                            }}
                        >
                            Enter VIN Number
                        </DialogTitle>
                        <DialogContent sx={{ pt: 2 }}>
                            <TextField
                                autoFocus
                                margin="dense"
                                label="VIN Number"
                                type="text"
                                fullWidth
                                variant="outlined"
                                value={vinInput}
                                onChange={(e) => setVinInput(e.target.value)}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '&:hover fieldset': {
                                            borderColor: '#4a287d',
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: '#4a287d',
                                        },
                                    },
                                    '& .MuiInputLabel-root.Mui-focused': {
                                        color: '#4a287d',
                                    },
                                }}
                            />
                        </DialogContent>
                        <DialogActions sx={{ padding: '16px', pt: 2 }}>
                            <Button
                                onClick={() => setIsModalOpen(false)}
                                sx={{
                                    color: '#4a287d',
                                    '&:hover': {
                                        backgroundColor:
                                            'rgba(74, 40, 125, 0.04)',
                                    },
                                    textTransform: 'none',
                                    fontWeight: 500,
                                    px: 3,
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                onClick={handleModalSubmit}
                                variant="contained"
                                sx={{
                                    backgroundColor: '#4a287d',
                                    '&:hover': {
                                        backgroundColor: '#3a1f62',
                                    },
                                    textTransform: 'none',
                                    fontWeight: 500,
                                    px: 3,
                                }}
                            >
                                Submit
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
                {loading ? (
                    <ContentLoader
                        speed={2}
                        width={400}
                        height={150}
                        viewBox="0 0 400 150"
                        backgroundColor="#f3f3f3"
                        foregroundColor="#ecebeb"
                    >
                        <rect
                            x="35"
                            y="10"
                            rx="5"
                            ry="5"
                            width="150"
                            height="10"
                        />
                        <rect
                            x="35"
                            y="45"
                            rx="5"
                            ry="5"
                            width="150"
                            height="10"
                        />
                        <rect
                            x="35"
                            y="80"
                            rx="5"
                            ry="5"
                            width="150"
                            height="10"
                        />
                        <rect
                            x="35"
                            y="115"
                            rx="5"
                            ry="5"
                            width="150"
                            height="10"
                        />
                        <rect
                            x="3"
                            y="5"
                            rx="4"
                            ry="4"
                            width="20"
                            height="20"
                        />
                        <rect
                            x="3"
                            y="40"
                            rx="4"
                            ry="4"
                            width="20"
                            height="20"
                        />
                        <rect
                            x="3"
                            y="75"
                            rx="4"
                            ry="4"
                            width="20"
                            height="20"
                        />
                        <rect
                            x="3"
                            y="110"
                            rx="4"
                            ry="4"
                            width="20"
                            height="20"
                        />
                    </ContentLoader>
                ) : error ? (
                    <div>Error: {error}</div>
                ) : (
                    <div>
                        <div
                            key={ims_forms_data?.id}
                            style={{ marginBottom: '20px' }}
                        >
                            <div>
                                <Grid
                                    container
                                    spacing={3}
                                    alignItems="center"
                                    justifyContent="space-between"
                                >
                                    <Grid item xs={6}>
                                        <Typography style={titleStyle}>
                                            IMS Form Category
                                        </Typography>
                                        <h2>{ims_forms_data?.category}</h2>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={6}
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'flex-end',
                                        }}
                                    >
                                        <Button
                                            variant="contained"
                                            endIcon={
                                                <AutoFixHighIcon
                                                    sx={{ marginLeft: 1 }}
                                                />
                                            }
                                            onClick={handlePopulateFormToIMS}
                                            className="fourth-button"
                                            style={{
                                                height: 40,
                                                width: '80%',
                                            }}
                                        >
                                            <Typography
                                                style={{ color: 'white' }}
                                                variant="button"
                                            >
                                                {loading
                                                    ? 'Processing...'
                                                    : 'Populate Form'}
                                            </Typography>
                                        </Button>
                                    </Grid>
                                </Grid>
                                <div
                                    style={{
                                        margin: '20px 0',
                                    }}
                                >
                                    <Divider />
                                </div>
                                <Grid container spacing={3} alignItems="center">
                                    <Grid
                                        container
                                        item
                                        xs={6}
                                        direction="column"
                                    >
                                        <Grid item>
                                            <Typography style={titleStyle}>
                                                Fee Code
                                            </Typography>
                                            <h4 style={internaUseValues}>
                                                {evaluation?.evaluation
                                                    ?.fee_code || '--------'}
                                            </h4>
                                        </Grid>
                                        <Grid item>
                                            <Typography style={titleStyle}>
                                                Location
                                            </Typography>
                                            <h4 style={internaUseValues}>
                                                {imsForm?.item?.location ||
                                                    '--------'}
                                            </h4>
                                        </Grid>
                                        <Grid item>
                                            <Typography style={titleStyle}>
                                                DC Service
                                            </Typography>
                                            <h4 style={internaUseValues}>
                                                {evaluation?.evaluation
                                                    ?.primary_contact ||
                                                    '--------'}
                                            </h4>
                                        </Grid>
                                        <Grid item>
                                            <Typography style={titleStyle}>
                                                Copart Lot Number
                                            </Typography>
                                            <h4 style={internaUseValues}>
                                                {imsForm?.lot_number ||
                                                    '--------'}
                                            </h4>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        container
                                        item
                                        xs={6}
                                        direction="column"
                                    >
                                        <Grid item>
                                            <Typography style={titleStyle}>
                                                Form Status
                                            </Typography>
                                            <div style={internaUseValues2}>
                                                {imsForm?.item?.form_sub ? (
                                                    <Chip
                                                        color="success"
                                                        icon={<CheckIcon />}
                                                        label="Form Submited to IMS"
                                                        size="small"
                                                    />
                                                ) : (
                                                    <Chip
                                                        icon={<InfoIcon />}
                                                        label="Form is not Submited"
                                                        size="small"
                                                    />
                                                )}
                                            </div>
                                        </Grid>
                                        <Grid item>
                                            <Typography style={titleStyle}>
                                                Opportunity
                                            </Typography>
                                            <h4 style={internaUseValues}>
                                                {imsForm?.item?.ims_forms_data
                                                    ?.opportunity_id ||
                                                    '--------'}
                                            </h4>
                                        </Grid>
                                        <Grid item>
                                            <Typography style={titleStyle}>
                                                Job Code
                                            </Typography>
                                            <h4 style={internaUseValues}>
                                                {imsForm?.jobcode || '--------'}
                                            </h4>
                                        </Grid>
                                        <Grid item>
                                            <Typography style={titleStyle}>
                                                Nomination
                                            </Typography>
                                            <FormControl
                                                style={{
                                                    position: 'relative',
                                                    width: '100%',
                                                }}
                                            >
                                                <Select
                                                    labelId="nomination-label"
                                                    id="nomination-select"
                                                    value={nominationValue}
                                                    onChange={
                                                        handleNominationChange
                                                    }
                                                    style={{
                                                        width: '70%',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                    }}
                                                    MenuProps={{
                                                        PaperProps: {
                                                            style: {
                                                                maxHeight: 200,
                                                            },
                                                        },
                                                    }}
                                                >
                                                    {[...Array(11)].map(
                                                        (_, index) => (
                                                            <MenuItem
                                                                key={index}
                                                                value={index}
                                                            >
                                                                {index}
                                                            </MenuItem>
                                                        )
                                                    )}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </div>

                            <div style={{ margin: '12px 0' }}></div>

                            <div
                                style={{
                                    maxHeight: '650px',
                                    overflowY: 'auto',
                                }}
                            >
                                {ims_forms_data?.groups?.map(
                                    (group: {
                                        id: React.Key | null | undefined
                                        display_name: any
                                        title: any
                                        components: any[]
                                    }) => (
                                        <Accordion
                                            key={group.id}
                                            style={{
                                                marginBottom: '10px',
                                                background: '#fff',
                                                boxShadow:
                                                    '0 4px 8px rgba(0,0,0,0.1)',
                                            }}
                                        >
                                            <AccordionSummary
                                                expandIcon={
                                                    <ArrowDownwardIcon />
                                                }
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                            >
                                                <Typography
                                                    style={{ color: '#4f2682' }}
                                                >
                                                    <h3>
                                                        {group?.display_name ||
                                                            group.title}
                                                    </h3>
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails
                                                style={{
                                                    padding: '10px',
                                                    background: '#f9f9f9',
                                                }}
                                            >
                                                <form>
                                                    {group?.components?.map(
                                                        (component: {
                                                            is_auto_populated: boolean
                                                            id:
                                                                | React.Key
                                                                | null
                                                                | undefined
                                                            control_type: string
                                                            datatype: string
                                                            label_display: any
                                                            label: any
                                                            prefix:
                                                                | string
                                                                | number
                                                                | boolean
                                                                | React.ReactElement<
                                                                      any,
                                                                      | string
                                                                      | React.JSXElementConstructor<any>
                                                                  >
                                                                | React.ReactPortal
                                                                | null
                                                                | undefined
                                                            suffix:
                                                                | string
                                                                | number
                                                                | boolean
                                                                | React.ReactElement<
                                                                      any,
                                                                      | string
                                                                      | React.JSXElementConstructor<any>
                                                                  >
                                                                | React.ReactPortal
                                                                | null
                                                                | undefined
                                                            component_response: {
                                                                photo: any
                                                            }
                                                            values: any[]
                                                            component_form:
                                                                | string
                                                                | any[]
                                                            helper_text:
                                                                | string
                                                                | number
                                                                | boolean
                                                                | React.ReactElement<
                                                                      any,
                                                                      | string
                                                                      | React.JSXElementConstructor<any>
                                                                  >
                                                                | React.ReactPortal
                                                                | null
                                                                | undefined
                                                        }) => (
                                                            <div
                                                                key={
                                                                    component.id
                                                                }
                                                                style={{
                                                                    marginBottom:
                                                                        '25px',
                                                                    width: '100%',
                                                                    marginLeft:
                                                                        '20px',
                                                                }}
                                                            >
                                                                {component.control_type ===
                                                                'checkbox' ? (
                                                                    <div
                                                                        style={{
                                                                            position:
                                                                                'relative',
                                                                            width: '100%',
                                                                        }}
                                                                    >
                                                                        <>
                                                                            <input
                                                                                type="checkbox"
                                                                                style={{
                                                                                    marginRight:
                                                                                        '5px',
                                                                                }}
                                                                                checked={
                                                                                    component.id !=
                                                                                    null
                                                                                        ? fieldValues[
                                                                                              String(
                                                                                                  component.id
                                                                                              )
                                                                                          ]
                                                                                              ?.value ===
                                                                                          true
                                                                                        : false
                                                                                }
                                                                                onChange={(
                                                                                    event
                                                                                ) =>
                                                                                    component.id !=
                                                                                        null &&
                                                                                    group.id !=
                                                                                        null &&
                                                                                    handleFieldChange(
                                                                                        String(
                                                                                            group.id
                                                                                        ),
                                                                                        String(
                                                                                            component.id
                                                                                        ),
                                                                                        event
                                                                                            .target
                                                                                            .checked,
                                                                                        component.datatype
                                                                                    )
                                                                                }
                                                                            />
                                                                        </>
                                                                        {typeof component.id ===
                                                                            'number' &&
                                                                            shouldShowAutoPopulatedIndicator(
                                                                                {
                                                                                    is_auto_populated:
                                                                                        component.is_auto_populated,
                                                                                    id: component.id as number, // Now we know it's a number
                                                                                    component_response:
                                                                                        component.component_response,
                                                                                },
                                                                                fieldValues
                                                                            ) && (
                                                                                <AutoPopulateIndicator />
                                                                            )}
                                                                    </div>
                                                                ) : null}
                                                                {component.control_type !==
                                                                    'textfield' &&
                                                                    component.control_type !==
                                                                        'checkboxgroup' &&
                                                                    component.control_type !==
                                                                        'heading' &&
                                                                    component.control_type !==
                                                                        'subheading' && (
                                                                        <label
                                                                            style={{
                                                                                marginBottom:
                                                                                    '12px',
                                                                            }}
                                                                        >
                                                                            {component.label_display ||
                                                                                component.label}
                                                                        </label>
                                                                    )}
                                                                {component.control_type ===
                                                                'textfield' ? (
                                                                    <div
                                                                        style={{
                                                                            position:
                                                                                'relative',
                                                                            width: '100%',
                                                                        }}
                                                                    >
                                                                        <div
                                                                            style={{
                                                                                display:
                                                                                    'flex',
                                                                                alignItems:
                                                                                    'center',
                                                                                maxWidth:
                                                                                    '80%',
                                                                            }}
                                                                        >
                                                                            {component.prefix && (
                                                                                <span
                                                                                    style={{
                                                                                        marginRight:
                                                                                            '5px',
                                                                                    }}
                                                                                >
                                                                                    {
                                                                                        component.prefix
                                                                                    }
                                                                                </span>
                                                                            )}
                                                                            <TextField
                                                                                id={
                                                                                    component.id !=
                                                                                    null
                                                                                        ? String(
                                                                                              component.id
                                                                                          )
                                                                                        : undefined
                                                                                }
                                                                                variant="standard"
                                                                                sx={{
                                                                                    width: '300px',
                                                                                }}
                                                                                label={
                                                                                    component.label_display ||
                                                                                    component.label
                                                                                }
                                                                                // Check if the value is an object and then access the `value` field
                                                                                value={
                                                                                    component.id !=
                                                                                    null
                                                                                        ? typeof fieldValues[
                                                                                              String(
                                                                                                  component.id
                                                                                              )
                                                                                          ] ===
                                                                                          'object'
                                                                                            ? fieldValues[
                                                                                                  String(
                                                                                                      component.id
                                                                                                  )
                                                                                              ]
                                                                                                  ?.value ||
                                                                                              ''
                                                                                            : fieldValues[
                                                                                                  String(
                                                                                                      component.id
                                                                                                  )
                                                                                              ] ||
                                                                                              ''
                                                                                        : ''
                                                                                }
                                                                                onChange={(
                                                                                    event
                                                                                ) => {
                                                                                    // Ensure both group.id and component.id are strings
                                                                                    if (
                                                                                        group.id &&
                                                                                        component.id
                                                                                    ) {
                                                                                        handleFieldChange(
                                                                                            String(
                                                                                                group.id
                                                                                            ),
                                                                                            String(
                                                                                                component.id
                                                                                            ),
                                                                                            event
                                                                                                .target
                                                                                                .value,
                                                                                            component.datatype
                                                                                        )
                                                                                    }
                                                                                }}
                                                                            />

                                                                            {component.suffix && (
                                                                                <span
                                                                                    style={{
                                                                                        marginLeft:
                                                                                            '5px',
                                                                                    }}
                                                                                >
                                                                                    {
                                                                                        component.suffix
                                                                                    }
                                                                                </span>
                                                                            )}
                                                                        </div>
                                                                        {typeof component.id ===
                                                                            'number' &&
                                                                            shouldShowAutoPopulatedIndicator(
                                                                                {
                                                                                    is_auto_populated:
                                                                                        component.is_auto_populated,
                                                                                    id: component.id as number, // Now we know it's a number
                                                                                    component_response:
                                                                                        component.component_response,
                                                                                },
                                                                                fieldValues
                                                                            ) && (
                                                                                <AutoPopulateIndicator />
                                                                            )}
                                                                    </div>
                                                                ) : component.control_type ===
                                                                  'numberfield' ? (
                                                                    <div
                                                                        style={{
                                                                            position:
                                                                                'relative',
                                                                            width: '100%',
                                                                        }}
                                                                    >
                                                                        <input
                                                                            type="number"
                                                                            style={{
                                                                                width: '100%',
                                                                                padding:
                                                                                    '5px',
                                                                                border: '1px solid #ccc',
                                                                                borderRadius:
                                                                                    '3px',
                                                                                margin: '10px 0',
                                                                            }}
                                                                            value={
                                                                                typeof component.component_response ===
                                                                                    'number' ||
                                                                                typeof component.component_response ===
                                                                                    'string'
                                                                                    ? component.component_response
                                                                                    : ''
                                                                            }
                                                                            onChange={(
                                                                                event
                                                                            ) => {
                                                                                if (
                                                                                    group.id !=
                                                                                        null &&
                                                                                    component.id !=
                                                                                        null
                                                                                ) {
                                                                                    handleFieldChange(
                                                                                        String(
                                                                                            group.id
                                                                                        ),
                                                                                        String(
                                                                                            component.id
                                                                                        ),
                                                                                        event
                                                                                            .target
                                                                                            .value,
                                                                                        component.datatype
                                                                                    ) // Casting to string
                                                                                }
                                                                            }}
                                                                        />
                                                                        {typeof component.id ===
                                                                            'number' &&
                                                                            shouldShowAutoPopulatedIndicator(
                                                                                {
                                                                                    is_auto_populated:
                                                                                        component.is_auto_populated,
                                                                                    id: component.id as number, // Now we know it's a number
                                                                                    component_response:
                                                                                        component.component_response,
                                                                                },
                                                                                fieldValues
                                                                            ) && (
                                                                                <AutoPopulateIndicator />
                                                                            )}
                                                                    </div>
                                                                ) : component.control_type ===
                                                                  'textarea' ? (
                                                                    <div
                                                                        style={{
                                                                            position:
                                                                                'relative',
                                                                            width: '100%',
                                                                        }}
                                                                    >
                                                                        <Textarea
                                                                            id={
                                                                                component.id !=
                                                                                null
                                                                                    ? String(
                                                                                          component.id
                                                                                      )
                                                                                    : undefined
                                                                            }
                                                                            minRows={
                                                                                2
                                                                            }
                                                                            size="md"
                                                                            style={{
                                                                                padding:
                                                                                    '5px',
                                                                                border: '1px solid #ccc',
                                                                                borderRadius:
                                                                                    '3px',
                                                                                maxWidth:
                                                                                    '300px',
                                                                            }}
                                                                            value={
                                                                                component.id !=
                                                                                null
                                                                                    ? typeof fieldValues[
                                                                                          String(
                                                                                              component.id
                                                                                          )
                                                                                      ]
                                                                                          ?.value !==
                                                                                      'undefined' // Check if value property exists
                                                                                        ? fieldValues[
                                                                                              String(
                                                                                                  component.id
                                                                                              )
                                                                                          ]
                                                                                              .value // Use .value if it exists
                                                                                        : fieldValues[
                                                                                              String(
                                                                                                  component.id
                                                                                              )
                                                                                          ] ||
                                                                                          '' // Otherwise use direct value
                                                                                    : ''
                                                                            }
                                                                            onChange={(
                                                                                event
                                                                            ) => {
                                                                                if (
                                                                                    component.id !=
                                                                                    null
                                                                                ) {
                                                                                    handleFieldChange(
                                                                                        String(
                                                                                            group.id
                                                                                        ),
                                                                                        String(
                                                                                            component.id
                                                                                        ),
                                                                                        event
                                                                                            .target
                                                                                            .value,
                                                                                        component.datatype
                                                                                    )
                                                                                }
                                                                            }}
                                                                        />
                                                                        {typeof component.id ===
                                                                            'number' &&
                                                                            shouldShowAutoPopulatedIndicator(
                                                                                {
                                                                                    is_auto_populated:
                                                                                        component.is_auto_populated,
                                                                                    id: component.id as number, // Now we know it's a number
                                                                                    component_response:
                                                                                        component.component_response,
                                                                                },
                                                                                fieldValues
                                                                            ) && (
                                                                                <AutoPopulateIndicator />
                                                                            )}
                                                                    </div>
                                                                ) : component.control_type ===
                                                                  'select' ? (
                                                                    <div
                                                                        style={{
                                                                            position:
                                                                                'relative',
                                                                            width: '100%',
                                                                        }}
                                                                    >
                                                                        <select
                                                                            style={{
                                                                                width: '100%',
                                                                                padding:
                                                                                    '5px',
                                                                                border: '1px solid #ccc',
                                                                                borderRadius:
                                                                                    '3px',
                                                                                margin: '10px 0',
                                                                            }}
                                                                        >
                                                                            {component.values?.map(
                                                                                (option: {
                                                                                    id:
                                                                                        | React.Key
                                                                                        | null
                                                                                        | undefined
                                                                                    component_response: any
                                                                                    value:
                                                                                        | string
                                                                                        | number
                                                                                        | boolean
                                                                                        | React.ReactElement<
                                                                                              any,
                                                                                              | string
                                                                                              | React.JSXElementConstructor<any>
                                                                                          >
                                                                                        | React.ReactPortal
                                                                                        | null
                                                                                        | undefined
                                                                                }) => (
                                                                                    <option
                                                                                        key={
                                                                                            option.id
                                                                                        }
                                                                                        value={
                                                                                            option.component_response ||
                                                                                            ''
                                                                                        }
                                                                                        onChange={(
                                                                                            event
                                                                                        ) =>
                                                                                            handleFieldChange(
                                                                                                String(
                                                                                                    group.id
                                                                                                ), // Ensure group.id is a string
                                                                                                String(
                                                                                                    component.id
                                                                                                ), // Ensure component.id is a string
                                                                                                (
                                                                                                    event.target as HTMLSelectElement
                                                                                                )
                                                                                                    .value, // Pass the selected value
                                                                                                component.datatype // Pass the datatype as the 4th argument
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        {
                                                                                            option.value
                                                                                        }
                                                                                    </option>
                                                                                )
                                                                            )}
                                                                        </select>
                                                                        {typeof component.id ===
                                                                            'number' &&
                                                                            shouldShowAutoPopulatedIndicator(
                                                                                {
                                                                                    is_auto_populated:
                                                                                        component.is_auto_populated,
                                                                                    id: component.id as number, // Now we know it's a number
                                                                                    component_response:
                                                                                        component.component_response,
                                                                                },
                                                                                fieldValues
                                                                            ) && (
                                                                                <AutoPopulateIndicator />
                                                                            )}
                                                                    </div>
                                                                ) : component.control_type ===
                                                                  'checkboxgroup' ? (
                                                                    <div
                                                                        style={{
                                                                            position:
                                                                                'relative',
                                                                            width: '100%',
                                                                        }}
                                                                    >
                                                                        <div
                                                                            style={{
                                                                                marginTop:
                                                                                    '30px',
                                                                                marginBottom:
                                                                                    '30px',
                                                                            }}
                                                                        >
                                                                            <div
                                                                                style={{
                                                                                    marginBottom:
                                                                                        '5px',
                                                                                }}
                                                                            >
                                                                                <label
                                                                                    style={{
                                                                                        margin: '0',
                                                                                    }}
                                                                                >
                                                                                    {component.label_display ||
                                                                                        component.label}
                                                                                </label>
                                                                            </div>
                                                        
                                                                            <Grid
                                                                                container
                                                                                alignItems="center"
                                                                            >
                                                                                {component.values?.map(
                                                                                    (option: {
                                                                                        id:
                                                                                            | React.Key
                                                                                            | null
                                                                                            | undefined
                                                                                        value:
                                                                                            | string
                                                                                            | number
                                                                                            | boolean
                                                                                            | React.ReactElement<
                                                                                                  any,
                                                                                                  | string
                                                                                                  | React.JSXElementConstructor<any>
                                                                                              >
                                                                                            | null
                                                                                            | undefined
                                                                                    }) => (
                                                                                        <div
                                                                                            key={
                                                                                                option.id
                                                                                            }
                                                                                            style={{
                                                                                                width: '100%',
                                                                                            }}
                                                                                        >
                                                                                            <Grid
                                                                                                container
                                                                                                alignItems="center"
                                                                                            >
                                                                                                <input
                                                                                                    type="checkbox"
                                                                                                    id={
                                                                                                        option.id !=
                                                                                                        null
                                                                                                            ? String(
                                                                                                                  option.id
                                                                                                              )
                                                                                                            : undefined
                                                                                                    }
                                                                                                    checked={
                                                                                                        option.id !=
                                                                                                        null
                                                                                                            ? fieldValues[
                                                                                                                  String(
                                                                                                                      option.id
                                                                                                                  )
                                                                                                              ] ===
                                                                                                                  true ||
                                                                                                              fieldValues[
                                                                                                                  String(
                                                                                                                      option.id
                                                                                                                  )
                                                                                                              ]
                                                                                                                  ?.value ===
                                                                                                                  true
                                                                                                            : false
                                                                                                    }
                                                                                                    style={{
                                                                                                        color: '#4f2682',
                                                                                                        marginLeft:
                                                                                                            '5px',
                                                                                                    }}
                                                                                                    onChange={(
                                                                                                        event
                                                                                                    ) =>
                                                                                                        option.id !=
                                                                                                            null &&
                                                                                                        group.id !=
                                                                                                            null &&
                                                                                                        handleFieldChange(
                                                                                                            String(
                                                                                                                group.id
                                                                                                            ),
                                                                                                            String(
                                                                                                                option.id
                                                                                                            ),
                                                                                                            event
                                                                                                                .target
                                                                                                                .checked,
                                                                                                            component.datatype
                                                                                                        )
                                                                                                    }
                                                                                                />
                                                                                                <label
                                                                                                    htmlFor={
                                                                                                        typeof option.value ===
                                                                                                        'string'
                                                                                                            ? option.value
                                                                                                            : undefined
                                                                                                    }
                                                                                                    style={{
                                                                                                        color: '#4f2682',
                                                                                                        marginLeft:
                                                                                                            '5px',
                                                                                                        marginBottom:
                                                                                                            '0',
                                                                                                    }}
                                                                                                >
                                                                                                    {
                                                                                                        option.value
                                                                                                    }
                                                                                                </label>
                                                                                            </Grid>
                                                                                        </div>
                                                                                    )
                                                                                )}
                                                                            </Grid>
                                                                        </div>
                                                                        {typeof component.id ===
                                                                            'number' &&
                                                                            shouldShowAutoPopulatedIndicator(
                                                                                {
                                                                                    is_auto_populated:
                                                                                        component.is_auto_populated,
                                                                                    id: component.id as number, // Now we know it's a number
                                                                                    component_response:
                                                                                        component.component_response,
                                                                                },
                                                                                fieldValues
                                                                            ) && (
                                                                                <AutoPopulateIndicator />
                                                                            )}
                                                                    </div>
                                                                ) : component.control_type ===
                                                                  'subheading' ? (
                                                                    <h4>
                                                                        {console.log(
                                                                            'Photo URL 1:',
                                                                            component
                                                                                .component_response
                                                                                ?.photo
                                                                        )}
                                                                        {component.label_display ||
                                                                            component.label}
                                                                    </h4>
                                                                ) : null}

                                                                {component.component_form &&
                                                                    component
                                                                        .component_form
                                                                        .length >
                                                                        0 && (
                                                                        <IconButton
                                                                            aria-label="Help"
                                                                            onClick={() => {
                                                                                window.open(
                                                                                    component
                                                                                        .component_form[0]
                                                                                        .help_link,
                                                                                    '_blank'
                                                                                )
                                                                            }}
                                                                        >
                                                                            <OpenInNewIcon
                                                                                style={{
                                                                                    fontSize:
                                                                                        '15px',
                                                                                    color: '#4f2682',
                                                                                }}
                                                                            />
                                                                        </IconButton>
                                                                    )}

                                                                {/* Render helper text */}
                                                                {component.helper_text && (
                                                                    <div
                                                                        style={{
                                                                            width: '70%',
                                                                        }}
                                                                    >
                                                                        <Typography
                                                                            variant="caption"
                                                                            style={{
                                                                                fontSize:
                                                                                    'small',
                                                                                color: '#666',
                                                                            }}
                                                                        >
                                                                            {
                                                                                component.helper_text
                                                                            }
                                                                        </Typography>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        )
                                                    )}
                                                </form>
                                            </AccordionDetails>
                                        </Accordion>
                                    )
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        )
    } else if (
        imsForm?.item?.ims_forms_data?.id ||
        Object.keys(imsForm?.item?.ims_forms_data).length !== 0
    ) {
        return (
            <div
                style={{
                    padding: '20px',
                    border: '1px solid #ccc',
                    borderRadius: '5px',
                    background: '#f9f9f9',
                    maxHeight: '95%',
                    overflowY: 'auto',
                    marginLeft: '20px',
                    marginRight: '20px',
                }}
            >
                {/* Add this near the end of your component return statement */}
                <Dialog
                    open={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                    PaperProps={{
                        style: {
                            borderRadius: '12px',
                            padding: '16px',
                            maxWidth: '500px',
                            width: '100%',
                        },
                    }}
                >
                    <DialogTitle
                        sx={{
                            color: '#4a287d',
                            fontWeight: 600,
                            fontSize: '1.5rem',
                            pb: 1,
                        }}
                    >
                        Enter VIN Number
                    </DialogTitle>
                    <DialogContent sx={{ pt: 2 }}>
                        <TextField
                            autoFocus
                            margin="dense"
                            label="VIN Number"
                            type="text"
                            fullWidth
                            variant="outlined"
                            value={vinInput}
                            onChange={(e) => setVinInput(e.target.value)}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '&:hover fieldset': {
                                        borderColor: '#4a287d',
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: '#4a287d',
                                    },
                                },
                                '& .MuiInputLabel-root.Mui-focused': {
                                    color: '#4a287d',
                                },
                            }}
                        />
                    </DialogContent>
                    <DialogActions sx={{ padding: '16px', pt: 2 }}>
                        <Button
                            onClick={() => setIsModalOpen(false)}
                            sx={{
                                color: '#4a287d',
                                '&:hover': {
                                    backgroundColor: 'rgba(74, 40, 125, 0.04)',
                                },
                                textTransform: 'none',
                                fontWeight: 500,
                                px: 3,
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={handleModalSubmit}
                            variant="contained"
                            sx={{
                                backgroundColor: '#4a287d',
                                '&:hover': {
                                    backgroundColor: '#3a1f62',
                                },
                                textTransform: 'none',
                                fontWeight: 500,
                                px: 3,
                            }}
                        >
                            Submit
                        </Button>
                    </DialogActions>
                </Dialog>
                <div>
                    <Snackbar
                        open={populateError}
                        autoHideDuration={6000}
                        onClose={handlePopulateErrorClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                    >
                        <Alert
                            onClose={handlePopulateClose}
                            severity="error"
                            variant="filled"
                            sx={{ width: '100%' }}
                        >
                            Wrong VIN number provided.
                        </Alert>
                    </Snackbar>
                    <Snackbar
                        open={populatedSuccess}
                        autoHideDuration={6000}
                        onClose={handlePopulateClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                    >
                        <Alert
                            onClose={handlePopulateClose}
                            severity="success"
                            variant="filled"
                            sx={{ width: '100%' }}
                        >
                            Form Populated Successfully
                        </Alert>
                    </Snackbar>
                    <Snackbar
                        open={successAlert}
                        autoHideDuration={6000}
                        onClose={handleSaveSuccessClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                    >
                        <Alert
                            onClose={handleSaveSuccessClose}
                            severity="success"
                            variant="filled"
                            sx={{ width: '100%' }}
                        >
                            IMS form is saved successfully!
                        </Alert>
                    </Snackbar>
                </div>

                {loading ? (
                    <ContentLoader
                        speed={2}
                        width={400}
                        height={150}
                        viewBox="0 0 400 150"
                        backgroundColor="#f3f3f3"
                        foregroundColor="#ecebeb"
                    >
                        <rect
                            x="35"
                            y="10"
                            rx="5"
                            ry="5"
                            width="150"
                            height="10"
                        />
                        <rect
                            x="35"
                            y="45"
                            rx="5"
                            ry="5"
                            width="150"
                            height="10"
                        />
                        <rect
                            x="35"
                            y="80"
                            rx="5"
                            ry="5"
                            width="150"
                            height="10"
                        />
                        <rect
                            x="35"
                            y="115"
                            rx="5"
                            ry="5"
                            width="150"
                            height="10"
                        />
                        <rect
                            x="3"
                            y="5"
                            rx="4"
                            ry="4"
                            width="20"
                            height="20"
                        />
                        <rect
                            x="3"
                            y="40"
                            rx="4"
                            ry="4"
                            width="20"
                            height="20"
                        />
                        <rect
                            x="3"
                            y="75"
                            rx="4"
                            ry="4"
                            width="20"
                            height="20"
                        />
                        <rect
                            x="3"
                            y="110"
                            rx="4"
                            ry="4"
                            width="20"
                            height="20"
                        />
                    </ContentLoader>
                ) : error ? (
                    <div>Error: {error}</div>
                ) : (
                    <div>
                        <div
                            key={newIMSData?.id}
                            style={{ marginBottom: '20px' }}
                        >
                            <div>
                                <Grid
                                    container
                                    spacing={3}
                                    alignItems="center"
                                    justifyContent="space-between"
                                >
                                    <Grid item xs={6}>
                                        <Typography style={titleStyle}>
                                            IMS Form Category
                                        </Typography>
                                        <h2>{ims_forms_data?.category}</h2>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={6}
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'flex-end',
                                        }}
                                    >
                                        <Button
                                            variant="contained"
                                            endIcon={
                                                <AutoFixHighIcon
                                                    sx={{ marginLeft: 1 }}
                                                />
                                            }
                                            onClick={handlePopulateFormToIMS}
                                            className="fourth-button"
                                            style={{
                                                height: 40,
                                                width: '80%',
                                            }}
                                        >
                                            <Typography
                                                style={{ color: 'white' }}
                                                variant="button"
                                            >
                                                {loading
                                                    ? 'Processing...'
                                                    : 'Populate Form'}
                                            </Typography>
                                        </Button>
                                    </Grid>
                                </Grid>
                                <div
                                    style={{
                                        backgroundColor: '#8f8c8c',
                                        height: '1px',
                                        margin: '20px 0',
                                    }}
                                ></div>
                                <Grid container spacing={3} alignItems="center">
                                    <Grid
                                        container
                                        item
                                        xs={6}
                                        direction="column"
                                    >
                                        <Grid item>
                                            <Typography style={titleStyle}>
                                                Fee Code
                                            </Typography>
                                            <h4 style={internaUseValues}>
                                                {evaluation?.evaluation
                                                    ?.fee_code || '--------'}
                                            </h4>
                                        </Grid>
                                        <Grid item>
                                            <Typography style={titleStyle}>
                                                Location
                                            </Typography>
                                            <h4 style={internaUseValues}>
                                                {imsForm?.item?.location ||
                                                    '--------'}
                                            </h4>
                                        </Grid>
                                        <Grid item>
                                            <Typography style={titleStyle}>
                                                DC Service
                                            </Typography>
                                            <h4 style={internaUseValues}>
                                                {evaluation?.evaluation
                                                    ?.primary_contact ||
                                                    '--------'}
                                            </h4>
                                        </Grid>
                                        <Grid item>
                                            <Typography style={titleStyle}>
                                                Copart Lot Number
                                            </Typography>
                                            <h4 style={internaUseValues}>
                                                {imsForm?.lot_number ||
                                                    '--------'}
                                            </h4>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        container
                                        item
                                        xs={6}
                                        direction="column"
                                    >
                                        <Grid item>
                                            <Typography style={titleStyle}>
                                                Form Status
                                            </Typography>
                                            <h4 style={internaUseValues}>
                                                Sent to IMS
                                            </h4>
                                        </Grid>
                                        <Grid item>
                                            <Typography style={titleStyle}>
                                                Opportunity
                                            </Typography>
                                            <h4 style={internaUseValues}>
                                                {imsForm?.item?.ims_forms_data
                                                    ?.opportunity_id ||
                                                    '--------'}
                                            </h4>
                                        </Grid>
                                        <Grid item>
                                            <Typography style={titleStyle}>
                                                Job Code
                                            </Typography>
                                            <h4 style={internaUseValues}>
                                                {imsForm?.jobcode || '--------'}
                                            </h4>
                                        </Grid>
                                        <Grid item>
                                            <Typography style={titleStyle}>
                                                Nomination
                                            </Typography>
                                            <FormControl
                                                style={{
                                                    position: 'relative',
                                                    width: '100%',
                                                }}
                                            >
                                                <Select
                                                    labelId="nomination-label"
                                                    id="nomination-select"
                                                    value={nominationValue}
                                                    onChange={
                                                        handleNominationChange
                                                    }
                                                    style={{
                                                        width: '70%',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                    }}
                                                    MenuProps={{
                                                        PaperProps: {
                                                            style: {
                                                                maxHeight: 200,
                                                            },
                                                        },
                                                    }}
                                                >
                                                    {[...Array(11)].map(
                                                        (_, index) => (
                                                            <MenuItem
                                                                key={index}
                                                                value={index}
                                                            >
                                                                {index}
                                                            </MenuItem>
                                                        )
                                                    )}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </div>
                            <div style={{ margin: '12px 0' }}></div>

                            <div
                                style={{
                                    maxHeight: '650px',
                                    overflowY: 'auto',
                                }}
                            >
                                {newIMSData?.groups?.map(
                                    (group: {
                                        id: React.Key | null | undefined
                                        display_name: any
                                        title: any
                                        components: any[]
                                    }) => (
                                        <Accordion
                                            key={group.id}
                                            style={{
                                                marginBottom: '10px',
                                                background: '#fff',
                                                boxShadow:
                                                    '0 4px 8px rgba(0,0,0,0.1)',
                                            }}
                                        >
                                            <AccordionSummary
                                                expandIcon={
                                                    <ArrowDownwardIcon />
                                                }
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                            >
                                                <Typography
                                                    style={{ color: '#4f2682' }}
                                                >
                                                    <h3>
                                                        {group?.display_name ||
                                                            group.title}
                                                    </h3>
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails
                                                style={{
                                                    padding: '10px',
                                                    background: '#f9f9f9',
                                                }}
                                            >
                                                <form>
                                                    {group?.components?.map(
                                                        (component: {
                                                            is_auto_populated: boolean
                                                            id:
                                                                | React.Key
                                                                | null
                                                                | undefined
                                                            control_type: string
                                                            datatype: string
                                                            label_display: any
                                                            label: any
                                                            photo_capture: number
                                                            prefix:
                                                                | string
                                                                | number
                                                                | boolean
                                                                | React.ReactElement<
                                                                      any,
                                                                      | string
                                                                      | React.JSXElementConstructor<any>
                                                                  >
                                                                | null
                                                                | undefined
                                                            suffix:
                                                                | string
                                                                | number
                                                                | boolean
                                                                | React.ReactElement<
                                                                      any,
                                                                      | string
                                                                      | React.JSXElementConstructor<any>
                                                                  >
                                                                | null
                                                                | undefined
                                                            component_response: {
                                                                photo: React.SetStateAction<
                                                                    | string
                                                                    | null
                                                                >
                                                            }
                                                            values: any[]
                                                            component_form:
                                                                | string
                                                                | any[]
                                                            helper_text:
                                                                | string
                                                                | number
                                                                | boolean
                                                                | React.ReactElement<
                                                                      any,
                                                                      | string
                                                                      | React.JSXElementConstructor<any>
                                                                  >
                                                                | React.ReactPortal
                                                                | null
                                                                | undefined
                                                        }) => (
                                                            <div
                                                                key={
                                                                    component.id
                                                                }
                                                                style={{
                                                                    marginBottom:
                                                                        '25px',
                                                                    width: '100%',
                                                                    marginLeft:
                                                                        '20px',
                                                                }}
                                                            >
                                                                {component.control_type ===
                                                                'checkbox' ? (
                                                                    <div
                                                                        style={{
                                                                            position:
                                                                                'relative',
                                                                            width: '100%',
                                                                        }}
                                                                    >
                                                                        <input
                                                                            type="checkbox"
                                                                            style={{
                                                                                marginRight:
                                                                                    '5px',
                                                                            }}
                                                                            checked={
                                                                                component.id !=
                                                                                null
                                                                                    ? fieldValues[
                                                                                          String(
                                                                                              component.id
                                                                                          )
                                                                                      ]
                                                                                          ?.value ===
                                                                                      true
                                                                                    : false
                                                                            }
                                                                            onChange={(
                                                                                event
                                                                            ) =>
                                                                                component.id !=
                                                                                    null &&
                                                                                group.id !=
                                                                                    null &&
                                                                                handleFieldChange(
                                                                                    String(
                                                                                        group.id
                                                                                    ),
                                                                                    String(
                                                                                        component.id
                                                                                    ),
                                                                                    event
                                                                                        .target
                                                                                        .checked,
                                                                                    component.datatype
                                                                                )
                                                                            }
                                                                        />
                                                                        {typeof component.id ===
                                                                            'number' &&
                                                                            shouldShowAutoPopulatedIndicator(
                                                                                {
                                                                                    is_auto_populated:
                                                                                        component.is_auto_populated,
                                                                                    id: component.id as number, // Now we know it's a number
                                                                                    component_response:
                                                                                        component.component_response,
                                                                                },
                                                                                fieldValues
                                                                            ) && (
                                                                                <AutoPopulateIndicator />
                                                                            )}
                                                                    </div>
                                                                ) : null}
                                                                {component.control_type !==
                                                                    'textfield' &&
                                                                    component.control_type !==
                                                                        'checkboxgroup' &&
                                                                    component.control_type !==
                                                                        'heading' &&
                                                                    component.control_type !==
                                                                        'subheading' && (
                                                                        <label
                                                                            style={{
                                                                                marginBottom:
                                                                                    '12px',
                                                                            }}
                                                                        >
                                                                            {component.label_display ||
                                                                                component.label}
                                                                        </label>
                                                                    )}
                                                                {component.control_type ===
                                                                'textfield' ? (
                                                                    component.photo_capture ===
                                                                    1 ? (
                                                                        <div
                                                                            style={{
                                                                                position:
                                                                                    'relative',
                                                                                width: '100%',
                                                                            }}
                                                                        >
                                                                            <div
                                                                                style={{
                                                                                    display:
                                                                                        'flex',
                                                                                    alignItems:
                                                                                        'center',
                                                                                    maxWidth:
                                                                                        '80%',
                                                                                    justifyContent:
                                                                                        'space-between',
                                                                                }}
                                                                            >
                                                                                {component.prefix && (
                                                                                    <span
                                                                                        style={{
                                                                                            marginRight:
                                                                                                '5px',
                                                                                        }}
                                                                                    >
                                                                                        {
                                                                                            component.prefix
                                                                                        }
                                                                                    </span>
                                                                                )}
                                                                                <TextField
                                                                                    id={
                                                                                        component.id !=
                                                                                        null
                                                                                            ? String(
                                                                                                  component.id
                                                                                              )
                                                                                            : undefined
                                                                                    }
                                                                                    variant="standard"
                                                                                    sx={{
                                                                                        width: '300px',
                                                                                    }}
                                                                                    label={
                                                                                        component.label_display ||
                                                                                        component.label
                                                                                    }
                                                                                    // Check if the value is an object and then access the `value` field
                                                                                    value={
                                                                                        component.id !=
                                                                                        null
                                                                                            ? typeof fieldValues[
                                                                                                  String(
                                                                                                      component.id
                                                                                                  )
                                                                                              ] ===
                                                                                              'object'
                                                                                                ? fieldValues[
                                                                                                      String(
                                                                                                          component.id
                                                                                                      )
                                                                                                  ]
                                                                                                      ?.value ||
                                                                                                  ''
                                                                                                : fieldValues[
                                                                                                      String(
                                                                                                          component.id
                                                                                                      )
                                                                                                  ] ||
                                                                                                  ''
                                                                                            : ''
                                                                                    }
                                                                                    onChange={(
                                                                                        event
                                                                                    ) => {
                                                                                        // Ensure both group.id and component.id are strings
                                                                                        if (
                                                                                            group.id &&
                                                                                            component.id
                                                                                        ) {
                                                                                            handleFieldChange(
                                                                                                String(
                                                                                                    group.id
                                                                                                ),
                                                                                                String(
                                                                                                    component.id
                                                                                                ),
                                                                                                event
                                                                                                    .target
                                                                                                    .value,
                                                                                                component.datatype
                                                                                            )
                                                                                        }
                                                                                    }}
                                                                                />

                                                                                {component.suffix && (
                                                                                    <span
                                                                                        style={{
                                                                                            marginLeft:
                                                                                                '5px',
                                                                                            fontSize:
                                                                                                '2px ',
                                                                                        }}
                                                                                    >
                                                                                        {
                                                                                            component.suffix
                                                                                        }
                                                                                    </span>
                                                                                )}
                                                                                <Stack
                                                                                    direction="row"
                                                                                    spacing={
                                                                                        2
                                                                                    }
                                                                                >
                                                                                    <img
                                                                                        src={
                                                                                            typeof component
                                                                                                .component_response
                                                                                                ?.photo ===
                                                                                            'string'
                                                                                                ? component
                                                                                                      .component_response
                                                                                                      .photo
                                                                                                : IMS_FORM_IMG_PLACEHOLDER
                                                                                        }
                                                                                        alt="Thumbnail"
                                                                                        style={{
                                                                                            width: 56,
                                                                                            height: 56,
                                                                                            cursor: 'pointer',
                                                                                        }}
                                                                                        onClick={() => {
                                                                                            setLightboxImage(
                                                                                                component
                                                                                                    .component_response
                                                                                                    ?.photo
                                                                                            )
                                                                                            setLightboxOpen(
                                                                                                true
                                                                                            )
                                                                                        }}
                                                                                    />
                                                                                    {lightboxOpen &&
                                                                                        lightboxImage && (
                                                                                            <Dialog
                                                                                                open={
                                                                                                    lightboxOpen
                                                                                                }
                                                                                                onClose={() =>
                                                                                                    setLightboxOpen(
                                                                                                        false
                                                                                                    )
                                                                                                }
                                                                                                maxWidth="lg"
                                                                                                fullWidth
                                                                                                style={{
                                                                                                    margin: 0,
                                                                                                }}
                                                                                            >
                                                                                                <DialogTitle>
                                                                                                    <IconButton
                                                                                                        edge="end"
                                                                                                        color="inherit"
                                                                                                        onClick={() =>
                                                                                                            setLightboxOpen(
                                                                                                                false
                                                                                                            )
                                                                                                        }
                                                                                                        aria-label="close"
                                                                                                    >
                                                                                                        <CloseIcon />
                                                                                                    </IconButton>
                                                                                                </DialogTitle>
                                                                                                <DialogContent
                                                                                                    style={{
                                                                                                        padding: 0,
                                                                                                    }}
                                                                                                >
                                                                                                    <img
                                                                                                        src={
                                                                                                            lightboxImage
                                                                                                        }
                                                                                                        alt="Full size"
                                                                                                        style={{
                                                                                                            width: '100%',
                                                                                                            height: 'auto',
                                                                                                        }}
                                                                                                    />
                                                                                                </DialogContent>
                                                                                            </Dialog>
                                                                                        )}
                                                                                </Stack>
                                                                            </div>
                                                                            {typeof component.id ===
                                                                                'number' &&
                                                                                shouldShowAutoPopulatedIndicator(
                                                                                    {
                                                                                        is_auto_populated:
                                                                                            component.is_auto_populated,
                                                                                        id: component.id as number, // Now we know it's a number
                                                                                        component_response:
                                                                                            component.component_response,
                                                                                    },
                                                                                    fieldValues
                                                                                ) && (
                                                                                    <AutoPopulateIndicator />
                                                                                )}
                                                                        </div>
                                                                    ) : (
                                                                        <div
                                                                            style={{
                                                                                position:
                                                                                    'relative',
                                                                                width: '100%',
                                                                            }}
                                                                        >
                                                                            <div
                                                                                style={{
                                                                                    display:
                                                                                        'flex',
                                                                                    alignItems:
                                                                                        'center',
                                                                                    maxWidth:
                                                                                        '80%',
                                                                                }}
                                                                            >
                                                                                {component.prefix && (
                                                                                    <span
                                                                                        style={{
                                                                                            marginRight:
                                                                                                '5px',
                                                                                        }}
                                                                                    >
                                                                                        {
                                                                                            component.prefix
                                                                                        }
                                                                                    </span>
                                                                                )}
                                                                                <TextField
                                                                                    id={
                                                                                        component.id !=
                                                                                        null
                                                                                            ? String(
                                                                                                  component.id
                                                                                              )
                                                                                            : undefined
                                                                                    }
                                                                                    variant="standard"
                                                                                    sx={{
                                                                                        width: '300px',
                                                                                    }}
                                                                                    label={
                                                                                        component.label_display ||
                                                                                        component.label
                                                                                    }
                                                                                    // Check if the value is an object and then access the `value` field
                                                                                    value={
                                                                                        component.id !=
                                                                                        null
                                                                                            ? typeof fieldValues[
                                                                                                  String(
                                                                                                      component.id
                                                                                                  )
                                                                                              ] ===
                                                                                              'object'
                                                                                                ? fieldValues[
                                                                                                      String(
                                                                                                          component.id
                                                                                                      )
                                                                                                  ]
                                                                                                      ?.value ||
                                                                                                  ''
                                                                                                : fieldValues[
                                                                                                      String(
                                                                                                          component.id
                                                                                                      )
                                                                                                  ] ||
                                                                                                  ''
                                                                                            : ''
                                                                                    }
                                                                                    onChange={(
                                                                                        event
                                                                                    ) => {
                                                                                        // Ensure both group.id and component.id are strings
                                                                                        if (
                                                                                            group.id &&
                                                                                            component.id
                                                                                        ) {
                                                                                            handleFieldChange(
                                                                                                String(
                                                                                                    group.id
                                                                                                ),
                                                                                                String(
                                                                                                    component.id
                                                                                                ),
                                                                                                event
                                                                                                    .target
                                                                                                    .value,
                                                                                                component.datatype
                                                                                            )
                                                                                        }
                                                                                    }}
                                                                                />

                                                                                {component.suffix && (
                                                                                    <span
                                                                                        style={{
                                                                                            marginLeft:
                                                                                                '5px',
                                                                                        }}
                                                                                    >
                                                                                        {
                                                                                            component.suffix
                                                                                        }
                                                                                    </span>
                                                                                )}
                                                                            </div>
                                                                            {typeof component.id ===
                                                                                'number' &&
                                                                                shouldShowAutoPopulatedIndicator(
                                                                                    {
                                                                                        is_auto_populated:
                                                                                            component.is_auto_populated,
                                                                                        id: component.id as number, // Now we know it's a number
                                                                                        component_response:
                                                                                            component.component_response,
                                                                                    },
                                                                                    fieldValues
                                                                                ) && (
                                                                                    <AutoPopulateIndicator />
                                                                                )}
                                                                        </div>
                                                                    )
                                                                ) : component.control_type ===
                                                                  'numberfield' ? (
                                                                    <div
                                                                        style={{
                                                                            position:
                                                                                'relative',
                                                                            width: '100%',
                                                                        }}
                                                                    >
                                                                        <input
                                                                            type="number"
                                                                            style={{
                                                                                width: '100%',
                                                                                padding:
                                                                                    '5px',
                                                                                border: '1px solid #ccc',
                                                                                borderRadius:
                                                                                    '3px',
                                                                                margin: '10px 0',
                                                                            }}
                                                                            value={
                                                                                typeof component.component_response ===
                                                                                    'string' ||
                                                                                typeof component.component_response ===
                                                                                    'number'
                                                                                    ? component.component_response
                                                                                    : ''
                                                                            }
                                                                            onChange={(
                                                                                event
                                                                            ) =>
                                                                                handleFieldChange(
                                                                                    group.id !=
                                                                                        null
                                                                                        ? String(
                                                                                              group.id
                                                                                          )
                                                                                        : '',
                                                                                    String(
                                                                                        component.id
                                                                                    ),
                                                                                    event
                                                                                        .target
                                                                                        .checked,
                                                                                    component.datatype
                                                                                )
                                                                            }
                                                                        />
                                                                        {typeof component.id ===
                                                                            'number' &&
                                                                            shouldShowAutoPopulatedIndicator(
                                                                                {
                                                                                    is_auto_populated:
                                                                                        component.is_auto_populated,
                                                                                    id: component.id as number, // Now we know it's a number
                                                                                    component_response:
                                                                                        component.component_response,
                                                                                },
                                                                                fieldValues
                                                                            ) && (
                                                                                <AutoPopulateIndicator />
                                                                            )}
                                                                    </div>
                                                                ) : component.control_type ===
                                                                  'textarea' ? (
                                                                    <div
                                                                        style={{
                                                                            position:
                                                                                'relative',
                                                                            width: '100%',
                                                                        }}
                                                                    >
                                                                        <Textarea
                                                                            id={
                                                                                component.id !=
                                                                                null
                                                                                    ? String(
                                                                                          component.id
                                                                                      )
                                                                                    : undefined
                                                                            }
                                                                            minRows={
                                                                                2
                                                                            }
                                                                            size="md"
                                                                            style={{
                                                                                padding:
                                                                                    '5px',
                                                                                border: '1px solid #ccc',
                                                                                borderRadius:
                                                                                    '3px',
                                                                                maxWidth:
                                                                                    '300px',
                                                                            }}
                                                                            value={
                                                                                component.id !=
                                                                                null
                                                                                    ? typeof fieldValues[
                                                                                          String(
                                                                                              component.id
                                                                                          )
                                                                                      ]
                                                                                          ?.value !==
                                                                                      'undefined' // Check if value property exists
                                                                                        ? fieldValues[
                                                                                              String(
                                                                                                  component.id
                                                                                              )
                                                                                          ]
                                                                                              .value // Use .value if it exists
                                                                                        : fieldValues[
                                                                                              String(
                                                                                                  component.id
                                                                                              )
                                                                                          ] ||
                                                                                          '' // Otherwise use direct value
                                                                                    : ''
                                                                            }
                                                                            onChange={(
                                                                                event
                                                                            ) => {
                                                                                if (
                                                                                    component.id !=
                                                                                    null
                                                                                ) {
                                                                                    handleFieldChange(
                                                                                        String(
                                                                                            group.id
                                                                                        ),
                                                                                        String(
                                                                                            component.id
                                                                                        ),
                                                                                        event
                                                                                            .target
                                                                                            .value,
                                                                                        component.datatype
                                                                                    )
                                                                                }
                                                                            }}
                                                                        />
                                                                        {typeof component.id ===
                                                                            'number' &&
                                                                            shouldShowAutoPopulatedIndicator(
                                                                                {
                                                                                    is_auto_populated:
                                                                                        component.is_auto_populated,
                                                                                    id: component.id as number, // Now we know it's a number
                                                                                    component_response:
                                                                                        component.component_response,
                                                                                },
                                                                                fieldValues
                                                                            ) && (
                                                                                <AutoPopulateIndicator />
                                                                            )}
                                                                    </div>
                                                                ) : component.control_type ===
                                                                  'select' ? (
                                                                    <div
                                                                        style={{
                                                                            position:
                                                                                'relative',
                                                                            width: '100%',
                                                                        }}
                                                                    >
                                                                        <select
                                                                            style={{
                                                                                width: '100%',
                                                                                padding:
                                                                                    '5px',
                                                                                border: '1px solid #ccc',
                                                                                borderRadius:
                                                                                    '3px',
                                                                                margin: '10px 0',
                                                                            }}
                                                                            onChange={(
                                                                                event
                                                                            ) => {
                                                                                const target =
                                                                                    event.target as HTMLSelectElement // Type assertion
                                                                                if (
                                                                                    group.id !=
                                                                                    null
                                                                                ) {
                                                                                    // Check if group.id is not null or undefined
                                                                                    handleFieldChange(
                                                                                        String(
                                                                                            group.id
                                                                                        ), // Convert to string
                                                                                        String(
                                                                                            component.id
                                                                                        ),
                                                                                        target.value, // Use the asserted type here
                                                                                        component.datatype
                                                                                    )
                                                                                }
                                                                            }}
                                                                        >
                                                                            {component.values?.map(
                                                                                (
                                                                                    option
                                                                                ) => (
                                                                                    <option
                                                                                        key={
                                                                                            option.id
                                                                                        }
                                                                                        value={
                                                                                            option.component_response ||
                                                                                            ''
                                                                                        }
                                                                                    >
                                                                                        {
                                                                                            option.value
                                                                                        }
                                                                                    </option>
                                                                                )
                                                                            )}
                                                                        </select>
                                                                        {typeof component.id ===
                                                                            'number' &&
                                                                            shouldShowAutoPopulatedIndicator(
                                                                                {
                                                                                    is_auto_populated:
                                                                                        component.is_auto_populated,
                                                                                    id: component.id as number, // Now we know it's a number
                                                                                    component_response:
                                                                                        component.component_response,
                                                                                },
                                                                                fieldValues
                                                                            ) && (
                                                                                <AutoPopulateIndicator />
                                                                            )}
                                                                    </div>
                                                                ) : component.control_type ===
                                                                  'checkboxgroup' ? (
                                                                    <div
                                                                        style={{
                                                                            marginTop:
                                                                                '30px',
                                                                            marginBottom:
                                                                                '30px',
                                                                        }}
                                                                    >
                                                                        <div
                                                                            style={{
                                                                                marginBottom:
                                                                                    '5px',
                                                                            }}
                                                                        >
                                                                            <label
                                                                                style={{
                                                                                    margin: '0',
                                                                                }}
                                                                            >
                                                                                {component.label_display ||
                                                                                    component.label}
                                                                            </label>
                                                                        </div>
                                                                      
                                                                        <Grid
                                                                            container
                                                                            alignItems="center"
                                                                        >
                                                                            {component.values?.map(
                                                                                (option: {
                                                                                    id:
                                                                                        | React.Key
                                                                                        | null
                                                                                        | undefined
                                                                                    value:
                                                                                        | string
                                                                                        | number
                                                                                        | boolean
                                                                                        | React.ReactElement<
                                                                                              any,
                                                                                              | string
                                                                                              | React.JSXElementConstructor<any>
                                                                                          >
                                                                                        | null
                                                                                        | undefined
                                                                                }) => (
                                                                                    <div
                                                                                        key={
                                                                                            option.id
                                                                                        }
                                                                                        style={{
                                                                                            width: '100%',
                                                                                        }}
                                                                                    >
                                                                                        <Grid
                                                                                            container
                                                                                            alignItems="center"
                                                                                        >
                                                                                            <input
                                                                                                type="checkbox"
                                                                                                id={
                                                                                                    option.id !=
                                                                                                    null
                                                                                                        ? String(
                                                                                                              option.id
                                                                                                          )
                                                                                                        : undefined
                                                                                                }
                                                                                                checked={
                                                                                                    option.id !=
                                                                                                    null
                                                                                                        ? fieldValues[
                                                                                                              String(
                                                                                                                  option.id
                                                                                                              )
                                                                                                          ] ===
                                                                                                              true ||
                                                                                                          fieldValues[
                                                                                                              String(
                                                                                                                  option.id
                                                                                                              )
                                                                                                          ]
                                                                                                              ?.value ===
                                                                                                              true
                                                                                                        : false
                                                                                                }
                                                                                                style={{
                                                                                                    color: '#4f2682',
                                                                                                    marginLeft:
                                                                                                        '5px',
                                                                                                }}
                                                                                                onChange={(
                                                                                                    event
                                                                                                ) =>
                                                                                                    option.id !=
                                                                                                        null &&
                                                                                                    group.id !=
                                                                                                        null &&
                                                                                                    handleFieldChange(
                                                                                                        String(
                                                                                                            group.id
                                                                                                        ),
                                                                                                        String(
                                                                                                            option.id
                                                                                                        ),
                                                                                                        event
                                                                                                            .target
                                                                                                            .checked,
                                                                                                        component.datatype
                                                                                                    )
                                                                                                }
                                                                                            />
                                                                                            <label
                                                                                                htmlFor={
                                                                                                    typeof option.value ===
                                                                                                    'string'
                                                                                                        ? option.value
                                                                                                        : undefined
                                                                                                }
                                                                                                style={{
                                                                                                    color: '#4f2682',
                                                                                                    marginLeft:
                                                                                                        '5px',
                                                                                                    marginBottom:
                                                                                                        '0',
                                                                                                }}
                                                                                            >
                                                                                                {
                                                                                                    option.value
                                                                                                }
                                                                                            </label>
                                                                                        </Grid>
                                                                                    </div>
                                                                                )
                                                                            )}
                                                                        </Grid>
                                                                    </div>
                                                                ) : component.control_type ===
                                                                  'heading' ? (
                                                                    <h3
                                                                        style={{
                                                                            color: '#4f2682',
                                                                        }}
                                                                    >
                                                                        {component.label_display ||
                                                                            component.label}
                                                                    </h3>
                                                                ) : component.control_type ===
                                                                  'subheading' ? (
                                                                    component.photo_capture ===
                                                                    1 ? (
                                                                        <div
                                                                            style={{
                                                                                display:
                                                                                    'flex',
                                                                                justifyContent:
                                                                                    'space-between',
                                                                                alignItems:
                                                                                    'center',
                                                                            }}
                                                                        >
                                                                            <h4>
                                                                                {component.label_display ||
                                                                                    component.label}
                                                                            </h4>
                                                                            <Stack
                                                                                direction="row"
                                                                                spacing={
                                                                                    2
                                                                                }
                                                                            >
                                                                                <img
                                                                                    src={
                                                                                        typeof component
                                                                                            .component_response
                                                                                            ?.photo ===
                                                                                            'string' &&
                                                                                        component
                                                                                            .component_response
                                                                                            .photo
                                                                                            .length >
                                                                                            0
                                                                                            ? component
                                                                                                  .component_response
                                                                                                  .photo
                                                                                            : IMS_FORM_IMG_PLACEHOLDER
                                                                                    }
                                                                                    alt="Thumbnail"
                                                                                    style={{
                                                                                        width: 56,
                                                                                        height: 56,
                                                                                        cursor: 'pointer',
                                                                                    }}
                                                                                    onClick={() => {
                                                                                        setLightboxImage(
                                                                                            component
                                                                                                .component_response
                                                                                                ?.photo
                                                                                        )
                                                                                        setLightboxOpen(
                                                                                            true
                                                                                        )
                                                                                    }}
                                                                                />
                                                                                {lightboxOpen &&
                                                                                    lightboxImage && (
                                                                                        <Dialog
                                                                                            open={
                                                                                                lightboxOpen
                                                                                            }
                                                                                            onClose={() =>
                                                                                                setLightboxOpen(
                                                                                                    false
                                                                                                )
                                                                                            }
                                                                                            maxWidth="lg"
                                                                                            fullWidth
                                                                                            style={{
                                                                                                margin: 0,
                                                                                            }}
                                                                                        >
                                                                                            <DialogTitle>
                                                                                                <IconButton
                                                                                                    edge="end"
                                                                                                    color="inherit"
                                                                                                    onClick={() =>
                                                                                                        setLightboxOpen(
                                                                                                            false
                                                                                                        )
                                                                                                    }
                                                                                                    aria-label="close"
                                                                                                >
                                                                                                    <CloseIcon />
                                                                                                </IconButton>
                                                                                            </DialogTitle>
                                                                                            <DialogContent
                                                                                                style={{
                                                                                                    padding: 0,
                                                                                                }}
                                                                                            >
                                                                                                <img
                                                                                                    src={
                                                                                                        lightboxImage
                                                                                                    }
                                                                                                    alt="Full size"
                                                                                                    style={{
                                                                                                        width: '100%',
                                                                                                        height: 'auto',
                                                                                                    }}
                                                                                                />
                                                                                            </DialogContent>
                                                                                        </Dialog>
                                                                                    )}
                                                                            </Stack>
                                                                        </div>
                                                                    ) : (
                                                                        <h4>
                                                                            {component.label_display ||
                                                                                component.label}
                                                                        </h4>
                                                                    )
                                                                ) : null}

                                                                {component.component_form &&
                                                                    component
                                                                        .component_form
                                                                        .length >
                                                                        0 && (
                                                                        <IconButton
                                                                            aria-label="Help"
                                                                            onClick={() => {
                                                                                window.open(
                                                                                    component
                                                                                        .component_form[0]
                                                                                        .help_link,
                                                                                    '_blank'
                                                                                )
                                                                            }}
                                                                        >
                                                                            <OpenInNewIcon
                                                                                style={{
                                                                                    fontSize:
                                                                                        '15px',
                                                                                    color: '#4f2682',
                                                                                }}
                                                                            />
                                                                        </IconButton>
                                                                    )}

                                                                {/* Render helper text */}
                                                                {component.helper_text && (
                                                                    <div
                                                                        style={{
                                                                            width: '70%',
                                                                        }}
                                                                    >
                                                                        <Typography
                                                                            variant="caption"
                                                                            style={{
                                                                                fontSize:
                                                                                    'small',
                                                                                color: '#666',
                                                                            }}
                                                                        >
                                                                            {
                                                                                component.helper_text
                                                                            }
                                                                        </Typography>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        )
                                                    )}
                                                </form>
                                            </AccordionDetails>
                                        </Accordion>
                                    )
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        )
    } else {
        return null
    }
}

export default ImsFormFields
