import React, { useState } from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { withStyles, WithStyles } from '@material-ui/core/styles'
import ModalDialog from '../fhg/components/dialog/ModalDialog'
import ErrorSnackbar from '../components/eval/components/ErrorSnackbar'
import FormattedTextField from '../components/eval/components/FormattedTextField'
import Typography from '../fhg/components/Typography'
import { requestForServer } from '../Utils/ServerUtil'
import { CREATE_USER, ADD_USER_TO_GROUP } from '../Constants'
import eventCapture from '../eventList'
import { posthogCapture } from '../PostHogFunction'
import { injectIntl } from 'react-intl'

const styles = (theme: { spacing: (arg0: number) => any }) => ({
    fullWidthInput: {
        marginTop: `${theme.spacing(0.5)}px !important`,
        height: 30,
        width: '100% !important',
    },
    mediumTopPad: {
        marginTop: theme.spacing(1.5),
    },
})

interface NewUserDialogProps
    extends RouteComponentProps,
        WithStyles<typeof styles> {
    open: boolean
    onClose: () => void
    onSubmit?: (user: any) => void
    group: { organization_id: string }
}

const NewUserDialog: React.FC<NewUserDialogProps> = ({
    classes,
    open,
    onClose,
    onSubmit,
    group,
}) => {
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [isSaving, setIsSaving] = useState(false)
    const [isChanged, setIsChanged] = useState(false)
    const [showError, setShowError] = useState(false)
    const [message, setMessage] = useState('')

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target
        switch (name) {
            case 'name':
                setName(value)
                break
            case 'email':
                setEmail(value)
                break
            case 'password':
                setPassword(value)
                break
        }
        setIsChanged(true)
    }

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault()
        let error: any
        let errorValues: any

        if (isChanged && !isSaving) {
            setIsSaving(true)
            try {
                const user: {
                    name?: string
                    email?: string
                    password?: string
                } = {
                    name: name || undefined,
                    email: email || undefined,
                    password: password || undefined,
                }

                posthogCapture(eventCapture.SAVE_NEW_USER)

                const resultUser = await requestForServer(
                    CREATE_USER,
                    'post',
                    user
                )
                if (!resultUser.error) {
                    posthogCapture(eventCapture.SAVE_NEW_USER)
                    const updatedUser = resultUser.data

                    const resultGroup = await requestForServer(
                        (ADD_USER_TO_GROUP as any).format({
                            orgId: group.organization_id,
                            userId: updatedUser.user_id,
                        }),
                        'put',
                        {}
                    )

                    if (resultGroup.error) {
                        error = resultGroup.error
                    } else {
                        if (onSubmit) {
                            onSubmit(updatedUser)
                        }
                        onClose()
                    }
                } else {
                    error = resultUser.error
                    errorValues = resultUser.errorValues
                }
            } catch (err) {
                error = err
            }

            if (error) {
                setShowError(true)
                setMessage(errorValues?.message || error.message)
                setIsSaving(false)
            } else {
                setIsSaving(false)
                setIsChanged(false)
            }
        }
    }

    const handleErrorClose = () => {
        setShowError(false)
    }

    return (
        <ModalDialog
            open={open}
            onClose={onClose}
            onSubmit={handleSubmit}
            titleKey="newUser.main.title"
            isSaving={isSaving}
            submitKey="newUser.submit.label"
            isForm
        >
            {showError && (
                <ErrorSnackbar
                    open={showError}
                    onClose={handleErrorClose}
                    errorId="newUser.save.error"
                    values={{ message }}
                />
            )}
            <Typography
                id="newUser.name.label"
                className={`${classes.mediumTopPad} label-text`}
            />
            <FormattedTextField
                {...({
                    name: 'name',
                    required: true,
                    autoFocus: true,
                    fullWidth: true,
                    value: name,
                    onChange: handleChange,
                    margin: 'none',
                    placeholder: 'Doug Jones',
                    inputProps: { className: classes.fullWidthInput },
                    disabled: isSaving,
                } as any)}
            />
            <Typography
                id="newUser.email.label"
                className={`${classes.mediumTopPad} label-text`}
            />
            <FormattedTextField
                {...({
                    name: 'email',
                    type: 'email',
                    required: true,
                    fullWidth: true,
                    value: email,
                    onChange: handleChange,
                    margin: 'none',
                    placeholder: 'doug@acme.com',
                    inputProps: { className: classes.fullWidthInput },
                    disabled: isSaving,
                } as any)}
            />
            <Typography
                id="newUser.password.label"
                className={`${classes.mediumTopPad} label-text`}
            />
            <FormattedTextField
                {...({
                    name: 'password',
                    fullWidth: true,
                    required: true,
                    disabled: isSaving,
                    autoComplete: 'current-password',
                    onChange: handleChange,
                    value: password,
                    inputProps: { className: classes.fullWidthInput },
                } as any)}
            />
        </ModalDialog>
    )
}

// export default withRouter(withStyles(styles)(NewUserDialog));
export default withStyles(styles)(withRouter(NewUserDialog))
