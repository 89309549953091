export const fontStyle = (
    theme,
    defaultSize,
    fontSize,
    letterSpacing,
    lineHeight
) => ({
    fontSize: `${fontSize || theme.size.font[defaultSize]}rem !important`,
    letterSpacing: `${
        letterSpacing !== undefined
            ? letterSpacing
            : theme.size.letterspacing[defaultSize]
    }px !important`,
    lineHeight: `${lineHeight || theme.size.lineheight[defaultSize]}rem !important`,
})
