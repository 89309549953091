import { Container, makeStyles } from '@material-ui/core'
import TextField from '@mui/material/TextField'
import classNames from 'classnames'
import React from 'react'
import Typography from '../../fhg/components/Typography'

const useStyles: any = makeStyles((theme) => ({
    container: {
        padding: 0,
        marginTop: '8px',
    },
    mediumTopPad: {
        marginTop: theme.spacing(1.5),
    },
    fullWidth: {
        width: '100%',
    },
    formControl: {
        transform: 'none',
        position: 'inherit',
    },
    textFieldRoot: {
        padding: 0,
        'label + &': {
            marginTop: `0 !important`,
        },
    },
    textFieldInput: {
        backgroundColor: theme.palette.common.white,
        borderRadius: 4,
        padding: '6px !important',
        marginTop: `${theme.spacing(1)}px !important`,
        width: `calc(100% - ${theme.spacing(3)}px)`,
        height: 36,
        color: `${theme.palette.text.primary} !important`, //#423313
        '&:disabled': {
            color: `${theme.palette.text.disabled} !important`,
            border: `1px solid ${theme.palette.text.disabled} !important`,
        },
        '@media print': {
            border: `none !important`,
        },
    },
    textFieldInputError: {
        border: `1px solid ${theme.palette.error.main} !important`,
        color: `${theme.palette.error.main} !important`,
        '&:focus': {
            border: `1px solid ${theme.palette.error.main} !important`,
        },
    },
}))

interface Props {
    name?: string
    label?: string
    error?: boolean
    disabled?: boolean
    required?: boolean
    value?: string
    onChange: (event: any) => void
    placeholder?: string
    type?: string
}
const FTInput: React.FC<Props> = ({
    name,
    label,
    error,
    disabled,
    required,
    value,
    placeholder,
    type,
    onChange,
}) => {
    const classes = useStyles()
    return (
        <Container className={classes.container}>
            {label && (
                <Typography
                    id={label}
                    className={classNames(
                        classes.mediumTopPad,
                        classes.fullWidth,
                        'label-text'
                    )}
                />
            )}
            <TextField
                name={name}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                error={error}
                disabled={disabled}
                required={required}
                type={type}
                InputProps={{
                    disableUnderline: true,
                    classes: {
                        root: classes.textFieldRoot,
                        input: classNames(
                            { error: error },
                            { [classes.textFieldInputError]: error },
                            classes.textFieldInput
                        ),
                    },
                }}
                InputLabelProps={{
                    shrink: true,
                    classes: {
                        formControl: classes.formControl,
                    },
                }}
                className={classNames(classes.fullWidth)}
            />
        </Container>
    )
}

export default FTInput
