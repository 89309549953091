import compact from 'lodash/compact'
import sumBy from 'lodash/sumBy'

import moment from 'moment'
import {
    getConsensusEstimate,
    getEstimatesByType,
} from '../../components/eval/EquipmentUtil'
import { DATE_ONLY_FORMAT } from '../../Constants'
import get from 'lodash/get'
import { toString, toNumber, isNil } from 'lodash'
import {
    isFeatureEnabled,
    SUB_PACKAGES,
    SEE_ESTIMATES,
    getUserData,
} from '../../Utils/ServerUtil'
import numberFormatter from 'number-formatter'

/**
 * Handle the export of the list to CSV.
 *
 * Note: only one asset list can be handled at a time. The UI must ensure that if two asset lists are handled, that
 * setExportList is called with the active asset list.
 */
export default class ExportCsv {
    static exportList = []

    static setExportList(array) {
        console.log(' ')
        console.log('export  0', get(array, '[0].item.vin'))
        console.log('export  1', get(array, '[1].item.vin'))
        console.log('...')
        console.log('export  end', get(array, `[${array.length - 1}].item.vin`))
        ExportCsv.exportList = array
    }

    /**
     * Get the estimates in CSV.
     * @param estimates
     * @return {*}
     */
    static getEstimatesCsv(evalItem, estimateType) {
        let csvArray = []
        const filteredEstimates = getEstimatesByType(evalItem, estimateType)

        for (const estimate of filteredEstimates) {
            csvArray.push(
                `${estimate.user_name || estimate.user_email || estimate.user} ${numberFormatter('$#,###,##0.', estimate.value)}`
            )
        }
        return compact(csvArray).join(' | ')
    }

    /**
     * Create the CSV for a single cell.
     *
     * @param text The cell text.
     * @param isLast Indicates if the cell is the last in a row.
     * @return {string} The CSV string for the cell.
     */
    static createCsvCell(text, isLast = false) {
        let theText
        if (text) {
            theText = text.replace(/(\r\n|\n|\r|\s+|\t|&nbsp;)/gm, ' ')
            theText = theText.replace(/"/g, '""')
            theText = theText.replace(/ +(?= )/g, '')
        } else {
            theText = ''
        }
        if (isLast) {
            return `"${theText}"`
        } else {
            return `"${theText}",`
        }
    }

    /**
     * Create the body of the CSV file from the array.
     *
     * @param array The array to convert to CSV.
     * @param showEstimates Indicates if the individual estimates should be included.
     * @return {string} The body in CSV format.
     */
    static createCsvBody = (array, showEstimates) => {
        let body = ''
        const estimateTypes = getUserData().estimateTypes

        for (let i = 0, length = array.length; i < length; i++) {
            let evaluation = array[i]
            let item = evaluation.item
            if (item) {
                body += ExportCsv.createCsvCell(item.item_id)
                body += ExportCsv.createCsvCell(
                    moment(item.added || item.updated).format(DATE_ONLY_FORMAT)
                )
                body += ExportCsv.createCsvCell(item.valuation_type)
                if (isFeatureEnabled(SUB_PACKAGES)) {
                    body += ExportCsv.createCsvCell(item.subpackage)
                }
                body += ExportCsv.createCsvCell(item.year)
                body += ExportCsv.createCsvCell(item.make)
                body += ExportCsv.createCsvCell(item.model)
                body += ExportCsv.createCsvCell(item.description)

                body += ExportCsv.createCsvCell(item.serial)
                body += ExportCsv.createCsvCell(item.vin)
                body += ExportCsv.createCsvCell(item.unique_id)
                body += ExportCsv.createCsvCell(item.personal_id)
                body += ExportCsv.createCsvCell(item.sticker_id)

                const meters =
                    (typeof item.meters === 'string'
                        ? JSON.parse(item.meters)
                        : item.meters) || []
                let validMeters = meters.map((meter) => {
                    if (meter.value) {
                        return (meter.name || meter.units) + '-' + meter.value
                    }
                    return undefined
                })
                validMeters = compact(validMeters)
                body += ExportCsv.createCsvCell(validMeters.join(' / '))
                body += ExportCsv.createCsvCell(item.notes)
                body += `"${window.location.protocol}//${window.location.host}${evaluation.uri}",`
                body += ExportCsv.createCsvCell(evaluation.notes)

                if (estimateTypes && estimateTypes.length > 0) {
                    for (const estimateType of estimateTypes) {
                        if (estimateType.active) {
                            if (
                                showEstimates &&
                                isFeatureEnabled(SEE_ESTIMATES) &&
                                estimateType.enable_workflow_columns
                            ) {
                                body += ExportCsv.createCsvCell(
                                    ExportCsv.getEstimatesCsv(
                                        evaluation,
                                        estimateType
                                    )
                                )
                            }
                            body += ExportCsv.createCsvCell(
                                toString(
                                    getConsensusEstimate(
                                        evaluation,
                                        estimateType
                                    ).value
                                )
                            )
                        }
                    }
                }
                body += '\r\n'
            }
        }

        body += `\r\n`
        let separators = ',,,,,,,,,,,,,,,,'
        if (isFeatureEnabled(SUB_PACKAGES)) {
            separators += ','
        }

        let totals = ''
        if (estimateTypes && estimateTypes.length > 0) {
            for (const estimateType of estimateTypes) {
                if (estimateType.active) {
                    if (
                        showEstimates &&
                        isFeatureEnabled(SEE_ESTIMATES) &&
                        estimateType.enable_workflow_columns
                    ) {
                        totals += ','
                    }
                    const sum = sumBy(array, (evaluation) => {
                        const valueEstimate = getConsensusEstimate(
                            evaluation,
                            estimateType
                        )
                        return isNil(valueEstimate.value)
                            ? 0
                            : toNumber(valueEstimate.value)
                    })
                    totals += ExportCsv.createCsvCell(sum.toFixed(0))
                }
            }
        }

        body += `"Total Values"${separators}${totals}\r\n`

        return body
    }

    static b64EncodeUnicode(str) {
        // first we use encodeURIComponent to get percent-encoded UTF-8,
        // then we convert the percent encodings into raw bytes which
        // can be fed into btoa.
        return btoa(
            encodeURIComponent(str).replace(
                /%([0-9A-F]{2})/g,
                function toSolidBytes(match, p1) {
                    return String.fromCharCode('0x' + p1)
                }
            )
        )
    }

    /**
     * Download the table as a CSV file.
     *
     * @param title Title of the file.
     */
    static exportCsv = (title, list, showEstimates = false) => {
        let properties = [
            'Item ID',
            'Capture Date',
            'Valuation Type',
            'Subpackage',
            'Year',
            'Make',
            'Model',
            'Item Type',
            'Serial',
            'VIN',
            'Unique ID #',
            'Inventory ID',
            'Sticker ID',
            'Meter Readings',
            'Notes',
            'Item URL',
            'Estimate Notes',
        ]
        const estimateTypes = getUserData().estimateTypes
        if (estimateTypes && estimateTypes.length > 0) {
            for (const estimateType of estimateTypes) {
                if (estimateType.active) {
                    if (
                        showEstimates &&
                        isFeatureEnabled(SEE_ESTIMATES) &&
                        estimateType.enable_workflow_columns
                    ) {
                        properties.push(
                            `${estimateType.display_name} Estimates`
                        )
                    }
                    properties.push(estimateType.display_name)
                }
            }
        }
        if (!isFeatureEnabled(SUB_PACKAGES)) {
            properties = properties.filter((value, index, arr) => {
                return value !== 'Subpackage'
            })
        }
        const headerRow = properties.join(',') + '\r\n'
        const bodyRows = ExportCsv.createCsvBody(list, showEstimates)

        const fileName = `${title}.csv`
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            var blob = new Blob([headerRow + bodyRows])
            window.navigator.msSaveOrOpenBlob(blob, fileName)
        } else {
            let link = document.createElement('a')
            link.href =
                'data:text/csv;charset=utf-8;base64,' +
                ExportCsv.b64EncodeUnicode(headerRow + bodyRows)
            link.target = '_blank'
            link.download = fileName

            document.body.appendChild(link)
            link.click()

            link.parentNode.removeChild(link)
        }
    }
}
