import React from 'react'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '../fhg/components/Typography'

interface Props {
    label: string
    location: string
}

const useStyles = makeStyles((theme) => ({
    link: {
        textDecoration: 'none',
        color: 'white',
    },
    titleStyle: {
        fontSize: '14px !important',
        fontWeight: 500,
        lineHeight: '17px',
        textAlign: 'center',
        color: theme.palette.secondary.contrastText,
        textTransform: 'none',
        whiteSpace: 'nowrap',
        [theme.breakpoints.down('xs')]: {
            whiteSpace: 'wrap',
        },
    },
}))

const FTLink: React.FC<Props> = ({ label, location }) => {
    const classes = useStyles()

    return (
        <Link to={location} className={classes.link}>
            <Typography
                className={classes.titleStyle}
                color="inherit"
                variant="h5"
                id={label}
            />
        </Link>
    )
}

export { FTLink }
