import * as React from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Typography from '@mui/material/Typography'
import { CardActionArea } from '@mui/material'
import EmailIcon from '@material-ui/icons/Email'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        maxWidth: 1000, // Set a maximum width for the container
        margin: '0 auto', // Center the container horizontally
    },
    header: {
        textAlign: 'center',
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
    cardContent: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    contactOption: {
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(0.8),
    },
    card: {
        maxWidth: 400,
        borderRadius: '5px !important', // Increase border radius
        boxShadow: '0 0 10px 4px rgba(0, 0, 0, 0.1) !important', // Apply box shadow with blur effect
    },
    icon: {
        fontSize: '2rem',
        marginRight: '1rem',
        marginLeft: '1rem',
        color: '#4f2682',
    },
    emailLink: {
        textDecoration: 'none',
        color: '#4f2682',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
}))

export default function Card_2() {
    const classes = useStyles()
    return (
        <Card className={classes.card}>
            <CardActionArea>
                <CardContent className={classes.cardContent}>
                    <div className={classes.contactOption}>
                        <EmailIcon className={classes.icon} />
                        <a
                            href="mailto:Techsupport@purplewave.com"
                            className={classes.emailLink}
                        >
                            <Typography
                                gutterBottom
                                variant="h6"
                                component="div"
                            >
                                techsupport@purplewave.com
                            </Typography>
                        </a>
                    </div>
                </CardContent>
            </CardActionArea>
        </Card>
    )
}
