import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import React, { Component } from 'react'

/**
 * The Update is available notification dialog.
 */
class UpdateAvailableDialog extends Component {
    render() {
        const { version } = this.props

        return (
            <Dialog
                open={true}
                keepMounted
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {'Update Available'}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        A software update for HeavyWorth is available, {version}
                        . Please close all HeavyWorth tabs and reopen this page
                        to update.
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        )
    }
}

export default UpdateAvailableDialog
