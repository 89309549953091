export function posthogCapture(EVENT_NAME, properties = {}) {
    window.posthog.capture(EVENT_NAME, properties)
}

export function trackIMSFormCreation(formId, itemId, formCategory, timestamp) {
    posthogCapture('Form Created', {
        'Form ID': String(formId),
        'Item ID': String(itemId),
        'Form Category': String(formCategory),
        'Date/Time': String(timestamp),
    })
}

export function trackIMSFormChanged(formId, itemId, timestamp) {
    posthogCapture('Form Changed', {
        'Form ID': String(formId),
        'Item ID': String(itemId),
        'Date/Time': String(timestamp),
    })
}

export function trackIMSFormEdited(formId, itemId, timestamp) {
    posthogCapture('Form Edited', {
        'Form ID': String(formId),
        'Item ID': String(itemId),
        'Date/Time': String(timestamp),
    })
}

// elsewhere in your code:
//   posthogCapture();
