import withStyles from '@material-ui/core/styles/withStyles'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import PropTypes from 'prop-types'
import React from 'react'
import { GET_ORGANIZATIONS, DEFAULT_LOCATION } from '../Constants'
import { withPrivilege } from '../fhg/security/WithPrivilege'
import { withRequest } from '../Utils/ServerUtil'
import GroupsPanel, { DRAWER_WIDTH, PROGRESS_WIDTH } from './GroupsPanel'
import UsersPanel from './UsersPanel'

const styles = (theme) => ({
    root: {
        width: '100%',
        height: '100vh',
        margin: 0,
        '@media print': {
            height: '100%',
        },
    },
    titleStyle: {
        fontSize: '18px !important',
        color: theme.palette.secondary.contrastText,
        textTransform: 'none',
        whiteSpace: 'nowrap',
        [theme.breakpoints.down('xs')]: {
            fontSize: '16px !important',
            whiteSpace: 'wrap',
        },
    },
    mainGrid: {
        height: '100%',
        width: '100%',
        margin: '0 auto',
        display: 'inline-block',
        backgroundColor: theme.palette.environment.light.level1.base,
        '@media print': {
            display: 'block',
        },
    },
    version: {
        color: theme.palette.secondary.contrastText,
        fontSize: `${theme.size.font['item-title']}rem`,
        textAlign: 'right',
        paddingRight: theme.spacing(2),
        [theme.breakpoints.down('xs')]: {
            paddingRight: theme.spacing(0.5),
        },
    },
})

const dataStyles = {
    progress: {
        position: 'absolute',
        top: 225,
        marginLeft: DRAWER_WIDTH / 2 - PROGRESS_WIDTH / 2,
        zIndex: 1001,
    },
}

/**
 * The main component for the users and groups components.
 */
class UsersGroupsMain extends React.Component {
    static propTypes = {
        classes: PropTypes.object.isRequired,
        history: PropTypes.object,
    }

    state = {
        selectedGroup: {},
    }

    /**
     * Handle selecting a group.
     *
     * @param selectedGroup The newly selected group.
     */
    handleSelectGroup = (selectedGroup = { organization_id: 0 }) => {
        this.setState({ selectedGroup })
    }

    /**
     * Handle adding a new group.
     *
     * @param newGroup The new group to add.
     */
    handleAddGroup = (newGroup) => {
        this.props.onRefresh()
    }

    /**
     * Handle deleting the selected group.
     */
    handleDeleteGroup = () => {
        this.setState({ selectedGroup: undefined })
        this.props.onRefresh()
    }

    render() {
        const { classes, data } = this.props
        const { selectedGroup } = this.state

        return (
            <div className={classes.root}>
                <div className={classes.mainGrid}>
                    <Paper
                        square
                        style={{ margin: 32, height: 'calc(100% - 174px)' }}
                    >
                        <Grid
                            container
                            direction={'row'}
                            style={{ height: '100%', overflow: 'hidden' }}
                            wrap={'nowrap'}
                        >
                            <GroupsPanel
                                onSelect={this.handleSelectGroup}
                                selectFirst={!selectedGroup}
                                groups={data}
                                onAddGroup={this.handleAddGroup}
                            />
                            <UsersPanel
                                group={selectedGroup}
                                groups={data}
                                onDeleteGroup={this.handleDeleteGroup}
                            />
                        </Grid>
                    </Paper>
                </div>
            </div>
        )
    }
}

export default withPrivilege({
    requiresAdmin: true,
    redirectUrl: DEFAULT_LOCATION,
})(
    withStyles(dataStyles)(
        withRequest({
            uri: GET_ORGANIZATIONS,
            showProgress: true,
            showOnLoad: true,
            errorMessageId: 'organizations.fetch.error',
        })(withStyles(styles)(UsersGroupsMain))
    )
)
