import { isEqual } from 'lodash'
import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import {
    MAX_MARGIN_ON_PAN,
    ADJUST_IMAGE_MARGINS_DELAY,
    THRESHOLD_MOVE_CLICK,
} from '../../../Constants'
import './ImageComponent.css'
import get from 'lodash/get'

/**
 * Component to show the Image. Handles wheel zooming, and panning.
 */
class ImageComponent extends PureComponent {
    isWheelZooming = 0
    currentX = undefined
    currentY = undefined
    parentBounds = undefined
    timer = undefined
    isMouseMoved = false

    topScale
    maxMarginPercentX = MAX_MARGIN_ON_PAN
    maxMarginPercentY = MAX_MARGIN_ON_PAN

    static propTypes = {
        classes: PropTypes.object,
        src: PropTypes.any,
        scale: PropTypes.number,
        rotation: PropTypes.number,
        top: PropTypes.number,
        isZoomed: PropTypes.bool,
    }

    static defaultProps = {
        scale: 1,
        rotation: 0,
        isZoomed: false,
    }

    constructor(props, context) {
        super(props, context)

        this.imageElementRef = React.createRef()
        this.hiddenImageRef = React.createRef()

        this.onMouseDown = this.onMouseDown.bind(this)
        this.onMouseMove = this.onMouseMove.bind(this)
        this.onMouseUp = this.onMouseUp.bind(this)

        document.addEventListener('keydown', this.handleKeyDown, false)
        document.addEventListener('keyup', this.handleKeyUp, false)

        this.state = {
            accumulatedDeltaX: 0,
            accumulatedDeltaY: 0,
            cursor: 'zoom-in',
        }
    }

    // componentWillReceiveProps(nextProps) {
    //    const { src, scale, isZoomed, isScaledLocation } = nextProps;
    //    if (isZoomed && isZoomed !== this.props.isZoomed) {
    //       this.element = document.getElementById('full-image-id');
    //       this.setState({ cursor: 'zoom-in', accumulatedDeltaX: 0, accumulatedDeltaY: 0, isDragging: false });
    //    }
    //    if (src !== this.props.src) {
    //       this.setState({ cursor: 'zoom-in', accumulatedDeltaX: 0, accumulatedDeltaY: 0, isDragging: false });
    //    } else if (!isZoomed && isZoomed !== this.props.isZoomed) {
    //       document.removeEventListener('mouseup', this.onMouseUp, false);
    //       document.removeEventListener('mousemove', this.onMouseMove, false);
    //       this.isMouseMoved = false;
    //       this.resetZoomOutMarginAdjustments();
    //       this.setState({ cursor: 'arrow', accumulatedDeltaX: 0, accumulatedDeltaY: 0, isDragging: false });
    //    }
    //    else if (scale !== this.props.scale || isScaledLocation !== this.props.isScaledLocation) {
    //       this.calculateMoveDelta(this.element, scale, false, isScaledLocation);
    //    } else if (isZoomed && (this.state.accumulatedDeltaX || this.state.accumulatedDeltaY)) {
    //       let getParentBounds = get(this, 'element.parentElement.getBoundingClientRect');
    //       if (typeof getParentBounds !== 'function') {
    //          this.element = document.getElementById('full-image-id');
    //          getParentBounds = get(this, 'element.parentElement.getBoundingClientRect');
    //       }
    //       if (typeof getParentBounds === 'function') {
    //          const parentBounds = this.element.parentElement.getBoundingClientRect();
    //          if (!isEqual(parentBounds, this.parentBounds)) {
    //             this.parentBounds = parentBounds;
    //             if (this.timer) {
    //                clearTimeout(this.timer);
    //             }
    //             this.timer = setTimeout(() => {
    //                if (this.element !== undefined) {
    //                   this.calculateMoveDelta(this.element, scale, false, false);
    //                }
    //             }, ADJUST_IMAGE_MARGINS_DELAY);
    //          }
    //       }
    //    }
    // }

    componentWillReceiveProps(nextProps) {
        const { src, scale, isZoomed, isScaledLocation } = nextProps

        if (isZoomed && isZoomed !== this.props.isZoomed) {
            this.element = this.imageElementRef.current // Use ref instead of getElementById
            this.setState({
                cursor: 'zoom-in',
                accumulatedDeltaX: 0,
                accumulatedDeltaY: 0,
                isDragging: false,
            })
        }

        if (src !== this.props.src) {
            this.setState({
                cursor: 'zoom-in',
                accumulatedDeltaX: 0,
                accumulatedDeltaY: 0,
                isDragging: false,
            })
        } else if (!isZoomed && isZoomed !== this.props.isZoomed) {
            document.removeEventListener('mouseup', this.onMouseUp, false)
            document.removeEventListener('mousemove', this.onMouseMove, false)
            this.isMouseMoved = false
            this.resetZoomOutMarginAdjustments()
            this.setState({
                cursor: 'arrow',
                accumulatedDeltaX: 0,
                accumulatedDeltaY: 0,
                isDragging: false,
            })
        } else if (
            scale !== this.props.scale ||
            isScaledLocation !== this.props.isScaledLocation
        ) {
            this.calculateMoveDelta(
                this.imageElementRef.current,
                scale,
                false,
                isScaledLocation
            ) // Use ref
        } else if (
            isZoomed &&
            (this.state.accumulatedDeltaX || this.state.accumulatedDeltaY)
        ) {
            let getParentBounds = get(
                this,
                'imageElementRef.current.parentElement.getBoundingClientRect'
            )
            if (typeof getParentBounds !== 'function') {
                getParentBounds = get(
                    this,
                    'imageElementRef.current.parentElement.getBoundingClientRect'
                )
            }
            if (typeof getParentBounds === 'function') {
                const parentBounds =
                    this.imageElementRef.current.parentElement.getBoundingClientRect()
                if (!isEqual(parentBounds, this.parentBounds)) {
                    this.parentBounds = parentBounds
                    if (this.timer) {
                        clearTimeout(this.timer)
                    }
                    this.timer = setTimeout(() => {
                        if (this.imageElementRef.current) {
                            this.calculateMoveDelta(
                                this.imageElementRef.current,
                                scale,
                                false,
                                false
                            )
                        }
                    }, ADJUST_IMAGE_MARGINS_DELAY)
                }
            }
        }
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleKeyDown, false)
        document.removeEventListener('keyup', this.handleKeyUp, false)
        if (this.timer) {
            clearTimeout(this.timer)
            this.timer = undefined
        }
    }

    /**
     * Change the cursor based on the 'Alt' key.
     * @param event The keydown event.
     */
    handleKeyDown = (event) => {
        if (event.key === 'Alt' && !this.state.isDragging) {
            this.setState({ cursor: 'zoom-out' })
        }
    }

    /**
     * Change the cursor when the 'Alt' key is released.
     * @param event The keyup event.
     */
    handleKeyUp = (event) => {
        if (event.key === 'Alt' && !this.state.isDragging) {
            this.setState({ cursor: 'zoom-in' })
        }
    }

    /**
     * When the user clicks the mouse, turn on dragging and setup the max mouse movement so that image doesn't get moved
     * out of the viewable area.
     *
     * @param event The mouse down event.
     */
    onMouseDown(event) {
        if (this.props.isZoomed) {
            event.stopPropagation()
            event.preventDefault()

            document.addEventListener('mouseup', this.onMouseUp, false)
            document.addEventListener('mousemove', this.onMouseMove, false)

            this.isMouseMoved = false
            this.saveX = event.screenX
            this.saveY = event.screenY
            this.previousScreenX = event.screenX
            this.previousScreenY = event.screenY
            this.calculateMoveDelta(event.target, this.props.scale, true, true)
            this.setState({ cursor: 'grabbing' })
        }
    }

    /**
     * If the user clicks without moving the mouse, zoom in on the location.
     * @param event The mouse click event.
     */
    onClick = (event) => {
        if (
            this.props.isZoomed &&
            (!this.isMouseMoved ||
                Math.max(
                    Math.abs(this.saveX - event.screenX),
                    Math.abs(this.saveY - event.screenY)
                ) <= THRESHOLD_MOVE_CLICK)
        ) {
            const boundingRect = event.target.getBoundingClientRect()

            this.currentX = event.clientX - boundingRect.left
            this.currentY = event.clientY - boundingRect.top

            this.props.onClick && this.props.onClick(event)
            this.setState({ cursor: event.altKey ? 'zoom-out' : 'zoom-in' })
        }
    }

    /**
     * Calculate the distance the image can be moved.  There are three main cases, if the user is dragging, zooming in or
     * zooming out.
     * 1) If the user is dragging, the viewable area is compared to the image size and allows the user to move the image
     *    so that any part is visible with a margin as a percentage of the parent size.
     * 2) If the user is zooming in, the location of the zoom is calculated and the offset is changed to keep the mouse
     *    point at the same location on the image.  If scaling causes too large a margin, the image is moved after the
     *    user is done zooming.
     * 3) If the user is zooming out, the margins are reduced until the image is centered at 100%. While the edges aren't
     *    visible, the image is zoomed out with the mouse or center of the image as the focal point. Once one edge is
     *    visible the margin is set to 0 so the edge is located at the edge of the parent. Once both edges are visible,
     *    the image is centered, so that as the user zooms out more, the margin on both sides will be the same.
     *
     * @param target The element of the image.
     * @param scale The scale used to determine the image offset.
     * @param isDragging True if the user is dragging the image.
     * @param isFocusCurrentLocation True if the zoom should use currentX and currentY instead of the center of the
     *        parent. True will make the image zoom keeping currentX and currentY at the same place on the zoomed image.
     */
    calculateMoveDelta = (
        target,
        scale,
        isDragging,
        isFocusCurrentLocation = false
    ) => {
        if (!target || !target.parentElement) {
            target = this.imageElementRef.current // Use ref
        }

        const image = this.hiddenImageRef.current // Use ref for hidden image
        const parentBounds = target.parentElement.getBoundingClientRect()

        const { naturalWidth, naturalHeight } = image
        let { accumulatedDeltaX, accumulatedDeltaY } = this.state

        // Calculate what object-fit is using for the scale.
        const xScale = parentBounds.width / naturalWidth
        const yScale = parentBounds.height / naturalHeight

        const objectFitScale = Math.min(xScale, yScale)

        // Calculate the scaled width using the object-fit scale together with the user zooming scale.
        const unscaledWidth = naturalWidth * objectFitScale
        const newScaledWidth = unscaledWidth * scale

        const unscaledHeight = naturalHeight * objectFitScale
        const newScaledHeight = unscaledHeight * scale

        // On zoom out start changing the margins to force the image back to center by 100%.
        this.adjustMaximumMargins(
            scale,
            parentBounds,
            unscaledWidth,
            unscaledHeight
        )

        // Allow maximum margin if the user is dragging. If zooming, limit the margin when zooming out and allow anything
        // zooming in.
        const maxMarginX = isDragging
            ? MAX_MARGIN_ON_PAN
            : this.maxMarginPercentX
        const maxMarginY = isDragging
            ? MAX_MARGIN_ON_PAN
            : this.maxMarginPercentY

        // If the user has zoomed, the new width is larger than the parent width.  Calculate the difference and divide by
        // 2(only move half distance left or half right). Add the extra margin the image is allowed to move.
        const widthDelta =
            (newScaledWidth - parentBounds.width) / 2 +
            parentBounds.width * maxMarginX
        const heightDelta =
            (newScaledHeight - parentBounds.height) / 2 +
            parentBounds.height * maxMarginY

        const maxX = Math.abs(widthDelta)
        const maxY = Math.abs(heightDelta)

        // Is the user zooming (i.e. not dragging)?
        if (!isDragging) {
            const deltas = this.adjustDelta(
                target,
                isFocusCurrentLocation,
                scale,
                maxMarginX !== 0 || maxMarginY !== 0,
                parentBounds,
                unscaledWidth,
                unscaledHeight,
                newScaledWidth,
                newScaledHeight,
                maxX,
                maxY
            )
            accumulatedDeltaX = deltas.accumulatedDeltaX
            accumulatedDeltaY = deltas.accumulatedDeltaY
        } else {
            this.resetZoomOutMarginAdjustments()
        }
        this.setState({
            isDragging,
            accumulatedDeltaX,
            accumulatedDeltaY,
            maxX,
            maxY,
        })
    }

    resetZoomOutMarginAdjustments() {
        this.topScale = undefined
        // While zooming in, allow a margin up to MAX_MARGIN_ON_PAN.
        this.maxMarginPercentX = MAX_MARGIN_ON_PAN
        this.maxMarginPercentY = MAX_MARGIN_ON_PAN
    }

    /**
     * Adjust the accumulatedDeltaX and accumulatedDeltaY for zooming.  There are two main adjustments:
     * 1) Adjust the deltas to move the image so that the focal point stays at the same place while zooming.
     * 2) Adjust the deltas so they are within the margin.
     *
     * @param target
     * @param isFocusCurrentLocation
     * @param scale The new scale for the image.
     * @param isEdgeVisible Indicates if an image edge is visible. If it is and zooming out don't use the mouse location
     *        for focal point.
     * @param parentBounds The bounding rectangle of the parent to the image.
     * @param unscaledWidth The width with no scaling.
     * @param unscaledHeight The height with no scaling.
     * @param newScaledWidth The width of the image with the new scale applied.
     * @param newScaledHeight The height of the image with the new scale applied.
     * @param maxX The maximum X delta.
     * @param maxY The maximum Y delta.
     * @return {{accumulatedDeltaX, accumulatedDeltaY}}
     */
    adjustDelta(
        target,
        isFocusCurrentLocation,
        scale,
        isEdgeVisible,
        parentBounds,
        unscaledWidth,
        unscaledHeight,
        newScaledWidth,
        newScaledHeight,
        maxX,
        maxY
    ) {
        let { accumulatedDeltaX, accumulatedDeltaY } = this.state

        const boundingRect = target.getBoundingClientRect()
        this.centerX = boundingRect.width / 2
        this.centerY = boundingRect.height / 2
        // If the user is zooming with the buttons or zooming out, use the center of the image as the focal point.
        if (
            !isFocusCurrentLocation ||
            (this.props.scale > scale && isEdgeVisible)
        ) {
            this.currentX = parentBounds.width / 2 - boundingRect.left
            this.currentY = parentBounds.height / 2 - boundingRect.top
        }
        let previousScaledWidth = unscaledWidth * this.props.scale
        const previousHeight = unscaledHeight * this.props.scale

        // Calculate the maximum amount the image is allowed to shift if the mouse is at the side of the image.
        const deltaX = (newScaledWidth - previousScaledWidth) / 2
        // Calculate how close the mouse is to the center.  Since the image is scaled from the center of the
        // image, if the mouse is at the center, the image doesn't move.
        let percentX =
            (this.centerX - this.currentX) / (previousScaledWidth / 2)

        // Pin the percent between 1 and -1 so the image doesn't move too much if the mouse is outside the image.
        if (percentX > 1) {
            percentX = 1
        } else if (percentX < -1) {
            percentX = -1
        }

        // See calculation descriptions for X.
        const deltaY = (newScaledHeight - previousHeight) / 2
        let percentY = (this.centerY - this.currentY) / (previousHeight / 2)
        if (percentY > 1) {
            percentY = 1
        } else if (percentY < -1) {
            percentY = -1
        }
        accumulatedDeltaX += deltaX * percentX
        accumulatedDeltaY += deltaY * percentY

        // If the accumulated deltas aren't 0 we need to make sure they are in the allowable margin.
        return this.pinAccumulatedDeltas(
            accumulatedDeltaX,
            accumulatedDeltaY,
            scale,
            parentBounds,
            newScaledWidth,
            newScaledHeight,
            maxX,
            maxY
        )
    }

    /**
     * Pin the accumulated deltas within the +/- maxX and maxY. If zooming out, remove the accumulated delta when scale
     * is 1 or when both edges are inside the parent bounds.
     * @param accumulatedDeltaX The current delta the image has been moved from the center.
     * @param accumulatedDeltaY The current delta the image has been moved from the center.
     * @param scale The new scale for the image.
     * @param parentBounds The bounding rectangle of the parent to the image.
     * @param newScaledWidth The width of the image with the new scale applied.
     * @param newScaledHeight The height of the image with the new scale applied.
     * @param maxX The maximum X delta.
     * @param maxY The maximum Y delta.
     * @return {{accumulatedDeltaX: *, accumulatedDeltaY: *}}
     */
    pinAccumulatedDeltas(
        accumulatedDeltaX,
        accumulatedDeltaY,
        scale,
        parentBounds,
        newScaledWidth,
        newScaledHeight,
        maxX,
        maxY
    ) {
        if (accumulatedDeltaX !== 0 || accumulatedDeltaY !== 0) {
            // If zooming out...
            if (this.props.scale > scale) {
                // When we reach 100% zoom, force the deltas back to zero.
                if (scale === 1) {
                    accumulatedDeltaX = 0
                    accumulatedDeltaY = 0
                } else {
                    const margins = this.calcMaximumMargins(
                        parentBounds,
                        accumulatedDeltaX,
                        newScaledWidth,
                        accumulatedDeltaY,
                        newScaledHeight
                    )
                    if (
                        margins.rightPercent !== 0 &&
                        margins.leftPercent !== 0
                    ) {
                        accumulatedDeltaX = 0
                    }
                    if (
                        margins.topPercent !== 0 &&
                        margins.bottomPercent !== 0
                    ) {
                        accumulatedDeltaY = 0
                    }

                    // Pin the deltas to be within the acceptable bounds.
                    const pinnedDelta = this.pinDelta(
                        accumulatedDeltaX,
                        maxX,
                        accumulatedDeltaY,
                        maxY
                    )
                    accumulatedDeltaX = pinnedDelta.deltaX
                    accumulatedDeltaY = pinnedDelta.deltaY
                }
                // If zooming in...
            } else {
                // Pin the deltas to be within the acceptable bounds.
                const pinnedDelta = this.pinDelta(
                    accumulatedDeltaX,
                    maxX,
                    accumulatedDeltaY,
                    maxY
                )
                accumulatedDeltaX = pinnedDelta.deltaX
                accumulatedDeltaY = pinnedDelta.deltaY
            }
        }
        return { accumulatedDeltaX, accumulatedDeltaY }
    }

    /**
     * Adjust the maximum margins of the image. If the user is not zooming out, the default MAX_MARGIN_ON_PAN is used. If
     * the user is zooming out the margins are shrunk proportionally from the maximum scale to 100%.
     * @param scale The new scale.
     * @param parentBounds The bounding rectangle of the parent of the image.
     * @param unscaledWidth The width with no scaling.
     * @param unscaledHeight The height with no scaling.
     */
    adjustMaximumMargins(scale, parentBounds, unscaledWidth, unscaledHeight) {
        // is the user zooming out?
        if (this.props.scale > scale) {
            // Is this the first time the user is zooming out after zooming in?
            if (!this.topScale) {
                const { accumulatedDeltaX, accumulatedDeltaY } = this.state

                // The previous width and height were at the largest scaled value before starting to zoom out.
                let previousScaledWidth = unscaledWidth * this.props.scale
                const previousScaledHeight = unscaledHeight * this.props.scale

                //  Calculate the largest margins for x and y.
                const { rightPercent, leftPercent, topPercent, bottomPercent } =
                    this.calcMaximumMargins(
                        parentBounds,
                        accumulatedDeltaX,
                        previousScaledWidth,
                        accumulatedDeltaY,
                        previousScaledHeight
                    )

                // Shrink the largest margin as the user zooms out.
                this.topMarginX = Math.max(rightPercent, leftPercent)
                this.topMarginY = Math.max(bottomPercent, topPercent)

                this.maxMarginPercentX = this.topMarginX
                this.maxMarginPercentY = this.topMarginY

                // // Save off the starting scale. Use -1 to finish moving the image when we reach 1 (i.e. 100% and not 0).
                this.topScale = this.props.scale - 1
            }
            // Since the adjusted scale is based on the previous scale, this starts adjusting the margin the first time.
            // This is needed to make the adjustments smoother for scales closer to 1.
            const adjustScale = (scale - 1) / this.topScale
            this.maxMarginPercentX = this.topMarginX * adjustScale
            this.maxMarginPercentY = this.topMarginY * adjustScale
        } else {
            // topScale is used to determine if the user starts to zoom out, so remove it when not zooming in.
            this.resetZoomOutMarginAdjustments()
        }
    }

    /**
     * Calculate maximum margins percentage for each edge. Determine if the edges are inside or outside of the parent
     * bounds. If outside, the percent of the bounds is 0. If it is inside, calculate the percentage of the parent
     * bounds.
     *
     * @param parentBounds The bounds of the image parent.
     * @param accumulatedDeltaX The current delta the image has been moved from the center.
     * @param width The width of the image to check.
     * @param accumulatedDeltaY The current delta the image has been moved from the center.
     * @param height The height of the image to check.
     * @return {{leftPercent: number, rightPercent: number, topPercent: number, bottomPercent: number}}
     */
    calcMaximumMargins(
        parentBounds,
        accumulatedDeltaX,
        width,
        accumulatedDeltaY,
        height
    ) {
        let leftPercent = 0,
            rightPercent = 0,
            topPercent = 0,
            bottomPercent = 0

        const rightEdge = parentBounds.width / 2 + accumulatedDeltaX + width / 2
        if (rightEdge < parentBounds.width) {
            rightPercent = (parentBounds.width - rightEdge) / parentBounds.width
        }
        const leftEdge = parentBounds.width / 2 + accumulatedDeltaX - width / 2
        if (leftEdge > 0) {
            leftPercent = leftEdge / parentBounds.width
        }

        const bottomEdge =
            parentBounds.height / 2 + accumulatedDeltaY + height / 2
        if (bottomEdge < parentBounds.height) {
            bottomPercent =
                (parentBounds.height - bottomEdge) / parentBounds.height
        }
        const topEdge = parentBounds.height / 2 + accumulatedDeltaY - height / 2
        if (topEdge > 0) {
            topPercent = topEdge / parentBounds.height
        }

        return { leftPercent, rightPercent, topPercent, bottomPercent }
    }

    /**
     * Keep the deltas within the +/- max. If the delta is outside, it is moved to within the range.
     *
     * @param deltaX The X delta to pin.
     * @param maxX The maximum X delta allowed.
     * @param deltaY The Y delta to pin.
     * @param maxY The maximum Y delta allowed.
     * @return {{deltaX: *, deltaY: *}} The pinned deltas.
     */
    pinDelta(deltaX, maxX, deltaY, maxY) {
        if (deltaX > maxX) {
            deltaX = maxX
        } else if (deltaX < -maxX) {
            deltaX = -maxX
        }
        if (deltaY > maxY) {
            deltaY = maxY
        } else if (deltaY < -maxY) {
            deltaY = -maxY
        }
        return { deltaX, deltaY }
    }

    /**
     * When the mouse moves, keep the delta within the allowed limits and set the new deltas.
     * @param event The mouse move event.
     */
    onMouseMove(event) {
        event.stopPropagation()
        event.preventDefault()

        const { accumulatedDeltaX, accumulatedDeltaY, maxX, maxY } = this.state

        const movementX =
            event.movementX !== undefined
                ? event.movementX
                : event.screenX - this.previousScreenX
        const movementY =
            event.movementY !== undefined
                ? event.movementY
                : event.screenY - this.previousScreenY

        console.log(`MovementX = ${movementX}, MovementY = ${movementY}`)
        const pinnedDelta = this.pinDelta(
            accumulatedDeltaX + movementX,
            maxX,
            accumulatedDeltaY + movementY,
            maxY
        )

        this.previousScreenX = event.screenX
        this.previousScreenY = event.screenY

        this.isMouseMoved = true
        this.setState({
            accumulatedDeltaX: pinnedDelta.deltaX,
            accumulatedDeltaY: pinnedDelta.deltaY,
        })
    }

    /**
     * When the mouse is up, turn off dragging and
     * @param event
     */
    onMouseUp(event) {
        event.stopPropagation()
        event.preventDefault()

        document.removeEventListener('mouseup', this.onMouseUp, false)
        document.removeEventListener('mousemove', this.onMouseMove, false)
        this.previousScreenX = undefined
        this.previousScreenY = undefined

        this.setState({ cursor: 'zoom-in', isDragging: false })
    }

    /**
     * When the wheel is moved, calculate the center and the mouse x and y.
     * @param event The wheel event.
     */
    onWheel = (event) => {
        const boundingRect = event.target.getBoundingClientRect()

        this.currentX = event.clientX - boundingRect.left
        this.currentY = event.clientY - boundingRect.top

        this.isWheelZooming = Date.now()

        if (this.props.onWheel) {
            this.props.onWheel(event)
        }
    }

    render() {
        const {
            className,
            rotation,
            src,
            top,
            scale,
            isZoomed,
            onError,
            useAnimation = true,
        } = this.props
        const { accumulatedDeltaX, accumulatedDeltaY, isDragging, cursor } =
            this.state
        let transitionStyle = ''

        const imageStyle = {
            transform: `translate(${accumulatedDeltaX}px, ${accumulatedDeltaY}px) rotate(${rotation}deg) scale(${scale})`,
            cursor: isZoomed ? cursor : 'arrow',
        }

        if (!useAnimation) {
            imageStyle.transition = 'none'
            imageStyle['-webkit-transition'] = 'none'
        }

        if (top) {
            imageStyle.top = top
        }

        if (isDragging) {
            transitionStyle = 'image-dragging '
        } else if (Date.now() - this.isWheelZooming > 100) {
            transitionStyle = 'image-zoomed '
        }

        return (
            <div
                key={src + isZoomed}
                id="full-image-id"
                className={`vc_single_image-wrapper ${transitionStyle} ${className}`}
                style={imageStyle}
                ref={this.imageElementRef} // Attach ref
                onMouseDown={this.onMouseDown}
                onClick={this.onClick}
                onWheel={this.onWheel}
            >
                <div
                    className="image-wrapper"
                    style={{ height: '100%', backgroundImage: `url(${src})` }}
                />
                <img
                    id="full-hidden-image-id"
                    src={src}
                    alt=""
                    style={{ display: 'none' }}
                    ref={this.hiddenImageRef} // Attach ref for hidden image
                    onError={onError}
                />
            </div>
        )
    }
}

export default ImageComponent
