import { withStyles } from '@material-ui/core/styles'
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { v4 as uuid } from 'uuid'
import FormattedTextField from '../components/eval/components/FormattedTextField'
import { CREATE_UPDATE_ORGANIZATION } from '../Constants'
import ModalDialog from '../fhg/components/dialog/ModalDialog'
import PhoneField from '../fhg/components/PhoneField'
import Typography from '../fhg/components/Typography'
import { requestForServer } from '../Utils/ServerUtil'
import eventCapture from '../eventList'
import { posthogCapture } from '../PostHogFunction'

const styles = (theme) => ({
    fullWidthInput: {
        marginTop: `${theme.spacing(0.5)}px !important`,
        height: 30,
        width: '100% !important',
    },
    mediumTopPad: {
        marginTop: theme.spacing(1.5),
    },
    largeUnrelatedPad: {
        padding: `${theme.spacing(4.5)}px 0 ${theme.spacing(1.5)}px`,
    },
    largeUnrelatedBottomPad: {
        padding: `0 0 ${theme.spacing(1.5)}px`,
    },
    paddingOutlineInput: {
        padding: 11.21,
    },
})

/**
 * The New Organization dialog which creates a new organization.
 */
class NewOrganizationDialog extends Component {
    constructor(props) {
        super(props)

        this.state = {
            name: '',
            isSaving: false,
            isChanged: false,
        }
    }

    /**
     * Handle changes to the organization.
     * @param target The target of the event that triggered the change.
     */
    handleChange = ({ target }) => {
        this.setState({ [target.name]: target.value, isChanged: true })
    }

    /**
     * Submits the changes to the organization.
     */
    onSubmit = async (event) => {
        posthogCapture(eventCapture.NEW_EVALUATION)
        event.preventDefault()

        const { isChanged, isSaving } = this.state
        const { onClose, onSubmit } = this.props

        if (isChanged && !isSaving) {
            this.setState({ isSaving: true })
            try {
                const org = {}
                const orgId = uuid()
                org.name = this.state.orgName || undefined
                org.contact = this.state.name || undefined
                org.email = this.state.email || undefined
                org.phone = this.state.phone || undefined

                const result = await requestForServer(
                    CREATE_UPDATE_ORGANIZATION.format({ orgId }),
                    'put',
                    org
                )

                if (result) {
                    // window?.posthog?.capture('Save New Organization')
                    posthogCapture(eventCapture.SAVE_NEW_ORGANIZATION)
                }
                this.setState({ isChanged: false, isSaving: false }, () => {
                    onSubmit && onSubmit(result.data)
                })
                onClose()
            } catch (error) {
                this.setState({
                    isSaving: false,
                    showError: true,
                    errorId: 'newOrganization.save.error',
                    ...error,
                })
                console.log(error)
            }
        }
    }

    render() {
        const { classes, open, onClose } = this.props
        const { isSaving } = this.state

        return (
            <ModalDialog
                open={open}
                onClose={onClose}
                onSubmit={this.onSubmit}
                titleKey={'newOrganization.main.title'}
                isSaving={isSaving}
                submitKey={'newOrganization.submit.label'}
                isForm
            >
                <Typography
                    id="newOrganization.orgName.label"
                    className={`${classes.mediumTopPad} label-text`}
                />
                <FormattedTextField
                    name="orgName"
                    required
                    autoFocus
                    fullWidth
                    value={this.state.orgName}
                    onChange={this.handleChange}
                    margin="none"
                    placeholder={'Acme Company'}
                    inputProps={{ className: classes.fullWidthInput }}
                    disabled={isSaving}
                />
                <Typography
                    id="newOrganization.admin.title"
                    variant={'inherit'}
                    className={`${classes.largeUnrelatedPad} dark-level-3-heading`}
                />
                <Typography
                    id="newOrganization.admin.info"
                    variant={'inherit'}
                    className={`${classes.largeUnrelatedBottomPad} default-text-small`}
                />
                <Typography
                    id="newOrganization.name.label"
                    className={`${classes.mediumTopPad} label-text`}
                />
                <FormattedTextField
                    name="name"
                    required
                    fullWidth
                    value={this.state.name}
                    onChange={this.handleChange}
                    margin="none"
                    placeholder={'Doug Jones'}
                    inputProps={{ className: classes.fullWidthInput }}
                    disabled={isSaving}
                />
                <Typography
                    id="newOrganization.email.label"
                    className={`${classes.mediumTopPad} label-text`}
                />
                <FormattedTextField
                    name="email"
                    type="email"
                    required
                    fullWidth
                    value={this.state.email}
                    onChange={this.handleChange}
                    margin="none"
                    placeholder={'doug@acme.com'}
                    inputProps={{ className: classes.fullWidthInput }}
                    disabled={isSaving}
                />
                <Typography
                    id="newOrganization.phone.label"
                    className={`${classes.mediumTopPad} label-text`}
                />
                <PhoneField
                    name="phone"
                    required
                    fullWidth
                    value={this.state.phone}
                    onChange={this.handleChange}
                    margin="none"
                    placeholder={'(402)867-5309'}
                    inputProps={{
                        className: classes.fullWidthInput,
                    }}
                    disabled={isSaving}
                />
            </ModalDialog>
        )
    }
}

export default withRouter(withStyles(styles)(NewOrganizationDialog))
