import { withStyles } from '@material-ui/core/styles'
import React, { Component } from 'react'

const styles = (theme) => ({
    errorMessage: {},
    close: {},
})

/**
 * Component to show the error messages..
 */
class Error404 extends Component {
    render() {
        return <div>Error 404</div>
    }
}

export default withStyles(styles)(Error404)
