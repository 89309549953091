import * as React from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Typography from '@mui/material/Typography'
import { CardActionArea } from '@mui/material'
import { makeStyles } from '@material-ui/core/styles'
import { BsSlack } from 'react-icons/bs'

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        maxWidth: 1000, // Set a maximum width for the container
        margin: '0 auto', // Center the container horizontally
    },
    card: {
        maxWidth: 400,
        borderRadius: '5px !important', // Increase border radius
        boxShadow: '0 0 10px 4px rgba(0, 0, 0, 0.1) !important', // Apply box shadow with blur effect
    },
    header: {
        textAlign: 'center',
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
    cardContent: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    contactOption: {
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(0.8),
    },
    icon: {
        fontSize: '2rem',
        marginLeft: '1rem',
        marginRight: '1rem',
        color: '#4f2682',
    },
    emailLink: {
        textDecoration: 'none',
        color: '#4f2682',
        '&:hover': {
            textDecoration: 'underline',
        },
        text: {},
    },
}))

export default function Card_3() {
    const classes = useStyles()
    return (
        <Card className={classes.card}>
            <CardActionArea>
                <CardContent className={classes.cardContent}>
                    <div className={classes.contactOption}>
                        <BsSlack className={classes.icon} />
                        <Typography gutterBottom variant="h6" component="div">
                            <a
                                href="https://purplewave-tech.slack.com/archives/C03HAK98XRP"
                                target="_blank"
                                rel="noopener noreferrer"
                                className={classes.emailLink}
                            >
                                #techsupport
                            </a>
                        </Typography>
                    </div>
                </CardContent>
            </CardActionArea>
        </Card>
    )
}
