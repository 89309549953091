import React, { useState, useEffect, createContext, useContext } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import PropTypes from "prop-types";
import { useIMSForm } from "../detail/IMSFormContext";
import { Auth } from "aws-amplify";
import { isAdminOnly, isMultipleOrganization } from "../../../Utils/ServerUtil";
import { FormHelperText } from "@material-ui/core";
import { InputAdornment } from "@material-ui/core";

const upcomingAuctions = [
  {
      "auction_id": 11286,
      "auction": "250312",
      "title": "Wednesday March 12 Vehicles and Equipment Auction",
      "auction_date": "250312",
      "workspace_label": "250312ve"
  },
  {
      "auction_id": 11287,
      "auction": "250313",
      "title": "Thursday March 13 Construction Equipment Auction",
      "auction_date": "250313",
      "workspace_label": "250313const"
  },
  {
      "auction_id": 11288,
      "auction": "250318",
      "title": "Tuesday March 18 Government Auction",
      "auction_date": "250318",
      "workspace_label": "250318govt"
  },
  {
      "auction_id": 11289,
      "auction": "250319",
      "title": "Wednesday March 19 Ag Equipment Auction",
      "auction_date": "250319",
      "workspace_label": "250319ag"
  },
  {
      "auction_id": 11290,
      "auction": "250320",
      "title": "Thursday March 20 Truck and Trailer Auction",
      "auction_date": "250320",
      "workspace_label": "250320truck"
  },
  {
      "auction_id": 11291,
      "auction": "250326",
      "title": "Wednesday March 26 Vehicles and Equipment Auction",
      "auction_date": "250326",
      "workspace_label": "250326ve"
  },
  {
      "auction_id": 11292,
      "auction": "250327",
      "title": "Thursday March 27 Construction Equipment Auction",
      "auction_date": "250327",
      "workspace_label": "250327const"
  },
  {
      "auction_id": 11304,
      "auction": "250401",
      "title": "Tuesday April 01 Government Auction",
      "auction_date": "250401",
      "workspace_label": "250401govt"
  },
  {
      "auction_id": 11305,
      "auction": "250402",
      "title": "Wednesday April 02 Ag Equipment Auction",
      "auction_date": "250402",
      "workspace_label": "250402ag"
  },
  {
      "auction_id": 11306,
      "auction": "250403",
      "title": "Thursday April 03 Truck and Trailer Auction",
      "auction_date": "250403",
      "workspace_label": "250403truck"
  },
  {
      "auction_id": 11307,
      "auction": "250409",
      "title": "Wednesday April 09 Vehicles and Equipment Auction",
      "auction_date": "250409",
      "workspace_label": "250409ve"
  },
  {
      "auction_id": 11308,
      "auction": "250410",
      "title": "Thursday April 10 Construction Equipment Auction",
      "auction_date": "250410",
      "workspace_label": "250410const"
  },
  {
      "auction_id": 11309,
      "auction": "250415",
      "title": "Tuesday April 15 Government Auction",
      "auction_date": "250415",
      "workspace_label": "250415govt"
  },
  {
      "auction_id": 11310,
      "auction": "250416",
      "title": "Wednesday April 16 Ag Equipment Auction",
      "auction_date": "250416",
      "workspace_label": "250416ag"
  },
  {
      "auction_id": 11311,
      "auction": "250417",
      "title": "Thursday April 17 Truck and Trailer Auction",
      "auction_date": "250417",
      "workspace_label": "250417truck"
  },
  {
      "auction_id": 11331,
      "auction": "250422",
      "title": "Tuesday April 22 Kansas Department of Transportation Auction",
      "auction_date": "250422",
      "workspace_label": "250422kdot"
  },
  {
      "auction_id": 11312,
      "auction": "250423",
      "title": "Wednesday April 23 Vehicles and Equipment Auction",
      "auction_date": "250423",
      "workspace_label": "250423ve"
  },
  {
      "auction_id": 11313,
      "auction": "250424",
      "title": "Thursday April 24 Construction Equipment Auction",
      "auction_date": "250424",
      "workspace_label": "250424const"
  },
  {
      "auction_id": 11314,
      "auction": "250429",
      "title": "Tuesday April 29 Government Auction",
      "auction_date": "250429",
      "workspace_label": "250429govt"
  },
  {
      "auction_id": 11315,
      "auction": "250430",
      "title": "Wednesday April 30 Ag Equipment Auction",
      "auction_date": "250430",
      "workspace_label": "250430ag"
  },
  {
      "auction_id": 11316,
      "auction": "250501",
      "title": "Thursday May 01 Truck and Trailer Auction",
      "auction_date": "250501",
      "workspace_label": "250501truck"
  },
  {
      "auction_id": 11317,
      "auction": "250507",
      "title": "Wednesday May 07 Vehicles and Equipment Auction",
      "auction_date": "250507",
      "workspace_label": "250507ve"
  },
  {
      "auction_id": 11318,
      "auction": "250508",
      "title": "Thursday May 08 Construction Equipment Auction",
      "auction_date": "250508",
      "workspace_label": "250508const"
  },
  {
      "auction_id": 11319,
      "auction": "250513",
      "title": "Tuesday May 13 Government Auction",
      "auction_date": "250513",
      "workspace_label": "250513govt"
  },
  {
      "auction_id": 11320,
      "auction": "250514",
      "title": "Wednesday May 14 Ag Equipment Auction",
      "auction_date": "250514",
      "workspace_label": "250514ag"
  },
  {
      "auction_id": 11321,
      "auction": "250515",
      "title": "Thursday May 15 Truck and Trailer Auction",
      "auction_date": "250515",
      "workspace_label": "250515truck"
  },
  {
      "auction_id": 11322,
      "auction": "250521",
      "title": "Wednesday May 21 Vehicles and Equipment Auction",
      "auction_date": "250521",
      "workspace_label": "250521ve"
  },
  {
      "auction_id": 11323,
      "auction": "250522",
      "title": "Thursday May 22 Construction Equipment Auction",
      "auction_date": "250522",
      "workspace_label": "250522const"
  },
  {
      "auction_id": 11324,
      "auction": "250527",
      "title": "Tuesday May 27 Government Auction",
      "auction_date": "250527",
      "workspace_label": "250527govt"
  },
  {
      "auction_id": 11325,
      "auction": "250528",
      "title": "Wednesday May 28 Ag Equipment Auction",
      "auction_date": "250528",
      "workspace_label": "250528ag"
  }
]

// Step 1: Create a context
export const IMSContext = createContext();

IMSformCategtorySelect.propTypes = {
  customerNumber: PropTypes.string,
};

function IMSformCategtorySelect({ customerNumber, evalItem }) {
  // Step 2: Use state from the context
  const {
    categoriesData,
    setCategoriesData,
    industriesData,
    setIndustriesData,
    selectedIndustry,
    setSelectedIndustry,
    selectedFamily,
    setSelectedFamily,
    selectedCategory,
    setSelectedCategory,
    setSelectedOpportunity,
    estimatedHammer,
    setEstimatedHammer,
    jobCodeData,
    setJobCodeData,
    auctionsJobCodeData,
    setAuctionsJobCodeData,
  } = useContext(IMSContext);

  const { imsForm, setImsForm } = useIMSForm();
  const [isLoading, setIsLoading] = useState(true);
  const [dataSource, setDataSource] = useState("none"); // 'none', 'mock', or 'api'
  const [lastValidJobCode, setLastValidJobCode] = useState(null);
  const [isInitialized, setIsInitialized] = useState(false);

  // useEffect(() => {
  //   console.log("TESTING REAL FORM ====================>>>>>>>>", imsForm);
  // }, [selectedCategory]);

  const imsFormData = async () => {
    try {
      const response = await fetch(
        "https://ims-api.purplewave.com/v1/industries?published=1"
      );
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      setIndustriesData(data);

      const allCategories = data.flatMap((industry) =>
        industry.Families.flatMap((family) =>
          family.Categories.map((category) => category.category)
        )
      );
      setCategoriesData(allCategories);

      console.log("ALL CATEGORIES", allCategories);
    } catch (error) {
      console.error("Error fetching industries:", error);
    }
  };

  // useEffect(() => {
  //   imsFormData();
  //   auctionsData();
  // }, []);

  // Separate useEffect for initial data loading
  useEffect(() => {
    const loadInitialData = async () => {
      setIsLoading(true);
      await Promise.all([imsFormData(), auctionsData()]);
      setIsLoading(false);
    };

    loadInitialData();
  }, []);

  // Separate useEffect for handling job code initialization
  useEffect(() => {
    if (!isInitialized || !imsForm?.item?.ims_forms_data?.jobcode) return;

    const jobcodeValue = imsForm.item.ims_forms_data.jobcode;

    console.log(
      "Checking job code:",
      jobcodeValue,
      "with data source:",
      dataSource
    );

    // Keep the current job code if it was previously validated
    if (jobcodeValue === lastValidJobCode) {
      console.log("Using previously validated job code:", jobcodeValue);
      return;
    }

    // Check current data source
    const currentData =
      auctionsJobCodeData.length > 0 ? auctionsJobCodeData : upcomingAuctions;
    const isValidJobCode = currentData.some(
      (auction) => auction.workspace_label === jobcodeValue
    );

    if (isValidJobCode) {
      console.log("Valid job code found in", dataSource, "data:", jobcodeValue);
      setJobCodeData(jobcodeValue);
      setLastValidJobCode(jobcodeValue);
    } else if (dataSource === "api") {
      // If not found in API data, check mock data
      const isValidMockJobCode = upcomingAuctions.some(
        (auction) => auction.workspace_label === jobcodeValue
      );

      if (isValidMockJobCode) {
        console.log("Valid job code found in mock data:", jobcodeValue);
        setJobCodeData(jobcodeValue);
        setLastValidJobCode(jobcodeValue);
        // Keep using mock data since we found a valid job code there
        setAuctionsJobCodeData(upcomingAuctions);
        setDataSource("mock");
      }
    }
  }, [isInitialized, imsForm, dataSource, auctionsJobCodeData]);

  // Separate useEffect for estimated hammer
  useEffect(() => {
    if (imsForm?.item?.ims_forms_data?.capture_estimate) {
      console.log(
        "Setting Hammer:",
        imsForm.item.ims_forms_data.capture_estimate
      );
      setEstimatedHammer(imsForm.item.ims_forms_data.capture_estimate);
    }
  }, [imsForm]);

  useEffect(() => {
    // Check if evalItem.item.ims_form_data.opportunity_id exists
    const opportunityId = imsForm?.item?.ims_forms_data?.opportunity_id
      ? imsForm?.item?.ims_forms_data?.opportunity_id
      : evalItem?.item?.ims_forms_data?.opportunity_id;
    // If opportunityId exists, set the form to opportunityId, else set it to null
    setSelectedOpportunity(opportunityId ? opportunityId : null);
  }, [evalItem, imsForm]);

  const industryOptions = industriesData.map((industry) => ({
    value: industry,
    label: industry.industry,
  }));

  const familyOptions = selectedIndustry
    ? selectedIndustry.Families.map((family) => ({
        value: family,
        label: family.family,
      }))
    : [];

  const categoryOptions = selectedFamily
    ? selectedFamily.Categories.map((category) => ({
        value: category,
        label: category.category,
      }))
    : [];

  const handleIndustryChange = (event) => {
    const value = event.target.value;
    setSelectedIndustry(value);
    setSelectedFamily(null);
    setSelectedCategory(null);
  };

  const handleFamilyChange = (event) => {
    const value = event.target.value;
    setSelectedFamily(value);
    setSelectedCategory(null);
  };

  const handleCategoryChange = (event) => {
    const value = event.target.value;
    setSelectedCategory(value);
  };

  const handleAutocompleteChange = (_, newValue) => {
    const selectedCategory = categoriesData.find(
      (category) => category === newValue
    );
    console.log("Selected Category:", selectedCategory); // Debug log

    if (selectedCategory) {
      // Find the corresponding industry, family, and category
      for (const industry of industriesData) {
        for (const family of industry.Families) {
          for (const category of family.Categories) {
            if (category.category === newValue) {
              console.log("Select Category in search:", selectedCategory);
              setSelectedIndustry(industry);
              setSelectedFamily(family);
              setSelectedCategory(category);
              return;
            }
          }
        }
      }
    }

    // If no matching industry, family, and category found, reset the selections
    setSelectedIndustry(null);
    setSelectedFamily(null);
    setSelectedCategory(null);
  };

  useEffect(() => {
    //console.log("RELOAD=======RELOAD==========RELOAD", imsForm, evalItem, selectedCategory);

    const selectedCategoryFromIMSform = imsForm?.item?.ims_forms_data.category;
    const selectedCategoryFromEvalItem =
      evalItem?.item?.ims_forms_data.category;

    console.log(
      "Selected category from IMS form:",
      selectedCategoryFromIMSform
    );
    console.log(
      "Selected category from Eval item:",
      selectedCategoryFromEvalItem
    );

    const itemId = evalItem?.item_id;
    const profileImage = evalItem?.image_data.find(imageObject => imageObject.image_view === "AMKT");
    
    const autoPopulateCategoryFields = () => {
      if (selectedCategoryFromIMSform) {
        const newValue = imsForm?.item.ims_forms_data.category;
        handleAutocompleteChange(null, newValue);
      } else if (selectedCategoryFromEvalItem) {
        const newValue = evalItem?.item?.ims_forms_data.category;
        handleAutocompleteChange(null, newValue);
      } else if (!selectedCategory) {
        console.log("Selected category is null. Skipping effect.");
      }
    }

    if (profileImage && itemId) {
      try {
        fetchPredictions(itemId, profileImage.sizes.original);
      } catch (error) {
        autoPopulateCategoryFields();
      }
      return;
    } 

    autoPopulateCategoryFields();
  }, [imsForm, evalItem, categoriesData]);

  console.log({IMAGE_PREDICTION_API_KEY: process.env.IMAGE_PREDICTION_API_KEY});

  const fetchPredictions = async (itemId, profileImageUrl) => {
    const headers = {
      'Content-Type': 'application/json', 
      'Authorization': `Bearer dapi46b43aade76f39ee044750494a84f981`,
    };
    
    const options = {
      method: 'POST',
      headers: headers,
      body: JSON.stringify({
        "inputs": [
          {
            "uuid": itemId,
            "image_url": profileImageUrl
          }
        ]
      })
    };

    const url = "/databricks-api/serving-endpoints/resnet_full_case_classification_demo_model/invocations";

    try {
      const response = await fetch(url, options);

      if (response.ok) {
        const predictionsResponse = await response.json();
        
        // const predictions = {
        //   "predictions": {
        //     "uuid": "ed73d698-f3fb-486a-a45b-aa967702fc4f",
        //     "scores": "[{\"Grain Drill or Seeder\": 0.39705386757850647}, {\"Other Grain or Fertilizer Handling\": 0.11448296159505844}, {\"Applicator Attach.\": 0.1141415685415268}, {\"Automotive Parts or Acces.\": 0.049990229308605194}, {\"Road Safety Equip.\": 0.03962022066116333}]"
        //   }
        // };
        
        const scoresArray = JSON.parse(predictionsResponse.predictions.scores);
        // const scoresArray = JSON.parse(predictions.predictions.scores);
        
        const highestScoringCategory = scoresArray.reduce((max, current) => {
          const key = Object.keys(current)[0];
          const score = current[key]; 
          return score > max.score ? { category: key, score } : max;
        }, { category: null, score: -Infinity });
        
        handleAutocompleteChange(null, highestScoringCategory.category);
      } else {
        console.error('API request failed with status:', response)
      }
    } catch (error) {
      console.error('Error occurred during API request:', error)
    }
  }

  const auctionsData = async () => {
    try {
      let jwtToken;
      try {
        const session = await Auth.currentSession();
        jwtToken = session.idToken.jwtToken;
      } catch (e) {
        console.log("Auth error, using mock data");
        setAuctionsJobCodeData(upcomingAuctions);
        setDataSource("mock");
        return;
      }

      const headers = {
        Authorization: jwtToken,
      };

      if (isAdminOnly() || isMultipleOrganization()) {
        const organization = localStorage["hw." + window.btoa("organization")];
        if (organization && window.atob(organization)) {
          headers["HW-Organization"] = window.atob(organization);
        }
      }

      const response = await fetch(
        "https://dashboard.purplewave.com/v3/leads/upcomingauctions",
        {
          method: "GET",
          headers: headers,
        }
      );
      if (response.ok) {
        const data = await response.json();
        if (data?.upcomingAuctions?.length > 0) {
          console.log("Setting API data");
          setAuctionsJobCodeData(data.upcomingAuctions);
          setDataSource("api");
        } else {
          console.log("API returned empty data, using mock data");
          setAuctionsJobCodeData(upcomingAuctions);
          setDataSource("mock");
        }
      }
    } catch (error) {
      console.error("Error fetching auctions:", error);
      console.log("Error occurred, using mock data");
      setAuctionsJobCodeData(upcomingAuctions);
      setDataSource("mock");
    } finally {
      setIsInitialized(true);
    }
  };

  return (
    <div>
      <Autocomplete
        disablePortal
        id="category-search"
        options={categoriesData}
        value={selectedCategory ? selectedCategory.category : null}
        sx={{ width: "60%" }}
        onChange={(_, newValue) => handleAutocompleteChange(_, newValue)}
        renderInput={(params) => (
          <TextField {...params} label="Search Category" />
        )}
      />
      <div style={{ margin: "12px 0" }}></div>

      <FormControl fullWidth>
        <InputLabel
          shrink={!!selectedIndustry}
          htmlFor="industry-select"
          style={{
            backgroundColor: "#ffffff", // Match the background color of the form
            padding: "0 4px", // Optional: adds padding to avoid label overlap with border
            zIndex: 1, // Ensure label is on top of the border
          }}
        >
          Select Industry
        </InputLabel>
        <Select
          value={selectedIndustry}
          onChange={handleIndustryChange}
          label="Select Industry"
          inputProps={{
            name: "industry",
            id: "industry-select",
          }}
          style={{
            width: "60%",
            fontWeight: "normal",
          }}
          MenuProps={{
            PaperProps: {
              style: {
                zIndex: 2, // Ensure dropdown options appear above everything
              },
            },
          }}
        >
          {industryOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <div style={{ margin: "12px 0" }}></div>

      <FormControl fullWidth>
        <InputLabel
          shrink={!!selectedFamily}
          htmlFor="family-select"
          style={{
            backgroundColor: "#ffffff", // Match the background color of the form
            padding: "0 4px", // Adds padding to avoid label overlap with border
            zIndex: 1, // Ensure label is on top of the border
          }}
        >
          Select Equipment Type
        </InputLabel>
        <Select
          value={selectedFamily}
          onChange={handleFamilyChange}
          label="Select Equipment Type"
          inputProps={{
            name: "family",
            id: "family-select",
          }}
          style={{
            width: "60%",
            fontWeight: "normal",
          }}
          MenuProps={{
            PaperProps: {
              style: {
                zIndex: 2, // Ensure dropdown options appear above everything
              },
            },
          }}
        >
          {familyOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <div style={{ margin: "12px 0" }}></div>

      <FormControl fullWidth>
        <InputLabel
          shrink={!!selectedCategory}
          htmlFor="category-select"
          style={{
            backgroundColor: "#ffffff",
            padding: "0 4px",
            zIndex: 1,
            fontWeight: "400 !important",
          }}
        >
          Select Category
        </InputLabel>
        <Select
          value={selectedCategory}
          onChange={handleCategoryChange}
          label="Select Category"
          inputProps={{
            name: "category",
            id: "category-select",
          }}
          style={{
            width: "60%",
            fontWeight: "normal",
            fontWeight: "400 !important",
          }}
          MenuProps={{
            PaperProps: {
              style: {
                zIndex: 2, // Ensure dropdown options appear above everything
              },
            },
          }}
        >
          {categoryOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <div style={{ margin: "12px 0" }}></div>
      <FormControl fullWidth >
        <InputLabel
          id="jobcode-select-label"
          shrink={!isInitialized || !!jobCodeData}
          htmlFor="jobcode-select"
          style={{
            backgroundColor: "#ffffff",
            padding: "0 4px",
            zIndex: 1,
          }}
        >
          {!isInitialized ? "Loading Job Codes..." : "Select Job Code"}
        </InputLabel>
        <Select
          labelId="jobcode-select-label"
          value={jobCodeData || ""}
          onChange={(event) => {
            const newValue = event.target.value;
            console.log("Selected new job code:", newValue);
            setJobCodeData(newValue);
            setLastValidJobCode(newValue);
          }}
          label="Select Job Code"
          inputProps={{
            name: "jobcode",
            id: "jobcode-select",
          }}
          style={{ width: "60%", fontWeight: "normal" }}
          disabled={!isInitialized}
        >
          {auctionsJobCodeData.map((jobcode) => (
            <MenuItem key={jobcode.auction_id} value={jobcode.workspace_label}>
              {jobcode.workspace_label}
            </MenuItem>
          ))}
        </Select>
        {!jobCodeData && isInitialized && (
          <FormHelperText style={{color: 'red'}}>
            Form submission will fail if job code is not provided
          </FormHelperText>
        )}
      </FormControl>

      <div style={{ margin: "12px 0" }}></div>

      <FormControl fullWidth error={!estimatedHammer}>
        <TextField
          label="Estimated Hammer"
          value={estimatedHammer}
          onChange={(event) => {
            const value = event.target.value.replace(/[^0-9]/g, "");
            setEstimatedHammer(value);
          }}
          InputProps={{
            name: "estimatedHammer",
            id: "estimatedHammer-select",
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          style={{ width: "60%", fontWeight: "normal" }}
          variant="outlined"
        />
        {!estimatedHammer && (
          <FormHelperText style={{color: 'red'}}>
            Form submission will fail if Estimated Hammer is not provided
          </FormHelperText>
        )}
      </FormControl>
    </div>
  );
}

export const IMSProvider = ({ children }) => {
  const [categoriesData, setCategoriesData] = useState([]);
  const [industriesData, setIndustriesData] = useState([]);
  const [selectedIndustry, setSelectedIndustry] = useState(null);
  const [selectedFamily, setSelectedFamily] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedOpportunity, setSelectedOpportunity] = useState("");
  const [jobCodeData, setJobCodeData] = useState("");
  const [estimatedHammer, setEstimatedHammer] = useState("");
  const [auctionsJobCodeData, setAuctionsJobCodeData] = useState([]);

  useEffect(() => {
    // Your useEffect logic without localStorage
  }, []);

  return (
    <IMSContext.Provider
      value={{
        categoriesData,
        setCategoriesData,
        industriesData,
        setIndustriesData,
        selectedIndustry,
        setSelectedIndustry,
        selectedFamily,
        setSelectedFamily,
        selectedCategory,
        setSelectedCategory,
        selectedOpportunity,
        setSelectedOpportunity,
        jobCodeData,
        setJobCodeData,
        auctionsJobCodeData,
        setAuctionsJobCodeData,
        estimatedHammer,
        setEstimatedHammer,
      }}
    >
      {children}
    </IMSContext.Provider>
  );
};

export default IMSformCategtorySelect;
