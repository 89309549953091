import MuiGrid from '@material-ui/core/Grid/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import useTheme from '@material-ui/core/styles/useTheme'
import { debounce } from 'lodash'
import * as PropTypes from 'prop-types'
import React, {
    useRef,
    useEffect,
    useState,
    useCallback,
    forwardRef,
} from 'react'

const useStyles = makeStyles(
    {
        gridStyle: (props) => {
            return {
                overflow: props.overflow,
                height:
                    props.fullHeight === true
                        ? '100%'
                        : props.fullHeight === false
                          ? 'unset'
                          : undefined,
                width:
                    props.fullWidth === true
                        ? '100%'
                        : props.fullWidth === false
                          ? 'unset'
                          : undefined,
                flex: props.flex
                    ? props.flex
                    : props.resizable
                      ? '1 1'
                      : props.resizable === false
                        ? '0 0 auto'
                        : undefined,
            }
        },
        innerGridStyle: (props) => {
            return {
                overflow: props.overflow,
                height:
                    props.fullHeight === true
                        ? '100%'
                        : props.fullHeight === false
                          ? 'unset'
                          : undefined,
                width: '100%',
                flex: props.flex
                    ? props.flex
                    : props.resizable
                      ? '1 1'
                      : props.resizable === false
                        ? '0 0 auto'
                        : undefined,
            }
        },
    },
    { name: 'gridStyles' }
)

/**
 * The Grid component that supports overflow as a property.
 *
 * Example:
 * <Grid overflow='auto' fullWidth resizable>...</Grid>
 *
 * Reviewed: 3/26/20
 */
const Grid = forwardRef(function Grid(props, ref) {
    const {
        name,
        overflow,
        fullWidth,
        flex,
        resizeable,
        children,
        className,
        resizable,
        fullHeight,
        item,
        container,
        spacing,
        justify,
        alignItems,
        alignContent,
        isScrollable,
        isAddScrollPadding = true,
        ...otherProps
    } = props
    // const isScrollable = false
    const adjustedProps = { ...props }
    adjustedProps.fullHeight = isScrollable ? true : props.fullHeight
    adjustedProps.overflow = isScrollable ? 'auto' : props.overflow

    const theme = useTheme()
    const mainRef = useRef()
    const classes = useStyles(adjustedProps)
    const [isVerticalScroll, setIsVerticalScroll] = useState()

    const checkIsScrolling = useCallback(() => {
        if (mainRef.current && isScrollable) {
            const isVerticalScrolling =
                mainRef.current.scrollHeight > mainRef.current.clientHeight
            setIsVerticalScroll(isVerticalScrolling)
        }
    }, [mainRef, isScrollable])

    // Debounce the scroll size check.
    const handleResizeDebounced = useRef(
        debounce(checkIsScrolling, 750)
    ).current

    useEffect(() => {
        if (isScrollable) {
            checkIsScrolling()
            window.addEventListener('resize', handleResizeDebounced)
            return () =>
                window.removeEventListener('resize', handleResizeDebounced)
        }
    }, [checkIsScrolling, handleResizeDebounced, isScrollable, mainRef])

    let inner

    if (isScrollable) {
        inner = (
            <MuiGrid
                name="Grid-scroll inner"
                ref={mainRef}
                className={classes.innerGridStyle}
                style={{
                    padding:
                        spacing !== undefined
                            ? theme.spacing(spacing)
                            : undefined,
                    paddingRight:
                        isVerticalScroll && isAddScrollPadding
                            ? 16
                            : spacing !== undefined
                              ? theme.spacing(spacing)
                              : undefined,
                }}
                container={container}
                item={item}
                spacing={spacing}
                justifyContent={justify}
                alignItems={alignItems}
                alignContent={alignContent}
            >
                {children}
            </MuiGrid>
        )
    } else {
        inner = children
    }

    return (
        <MuiGrid
            ref={ref}
            // ref={mainRef}
            name={name}
            className={`${classes.gridStyle} ${className}`}
            item={isScrollable ? true : item}
            container={container}
            {...otherProps}
            // container={isScrollable ? false : container} {...otherProps}
            spacing={!isScrollable ? spacing : undefined}
            justifyContent={!isScrollable ? justify : undefined}
            alignItems={!isScrollable ? alignItems : undefined}
            alignContent={!isScrollable ? alignContent : undefined}
        >
            {inner}
        </MuiGrid>
    )
})

Grid.propTypes = {
    flex: PropTypes.string, // The flex style property.
    resizable: PropTypes.bool, // Indicates if the grid should use flex resizing (i.e. flex: '1 1')
    fullWidth: PropTypes.bool, // Indicates if the grid should be 100%.
    fullHeight: PropTypes.bool, // Indicates if the grid should be 100%.
    overflow: PropTypes.string, // Key to message in the localization file.
    //Properties from Grid
    alignContent: PropTypes.any,
    alignItems: PropTypes.any,
    direction: PropTypes.any,
    justify: PropTypes.any,
    lg: PropTypes.any,
    md: PropTypes.any,
    sm: PropTypes.any,
    spacing: PropTypes.any,
    wrap: PropTypes.any,
    xs: PropTypes.any,
    zeroMinWidth: PropTypes.any,
    isScrollable: PropTypes.bool,
    ...MuiGrid.propTypes, // Supports all the properties from Grid.
}

Grid.defaultProps = {
    overflow: 'hidden',
}

export default Grid
