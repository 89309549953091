import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { formatMessage } from './Utils'

useMessage.propTypes = {
    id: PropTypes.string.isRequired, // The id of the localized message.
    defaultMessage: PropTypes.string, // The default message if not localized.
    values: PropTypes.array, // Values to insert in the localized message.
}

/**
 * Hook to get a message.
 *
 * Reviewed: 8/5/2020
 *
 * @param id The localization ID from the en-US.js messages file.
 * @param defaultMessage The default message to use if the localized message isn't found.
 * @param values The values to insert into the message.
 * @return {string} The localized string.
 */
export default function useMessage(id, defaultMessage, values) {
    const intl = useIntl()

    return formatMessage(intl, id, defaultMessage, values)
}
