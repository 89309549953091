import React, { useState } from 'react'
import { PASSWORD_RESET } from '../../Constants'
import { Auth } from 'aws-amplify'
import { posthogCapture } from '../../PostHogFunction'
import { requestForServer } from '../../Utils/ServerUtil'
import eventCapture from '../../eventList'
import Typography from '../../fhg/components/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { FTInput } from '../inputs'
import Box from '@material-ui/core/Box'
import { FTButton } from '../buttons'
//import { ArrowBack } from "@material-ui/icons";

const useStyles = makeStyles((theme: any) => ({
    mainContainer: {
        width: '100%',
        height: '60%',
        backgroundColor: theme.palette.environment.light.level1.base,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '8px',
    },
    success: {
        width: '100%',
        marginTop: '32px !important',
        color: theme.palette.button.minimal.success,
    },
    error: {
        width: '100%',
        marginTop: `&{theme.spacing(4)}px !important`,
    },
    buttonBox: {
        marginTop: '16px',
    },
    headingText: {
        marginBottom: '32px',
        textAlign: 'center',
    },
}))

interface Props {
    handleSetShowCode: (state: boolean, email: string) => void
    handleGoBack: () => void
}
const FTResetPasswordRequestForm: React.FC<Props> = ({
    handleSetShowCode,
    handleGoBack,
}) => {
    const classes = useStyles()
    const [isSaving, setIsSaving] = useState<boolean>(false)
    const [error, setError] = useState<string | null>()
    const [email, setEmail] = useState<string>('')

    const handleSubmit = async () => {
        try {
            setIsSaving(true)
            const data = await Auth.forgotPassword(email)
            posthogCapture(eventCapture.PASSWORD_RESET)
            requestForServer(PASSWORD_RESET, 'post', {
                email: email,
                platform: 'Web',
            })
            handleSetShowCode(true, email)
        } catch (e) {
            setError(error)
            setIsSaving(false)
        }
    }

    return (
        <Box className={classes.mainContainer}>
            <form onSubmit={handleSubmit}>
                <Typography
                    variant="body1"
                    id={'reset.subheading'}
                    className={` ${classes.headingText} level-3-heading`}
                >
                    Type your email below. We will email you a code that you can
                    use in the next step to reset your password.
                </Typography>

                {error && (
                    <Typography
                        className={classes.error}
                        color={'error'}
                        variant={'body1'}
                        id={error}
                    >
                        Error Message.
                    </Typography>
                )}
                <FTInput
                    label="login.email.label"
                    required
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                    disabled={isSaving}
                    name="email"
                    type="email"
                />
                <Box className={classes.buttonBox}>
                    <FTButton
                        title={'reset.button.label'}
                        buttonAction={handleSubmit}
                        disabled={isSaving}
                    />
                </Box>
            </form>
            {/* <div>
                <FTButtonIconText 
                    title={"Go Back"} 
                    icon={<ArrowBack />} 
                    buttonAction={handleGoBack} 
                />
            </div> */}
        </Box>
    )
}

export default FTResetPasswordRequestForm
