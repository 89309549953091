import React, { useEffect, useState, useRef, useContext } from 'react'
import { Auth } from 'aws-amplify'
import { isAdminOnly, isMultipleOrganization } from '../../../Utils/ServerUtil'
import { IMSContext } from '../list/IMSformCategtorySelect'
import { IsTabClickedContext } from './AssetPropertiesDrawer.js'
import get from 'lodash/get'
import { saveIMSForms } from '../../../Utils/SubmitUtil.js'
import { IMSFormContext } from './IMSFormContext.js'
import ImsFormFields from './ImsFormFields'
import { CognitoUserSession } from 'amazon-cognito-identity-js'

interface IMSFormProps {
    selectedCategory: {
        forms: any
        id: number
        category: string
    }
    evalItem: {
        item_id: string
        jobcode?: string
        item?: {
            ims_forms_data?: any
            initial_eval?: string
            capture_estimate: number
            sticker_id?: string
        }
    }
    evaluation?: any
}

interface ImsFormsData {
    id: number
    nomination: string
    jobcode: string
    groups: Group[]
    category?: string
    opportunity_id?: string
}

interface Value {
    id: any
    order: any
    value: any
    component_id: any
    component_response: any
}

interface ComponentResponse {
    value: string
    photo: string
}

interface Component {
    id: number
    label: string
    label_display: string
    limit: number
    order: number
    values: {
        id: number
        order: number
        value: string
        component_id: number
        component_response: string // Adjust as necessary
    }[]
    prefix: string
    suffix: string
    datatype: string
    group_id: number
    required: string
    max_value: number
    min_value: number
    char_count: number
    max_length: number
    min_length: number
    helper_text: string
    control_type: string
    photo_capture: number
    component_form: any[]
    barcode_scanning: number
    component_response: string | ComponentResponse // Allowing for both types
    is_auto_populated: boolean
}

interface Group {
    id: number
    title: string
    components: Component[]
}

interface Category {
    id: number
    title: string
    groups: Group[]
}

const IMSForm: React.FC<IMSFormProps> = ({
    selectedCategory,
    evalItem,
    evaluation,
}) => {
    const [formData, setFormData] = useState<any>({})
    const [error, setError] = useState<string | null>(null)
    const [loading, setLoading] = useState<boolean>(false)
    const [nominationValue, setNominationValue] = useState<string>('0')
    const [fieldValues, setFieldValues] = useState<{ [key: string]: any }>({})
    const { imsForm, setImsForm } = useContext(IMSFormContext)
    const [newIMSData, setNewIMSData] = useState<any>(null)
    const [isCategoryChanged, setIsCategoryChanged] = useState<boolean>(false)
    const [opportunityID, setOpportunityID] = useState<string | null>(null)
    const { jobCodeData, setJobCodeData } = useContext(IMSContext)
    const { estimatedHammer, setEstimatedHammer } = useContext(IMSContext)
    const { loadForm, saveForm, setSaveForm } = useContext(IsTabClickedContext)
    const [successAlert, setSuccessAlert] = useState<boolean>(false)

    const isMounted = useRef<boolean>(false)

    const handleSaveSuccess = () => {
        setSuccessAlert(true)
    }

    const handleSaveSuccessClose = () => {
        setSuccessAlert(false)
    }

    const handleNominationChange = (
        event: React.ChangeEvent<{ value: unknown }>
    ) => {
        const value = event.target.value
        if (typeof value === 'number') {
            console.log(value) 
            setNominationValue(value.toString()) 
        }
    }

    useEffect(() => {
        if (selectedCategory && selectedCategory.id) {
            setIsCategoryChanged(true)
        }
    }, [selectedCategory])

    useEffect(() => {
        if (imsForm?.item?.ims_forms_data?.nomination) {
            const nominationStringValue =
                imsForm?.item?.ims_forms_data.nomination.toString()
            setNominationValue(nominationStringValue)
        } else if (evalItem?.item?.ims_forms_data?.nomination) {
            const nominationStringValue =
                evalItem.item.ims_forms_data.nomination.toString()
            setNominationValue(nominationStringValue)
        }
    }, [imsForm, evalItem])


    // const handleFieldChange = (
    //     groupId: any,
    //     componentId: string | number,
    //     newValue: string | boolean,
    //     datatype: any,
    //     photoCapture = 0
    //   ) => {
    //     let formattedValue;
    
    //     if (photoCapture === 1) {
    //       // If the component is a photo capture, store both value and photo fields
    //       formattedValue = { value: newValue, photo: "" };
    //     } else {
    //       // Handle other datatypes
    //       switch (datatype) {
    //         case "boolean":
    //           formattedValue = newValue === true || newValue === "true";
    //           break;
    //         case "number":
    //           formattedValue = Number(newValue);
    //           break;
    //         default:
    //           formattedValue = newValue;
    //       }
    //     }
    //     // Set the formattedValue
    //     const updatedFieldValues = { ...fieldValues };
    //     updatedFieldValues[componentId] = formattedValue;
    //     setFieldValues(updatedFieldValues);
    //   };

    const handleFieldChange = (
        groupId: any,
        componentId: string | number,
        newValue: string | boolean,
        datatype: any,
        photoCapture = 0
    ) => {
        const currentValue = fieldValues[componentId];
        const isEmptyValue = 
            newValue === '' || 
            newValue === null || 
            newValue === undefined || 
            (typeof newValue === 'string' && newValue.trim() === '');
    
        let formattedValue: any;
    
        if (datatype === 'boolean') {
            formattedValue = {
                value: newValue === true || newValue === 'true',
                is_auto_populated: false,
                was_auto_populated: currentValue?.is_auto_populated || false,
                was_cleared: isEmptyValue
            };
        } else if (photoCapture === 1) {
            formattedValue = {
                value: newValue,
                photo: currentValue?.photo || '',
                is_auto_populated: false,
                was_auto_populated: currentValue?.is_auto_populated || false,
                was_cleared: isEmptyValue
            };
        } else {
            switch (datatype) {
                case 'number':
                    formattedValue = {
                        value: Number(newValue),
                        is_auto_populated: false,
                        was_auto_populated: currentValue?.is_auto_populated || false,
                        was_cleared: isEmptyValue
                    };
                    break;
                default:
                    formattedValue = {
                        value: newValue,
                        is_auto_populated: false,
                        was_auto_populated: currentValue?.is_auto_populated || false,
                        was_cleared: isEmptyValue
                    };
            }
        }
    
        // Update field values
        const updatedFieldValues = { ...fieldValues };
        updatedFieldValues[componentId] = formattedValue;
        setFieldValues(updatedFieldValues);
    };


    console.log('-------------EVAL DATA TEST---------------', evaluation)


    useEffect(() => {
        const initialValues: { [key: string]: boolean | string } = {}
        const populateInitialValues = (groups: any[]) => {
            groups.forEach((group: { components: any[] }) => {
                group.components.forEach(
                    (component: {
                        control_type: string
                        id: string | number
                        component_response: string | boolean
                        values: any[]
                    }) => {
                        if (component.control_type === 'checkbox') {
                            // Ensure it is a boolean (true/false)
                            initialValues[component.id.toString()] =
                                component.component_response === true ||
                                component.component_response === 'true'
                        } else if (component.control_type === 'checkboxgroup') {
                            // Handle checkbox groups
                            component.values.forEach(
                                (value: {
                                    id: string | number
                                    component_response: string | boolean
                                }) => {
                                    initialValues[value.id.toString()] =
                                        value.component_response === true ||
                                        value.component_response === 'true'
                                }
                            )
                        } else {
                            // Handle other control types
                            initialValues[component.id.toString()] =
                                component.component_response || ''
                        }
                    }
                )
            })
        }

        if (imsForm?.item?.ims_forms_data?.groups) {
            populateInitialValues(imsForm.item.ims_forms_data.groups)
        } else {
            populateInitialValues(ims_forms_data.groups)
        }

        setFieldValues(initialValues)
    }, [imsForm])

    const fetchIMSData = async () => {
        let jwtToken
        let tokenRefreshError = false

        try {
            const session: CognitoUserSession = await Auth.currentSession()
            jwtToken = session.getIdToken().getJwtToken()
        } catch (e) {
            try {
                const session: CognitoUserSession = await Auth.currentSession()
                jwtToken = session.getIdToken().getJwtToken()
            } catch (e) {
                //console.log("Error refreshing token", e);
                tokenRefreshError = true
                // Allow to go through without jwtToken for server requests not requiring an authenticated user.
            }
        }

        const headers: { [key: string]: string | undefined } = {
            authorization: jwtToken,
            accept: 'application/json',
        }
        // This adds a header for selected organization_ID or _Key which is stored in local storage
        if (isAdminOnly() || isMultipleOrganization()) {
            const organization =
                localStorage['hw.' + window.btoa('organization')]
            if (organization && window.atob(organization)) {
                headers['HW-Organization'] = window.atob(organization)
            }
        }

        const filteredHeaders: { [key: string]: string } = Object.fromEntries(
            Object.entries(headers)
                .filter(([_, value]) => value !== undefined)
                .map(([key, value]) => [key, value as string]) // Ensure values are treated as strings
        )

        const options: RequestInit = {
            method: 'GET',
            headers: filteredHeaders,
        }

        const url = `${process.env.REACT_APP_API_ENDPOINT}/items/${evalItem.item_id}`

        // console.log("url====> ",url);
        try {
            const response = await fetch(url, options)
            if (response.ok) {
                const itemData = await response.json()
                console.log('RETURNED ITEM ========>', itemData)

                setImsForm(itemData)
            } else {
                // Handle the error if the response is not ok
                console.log('Request failed with status:', response.status)
                return null
            }
        } catch (error) {
            // Handle any network or other errors
            console.error('Error:', error)
        }
    }

    useEffect(() => {
        fetchIMSData()
    }, [])

    useEffect(() => {
        const opportunityDataFetch = async () => {
            let jwtToken
            let tokenRefreshError = false

            try {
                const session: CognitoUserSession = await Auth.currentSession()
                jwtToken = session.getIdToken().getJwtToken()
            } catch (e) {
                try {
                    const session: CognitoUserSession =
                        await Auth.currentSession()
                    jwtToken = session.getIdToken().getJwtToken()
                } catch (e) {
                    //console.log("Error refreshing token", e);
                    tokenRefreshError = true
                    // Allow to go through without jwtToken for server requests not requiring an authenticated user.
                }
            }
            const headers: { [key: string]: string | undefined } = {
                authorization: jwtToken,
                accept: 'application/json',
            }
            // This adds a header for selected organization_ID or _Key which is stored in local storage
            if (isAdminOnly() || isMultipleOrganization()) {
                const organization =
                    localStorage['hw.' + window.btoa('organization')]
                if (organization && window.atob(organization)) {
                    headers['HW-Organization'] = window.atob(organization)
                }
            }

            const filteredHeaders: { [key: string]: string } =
                Object.fromEntries(
                    Object.entries(headers)
                        .filter(([_, value]) => value !== undefined)
                        .map(([key, value]) => [key, value as string]) // Ensure values are treated as strings
                )

            const options: RequestInit = {
                method: 'GET',
                headers: filteredHeaders,
            }

            const url = `${process.env.REACT_APP_API_ENDPOINT}/evaluations/${evalItem?.item?.initial_eval}`

            // console.log("url====> ",url);
            try {
                const response = await fetch(url, options)
                if (response.ok) {
                    const data = await response.json()

                    setOpportunityID(data?.evaluation?.opportunity_id)
                } else {
                    // Handle the error if the response is not ok
                    console.log('Request failed with status:', response.status)
                    return null
                }
            } catch (error) {
                // Handle any network or other errors
                console.error('Error:', error)
            }
        }

        opportunityDataFetch()
        //handleSaveSuccess()
    }, [])

    useEffect(() => {
        if (imsForm && imsForm?.jobcode) {
            setJobCodeData(imsForm?.jobcode)
        } else if (evalItem && evalItem?.jobcode) {
            setJobCodeData(evalItem?.jobcode)
        }
    }, [imsForm, evalItem])

    useEffect(() => {
        if (imsForm?.item?.capture_estimate) {
            setEstimatedHammer(imsForm?.item?.capture_estimate)
        } else if (evalItem && evalItem?.item?.capture_estimate) {
            setEstimatedHammer(evalItem?.item?.capture_estimate)
        }
    }, [imsForm, evalItem])

    useEffect(() => {
        const fetchData = async () => {
            if (!selectedCategory || !selectedCategory.id) return

            try {
                setLoading(true)
                const response = await fetch(
                    `https://ims-api.purplewave.com/v1/forms/category/${selectedCategory.id}`
                )
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`)
                }
                const data = await response.json()
                console.log('Form data received:', data)
                setFormData(data)

                setError(null)
            } catch (error: unknown) {
                if (error instanceof Error) {
                    console.error('Error fetching form data:', error)
                    setError(error.message) // Access the error message
                } else {
                    console.error('Unknown error occurred', error)
                    setError('An unknown error occurred.')
                }
            } finally {
                setLoading(false)
            }
        }

        fetchData()
    }, [selectedCategory, evalItem])

    const newData = { ...formData }

    // Check if Categories array exists and is an array
    if (formData.Categories && Array.isArray(formData.Categories)) {
        // Iterate through categories to sort components
        newData.Categories.forEach(
            (category: { Components: any[]; Groups: any[] }) => {
                // Sort components by id
                category.Components.sort(
                    (a: { id: number }, b: { id: number }) => a.id - b.id
                )

                if (category.Groups && Array.isArray(category.Groups)) {
                    category.Groups.forEach((group: { Components: any[] }) => {
                        // Sort components by order_id
                        group.Components.sort(
                            (a: { order: number }, b: { order: number }) =>
                                a.order - b.order
                        )

                        // Filter components based on the category components
                        if (
                            group.Components &&
                            Array.isArray(group.Components)
                        ) {
                            group.Components = group.Components.filter(
                                (component: { group_id: any; id: any }) => {
                                    return category.Components.some(
                                        (categoryComponent: {
                                            group_id: any
                                            id: any
                                        }) => {
                                            return (
                                                component.group_id ===
                                                    categoryComponent.group_id &&
                                                component.id ===
                                                    categoryComponent.id
                                            )
                                        }
                                    )
                                }
                            )
                        }
                    })
                }
            }
        )
    } else {
        console.error('Categories array is missing or not an array.')
    }

    // Initialize the structure variable
    // const ims_forms_data = {
    //   id: 0,
    //   groups: [],
    //   category: selectedCategory?.category,
    //   nomination: "0",
    //   opportunity_id: opportunityID != "" ? opportunityID : "",
    //   jobcode: "",
    // };

    const ims_forms_data: ImsFormsData = {
        id: 0,
        groups: [],
        category: selectedCategory?.category || '', // Provide a default if undefined
        nomination: '0',
        opportunity_id: opportunityID ? opportunityID : undefined, // Ensure it's a string
        jobcode: '', // Default value
    }

    // Define the types for the component response
    type ComponentResponse = string | { value: string; photo: string }

    // Initialize the component response
    let componentResponse: ComponentResponse

    // Update the structure based on the received form data
    if (formData.id) {
        ims_forms_data.id = selectedCategory?.id || 0 
        ims_forms_data.nomination = nominationValue
        ims_forms_data.jobcode = jobCodeData

        formData.Categories.forEach(
            (category: { id: any; category: any; Groups: any[] }) => {
                const categoryObj: Category = {
                    id: category.id || 0,
                    title: category.category || '',
                    groups: [],
                }

                category.Groups.forEach(
                    (group: { id: any; title: any; Components: any[] }) => {
                        const groupObj: Group = {
                            id: group.id || 0,
                            title: group.title || '',
                            components:
                                group.Components?.map(
                                    (component: {
                                        component_response:
                                            | string
                                            | { value: string; photo: string } // Adjust the type if necessary
                                        photo_capture: number
                                        id: any
                                        label: any
                                        label_display: any
                                        limit: any
                                        order: any
                                        Values: any[]
                                        prefix: any
                                        suffix: any
                                        datatype: any
                                        group_id: any
                                        required: any
                                        max_value: any
                                        min_value: any
                                        char_count: any
                                        max_length: any
                                        min_length: any
                                        helper_text: any
                                        control_type: any
                                        component_form: any
                                        barcode_scanning: any
                                        is_auto_populated: boolean
                                    }) => {
                                        // Define the response based on photo_capture
                                        let componentResponse:
                                            | ComponentResponse
                                            | string
                                        if (component.photo_capture === 1) {
                                            componentResponse = {
                                                value:
                                                    (
                                                        component.component_response as {
                                                            value: string
                                                        }
                                                    )?.value || '',
                                                photo:
                                                    (
                                                        component.component_response as {
                                                            photo: string
                                                        }
                                                    )?.photo || '',
                                            }
                                        } else {
                                            componentResponse = '' // Ensure it's a string when photo_capture is not 1
                                        }

                                        return {
                                            id: component.id || 0,
                                            label: component.label || '',
                                            label_display:
                                                component.label_display || '',
                                            limit: component.limit || 0,
                                            order: component.order || 0,
                                            values:
                                                component.Values?.map(
                                                    (value: {
                                                        id: any
                                                        order: any
                                                        value: any
                                                        component_id: any
                                                        component_response: any
                                                    }) => ({
                                                        id: value.id || 0,
                                                        order: value.order || 0,
                                                        value:
                                                            value.value || '',
                                                        component_id:
                                                            value.component_id ||
                                                            0,
                                                        component_response:
                                                            value.component_response ||
                                                            '',
                                                    })
                                                ) || [],
                                            prefix: component.prefix || '',
                                            suffix: component.suffix || '',
                                            datatype: component.datatype || '',
                                            group_id: component.group_id || 0,
                                            required: component.required || '',
                                            max_value: component.max_value || 0,
                                            min_value: component.min_value || 0,
                                            char_count:
                                                component.char_count || 0,
                                            max_length:
                                                component.max_length || 0,
                                            min_length:
                                                component.min_length || 0,
                                            helper_text:
                                                component.helper_text || '',
                                            control_type:
                                                component.control_type || '',
                                            photo_capture:
                                                component.photo_capture || 0,
                                            component_form:
                                                component.component_form || [],
                                            barcode_scanning:
                                                component.barcode_scanning || 0,
                                            component_response:
                                                componentResponse, 
                                            is_auto_populated: component.is_auto_populated || false,
                                        }
                                    }
                                ) || [],
                        }

                        categoryObj.groups.push(groupObj) // Add each group directly to the groups array under this category
                    }
                )

                ims_forms_data.groups.push(...categoryObj.groups) // Add all groups from this category to the top-level groups array
            }
        )
    }

    // Create a ref for storing the previous evalItem
    const prevEvalItemRef = useRef(null)

    // Update prevEvalItemRef when evalItem changes
    useEffect(() => {
        prevEvalItemRef.current =
            imsForm?.item?.ims_forms_data &&
            Object.keys(imsForm?.item?.ims_forms_data).length > 0
                ? imsForm
                : evalItem
    }, [evalItem, imsForm])

    // Access the previous value of evalItem using prevEvalItemRef.current
    const prevEvalItem = prevEvalItemRef.current


    const handleIMSFormSave = async () => {
        let updatedEvalItem
    
        if (saveForm) {
            updatedEvalItem = { ...imsForm?.item }
    
            if (formData.id) {
                // Update ims_forms_data groups and manage is_auto_populated based on value changes
                ims_forms_data.groups.forEach((group) => {
                    group.components.forEach((component: any) => {
                        const componentId = String(component.id);
                        const fieldValue = fieldValues[componentId];
    
                        // Get original component and its value from imsForm
                        const originalComponent = imsForm?.item?.ims_forms_data?.groups
                            ?.flatMap((g: { components: any }) => g.components)
                            ?.find((c: { id: any }) => String(c.id) === componentId);
    
                        const originalValue = originalComponent?.component_response?.value !== undefined 
                            ? originalComponent.component_response.value 
                            : originalComponent?.component_response;
    
                        // Get current value
                        const currentValue = fieldValue?.value !== undefined ? fieldValue.value : fieldValue;
    
                        // Compare values and update is_auto_populated
                        if (originalComponent?.is_auto_populated) {
                            // Only set to false if values are different
                            component.is_auto_populated = originalValue === currentValue;
                        }
    
                        // Handle component response
                        if (fieldValue !== undefined) {
                            const valueToUse = fieldValue?.value !== undefined ? fieldValue.value : fieldValue;
                            component.component_response = convertToDataType(valueToUse, component.datatype);
                        } else {
                            component.values?.forEach((value: any) => {
                                const valueFieldValue = fieldValues[value.id];
                                if (valueFieldValue !== undefined) {
                                    const valueToUse = valueFieldValue?.value !== undefined 
                                        ? valueFieldValue.value 
                                        : valueFieldValue;
                                    value.component_response = convertToDataType(valueToUse, component.datatype);
                                }
                            });
                        }
                    });
                });
    
                // Similarly update formData
                formData.Categories.forEach((category: { Groups: any[] }) => {
                    category.Groups.forEach((group: { Components: any[] }) => {
                        group.Components.forEach((component: any) => {
                            const componentId = String(component.id);
                            const fieldValue = fieldValues[componentId];
    
                            // Get original component and its value
                            const originalComponent = imsForm?.item?.ims_forms_data?.groups
                                ?.flatMap((g: { components: any }) => g.components)
                                ?.find((c: { id: any }) => String(c.id) === componentId);
    
                            const originalValue = originalComponent?.component_response?.value !== undefined 
                                ? originalComponent.component_response.value 
                                : originalComponent?.component_response;
    
                            // Get current value
                            const currentValue = fieldValue?.value !== undefined ? fieldValue.value : fieldValue;
    
                            // Compare values and update is_auto_populated
                            if (originalComponent?.is_auto_populated) {
                                // Only set to false if values are different
                                component.is_auto_populated = originalValue === currentValue;
                            }
    
                            // Handle component response
                            if (fieldValue !== undefined) {
                                const valueToUse = fieldValue?.value !== undefined ? fieldValue.value : fieldValue;
                                component.component_response = convertToDataType(valueToUse, component.datatype);
                            } else {
                                component.Values?.forEach((value: any) => {
                                    if (fieldValues[value.id] !== undefined) {
                                        value.component_response = convertToDataType(
                                            fieldValues[value.id],
                                            component.datatype
                                        );
                                    }
                                });
                            }
                        });
                    });
                });
    
                updatedEvalItem.ims_forms_data = ims_forms_data;
                updatedEvalItem.jobcode = ims_forms_data.jobcode;
                updatedEvalItem.capture_estimate = estimatedHammer;
                updatedEvalItem.sticker_id = imsForm.item.sticker_id;
            } else {
                updatedEvalItem.ims_forms_data = {};
            }
    
            const itemId = get(evalItem, 'item_id', get(evalItem, 'PK'));
    
            if (!itemId) {
                throw new Error('Item ID is missing');
            }
    
            try {
                const result = await saveIMSForms(
                    itemId,
                    updatedEvalItem,
                    prevEvalItem,
                    setSaveForm,
                );
                handleSaveSuccess()
                return result;
                
            } catch (e) {
                console.log('Error:', e);
            }
        }
    };

  


    const convertToDataType = (value: string | boolean, datatype: any) => {
        switch (datatype) {
            case 'boolean':
                return value === true || value === 'true' // Handle string 'true' or boolean true
            case 'number':
                return Number(value) // Ensure it converts to a number
            default:
                return value // Default case: convert to string
        }
    }

    useEffect(() => {
        if (!isMounted.current) {
            isMounted.current = true
            return
        }

        if (saveForm) {
            handleIMSFormSave()
            setSaveForm(false)
            //window.location.reload();
        }
    }, [saveForm])

    useEffect(() => {
        ///console.log("Test IMSFORM===================>>>>>>>>>>>:", imsForm);
        const new_ims_forms_data = { ...imsForm?.item?.ims_forms_data }

        // Iterate over each group and their components
        new_ims_forms_data.groups = new_ims_forms_data?.groups?.map(
            (group: { components: any[] }) => {
                group.components = group.components.map(
                    (component: {
                        control_type: string
                        photo_capture: number
                        component_response: { value: any; photo: any }
                        photo: any
                    }) => {
                        // Check if the component meets the criteria
                        if (
                            component.control_type === 'subheading' &&
                            component.photo_capture === 1
                        ) {
                            // Check if component_response is an object
                            if (
                                component.component_response &&
                                typeof component.component_response === 'object'
                            ) {
                                // Copy the photo key from component_response to the component itself
                                component.photo =
                                    component.component_response.photo
                            }
                        }
                        return component
                    }
                )
                return group
            }
        )

        setNewIMSData(new_ims_forms_data)
    }, [imsForm, loadForm])

    return (
        <>
            <ImsFormFields
                loadForm={loadForm}
                selectedCategory={selectedCategory}
                successAlert={successAlert}
                handleSaveSuccessClose={handleSaveSuccessClose}
                imsForm={imsForm}
                loading={loading}
                error={error}
                titleStyle={{
                    fontSize: '16px',
                    marginBottom: '8px',
                    color: '#333',
                }}
                internaUseValues={{ marginTop: '4px' }}
                internaUseValues2={{ marginBottom: '20px' }}
                handleFieldChange={handleFieldChange}
                fieldValues={fieldValues}
                nominationValue={nominationValue}
                handleNominationChange={handleNominationChange}
                newIMSData={newIMSData}
                ims_forms_data={ims_forms_data}
                evaluation={evaluation}
                handleIMSFormSave={handleIMSFormSave}
                setSaveForm={setSaveForm}
                setImsForm={setImsForm}
                prevEvalItem={prevEvalItem}
                handleSaveSuccess={handleSaveSuccess}
                setFieldValues={setFieldValues}
            />
        </>
    )
}

export default IMSForm
