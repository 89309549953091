import { jssPreset, StylesProvider } from '@material-ui/styles'
import { create } from 'jss'
import jssCompose from 'jss-compose'
import React, { Component } from 'react'
import theme from './slick-ui/build/material/theme'
import UpdateAvailableDialog from './components/UpdateAvailableDialog'
import { GET_VERSION } from './Constants'
import BasicApp, { getCurrentLocale } from './fhg/components/app/BasicApp'
import { isLocalhost } from './fhg/utils/Utils'
import { registerServiceWorkerPromise } from './index'
import Main from './Main'
import { requestForServer } from './Utils/ServerUtil'

import '@formatjs/intl-pluralrules/polyfill'
import '@formatjs/intl-pluralrules/dist/locale-data/en'

// Configure JSS
const jss = create({ plugins: [...jssPreset().plugins, jssCompose()] })

//Initialize the default application theme from Slick-ui.
let overrideTheme = theme

// Initialize the locale to the browser's current locale.
const locale = getCurrentLocale()
let messages
try {
    const allMessages = require(`./messages/${locale}`)
    if (process.env.REACT_APP_ENVIRONMENT_NAME === 'purplewave.net') {
        const otherMessages = require(`./messages/purple_wave/${locale}`)
        messages = { ...allMessages, ...otherMessages }
    } else {
        messages = allMessages
    }
} catch (e) {
    const allMessages = require('./messages/en-US')
    if (process.env.REACT_APP_ENVIRONMENT_NAME === 'purplewave.net') {
        const otherMessages = require('./messages/purple_wave/en-US')
        messages = { ...allMessages, ...otherMessages }
    } else {
        messages = allMessages
    }
}

// use 2 minutes on dev3, MAX number on local host so it never executes, and 5 minutes on other hosts.
const MAX_INTERVAL =
    window.location.hostname === 'dev3.heavyworth.com'
        ? 2 * 61 * 1000
        : isLocalhost
          ? Number.MAX_SAFE_INTEGER - 1
          : 5 * 60 * 1000

/**
 * The main application entry point. Sets up the localization message file and default theme.
 */
class App extends Component {
    interval

    constructor(props) {
        super(props)

        this.state = {
            updateAvailable: undefined,
        }
        window.Intercom('boot', {
            app_id: 'fl2igt9h',
        })

        if (!isLocalhost) {
            this.interval = setInterval(() => {
                this.checkVersions()
                if (navigator.serviceWorker) {
                    navigator.serviceWorker
                        .getRegistrations()
                        .then((registrations) => {
                            for (const registration of registrations) {
                                registration.update()
                            }
                        })
                }
            }, MAX_INTERVAL)

            registerServiceWorkerPromise.then((result) => {
                console.log(
                    `Service worker is updating. Current version - ${process.env.REACT_APP_VERSION}.`
                )
                if (this.state.updateAvailable === undefined) {
                    this.checkVersions()
                }
            })
        }
    }

    checkVersions = async () => {
        if (!isLocalhost) {
            const result = await requestForServer(
                GET_VERSION.format({ domain: window.location.hostname }),
                'get'
            )
            //No results should be a local build.  Should have a version for a released version.
            if (result && result.data && result.data.length >= 1) {
                if (process.env.REACT_APP_VERSION !== result.data[0].version) {
                    clearInterval(this.interval)
                    this.setState({ updateAvailable: result.data[0].version })
                    console.log(
                        `Versions do not match. Current version - ${process.env.REACT_APP_VERSION}. New Version - ${result.data[0].version}`
                    )
                }
            }
        }
    }

    render() {
        const { updateAvailable } = this.state

        return (
            <StylesProvider jss={jss}>
                <BasicApp theme={overrideTheme} messages={messages}>
                    <div className="App">
                        {updateAvailable && (
                            <UpdateAvailableDialog
                                version={updateAvailable}
                                open={true}
                            />
                        )}
                        <Main />
                    </div>
                </BasicApp>
            </StylesProvider>
        )
    }
}

export default App
