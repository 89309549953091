import { Auth } from 'aws-amplify'
import {
    isAdminOnly,
    isMultipleOrganization,
} from '../../../../Utils/ServerUtil'

interface FetchDataParams {
    selectedEmployees: string[]
    filter: string[]
    pageNumber: number
    rowsPerPage: number
}

const LAST_URL_KEY = 'last_fetch_url'

const fetchData = async ({
    selectedEmployees,
    filter,
    pageNumber,
    rowsPerPage,
}: FetchDataParams): Promise<any> => {
    // Try to restore from cached params if they exist
    let useCache = false
    
    if (!selectedEmployees?.length && !filter?.length) {
        // Check if we have cached parameters
        const cachedUrl = localStorage.getItem(LAST_URL_KEY)
        if (cachedUrl) {
            try {
                const url = new URL(cachedUrl)
                const params = new URLSearchParams(url.search)
                
                // Extract parameters from the cached URL
                const cachedEmployees = params.get('by_user')?.split(',') || []
                const cachedFilter = params.get('status')?.split(',') || []
                const cachedPage = parseInt(params.get('page') || '1') - 1
                const cachedSize = parseInt(params.get('size') || '10')
                
                // Use cached parameters if they exist
                if (cachedEmployees.length || cachedFilter.length) {
                    selectedEmployees = cachedEmployees
                    filter = cachedFilter
                    pageNumber = cachedPage
                    rowsPerPage = cachedSize
                    useCache = true
                    
                    console.log('Restored parameters from cache:', {
                        selectedEmployees,
                        filter,
                        pageNumber,
                        rowsPerPage
                    })
                }
            } catch (error) {
                console.error('Error parsing cached URL:', error)
            }
        }
    }

    let jwtToken: string | undefined
    // let tokenRefreshError = false;

    try {
        const session: any = await Auth.currentSession()
        jwtToken = session.idToken.jwtToken
    } catch (e) {
        try {
            const session: any = await Auth.currentSession()
            jwtToken = session.idToken.jwtToken
        } catch (e) {
            // tokenRefreshError = true;
        }
    }

    const headers: Record<string, string> = {
        authorization: jwtToken || '',
        accept: 'application/json',
    }

    if (isAdminOnly() || isMultipleOrganization()) {
        const organization = localStorage.getItem(
            'hw.' + window.btoa('organization')
        )
        if (organization && window.atob(organization)) {
            headers['HW-Organization'] = window.atob(organization)
        }
    }

    const options: RequestInit = {
        method: 'GET',
        headers: headers,
    }

    const employeesSelected = selectedEmployees?.join(',')
    const mainStatus = filter.join(',')

    const url = `${process.env.REACT_APP_API_ENDPOINT_V3}/packages?page=${
        pageNumber + 1
    }&size=${rowsPerPage}&by_user=${employeesSelected}&status=${mainStatus}`

    // Save the current URL to localStorage for future use
    if (employeesSelected || mainStatus) {
        localStorage.setItem(LAST_URL_KEY, url)
    }

    console.log(
        '=========== Checking FetchData ============',
        useCache ? '(restored from cache)' : '',
        'Employees:', employeesSelected,
        'Status:', mainStatus
    )

    try {
        const response = await fetch(url, options)
        if (response.ok) {
            const evalData = await response.json()
            //console.log("TEST FROM API==============>>>>>>>>>>>>>>",evalData);
            return evalData
        } else {
            console.log('Request failed with status:', response.status)
            return null
        }
    } catch (error) {
        console.error('Error:', error)
        return null
    }
}

export default fetchData