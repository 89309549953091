import { IconButton } from '@material-ui/core'
import MuiLink from '@material-ui/core/Link'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import makeStyles from '@material-ui/core/styles/makeStyles'
import MenuIcon from '@material-ui/icons/Menu'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useHistory, Link } from 'react-router-dom'
//import {SUPPORT_LINK} from '../../../Constants';
import Typography from '../../../fhg/components/Typography'
import { handleLogOut } from './UserMenu'

const useStyles = makeStyles((theme) => ({
    version: {
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(0.5),
        fontSize: `${theme.size.font['item-title']}rem`,
        fontStyle: 'italic',
        textAlign: 'right',
    },
}))

export default function NavigationMenu({ admin, onStateChange }) {
    const history = useHistory()
    const [anchorEl, setAnchorEl] = useState()
    const classes = useStyles()

    const handleMenuClick = (event) => {
        event.preventDefault()
        event.stopPropagation()
        setAnchorEl(event.currentTarget)
    }

    const handleCloseMenu = () => {
        setAnchorEl(null)
    }

    const handleLogOutClick = () => {
        handleCloseMenu()
        handleLogOut(history, onStateChange)
    }

    return (
        <>
            <IconButton style={{ marginLeft: 'auto' }}>
                <MenuIcon htmlColor={'white'} onClick={handleMenuClick} />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={!!anchorEl}
                onClose={handleCloseMenu}
            >
                <MenuItem
                    component={Link}
                    to="/evaluations/all/open/"
                    onClick={handleCloseMenu}
                >
                    <Typography
                        color="inherit"
                        variant="body1"
                        id={'evalDashboard.heading'}
                    />
                </MenuItem>
                <MenuItem
                    component={MuiLink}
                    target="_blank"
                    to={'/support'}
                    onClick={handleCloseMenu}
                >
                    <Typography
                        color="inherit"
                        variant="body1"
                        id={'nav.support.link'}
                    />
                </MenuItem>
                {admin && (
                    <MenuItem
                        component={Link}
                        to="/admin"
                        onClick={handleCloseMenu}
                    >
                        <Typography
                            color="inherit"
                            variant="body1"
                            id={'nav.admin.link'}
                        />
                    </MenuItem>
                )}
                <MenuItem
                    value="logout"
                    component={Link}
                    onClick={handleLogOutClick}
                >
                    <Typography
                        color="inherit"
                        variant="body1"
                        id="emailSubscribe.signOut.button"
                    />
                </MenuItem>
                <Typography className={classes.version}>
                    v{process.env.REACT_APP_VERSION}
                </Typography>
            </Menu>
        </>
    )
}

NavigationMenu.propTypes = {
    admin: PropTypes.any,
    onStateChange: PropTypes.func,
}
