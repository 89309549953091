import { Checkbox } from 'aws-amplify-react'
import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import classNames from 'classnames'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'

const useStyles = makeStyles((theme) => ({
    statusCheckbox: {
        color: '#4f2682 !important',
    },
    menuText: {
        display: 'inline-block',
        marginLeft: theme.spacing(1),
    },
    dot: {
        height: 8,
        width: 8,
        borderRadius: '50%',
        display: 'inline-block',
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        verticalAlign: 'middle',
    },
    capture_in_progress: {
        backgroundColor: '#F8CE1C',
    },
    capture_complete: {
        backgroundColor: '#7ED321',
    },
    sent_to_ims: {
        backgroundColor: '#4A90E2',
    },
    final_review: {
        backgroundColor: '#742682',
    },
    finalized: {
        backgroundColor: '#406370',
    },
    archived: {
        backgroundColor: '#D8D8D8',
    },
}))

interface Props {
    key: string
    value: string
    onClick: (val: string) => void
    checked: boolean
    status?: string
    disabled?: boolean
}
const FTCheckbox: React.FC<Props> = ({
    key,
    value,
    checked,
    status,
    disabled,
    onClick,
}) => {
    const classes: any = useStyles()
    return (
        <MenuItem key={key} onClick={() => onClick(value)} disabled={disabled}>
            <Checkbox
                edge="start"
                checked={checked}
                className={classes.statusCheckbox}
            />
            {status && (
                <span className={classNames(classes.dot, classes[status])} />
            )}
            <Typography
                className={classes.menuText}
                color="inherit"
                variant="body1"
                id={value}
            >
                {value}
            </Typography>
        </MenuItem>
    )
}

export { FTCheckbox }
