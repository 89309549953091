const eventCapture = {
    SAVE_NEW_USER: 'Save New User',
    SAVE_NEW_ORGANIZATION: 'Save New Organization',
    NEW_EVALUATION: 'New Evaluation',
    CURRENT_SAVE_EVALUATION: 'Save Evaluation',
    SEARCHES: 'Searches',
    FILTER_CHANGED: 'Filter Changed',
    EQUIPMENT_LIST_VIEW: 'Equipment List View',
    EDITED_PHOTO_VIEW: 'Edit Image View',
    EDIT_ITEM: 'Edit Item',
    NEXT_ITEM: 'Next Item',
    PREVIOUS_ITEM: 'Previous Item',
    DELETE_IMAGE: 'Image Delete',
    NEW_ITEM: 'New Item',
    ENTERED_PHOTO_VIEW: 'Entered Image View',
    SAVE_NEW_ITEM: 'Save New Item',
    NOTIFICATION_OPEN: 'Notification Open',
    PASSWORD_RESET: 'Password Reset',
    FAILED_LOGIN: 'Login Failed',
    SAVE_NEW_ITEM: 'Save New Item',
    ROTATE_IMAGE: 'Rotate Image',
    PDF_DOWNLOAD: 'PDF Download',
    EXPORT_PHOTOS_TO_ZIP_FILE: 'Export Image to zip file',
    SUBMIT_CATALOG_TO_IMS: 'Submit Catalog to IMS',
    CSV_DOWNLOAD: 'CSV Download',
    GOOGLE_SHEETS_EXPORT: 'Google Sheet Export',
    PDF_DOWNLOAD: 'PDF Download',
    PDF_DOWNLOAD_PRIMARY_ONLY: 'PDF Download - Primary Only',
    PDF_DOWNLOAD_NO_VALUES: 'PDF Download - No Values',
    EVENT_SUBSCRIBE: 'subscribed',
    EVENT_UNSUBSCRIB: 'unsubscribed',
    EDIT_EVALUATION: 'Edit Evaluation',
    RESUBMIT_EVALUATION: 'Resubmit Catalog to IMS',
    RESUBMIT_ASSET: 'Resubmit Asset to IMS',
    RESUBMIT_IMAGE: 'Resubmit Image to IMS',
    FILTERED_BY_EMPLOYEES: 'Filtered by Employess / User',
    CUSTOMER_NUMBER_CLICKED:
        'Customer number clicked, redirected to Cliquidator',
    IMS_FORM_EDITED: 'IMS Form Edited',
}

export default eventCapture
