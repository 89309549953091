import { Popover } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import numberFormatter from 'number-formatter'
import PropTypes from 'prop-types'
import React from 'react'
import Typography from '../../../fhg/components/Typography'

/**
 * Popover component to display the Subpackage totals.
 *
 * Properties:
 * subpackageTotals
 * {
 *    [subpackage]: [total amount]
 *    'subpackage 1': 100,
 *    'subpackage 2': 200
 * }
 */
export default function SubpackagesPopover({
    open,
    classes,
    anchorEl,
    onClose,
    subpackageTotals,
    ...popoverProps
}) {
    return (
        <Popover
            open={open}
            classes={classes}
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            transformOrigin={{ vertical: 'top ', horizontal: 'center' }}
            onClose={onClose}
            {...popoverProps}
        >
            <div style={{ padding: 8 }}>
                <Grid
                    container
                    direction={'column'}
                    wrap={'nowrap'}
                    spacing={2}
                >
                    {Object.keys(subpackageTotals)
                        .sort()
                        .map((subpackageKey, index) => (
                            <Grid
                                key={index + 'gridSubpackages'}
                                container
                                item
                                direction={'row'}
                                justifyContent={'space-between'}
                                spacing={1}
                            >
                                <Grid item>
                                    <Typography
                                        className={`${classes.defaultTextStyle}`}
                                        noWrap
                                    >
                                        {subpackageKey}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography
                                        key={index + 'value'}
                                        className={`${classes.defaultTextStyle} right-align`}
                                        noWrap
                                    >
                                        {numberFormatter(
                                            '$#,###,##0.',
                                            subpackageTotals[subpackageKey]
                                        )}
                                    </Typography>
                                </Grid>
                            </Grid>
                        ))}
                </Grid>
            </div>
        </Popover>
    )
}

SubpackagesPopover.propTypes = {
    open: PropTypes.bool, // Indicates if the popover should be open.
    classes: PropTypes.object, // The style classes
    anchorEl: PropTypes.any, // The anchor element.
    onClose: PropTypes.func, // The callback when the popover is closed.
    subpackageTotals: PropTypes.object, // The object containing the subpackage totals.
    // For example: {'subpackage 1': 100, 'subpackage 2': 200}
}

SubpackagesPopover.defaultProps = {
    open: false,
    anchorEl: null,
    subpackageTotals: {},
}
