import { useState, useEffect } from 'react'
import { requestForServer } from './ServerUtil'

/**
 * Hook to handle requests to the server. The query function will be called to send the changes to the server.
 *
 * Reviewed: 8/5/2020
 *
 * @param responseType The type of data for the response.
 * @param api The api to use from the Amplify.configure API endpoints.
 * @return {[function(*=, *=, *=, *=): Promise<unknown>, {data: {}, loading: boolean, error: undefined}]}
 */
export default function useRequestForServer({
    uri,
    method = 'get',
    body,
    responseType = 'json',
    api,
    test,
}) {
    const [error, setError] = useState()
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState({})

    /**
     * Calls requestForServer with the parameters. The errors and progress are updated.
     * @type {function(*=, *=, *=, *=): Promise<unknown>} The promise resolves when the call is made successfully. The
     *    promise is rejected on errors in the results or from the call.
     */
    useEffect(() => {
        const queryFunction = async () => {
            setLoading(true)
            try {
                const result = await requestForServer(
                    uri,
                    method,
                    body,
                    responseType,
                    api
                )
                if (test) {
                    setData(test)
                } else {
                    if (result.error) {
                        setError(result.error)
                    } else {
                        setData(result.data)
                    }
                }
            } catch (error) {
                console.log('error', error)
                setError(error)
                setLoading(false)
            } finally {
                setLoading(false)
            }
        }

        queryFunction()
    }, [uri, method, body, responseType])

    return { loading, error, data }
}
