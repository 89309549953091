import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import React, { useEffect } from 'react'
import { FT_LOGO } from '../../Constants'
import Typography from '../../fhg/components/Typography'
import { SIGN_IN_STEP, EMAIL_STEP } from './MySignIn'
import { RouterWindow } from '../../Utils/IntercomUtil'

const useStyles = makeStyles((theme: any) => ({
    mainGrid: {
        height: 'calc(100% - 80px)',
        width: '100%',
        minHeight: 50,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.brand.gray, //'#5D788F',
        overflow: 'hidden',
    },
    contentStyle: {
        width: 436,
        padding: theme.spacing(4, 7),
        overflow: 'auto',
        backgroundColor: theme.palette.environment.light.level1.base,
        borderRadius: '8px',
    },
    buttonStyle: {
        color: theme.palette.common.white,
        textTransform: 'none',
        borderRadius: 4,
        backgroundColor: '#4f2682',
        marginTop: `${theme.spacing(0.5)}px !important`,
        padding: '14px 0',
        lineHeight: '20px !important',
        height: '43px !important',
        width: '100%',

        '&:hover': {
            backgroundColor: '#7743b8',
        },
    },
    emailButtonStyle: {
        color: '#4f2682',
        textTransform: 'none',
        borderRadius: 4,
        backgroundColor: theme.palette.button.standard.primary,
        margin: `${theme.spacing(1.5)}px ${theme.spacing(0)}px ${theme.spacing(1)}px !important`,
        padding: '14px 0',
        lineHeight: '20px !important',
        height: '43px !important',
        width: '100%',

        '&:hover': {
            backgroundColor: theme.palette.button.standard.primary,
        },
    },
    firstTitleStyle: {
        marginBottom: theme.spacing(3),
        textAlign: 'center',
    },
    titleStyle: {
        margin: theme.spacing(4.5, 0, 2),
        textAlign: 'center',
    },
    subtitleStyle: {
        margin: theme.spacing(0, 4, 3),
        textAlign: 'center',
    },
}))

interface Props {
    onSubmit: (val: any) => void
}

const SignInOptionsPanel: React.FC<Props> = ({ onSubmit }) => {
    const classes = useStyles()
    const handleEmailSubmit = () => {
        onSubmit(EMAIL_STEP)
    }
    const handleSignInSubmit = () => {
        onSubmit(SIGN_IN_STEP)
    }

    const handleKey = (event: any) => {
        if (!event.defaultPrevented) {
            if (event.key === 'Enter') {
                handleSignInSubmit()
            }
        }
    }

    useEffect(() => {
        if (process.env.REACT_APP_ENVIRONMENT_NAME === 'purplewave.net') {
            window.Intercom('update', {
                hide_default_launcher: true,
            })
        }

        document.addEventListener('keydown', handleKey, false)

        return () => {
            document.removeEventListener('keydown', handleKey, false)
        }
    })

    return (
        <div className={classes.mainGrid}>
            <Grid
                container
                direction={'column'}
                className={classes.contentStyle}
                alignItems={'center'}
            >
                <Typography
                    className={`${classes.firstTitleStyle} level-2-heading`}
                    id={'login.title'}
                />
                <img
                    src={FT_LOGO}
                    alt=""
                    style={{ width: '200px', height: 'auto' }}
                />
                <Typography
                    className={`${classes.titleStyle} level-3-heading`}
                    id={'signInOptions.title'}
                />
                <Typography
                    className={`${classes.subtitleStyle} tight-default-text`}
                    id={'signInOptions.subtitle'}
                />
                <Button
                    className={`subtle-button ${classes.emailButtonStyle}`}
                    onClick={handleEmailSubmit}
                >
                    <Typography
                        color="inherit"
                        className={'large-button-text'}
                        id={'signInOptions.email.label'}
                    />
                </Button>
                <Button
                    variant={'text'}
                    color="secondary"
                    className={classes.buttonStyle}
                    onClick={handleSignInSubmit}
                >
                    <Typography
                        color="inherit"
                        className={'large-button-text'}
                        id={'signInOptions.signin.label'}
                    />
                </Button>
            </Grid>
        </div>
    )
}

export default SignInOptionsPanel
