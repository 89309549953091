import React from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import { withStyles } from '@material-ui/core/styles'
import { Info } from '@material-ui/icons'
import makeStyles from '@material-ui/core/styles/makeStyles'

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        composes: 'subtle-text-small',
        backgroundColor: theme.palette.background.default, //theme.palette.common.white,
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}))(Tooltip)

const useStyles = makeStyles((theme) => ({
    radioColor: {
        color: '#9f9f9f !important',
        fontSize: '15px',
    },
}))

interface Props {
    title: string
}
const FTTooltip: React.FC<Props> = ({ title }) => {
    const classes = useStyles()
    return (
        <LightTooltip disableFocusListener title={title}>
            <Info fontSize={'small'} className={classes.radioColor} />
        </LightTooltip>
    )
}

export default FTTooltip
