import Grid from '@material-ui/core/Grid'
import Popover from '@material-ui/core/Popover'
import withStyles from '@material-ui/core/styles/withStyles'
import React, { PureComponent, createRef, Fragment } from 'react'
import * as ReactDOM from 'react-dom'

const styles = (theme) => ({
    popover: {
        pointerEvents: 'none',
    },
    paper: {
        padding: theme.spacing(1),
        margin: theme.spacing(-1, 0, 0, -1),
        backgroundColor: (props) => props.popupBackgroundColor,
    },
    overflowStyle: {
        '&:before': {
            content: '"..."',
            fontSize: `${theme.size.font.label}rem !important`,
            // zIndex: 1001,
            position: 'absolute',
            top: 0,
            paddingTop: (props) => props.paddingTop,
            // paddingTop: 6,
            bottom: 0,
            right: -1,
            backgroundColor: (props) => props.backgroundColor,
        },
    },
})

function isEllipsisActiveFunction(element) {
    return element && element.offsetWidth < element.scrollWidth
}

/**
 * The Typography component that supports intl. The default value is the child element.
 *
 * Example:
 * <Typography id='path.suppliers' variant='button'>Suppliers</Typography>
 */
class GridOverflow extends PureComponent {
    gridRef = createRef()

    state = {
        anchorEl: undefined,
        isEllipsisActive: false,
    }

    componentDidMount() {
        const node = ReactDOM.findDOMNode(this.gridRef.current)
        const isEllipsisActive = isEllipsisActiveFunction(node)
        this.setState({ isEllipsisActive })
    }

    handlePopoverOpen = (event) => {
        const isEllipsisActive = isEllipsisActiveFunction(event.currentTarget)
        this.setState({
            isEllipsisActive,
            open: true,
            anchorEl: event.currentTarget,
        })
    }

    handlePopoverClose = () => {
        this.setState({ open: false })
    }

    render() {
        //paddingTop, popupBackgroundColor and backgroundColor are used in the style, but need to be removed from
        // "otherProps".
        const {
            id,
            classes,
            className,
            children,
            style,
            overflowStyle,
            paddingTop,
            popupBackgroundColor,
            backgroundColor,
            ...otherProps
        } = this.props
        const { isEllipsisActive } = this.state
        const open = !!(isEllipsisActive && this.state.open)
        const overflowClass = isEllipsisActive ? classes.overflowStyle : ''

        return (
            <Fragment>
                <Grid
                    ref={this.gridRef}
                    style={{ position: 'relative', ...style }}
                    alignItems={'center'}
                    className={`${className || ''} ${overflowClass}`}
                    {...otherProps}
                    onMouseEnter={this.handlePopoverOpen}
                    onMouseLeave={this.handlePopoverClose}
                >
                    {children}
                </Grid>
                <Popover
                    className={this.props.classes.popover}
                    classes={{
                        paper: this.props.classes.paper,
                    }}
                    open={open}
                    anchorEl={this.state.anchorEl}
                    anchorOrigin={{
                        vertical: 'center',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'center',
                        horizontal: 'left',
                    }}
                    onClose={this.handlePopoverClose}
                    disableRestoreFocus
                >
                    <Grid style={overflowStyle} {...otherProps}>
                        {children}
                    </Grid>
                </Popover>
            </Fragment>
        )
    }
}

GridOverflow.propTypes = {
    ...Grid.propTypes, // Supports all the properties from Typography.
}

export default withStyles(styles)(GridOverflow)
