import FormControlLabel from '@material-ui/core/FormControlLabel'
import Grid from '@material-ui/core/Grid'
import Radio from '@material-ui/core/Radio'
import React from 'react'
import FTTooltip from '../FTTooltip'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '../../fhg/components/Typography'

const useStyles = makeStyles<Theme>((theme) => ({
    radionDense: {
        padding: theme.spacing(0.5),
        color: '#4f2682',
    },
    radioColor: {
        color: '#4f2682 !important',
    },
}))

export interface RadioItemProps {
    value: string
    title: string
    tooltipTitle?: string
}

const FTRadioItem: React.FC<RadioItemProps> = ({
    value,
    title,
    tooltipTitle,
}) => {
    const classes = useStyles()
    return (
        <FormControlLabel
            value={value}
            control={
                <Radio
                    className={classes.radioDense}
                    classes={{ checked: classes.radioColor }}
                    size={'small'}
                />
            }
            label={
                <Grid
                    container
                    direction={'row'}
                    alignItems={'center'}
                    spacing={1}
                >
                    <Grid item>
                        <Typography
                            color="textSecondary"
                            variant={'body1'}
                            id={title}
                        />
                    </Grid>
                    {tooltipTitle && (
                        <Grid item>
                            <FTTooltip title={tooltipTitle} />
                        </Grid>
                    )}
                </Grid>
            }
        />
    )
}

export default FTRadioItem
