import create from 'zustand' // Correct import for Zustand

// Define types for the store state and actions
interface EvaluationState {
    evaluation: any[]
    setEvaluation: (newEvaluation: any[]) => void
}

interface ItemState {
    evalItem: any[]
    setItem: (newItem: any[]) => void
}
// Define types for the store state and actions
interface EvaluationIdState {
    evaluationId: string | null // Store eval_id
    setEvaluationId: (id: string) => void
}

interface EmployeesState {
    SelectedEmployeesState: any[]
    setSelectedEmployeesState: (newEmployees: any[]) => void
}

interface MenuStoreState {
    MenuClosedState: boolean // The state to track if the menu is closed
    setMenuClosedState: (closed: boolean) => void // Function to update the state
}

// Create evalstore store with TypeScript
export const useEvaluationStore = create<EvaluationState>(
    (set: (arg0: { evaluation: any[] }) => any) => ({
        evaluation: [],
        setEvaluation: (newEvaluation: any[]) =>
            set({ evaluation: newEvaluation }),
    })
)

// Create item store with Typescript
export const useItemStore = create<ItemState>(
    (set: (arg0: { evalItem: any[] }) => any) => ({
        evalItem: [],
        setItem: (newItem: any[]) => set({ evalItem: newItem }),
    })
)

// Create eval store with TypeScript
export const useEvaluationIdStore = create<EvaluationIdState>(
    (set: (arg0: { evaluationId: string }) => any) => ({
        evaluationId: null,
        setEvaluationId: (id: string) => set({ evaluationId: id }),
    })
)

// Create evalstore store with TypeScript
export const useEmployeesStore = create<EmployeesState>(
    (set: (arg0: { SelectedEmployeesState: any[] }) => any) => ({
        SelectedEmployeesState: [],
        setSelectedEmployeesState: (newEmployees: any[]) =>
            set({ SelectedEmployeesState: newEmployees }),
    })
)

export const useMenuStore = create<MenuStoreState>((set) => ({
    MenuClosedState: false, // initial state
    setMenuClosedState: (closed: any) => set({ MenuClosedState: closed }), // action to update state
}))
