import { makeStyles } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Link from '@material-ui/core/Link'
import React, { useState, ChangeEvent, useEffect } from 'react'
import Typography from '../../fhg/components/Typography'
import { FTlLoginForm } from '../forms'

const useStyles = makeStyles((theme: any) => ({
    mainGrid: {
        height: 'calc(100% - 80px)',
        width: '100%',
        minHeight: 580,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.brand.gray, //'#5D788F',
        overflow: 'hidden',
    },
    gridContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    topWhiteLabel: {
        marginTop: theme.spacing(5),
        color: 'white',
    },
    bottomWhiteLabel: {
        marginTop: theme.spacing(4),
        color: 'white',
    },
    linkStyle: {
        color: 'white',
        marginTop: theme.spacing(1),
        fontSize: 16,
        '&:hover': {
            backgroundColor: theme.palette.brand.gray,
        },
    },
}))

interface SignInPanelProps {
    showSuccess?: boolean
    error?: any
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void
    onResetPassword?: () => void
    onEmailSignIn?: () => void
}

const SignInPanel: React.FC<SignInPanelProps> = ({
    onResetPassword,
    onEmailSignIn,
}) => {
    const classes = useStyles()
    const [purplewave] = useState<boolean>(
        process.env.REACT_APP_ENVIRONMENT_NAME === 'purplewave.net'
    )

    useEffect(() => {
        if (process.env.REACT_APP_ENVIRONMENT_NAME === 'purplewave.net') {
            window.Intercom('update', {
                hide_default_launcher: true,
            })
        }
    }, [])

    return (
        <div className={classes.mainGrid}>
            <FTlLoginForm />
            <Grid
                item
                container
                direction={'column'}
                wrap={'nowrap'}
                className={classes.gridContainer}
            >
                <Typography
                    className={classes.topWhiteLabel}
                    id={'login.forgot.label'}
                />
                <Link
                    className={classes.linkStyle}
                    component="button"
                    variant="body2"
                    underline="always"
                    onClick={onResetPassword}
                >
                    <Typography color="inherit" id={'login.reset.link'} />
                </Link>
                <Typography
                    className={classes.bottomWhiteLabel}
                    id={'login.codeSignIn.label'}
                />
                <Link
                    className={classes.linkStyle}
                    component="button"
                    variant="body2"
                    underline="always"
                    onClick={onEmailSignIn}
                >
                    <Typography color="inherit" id={'login.codeSignIn.link'} />
                </Link>
                {purplewave && (
                    <p className={classes.bottomWhiteLabel}>
                        <Typography
                            color="inherit"
                            id={'login.supportInstructions'}
                        />
                    </p>
                )}
            </Grid>
        </div>
    )
}

export default SignInPanel
