import makeStyles from '@material-ui/core/styles/makeStyles'
import { defer } from 'lodash'
import React, { useState } from 'react'
import { v4 as uuid } from 'uuid'
import { CREATE_LINK, UPDATE_LINK } from '../../../Constants'
import ModalDialog from '../../../fhg/components/dialog/ModalDialog'
import DisplayErrorAndProgress from '../../../fhg/components/DisplayErrorAndProgress'
import useEditData from '../../../fhg/components/edit/useEditData'
import ReactSelect from '../../../fhg/components/ReactSelect'
import Typography from '../../../fhg/components/Typography'
import ValidateTarget from '../../../fhg/components/ValidateTarget'
import useMessage from '../../../fhg/utils/useMessage'
import useLazyRequestForServer from '../../../Utils/useLazyRequestForServer'
import FormattedTextField from '../components/FormattedTextField'

const LINK_TYPE_OPTIONS = [
    { label: 'comp', value: 'comp' },
    { label: 'details', value: 'details' },
    { label: 'support', value: 'support' },
]

const useStyles = makeStyles(
    (theme) => ({
        contentStyle: {
            width: 440,
        },
        fullWidthInput: {
            marginTop: `${theme.spacing(1)}px !important`,
            height: 30,
            width: '100% !important',
        },
        mediumTopPad: {
            marginTop: theme.spacing(1.5),
        },
        titleStyle: {
            paddingLeft: theme.spacing(4.5),
        },
    }),
    'EditLinkStyles'
)

/**
 * The edit link dialog.
 *
 * Reviewed: 8/5/2020
 */
export default function EditLinkDialog({
    asset,
    link,
    open,
    onClose,
    onSubmit,
}) {
    const classes = useStyles()
    const typePlaceholder = useMessage('link.type.placeholder')
    const textPlaceholder = useMessage('link.text.placeholder')
    const linkPlaceholder = useMessage('link.link.placeholder')

    const [
        editValues,
        handleChange,
        { isChanged, setIsChanged, defaultValues },
    ] = useEditData(link)

    const [submitLink, { loading, error }] = useLazyRequestForServer()
    const [isSaving, setIsSaving] = useState(false)
    const [errorId, setErrorId] = useState()

    /**
     * Submits the changes to the link.
     */
    const handleSubmit = async (event) => {
        event.preventDefault()
        event.stopPropagation()

        if (!isSaving) {
            if (isChanged) {
                setIsSaving(true)
                let uri, method, editLink

                if (!!link) {
                    setErrorId('link.update.error')
                    uri = UPDATE_LINK.format({
                        assetKey: link.asset_key,
                        linkKey: link.key,
                    })
                    editLink = { ...link, ...editValues }
                    method = 'put'
                } else {
                    setErrorId('link.create.error')
                    uri = CREATE_LINK.format({ assetKey: asset.item_id })
                    editLink = {
                        ...editValues,
                        key: uuid(),
                        asset_key: asset.item_id,
                    }
                    method = 'post'
                }
                if (editLink.url && editLink.url.indexOf('http') < 0) {
                    // Relative links use the web app base URL and don't work. Make the link not relative to web app URL.
                    editLink.url = 'http://' + editLink.url
                }
                try {
                    // Send the link to the server.
                    const result = await submitLink(uri, method, editLink)
                    // Update the link and link lists with the changes.
                    if (!link) {
                        asset.links.push(result)
                        onSubmit && onSubmit()
                    } else {
                        link.link_type = editLink.link_type
                        link.title = editLink.title
                        link.url = editLink.url
                    }
                    setIsChanged(false)
                    defer(onClose)
                } catch (error) {
                    //Intentionally ignored. Needed to avoid false error indicating an uncaught exception.
                } finally {
                    setIsSaving(false)
                }
            } else {
                onClose && onClose()
            }
        }
    }

    return (
        <ModalDialog
            formId={'EditLinkDialogId'}
            open={open}
            onClose={onClose}
            onSubmit={handleSubmit}
            titleKey={link ? 'link.edit.title' : 'link.add.title'}
            classes={{
                titleStyle: classes.titleStyle,
                contentStyle: classes.contentStyle,
            }}
            submitKey={'save.button'}
            isSaving={isSaving}
            isForm={true}
            useCaptureKeydown={true}
        >
            {error && (
                <DisplayErrorAndProgress
                    errorId={errorId}
                    error={error}
                    showProgress={loading}
                />
            )}
            <Typography
                id="link.type.label"
                className={`${classes.mediumTopPad} label-text`}
                style={{ marginBottom: 8 }}
                display={'block'}
            />
            <ReactSelect
                name={'link_type'}
                fullWidth
                autoFocus
                defaultValue={
                    defaultValues.link_type
                        ? {
                              label: defaultValues.link_type,
                              value: defaultValues.link_type,
                          }
                        : null
                }
                value={
                    editValues.link_type
                        ? {
                              label: editValues.link_type,
                              value: editValues.link_type,
                          }
                        : undefined
                }
                onChange={handleChange}
                placeholder={typePlaceholder}
                options={LINK_TYPE_OPTIONS}
                isMulti={false}
                escapeClearsValue={true}
                disabled={isSaving}
            />
            <ValidateTarget
                name={'link_type Validate'}
                value={editValues.link_type || defaultValues.link_type}
                title={'Please fill out this field.'}
                width={'auto'}
                left={0}
            />
            <Typography
                id="link.text.label"
                className={'label-text'}
                style={{ marginTop: 16 }}
                display={'block'}
            />
            <FormattedTextField
                name="title"
                fullWidth
                defaultValue={defaultValues.title}
                value={editValues.title}
                onChange={handleChange}
                margin="none"
                placeholder={textPlaceholder}
                inputProps={{ className: classes.fullWidthInput }}
                disabled={isSaving}
            />
            <Typography
                className={'label-text'}
                style={{ marginTop: 16 }}
                display={'block'}
                id="link.link.label"
            />
            <FormattedTextField
                name="url"
                required
                fullWidth
                defaultValue={defaultValues.url}
                value={editValues.url}
                onChange={handleChange}
                margin="none"
                placeholder={linkPlaceholder}
                inputProps={{ className: classes.fullWidthInput }}
                disabled={isSaving}
            />
        </ModalDialog>
    )
}
