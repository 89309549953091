import withStyles from '@material-ui/core/styles/withStyles'
import TextField from '@material-ui/core/TextField'
import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import FormattedTextField from '../../components/eval/components/FormattedTextField'
import TextMaskCustom from './TextMaskCustom'

// Default phone mask for phone inputs.
const PHONE_MASK = [
    '(',
    /[1-9]/,
    /\d/,
    /\d/,
    ')',
    ' ',
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
]

const styles = (theme) => ({
    textFieldInput: {
        color: `${theme.palette.text.primary} !important`, //#423313
        '&:hover': {
            borderColor: theme.palette.divider,
        },
        '&:disabled': {
            color: `${theme.palette.text.disabled} !important`,
            border: `1px solid ${theme.palette.text.disabled} !important`,
        },
        '@media print': {
            border: `none !important`,
        },
    },
})

/**
 * The phone number input component.
 * @param props
 * @return {*}
 * @constructor
 */
export function PhoneNumberInput(props) {
    const { inputRef, showMask = false, ...other } = props

    return (
        <TextMaskCustom
            {...other}
            inputRef={inputRef}
            mask={PHONE_MASK}
            showMask={showMask}
        />
    )
}

PhoneNumberInput.propTypes = {
    inputRef: PropTypes.func.isRequired,
    showMask: PropTypes.bool,
    ...TextMaskCustom.propTypes,
}

/**
 * Component which is a phone number field.
 */
class PhoneField extends PureComponent {
    static propTypes = {
        ...TextField.propTypes,
    }

    render() {
        const { classes, inputProps, ...props } = this.props

        return (
            <FormattedTextField
                {...props}
                type="tel"
                inputProps={{
                    pattern:
                        '\\(   \\)    -    |^\\([0-9]{3}\\) [0-9]{3}[-]?([0-9]{4})$',
                    title: '(999) 999-9999 phone number',
                    ...inputProps,
                }}
                // eslint-disable-next-line
                InputProps={{
                    inputComponent: PhoneNumberInput,
                }}
            />
        )
    }
}

export default withStyles(styles)(PhoneField)
