import get from 'lodash/get'
import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { getUserData } from '../../Utils/ServerUtil'

/**
 * A component that compares the group to the user's groups. If allowIfInGroup is true and the user is in the group or
 * allowIfInGroup is false and the user is not in the group, the children will be displayed.
 **/
export default function AllowGroup({ group, children, allowIfInGroup = true }) {
    const [allow, setAllow] = useState(false)

    /**
     * When the group changes update whether the children can be displayed.
     */
    useEffect(() => {
        const groups = get(getUserData(), 'groups', [])
        const isInGroup = groups.indexOf(group) >= 0
        setAllow(isInGroup === allowIfInGroup)
    }, [group])

    return allow ? children : null
}

AllowGroup.propTypes = {
    group: PropTypes.string.isRequired, //Name of the group to check.
    children: PropTypes.any.isRequired, //Children to be optionally displayed.
    allowIfInGroup: PropTypes.bool, //True if the user's must be in the group to display the children
}
