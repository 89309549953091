import { isWidthUp } from '@material-ui/core'
import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import withStyles from '@material-ui/core/styles/withStyles'
import classNames from 'classnames'
import * as PropTypes from 'prop-types'
import React, { Component, Fragment } from 'react'
import ReactTable from 'react-table'
import { DELETE_ICON } from '../../../Constants'
import Typography from '../../../fhg/components/Typography'
import Feature from '../../../fhg/security/Feature'
import { SUB_PACKAGES } from '../../../Utils/ServerUtil'
import SubPackageMenu from '../components/SubPackageMenu'
import SubpackagesPopover from '../components/SubpackagesPopover'
import ValuationTypeMenu from '../components/ValuationTypeMenu'
import { PackageTotal } from './EquipmentList'

const styles = (theme) => ({
    toolbarStyle: {
        paddingLeft: theme.spacing(1),
    },
    equipmentHeaderStyle: {
        maxHeight: '100%',
        border: 'none',
        paddingLeft: 2,
        width: '100%',
        overflow: 'hidden',
        '@media print': {
            display: 'table',
        },
        '& .rt-tbody': {
            height: 0,
        },
        '& .rt-th div': {
            height: '100%',
            marginTop: 'auto',
            marginBottom: 'auto',
        },
        '& .rt-tr': {
            marginLeft: '4px !important',
            marginTop: 1,
            marginBottom: 1,
            flex: '0 0 auto',
        },
        '& .rt-tbody .rt-tr': {
            // Needed to make the :after 'absolute' position work for the selection borders.
            position: 'relative',
        },
        '& .rt-tbody .rt-tr-group': {
            borderBottom: `1px solid ${theme.palette.environment.light.level0.accent} !important;`,
        },
        '& .rt-resizable-header-content': {
            height: '100%',
            paddingBottom: theme.spacing(1),
        },
    },
    titleStyle: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontWeight: 'bold !important',
    },
    deleteImageStyle: {
        '@supports not (-ms-high-contrast: none)': {
            mask: `url(${DELETE_ICON}) no-repeat`,
            maskSize: 'cover',
            backgroundColor: '#4f2682 !important',
        },
        '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)':
            {
                opacity: 0.5,
                background: `url(${DELETE_ICON}) no-repeat`,
                backgroundSize: 'cover',
            },
        width: 14,
        height: 16,
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    linkButton: {
        composes: 'no-print',
        zIndex: 1001,
        marginLeft: theme.spacing(1),
        color: theme.palette.button.standard.secondary, //'#5D788F',
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(0.5, 0.5),
        },
    },
    linkLabel: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        color: '#4f2682 !important',
    },
    cancelButtonStyle: {
        color: theme.palette.button.minimal.cancel, //'#878e94',
    },
    cancelTextStyle: {
        fontWeight: 400,
    },
    buttonGridStyle: {
        paddingTop: theme.spacing(1),
    },
    paper: {
        padding: theme.spacing(1),
        margin: theme.spacing(-1, 0, 0, -1),
    },
    defaultTextStyle: {
        color: theme.palette.text.secondary,
    },
})

class MultiSelectItemBar extends Component {
    static propTypes = {
        classes: PropTypes.object, // Styles for the component.
        onCancel: PropTypes.func, // Callback when the "Cancel" button is clicked.
        onRemove: PropTypes.func, // Callback when the "Remove" menu item is selected.
        onAddSubpackages: PropTypes.func, // Callback when the "Clear Subpackages" button is selected.
        onClearSubpackages: PropTypes.func, // Callback when the "Add Subpackages" button is selected.
        onShowSubpackages: PropTypes.func, // Callback when the Heavyworth value is selected.
        onChangeValuationType: PropTypes.func, // Callback when the valuation type is set.
        selectedValuationTypes: PropTypes.array, // valuation types of selected assets.
        selectedCount: PropTypes.number, // Number of assets selected.
        subpackageTotals: PropTypes.object, // The subpackage totals
        isWideView: PropTypes.bool, // Indicates if the width should be for the wide view.
    }

    state = {
        anchorEl: undefined,
        subpackageTotals: undefined,
    }

    /**
     * Show the subpackage popover.
     * @param event The event that triggered the show subpackage.
     */
    handleShowSubpackages = (event) => {
        const subpackageTotals = this.props.onShowSubpackages()
        this.setState({ subpackageTotals, anchorEl: event.currentTarget })
    }

    /**
     * Close the subpackages popover.
     */
    handlePopoverClose = () => {
        this.setState({ subpackageTotals: undefined, anchorEl: undefined })
    }

    render() {
        const {
            classes,
            onCancel,
            onRemove,
            subpackages,
            onAddSubpackages,
            onClearSubpackages,
            onSelectValuationType,
            selectedCount,
            estimateTotal,
            total,
            isWideView,
        } = this.props
        const { anchorEl, subpackageTotals } = this.state
        const open = Boolean(anchorEl)

        return (
            <Fragment>
                {open && (
                    <SubpackagesPopover
                        open={open}
                        classes={{
                            paper: this.props.classes.paper,
                        }}
                        anchorEl={anchorEl}
                        onClose={this.handlePopoverClose}
                        subpackageTotals={subpackageTotals}
                    />
                )}
                <AppBar position="static" color="inherit" elevation={2}>
                    <ReactTable
                        className={classNames(
                            classes.equipmentHeaderStyle,
                            'equipment-list'
                        )}
                        defaultPageSize={10000}
                        minRows={0}
                        sortable={false}
                        resizable={false}
                        multiSort={false}
                        noDataText={''}
                        getNoDataProps={() => ({
                            style: { height: 0, width: 0, padding: 0 },
                        })}
                        data={[]}
                        showPagination={false}
                        columns={[
                            {
                                id: 'image',
                                Header: (
                                    <Grid
                                        container
                                        alignItems={
                                            isWideView ? 'center' : 'flex-start'
                                        }
                                        style={{
                                            height: isWideView ? 49 : 150,
                                        }}
                                        wrap={'nowrap'}
                                    >
                                        <Grid item style={{ height: 'unset' }}>
                                            <Typography
                                                className={`${classes.titleStyle} subtle-text-small-bold`}
                                                id={
                                                    'equipmentList.multiSelect.title'
                                                }
                                                values={{ selectedCount }}
                                            />
                                        </Grid>
                                        <Grid
                                            container
                                            item
                                            direction={
                                                isWideView ? 'row' : 'column'
                                            }
                                            justifyContent="center"
                                        >
                                            <Button
                                                className={classes.linkButton}
                                                variant={'text'}
                                                onClick={onRemove}
                                                disableRipple
                                            >
                                                <span
                                                    className={
                                                        classes.deleteImageStyle
                                                    }
                                                />
                                                <Typography
                                                    className={
                                                        classes.linkLabel
                                                    }
                                                    variant="button"
                                                    color={'inherit'}
                                                    id={'delete.asset'}
                                                />
                                            </Button>
                                            {/* <Feature feature={SUB_PACKAGES}>
                                             <SubPackageMenu onAddSubpackages={onAddSubpackages}
                                                             subpackages={subpackages}/>
                                             <Button className={classes.linkButton} variant={'text'}
                                                     onClick={onClearSubpackages} disableRipple>
                                                <Typography className={classes.linkLabel} variant='button'
                                                            color={'inherit'}
                                                            id={'subpackage.clear.button'}/>
                                             </Button>
                                          </Feature>
                                          <ValuationTypeMenu onSelectValuationType={onSelectValuationType}/> */}
                                            <Button
                                                className={`${classes.linkButton} ${classes.cancelButtonStyle}`}
                                                variant={'text'}
                                                onClick={onCancel}
                                                disableRipple
                                            >
                                                <Typography
                                                    className={`${classes.linkLabel} ${classes.cancelTextStyle}`}
                                                    variant="button"
                                                    color={'inherit'}
                                                    id={'cancel.button'}
                                                />
                                            </Button>
                                        </Grid>
                                    </Grid>
                                ),
                                maxWidth: 2000,
                                minWidth: 200,
                            },
                            {
                                id: 'expanding column',
                                minWidth: 1,
                            },
                            {
                                id: 'estimates',
                                show: isWideView,
                                Header: (
                                    <Grid
                                        container
                                        direction={'row'}
                                        spacing={2}
                                        wrap={'nowrap'}
                                        justifyContent="flex-end"
                                        alignItems={'center'}
                                    >
                                        <Grid item alignItems={'center'}>
                                            <PackageTotal
                                                classes={classes}
                                                total={estimateTotal}
                                                totalLabel="equipmentList.yourTotal.label"
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            alignItems={'center'}
                                            onClick={this.handleShowSubpackages}
                                        >
                                            <Button
                                                variant={'button'}
                                                className={
                                                    'secondary-minimal-button-small no-print'
                                                }
                                            >
                                                <PackageTotal
                                                    classes={classes}
                                                    total={total}
                                                    totalLabel="equipmentList.total.label"
                                                />
                                            </Button>
                                        </Grid>
                                    </Grid>
                                ),
                            },
                            {
                                id: 'space for column',
                                width: 18,
                            },
                        ]}
                    />
                </AppBar>
            </Fragment>
        )
    }
}

export default withStyles(styles)(MultiSelectItemBar)
