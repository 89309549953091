import Drawer from '@material-ui/core/Drawer'
import { isWidthDown } from '@material-ui/core/withWidth'
import withWidth from '@material-ui/core/withWidth'
import IconButton from '@material-ui/core/IconButton'
import withStyles from '@material-ui/core/styles/withStyles'
import CloseIcon from '@material-ui/icons/Close'
import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import { EQUIPMENT_LIST_PROPERTY_WIDTH } from '../../Constants'

const styles = (theme) => ({
    drawer: {
        backgroundColor: theme.palette.environment.light.level1.base,
        height: '100%',
        '@media print': {
            display: 'none',
        },
    },
    drawerPaper: {
        backgroundColor: theme.palette.environment.light.level1.base,
        minWidth: 300,
        width: EQUIPMENT_LIST_PROPERTY_WIDTH,
        maxWidth: '100%',
        position: 'relative !important',
        marginLeft: 'auto',
    },
    closeButtonStyle: {
        '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)':
            {
                position: 'absolute',
                top: 0,
                right: 0,
            },
        '@supports not (-ms-high-contrast: none)': {
            position: 'sticky',
        },
        right: 0,
        top: 0,
        marginLeft: 'auto',
        marginBottom: theme.spacing(-6),
        zIndex: 1001,
    },
})

/**
 * Responsive Drawer Component which changes between a permanent drawer above the breakpoint and is temporary at or
 * below the breakpoint. The close button floats above the children and stays at in the top right of the component. The
 * children should allow space in the upper right corner for the close button.
 */
class ResponsiveDrawer extends PureComponent {
    static propTypes = {
        classes: PropTypes.any.isRequired, // The classes from the style for the component.
        breakpoint: PropTypes.string, // The breakpoint: above drawer is permanent; below it is temporary.
        isOpen: PropTypes.any, // Indicates if the drawer should be open.
        onClose: PropTypes.any, // Called when the drawer is closed.
        children: PropTypes.any.isRequired, // Children in the drawer.
        width: PropTypes.string.isRequired, // Provided by the withWidth HOC to handle breakpoint.
        sizeWidth: PropTypes.string.isRequired, // Actual width in pixels of the drawer.
        isTemporary: PropTypes.bool, // Is the drawer forced to be temporary.
    }

    static defaultProps = {
        breakpoint: 'sm',
        isTemporary: false,
    }

    render() {
        const {
            isOpen,
            onClose,
            classes,
            children,
            width,
            sizeWidth,
            breakpoint,
            isTemporary,
        } = this.props

        if (isWidthDown(breakpoint, width, true) || isTemporary) {
            return (
                <Drawer
                    variant="temporary"
                    anchor="right"
                    open={isOpen}
                    onClose={onClose}
                    PaperProps={{ style: { width: sizeWidth } }}
                    classes={{ paper: classes.drawerPaper }}
                    ModalProps={{ keepMounted: true }}
                >
                    {isOpen && onClose && (
                        <IconButton
                            key="close"
                            className={classes.closeButtonStyle}
                            aria-label="Close"
                            color="inherit"
                            onClick={onClose}
                        >
                            <CloseIcon />
                        </IconButton>
                    )}
                    {children}
                </Drawer>
            )
        } else {
            //If the user increases width of window until the permanent drawer shows, "close" the drawer. This prevents the
            // drawer from automatically being open when they shrink the window again.
            if (isOpen) {
                onClose && onClose()
            }
            return (
                <Drawer
                    variant="permanent"
                    anchor="right"
                    open
                    className={classes.drawer}
                    PaperProps={{ style: { width: sizeWidth } }}
                    classes={{ paper: classes.drawerPaper }}
                >
                    {children}
                </Drawer>
            )
        }
    }
}

export default withWidth()(withStyles(styles)(ResponsiveDrawer))
