import React, { createContext, useContext, useState } from 'react'

// Create a new context for imsForm
export const IMSFormContext = createContext()

// Create a custom hook to consume imsForm context
export const useIMSForm = () => useContext(IMSFormContext)

// Create a context provider component for imsForm
export const IMSFormProvider = ({ children }) => {
    const [imsForm, setImsForm] = useState({})

    return (
        <IMSFormContext.Provider value={{ imsForm, setImsForm }}>
            {children}
        </IMSFormContext.Provider>
    )
}
