import React, { useState } from 'react'
import { LOGIN_FAILED } from '../../Constants'
import { Auth } from 'aws-amplify'
import { posthogCapture } from '../../PostHogFunction'
import { requestForServer } from '../../Utils/ServerUtil'
import eventCapture from '../../eventList'
import { isLocalhost } from '../../fhg/utils/Utils'
import Typography from '../../fhg/components/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { FTInput } from '../inputs'
import Box from '@material-ui/core/Box'
import { FTButton } from '../buttons'
import { ThreeDots } from 'react-loader-spinner'

const useStyles = makeStyles((theme: any) => ({
    mainContainer: {
        width: '30%',
        height: '50%',
        backgroundColor: theme.palette.environment.light.level1.base,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '8px',
        padding: 0,
    },
    success: {
        width: '100%',
        marginTop: '32px !important',
        color: theme.palette.button.minimal.success,
    },
    error: {
        width: '100%',
        marginTop: `&{theme.spacing(4)}px !important`,
    },
    buttonBox: {
        marginTop: '16px',
        height: '45px',
    },
}))

const FTlLoginForm: React.FC = () => {
    const classes = useStyles()
    const [isSaving, setIsSaving] = useState<boolean>(false)
    const [error, setError] = useState<string | null>()
    const [email, setEmail] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const [showSuccess, setShowSuccess] = useState<boolean | null>(true)

    const handleSubmit = () => {
        setIsSaving(true)
        Auth.signIn(email.toLocaleLowerCase(), password)
            .then(() => {
                setShowSuccess(true)
                window.location.reload()
            })
            .catch((error) => {
                console.log('========================', error)
                posthogCapture(eventCapture.FAILED_LOGIN)
                setError(error.message)
                if (!isLocalhost) {
                    console.log('==========================', 'Failed Login')
                    //window?.posthog?.capture(eventCapture.FAILED_LOGIN);

                    requestForServer(LOGIN_FAILED, 'post', {
                        email: email,
                        platform: 'Web',
                    })
                    posthogCapture(eventCapture.FAILED_LOGIN)
                }
            })
            .finally(() => {
                setIsSaving(false)
            })
    }
    return (
        <Box className={classes.mainContainer}>
            <div>
                {isSaving && (
                    <ThreeDots
                        visible={true}
                        height="80"
                        width="80"
                        color="#4a287d"
                        radius="9"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                    />
                )}
            </div>
            <form onSubmit={handleSubmit}>
                <Typography
                    variant={'h4'}
                    className={'level-2-heading'}
                    id={'login.title'}
                />
                {/* {(showSuccess && !error) && (
                    <Typography className={classes.success} variant={'body1'} id={'login.success'}/>
                )} */}
                {error && (
                    <Typography
                        className={classes.error}
                        color={'error'}
                        variant={'body1'}
                        id={error}
                    >
                        Password or username did not match
                    </Typography>
                )}
                <FTInput
                    label="login.email.label"
                    required
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                    disabled={isSaving}
                    name="email"
                    type="email"
                />
                <FTInput
                    label="login.password.label"
                    required
                    value={password}
                    onChange={(event) => setPassword(event.target.value)}
                    disabled={isSaving}
                    name="password"
                    type="password"
                />
                <Box className={classes.buttonBox}>
                    <FTButton
                        title={'login.signIn.button'}
                        buttonAction={handleSubmit}
                        disabled={isSaving}
                    />
                </Box>
            </form>
        </Box>
    )
}

export default FTlLoginForm
