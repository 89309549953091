export function LOGO() {
    if (process.env.REACT_APP_ENVIRONMENT_NAME === 'purplewave.net') {
        return '/img/purple_wave/ft-icon-color-white.png'
    } else {
        return '/img/purple_wave/ft-icon-color-white.png'
    }
}

export function LARGE_LOGO() {
    if (process.env.REACT_APP_ENVIRONMENT_NAME === 'purplewave.net') {
        return '/img/purple_wave/ft-icon-color-white.png'
    } else {
        return '/img/purple_wave/ft-icon-color-white.png'
    }
}
