import 'core-js/stable'
import 'regenerator-runtime/runtime'
import 'whatwg-fetch'

import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import './textStyles.scss'
import './buttonStyles.scss'
import { Router } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import App from './App'
import * as serviceWorker from './serviceWorker'

import { createBrowserHistory } from 'history'
import { datadogRum } from '@datadog/browser-rum'
import { IMSProvider } from './components/eval/list/IMSformCategtorySelect'
import { IsTabClickedProvider } from './components/eval/detail/AssetPropertiesDrawer'
import { IMSFormProvider } from './components/eval/detail/IMSFormContext'

datadogRum.init({
    applicationId: '43f0d339-f629-4e00-b461-ffca38555183',
    clientToken: 'pubd62169a1180d6ca64ef53a1b72cac572',
    site: 'datadoghq.com',
    service: 'field-tool-web',
    env: 'prod',

    // Specify a version number to identify the deployed version of your application in Datadog
    version: process.env.REACT_APP_VERSION ?? '1.0.0',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
})

// Ensure datadog only run on Production Environment

if (process.env.REACT_APP_ENVIRONMENT_URL === 'app.purplewave.heavyworth.net') {
    datadogRum.startSessionReplayRecording()
}

Sentry.init({
    dsn: 'https://d6a63393aa534b208a13d885d805127e@o396255.ingest.sentry.io/5279172',
    environment: process.env.REACT_APP_ENVIRONMENT,
    release: 'heavyworth-web@' + process.env.npm_package_version,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
})

const customHistory = createBrowserHistory()

//Save the current location as one of the recent locations.
customHistory.recentLocations = []
if (sessionStorage.lastURL) {
    customHistory.recentLocations.push(JSON.parse(sessionStorage.lastURL))
}
customHistory.recentLocations.push(customHistory.location)
sessionStorage.lastURL = JSON.stringify(customHistory.location)

/**
 * Get the last 3 URLs from the history.
 * @return {T[]} The last 3 locations.
 */
customHistory.getRecentLocations = function () {
    return customHistory.recentLocations.slice(0)
}

customHistory.getPreviousLocation = function () {
    const locations = this.getRecentLocations()
    if (locations.length > 1) {
        return locations[locations.length - 2]
    }
    return undefined
}

// Cache the last 3 URLs for pages to determine what should be selected based on last page.
customHistory.listen(function (location) {
    sessionStorage.lastURL = JSON.stringify(location)
    customHistory.recentLocations.push(location)
    window.Intercom('update')
    if (customHistory.recentLocations.length > 3) {
        customHistory.recentLocations.shift()
    }
})

// Add the format command for adding parameters to strings. For Example:
//    'This is a test: {testName}'.format({testName: 'Test Hello World'})
if (!String.prototype.format) {
    // eslint-disable-next-line
    String.prototype.format = function (values) {
        return this.replace(/{(\w+)}/g, function (match, key) {
            return typeof values[key] !== 'undefined' ? values[key] : match
        })
    }
}

function registerServiceWorker() {
    return new Promise((resolve) => {
        const onUpdate = (registration) => {
            resolve({ update: true, registration })
        }
        const onSuccess = (registration) => {
            resolve({ update: false, registration })
        }
        // If you want your app to work offline and load faster, you can change
        // unregister() to register() below. Note this comes with some pitfalls.
        // Learn more about service workers: http://bit.ly/CRA-PWA
        serviceWorker.register({ onUpdate, onSuccess })
    })
}

export const registerServiceWorkerPromise = registerServiceWorker()
ReactDOM.render(
    <Router history={customHistory}>
        <IMSFormProvider>
            <IsTabClickedProvider>
                <IMSProvider>
                    <App />
                </IMSProvider>
            </IsTabClickedProvider>
        </IMSFormProvider>
    </Router>,
    document.getElementById('root')
)
