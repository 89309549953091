import TextField from '@material-ui/core/TextField'
import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
    searchStyle: {
        marginRight: theme.spacing(3),
        marginBottom: theme.spacing(2),
    },
    themeUnderline: {
        '&:hover:not(:disabled):before,&:before': {
            borderColor: '#4f2682',
        },
        '&:after': {
            borderColor: '#4f2682',
        },
    },
}))
interface Props {
    name?: string
    onChange: () => void
    placeholder?: string
    value?: string
    defaultValue?: string
    onKeyDown: () => void
}
const FTSearchInput: React.FC<Props> = ({
    name,
    placeholder,
    value,
    defaultValue,
    onChange,
    onKeyDown,
}) => {
    const classes = useStyles()
    return (
        <TextField
            name={name}
            className={classes.searchStyle}
            onChange={onChange}
            autoFocus
            placeholder={placeholder || 'Search'}
            defaultValue={defaultValue}
            value={value}
            onKeyDown={onKeyDown}
            InputProps={{
                'aria-label': 'Search',
                classes: {
                    underline: classes.themeUnderline,
                },
            }}
        />
    )
}

export default FTSearchInput
