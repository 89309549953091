import { useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'

useKeyDown.propTypes = {
    onClose: PropTypes.func,
    onSubmit: PropTypes.func,
}

/**
 * Hook to call onClose when escape is pressed, and onSubmit when the enter key is pressed.
 * @param onClose The callback for closing for escape key.
 * @param onSubmit The callback for submit for enter key.
 * @param ignoreDefaultPrevented Indicates if the defaultPrevented should be ignored.
 */
function useKeyDown(onClose, onSubmit, ignoreDefaultPrevented = false) {
    const handleKey = useCallback(
        (event) => {
            if (ignoreDefaultPrevented || !event.defaultPrevented) {
                if (event.key === 'Escape' && onClose) {
                    event.preventDefault()
                    onClose(event)
                } else if (event.key === 'Enter' && onSubmit) {
                    onSubmit(event)
                }
            }
        },
        [onSubmit, onClose]
    )

    /**
     * Handles keydown events for Escape and Enter.
     */
    useEffect(() => {
        document.addEventListener('keydown', handleKey, false)

        // Cleanup the listener when this component is removed.
        return () => {
            document.removeEventListener('keydown', handleKey, false)
        }
    }, [onClose, onSubmit, handleKey])

    return [handleKey]
}

export default useKeyDown
