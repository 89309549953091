import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import { FTSignInCodeForm } from '../forms'
import { FTButtonIconText } from '../buttons'
import { ArrowBack } from '@material-ui/icons'

const useStyles = makeStyles((theme: any) => ({
    mainContainer: {
        width: '100%',
        height: '100%',
        backgroundColor: theme.palette.brand.gray,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '8px',
    },
    contentContainer: {
        backgroundColor: theme.palette.environment.light.level1.base,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '8px',
        padding: theme.spacing(4),
    },
    error: {
        width: '100%',
        marginTop: `&{theme.spacing(4)}px !important`,
    },
    backContainer: {
        marginTop: '16px',
    },
}))

interface Props {
    onManualSignIn: () => void
}

const EmailPanel: React.FC<Props> = ({ onManualSignIn }) => {
    const classes = useStyles()
    return (
        <div className={classes.mainContainer}>
            <div className={classes.contentContainer}>
                <FTSignInCodeForm />
                <div className={classes.backContainer}>
                    <FTButtonIconText
                        title={'checkEmail.signin.label'}
                        icon={<ArrowBack />}
                        buttonAction={onManualSignIn}
                    />
                </div>
            </div>
        </div>
    )
}

export default EmailPanel
