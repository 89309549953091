import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Slide from '@material-ui/core/Slide'
import Snackbar from '@material-ui/core/Snackbar'
import { withStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Typography from '../../../fhg/components/Typography'

export function SlideTransition(props) {
    return <Slide {...props} direction="down" />
}

const styles = (theme) => ({
    errorMessage: {
        backgroundColor: `${theme.palette.error.light} !important`,
        paddingRight: theme.spacing(6),
    },
    closeButtonStyle: {
        position: 'absolute',
        right: 0,
        top: 0,
        marginLeft: 'auto',
        zIndex: 1001,
    },
})

/**
 * Component to show the error messages.
 *
 * Note:
 *    Message is the default property in values.
 */
class ErrorSnackbar extends Component {
    static propTypes = {
        classes: PropTypes.object.isRequired, // Styles for the component.
        open: PropTypes.bool, // Indicates if the Error snackbar should be shown to the user.
        enableRefresh: PropTypes.bool, // Indicates if the Refresh action should be shown / enabled.
        onClose: PropTypes.func, // Callback when the snackbar is closed.
        errorId: PropTypes.string, // Intl ID for the error message.
        message: PropTypes.string, // Text for the error message.
        values: PropTypes.object, // Value object for the error message.
    }

    static defaultProps = {
        enableRefresh: true,
        values: { message: 'N/A' },
    }

    /**
     * Force a browser reload.
     */
    handleRefresh = () => {
        window.location.reload(true)
    }

    render() {
        const {
            classes,
            open,
            onClose,
            errorId,
            values,
            message,
            enableRefresh,
        } = this.props

        return (
            <Snackbar
                open={open}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                ContentProps={{
                    'aria-describedby': 'message-id',
                    classes: {
                        root: classes.errorMessage,
                    },
                }}
                TransitionComponent={SlideTransition}
                message={
                    <Typography id={errorId} values={values} color={'inherit'}>
                        {message}
                    </Typography>
                }
                action={[
                    enableRefresh && (
                        <Button
                            key="undo"
                            color="inherit"
                            size="small"
                            onClick={this.handleRefresh}
                        >
                            <Typography color="inherit" id="refresh">
                                Refresh
                            </Typography>
                        </Button>
                    ),
                    onClose && (
                        <IconButton
                            key="close"
                            aria-label="Close"
                            color="inherit"
                            className={classes.closeButtonStyle}
                            onClick={onClose}
                        >
                            <CloseIcon />
                        </IconButton>
                    ),
                ]}
            />
        )
    }
}

export default withStyles(styles)(ErrorSnackbar)
