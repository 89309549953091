import FormControl from '@material-ui/core/FormControl'
import Input from '@material-ui/core/Input'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import withStyles from '@material-ui/core/styles/withStyles'
import classNames from 'classnames'
import { every } from 'lodash'
import get from 'lodash/get'
import PropTypes from 'prop-types'
import React, { PureComponent, Fragment } from 'react'
import { SAVE_WORKFLOW_STATUS } from '../../../Constants'
import ModalDialog from '../../../fhg/components/dialog/ModalDialog'
import DisplayError from '../../../fhg/components/DisplayError'
import Typography from '../../../fhg/components/Typography'
import { requestForServer } from '../../../Utils/ServerUtil'

const styles = (theme) => ({
    formControl: {
        color: 'inherit',
        backgroundColor: 'transparent',
        '&:focus': {
            backgroundColor: 'transparent',
        },
    },
    select: {
        background: 'transparent',
        '&:focus': {
            background: 'transparent',
        },
    },
    dot: {
        height: 8,
        width: 8,
        borderRadius: '50%',
        display: 'inline-block',
        marginRight: theme.spacing(0.5),
        verticalAlign: 'middle',
    },
    capture_in_progress: {
        backgroundColor: '#F8CE1C',
    },
    capture_complete: {
        backgroundColor: theme.palette.status.captureInProgress, //'#7ED321',
    },
    sent_to_ims: {
        backgroundColor: theme.palette.status.evalInProgress, //'#4A90E2',
    },
    //  final_review: {
    //    backgroundColor: "#742682"
    //  },
    //  finalized: {
    //    backgroundColor: theme.palette.status.finalized, //'#406370',
    //  },
    //  archived: {
    //    backgroundColor: theme.palette.status.archived, //'#D8D8D8',
    //  },
    menuText: {
        display: 'inline',
        whiteSpace: 'normal',
    },
})

class WorkflowStatusSelect extends PureComponent {
    state = {}

    constructor(props, context) {
        super(props, context)

        this.state = {
            status: WorkflowStatusSelect.getNewStatus(props),
            isStatusUpdated: false, // Track the status update
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const newStatus = WorkflowStatusSelect.getNewStatus(
            this.props,
            prevProps,
            this.state
        )
        if (newStatus) {
            this.setState({ status: newStatus })
        }
    }

    componentDidMount() {
        this.isAllItemsDone()
    }

    static getNewStatus(nextProps, prevProps = {}, currentState = {}) {
        const { status } = nextProps
        let newStatus
        if (
            status &&
            (status !== prevProps.status || status !== currentState.status)
        ) {
            newStatus = status
        } else if (nextProps.evaluation) {
            const nextStatus = get(
                nextProps,
                'evaluation.evaluation.workflow_status'
            )
            const previousStatus = get(
                prevProps,
                'evaluation.evaluation.workflow_status'
            )
            if (nextStatus !== previousStatus) {
                newStatus = nextStatus
            }
        }
        return newStatus
    }

    setStatusUpdated = () => {
        this.setState({ isStatusUpdated: true })
    }

    /**
     * Handle changes to the status.
     * @param event The change event.
     */
    handleChange = (event) => {
        const value = event.target.value
        this.confirmFinalize(value, this.props.evaluation, () => {
            this.setState({ [event.target.name]: value }, this.setStatus(value))
            this.props.onChange && this.props.onChange(event)
        })
    }

    inProgressDisabled = () => {
        const { evaluation } = this.props

        if (
            !evaluation ||
            evaluation.evaluation.workflow_status === 'capture_complete'
        ) {
            return true
        } else {
            return false
        }
    }

    isAllItemsDone = () => {
        const { evaluation } = this.props

        if (!evaluation || !evaluation.items || evaluation.items.length === 0) {
            return true
        } else if (
            evaluation.items.every((item) => item.asset_status === 'done')
        ) {
            return false
        } else {
            return true
        }
    }

    confirmFinalize = (status, evaluation, submit) => {
        if (
            status === 'finalized' &&
            !every(evaluation.items, (value) => Number(value.consensus) > 0)
        ) {
            this.resolveConfirmFinalize = (event) => {
                event.stopPropagation()
                event.preventDefault()
                this.setState({ showConfirmFinalize: false }, submit)
            }
            this.rejectConfirmFinalize = (event) => {
                event.stopPropagation()
                event.preventDefault()
                this.setState({ showConfirmFinalize: false })
            }
            this.setState({ showConfirmFinalize: true })
        } else {
            submit()
        }
    }

    /**
     * Set the status of the evaluation.
     * @param status The new status.
     * @return {Function} Set status callback.
     */
    // setStatus = (status) => async () => {
    //    if (this.props.doHandleChanges && get(this.props, 'evaluation.evaluation')) {
    //       await requestForServer(SAVE_WORKFLOW_STATUS.format({id: this.props.evaluation.evaluation.PK, status}), 'put');
    //       this.props.evaluation.evaluation.workflow_status = status;
    //    } else {
    //       console.log('Status could not be saved.');
    //    }
    // };

    setStatus = (status) => async () => {
        if (
            this.props.doHandleChanges &&
            get(this.props, 'evaluation.evaluation')
        ) {
            try {
                await requestForServer(
                    SAVE_WORKFLOW_STATUS.format({
                        id: this.props.evaluation.evaluation.PK,
                        status,
                    }),
                    'put'
                )
                    .then(() => {
                        window.alert('Status Updated Successfully')
                        window.location.reload()
                    })
                    .catch((error) => {
                        window.alert('Status Update Unsuccessful')
                        // Display error message
                        //console.log("Status update unsuccessful");
                    })
                this.props.evaluation.evaluation.workflow_status = status

                // Set the status update flag
                this.setStatusUpdated()
            } catch (error) {
                console.error('Error updating status:', error)
            }
        } else {
            console.log('Status could not be saved.')
        }
    }
    render() {
        const { classes, onChange, doHandleChanges, ...otherProps } = this.props
        const { status, showError, showConfirmFinalize } = this.state

        const statusText = {
            capture_in_progress: 'Capture In Progress',
            capture_complete: 'Capture Complete',
            sent_to_ims: 'Sent to IMS',
            //   final_review: 'Final Review',
            //   finalized: 'Finalized',
            //   archived: 'Archived',
        }

        return (
            <Fragment>
                {showError && (
                    <DisplayError
                        errorId={'evalDashboard.consensus.error'}
                        error={true}
                        enableRefresh={false}
                        onClose={() => {
                            this.setState({ showError: false })
                        }}
                    />
                )}
                {showConfirmFinalize && (
                    <ModalDialog
                        open={showConfirmFinalize}
                        onClose={this.rejectConfirmFinalize}
                        onSubmit={this.resolveConfirmFinalize}
                        messageKey={'evalDashboard.consensus.error'}
                        titleKey={'evalDashboard.consensus.title'}
                        maxWidth={'xs'}
                        submitKey={'evalDashboard.consensus.submit'}
                    />
                )}
                <FormControl className={classes.formControl} {...otherProps}>
                    {/* Conditionally render the prompt */}
                    {this.isStatusUpdated && (
                        <div className="status-update-prompt">
                            Status select successfully updated.
                        </div>
                    )}
                    <Typography
                        className={classes.menuText}
                        variant="body1"
                        color="inherit"
                    >
                        <span
                            className={classNames(classes.dot, classes[status])}
                        />
                        {statusText[status] || ''}
                    </Typography>
                </FormControl>
            </Fragment>
        )
    }
}

WorkflowStatusSelect.propTypes = {
    classes: PropTypes.any,
    status: PropTypes.any,
    onChange: PropTypes.func,
    doHandleChanges: PropTypes.bool,
    evaluation: PropTypes.any,
}

WorkflowStatusSelect.defaultProps = {
    doHandleChanges: false,
}

export default withStyles(styles)(WorkflowStatusSelect)
