import CircularProgress from '@material-ui/core/CircularProgress'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import React, { Component, Fragment } from 'react'
import DisplayError from './DisplayError'

const styles = (theme) => ({
    progressStyle: {
        position: 'absolute',
        top: '25%',
        left: 'calc(50% - 20px)',
        zIndex: 1001,
    },
})

/**
 * Component to show the error messages and loading spinner.
 *
 * Note:
 *    Message is the default property in values.
 */
class DisplayErrorAndProgress extends Component {
    static propTypes = {
        classes: PropTypes.object.isRequired, // Styles for the component.
        showProgress: PropTypes.bool, // Indicates if the data is still loading.
        open: PropTypes.bool, // Indicates if the Error snackbar should be shown to the user.
        enableRefresh: PropTypes.bool, // Indicates if the Refresh action should be shown / enabled.
        onClose: PropTypes.func, // Callback when the snackbar is closed.
        errorId: PropTypes.string, // Intl ID for the error message.
        message: PropTypes.string, // Text for the error message.
        values: PropTypes.object, // Value object for the error message.
    }

    static defaultProps = {
        enableRefresh: true,
        values: { message: 'N/A' },
        errorId: 'fetch.error',
        data: {},
    }

    state = {}

    render() {
        const {
            classes,
            onClose,
            errorId,
            error,
            showProgress,
            enableRefresh,
            showOnProgress,
            data,
            values,
            children,
        } = this.props

        const hasChildren = typeof children === 'function'
        return (
            <Fragment>
                <DisplayError
                    error={error}
                    onClose={onClose}
                    errorId={errorId}
                    values={values || error}
                    enableRefresh={enableRefresh}
                />
                {showProgress && (
                    <CircularProgress className={classes.progressStyle} />
                )}
                {(!showProgress || showOnProgress) &&
                    hasChildren &&
                    children(data)}
            </Fragment>
        )
    }
}

export default withStyles(styles)(DisplayErrorAndProgress)
