import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import withStyles from '@material-ui/core/styles/withStyles'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import isEmpty from 'lodash/isEmpty'
import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import ErrorSnackbar from '../components/eval/components/ErrorSnackbar'
import { DELETE_ORGANIZATION } from '../Constants'
import ModalDialog from '../fhg/components/dialog/ModalDialog'
import SearchField from '../fhg/components/SearchField'
import Typography from '../fhg/components/Typography'
import { requestForServer } from '../Utils/ServerUtil'
import NewUserDialog from './NewUserDialog'
import UsersTable from './UsersTable'
import isEqual from 'lodash/isEqual'

const styles = (theme) => ({
    tabsStyle: {
        borderBottom: `1px solid ${theme.palette.environment.light.divider}`,
    },
    indicatorStyle: {
        backgroundColor: theme.palette.button.standard.secondary,
    },
    buttonStyle: {
        marginLeft: theme.spacing(3),
        height: 33,
    },
    resizingContainer: {
        flex: '1 1',
        position: 'relative',
        overflow: 'hidden',
    },
    userListContainer: {
        margin: '16px 0',
        position: 'relative',
        width: '100%',
        overflow: 'hidden',
        height: 'calc(100% - 80px)',
    },
    settingsContainer: {
        margin: theme.spacing(3),
    },
    largeUnrelatedPad: {
        padding: theme.spacing(6, 0, 2),
    },
    wideButton: {
        width: 199,
        height: 43,
    },
})

/**
 * The User Panel component that displays a list of users for a group.
 */
class UsersPanel extends PureComponent {
    static propTypes = {
        classes: PropTypes.object,
        group: PropTypes.object,
    }

    static defaultProps = {
        group: {},
    }

    state = {
        value: 1,
        isShowNewUser: false,
        search: undefined,
        showClose: false,
        refresh: Date.now(),
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (!isEqual(nextProps.group, this.props.group)) {
            this.setState({ search: undefined })
        }
    }

    /**
     * Handle changes to the target.
     * @param target The target of the event that triggered the change.
     */
    handleChange = (event, value) => {
        this.setState({ value })
    }

    /**
     * When the add event occurs, show the new user dialog.
     */
    handleAdd = () => {
        this.setState({ isShowNewUser: true })
    }

    /**
     * When the close new user event occurs, hide the new user dialog.
     */
    handleNewUserClose = () => {
        this.setState({ isShowNewUser: false })
    }

    /**
     * Submit the new user to the server.
     * @param user The new user to submit
     */
    handleNewUserSubmit = (user) => {
        this.setState({ selected: user.user_id, refresh: Date.now() })
    }

    /**
     * Handle showing the confirm for the delete.
     */
    handleConfirmDelete = () => {
        this.setState({ showConfirmDelete: true })
    }

    /**
     * Close the delete confirm.
     */
    closeConfirm = () => {
        this.setState({ showConfirmDelete: false })
    }

    /**
     * Handle deleting a group.
     */
    handleDelete = () => {
        const { group, onDeleteGroup } = this.props

        if (group) {
            requestForServer(
                DELETE_ORGANIZATION.format({ orgId: group.organization_id }),
                'del'
            )
                .then((result) => {
                    if (!result.error) {
                        this.setState(
                            {
                                selectedIndex: undefined,
                                showConfirmDelete: false,
                            },
                            onDeleteGroup
                        )
                    } else {
                        this.setState(
                            {
                                selectedIndex: undefined,
                                showError: true,
                                message: result.error.message,
                            },
                            onDeleteGroup
                        )
                    }
                })
                .catch((error) => {
                    this.setState({ showError: true, message: error.message })
                })
        } else {
            console.log('A group is required for delete organization.')
        }
    }

    /**
     * Perform the search of data for the search string the user entered.
     * @type {Function} the debounced function.
     */
    handleSearchChange = (search) => {
        this.setState({ search }, this.props.onSearch)
    }

    /**
     * Close the error snackbar.
     */
    handleErrorClose = () => {
        this.setState({ showError: false })
    }

    render() {
        const { classes, group, groups } = this.props
        const {
            value,
            isShowNewUser,
            search,
            selected,
            showConfirmDelete,
            showError,
            message,
        } = this.state

        return (
            <Grid item className={classes.resizingContainer}>
                {showError && (
                    <ErrorSnackbar
                        open={showError}
                        onClose={this.handleErrorClose}
                        errorId={'admin.deleteOrganizationConfirm.error'}
                        values={{ message }}
                        enableRefresh={false}
                    />
                )}
                {isShowNewUser && (
                    <NewUserDialog
                        open={isShowNewUser}
                        onClose={this.handleNewUserClose}
                        onSubmit={this.handleNewUserSubmit}
                        group={group}
                    />
                )}
                {showConfirmDelete && (
                    <ModalDialog
                        open={showConfirmDelete}
                        onClose={this.closeConfirm}
                        onSubmit={this.handleDelete}
                        messageKey={'admin.deleteOrganizationConfirm.text'}
                        titleKey={'admin.deleteOrganizationConfirm.title'}
                        maxWidth={'xs'}
                        submitKey={'delete.button'}
                        submitColorStyle={'destroy-button'}
                        values={{ orgName: group.name }}
                    />
                )}
                <Tabs
                    className={classes.tabsStyle}
                    value={value}
                    onChange={this.handleChange}
                    classes={{ indicator: classes.indicatorStyle }}
                >
                    <Tab
                        label={
                            <Typography
                                className={'secondary-minimal-tab-small'}
                                id={'admin.settings.label'}
                            />
                        }
                    />
                    <Tab
                        label={
                            <Typography
                                className={'secondary-minimal-tab-small'}
                                id={'admin.users.label'}
                            />
                        }
                    />
                </Tabs>
                {value === 0 && group.organization_id !== 0 && (
                    <Grid
                        container
                        className={classes.settingsContainer}
                        direction={'column'}
                        wrap={'nowrap'}
                    >
                        <Typography
                            className={'level-2-heading'}
                            id="admin.administration.title"
                        />
                        <Typography className={'normal-default-text'}>
                            {group.contact || 'None'}
                        </Typography>
                        <Typography className={'normal-default-text'}>
                            {group.email}
                        </Typography>
                        <Typography className={'normal-default-text'}>
                            {group.phone}
                        </Typography>
                        <Typography
                            className={`${classes.largeUnrelatedPad} level-2-heading`}
                            id="admin.deleteOrganization.title"
                        />
                        <Typography
                            className={'normal-default-text'}
                            id="admin.deleteOrganization.text"
                            values={{ orgName: group.name }}
                        />
                        <Button
                            className={`${classes.fatButtonStyle} button destroy-button ${classes.wideButton}`}
                            onClick={this.handleConfirmDelete}
                        >
                            <Typography
                                color="inherit"
                                id="admin.deleteOrganization.button"
                            />
                        </Button>
                    </Grid>
                )}
                {value === 1 && (
                    <Grid
                        container
                        className={classes.userListContainer}
                        direction={'column'}
                        wrap={'nowrap'}
                    >
                        <Grid
                            container
                            item
                            direction={'row'}
                            justifyContent={'space-between'}
                        >
                            <Button
                                variant={'text'}
                                className={`primary-button ${classes.buttonStyle}`}
                                onClick={this.handleAdd}
                                disabled={
                                    isEmpty(group) ||
                                    group.organization_id === 0
                                }
                            >
                                <Typography
                                    color="inherit"
                                    variant="button"
                                    id={'admin.newUser.label'}
                                />
                            </Button>
                            <SearchField
                                onChange={this.handleSearchChange}
                                value={this.state.search}
                            />
                        </Grid>

                        <UsersTable
                            key={group.organization_id}
                            search={search}
                            selected={selected}
                            groupId={group && group.organization_id}
                            groups={groups}
                            refresh={this.state.refresh}
                        />
                    </Grid>
                )}
            </Grid>
        )
    }
}

export default withStyles(styles)(UsersPanel)
