import React, { useState } from 'react'
import { Auth } from 'aws-amplify'
import Typography from '../../fhg/components/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { FTInput } from '../inputs'
import Box from '@material-ui/core/Box'
import { FTButton } from '../buttons'
import { SIGN_IN_STEP } from '../security/MySignIn'
import { useHistory, useLocation } from 'react-router-dom'
import { posthogCapture } from '../../PostHogFunction'
import eventCapture from '../../eventList'

const useStyles = makeStyles((theme: any) => ({
    mainContainer: {
        width: '100%',
        height: '40%',
        backgroundColor: theme.palette.environment.light.level1.base,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '8px',
    },
    success: {
        width: '100%',
        marginTop: '32px !important',
        color: theme.palette.button.minimal.success,
    },
    error: {
        width: '100%',
        marginTop: `&{theme.spacing(4)}px !important`,
    },
    buttonBox: {
        marginTop: '16px',
        height: '45px',
    },
    headingText: {
        marginBottom: '32px',
        textAlign: 'center',
    },
}))

interface Props {
    email: string
    onStateChange: (state: string, data: any) => void
}

const FTResetPasswordForm: React.FC<Props> = ({ email, onStateChange }) => {
    const classes = useStyles()
    const [isSaving, setIsSaving] = useState<boolean>(false)
    const [error, setError] = useState<string | null>()
    const [code, setCode] = useState<string>('')
    const [password, setPassword] = useState<string>('')

    const handleSubmit = async () => {
        try {
            setIsSaving(true)
            await Auth.forgotPasswordSubmit(email, code, password)
            if (onStateChange) {
                onStateChange('signIn', null)
            }
        } catch (e: any) {
            console.log('error reseting password: ', error)
            setError(e)
            setIsSaving(false)
        }
    }
    return (
        <Box className={classes.mainContainer}>
            <form onSubmit={handleSubmit}>
                <Typography
                    variant={'body1'}
                    id={'submit.subheading'}
                    values={{ email }}
                    className={`${classes.headingText} level-3-heading`}
                >
                    Enter the code we sent to nick@mail.com and then type your
                    new password.
                </Typography>

                {error && (
                    <Typography
                        className={classes.error}
                        color={'error'}
                        variant={'body1'}
                        id={error}
                    >
                        Error Message.
                    </Typography>
                )}
                <FTInput
                    label="submit.code.label"
                    required
                    value={code}
                    onChange={(event) => setCode(event.target.value)}
                    disabled={isSaving}
                    name="passwordCode"
                    type="text"
                />
                <FTInput
                    label="login.password.label"
                    required
                    value={password}
                    onChange={(event) => setPassword(event.target.value)}
                    disabled={isSaving}
                    name="password"
                    type="password"
                />
                <Box className={classes.buttonBox}>
                    <FTButton
                        title={'submit.button.label'}
                        buttonAction={handleSubmit}
                        disabled={isSaving}
                    />
                </Box>
            </form>
        </Box>
    )
}

export default FTResetPasswordForm
