import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import Hidden from '@material-ui/core/Hidden'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { isEmpty } from 'lodash'
import get from 'lodash/get'
import React, { useEffect, useState } from 'react'
import {
    Route,
    Switch,
    Redirect,
    useHistory,
    useLocation,
} from 'react-router-dom'
import 'react-table/react-table.css'
import UsersGroupsMain from '../../admin/UsersGroupsMain'
import Support from '../../support/Support'
import {
    APP_BAR_HEIGHT,
    EDIT_ITEM_PATH,
    CATALOG_PATH,
    DEFAULT_LOCATION,
    GET_USER_SESSION,
} from '../../Constants'
import Typography from '../../fhg/components/Typography'
import PrivilegeRoute from '../../fhg/security/PrivilegeRoute'
import {
    getUserData,
    isFilesOnly,
    isNormalUser,
    isAdminOnly,
    setEstimateConfig,
    setDefaultValuationType,
} from '../../Utils/ServerUtil'
import useRequestForServer from '../../Utils/useRequestForServer'
import NavigationMenu from './components/NavigationMenu'
import OrganizationSelect from './components/OrganizationSelect'
import UserMenu from './components/UserMenu'
import EvalDashboard from './dashboard/EvalDashboard'
import EquipmentListMain from './list/EquipmentListMain'
import ValuationMain from './list/ValuationMain'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { MainAppBar } from '../MainAppBar'
import { FTLink } from '../FTLink'
import ReleaseNotesModal from './components/VersionNotes'

const useStyles = makeStyles(
    (theme) => ({
        root: {
            width: '100%',
            backgroundColor: '#f0f2f5',
            height: '100vh',
            margin: 0,
            '@media print': {
                height: '100%',
            },
        },
        mainGrid: {
            height: `calc(100% - ${APP_BAR_HEIGHT}px)`,
            margin: '0 auto',
            '@media print': {
                display: 'block',
            },
        },
        mainGridFullScreen: {
            height: `100%`,
            margin: '0 auto',
            '@media print': {
                display: 'block',
            },
        },
        button: {
            color: 'inherit !important',
            textTransform: 'none !important',
            fontSize: '18px !important',
            [theme.breakpoints.down('sm')]: {
                padding: theme.spacing(0.5, 0.5),
            },
        },
        titleStyle: {
            fontSize: '14px !important',
            fontWeight: 500,
            lineHeight: '17px',
            textAlign: 'center',
            color: theme.palette.secondary.contrastText,
            textTransform: 'none',
            whiteSpace: 'nowrap',
            [theme.breakpoints.down('xs')]: {
                whiteSpace: 'wrap',
            },
        },
        close: {
            width: theme.spacing(4),
            height: theme.spacing(4),
        },
        list: {
            height: '100%',
            width: '100%',
        },
        version: {
            color: theme.palette.secondary.contrastText,
            fontSize: `${theme.size.font['item-title']}rem`,
            opacity: 0.8,
            fontStyle: 'italic',
            textAlign: 'right',
            cursor: 'pointer',
            '&:hover': {
                opacity: 1,
                textDecoration: 'underline',
            },
            transition: 'all 0.2s ease',
        },
        image: {
            height: 40,
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(5),
            [theme.breakpoints.down('sm')]: {
                marginLeft: theme.spacing(0),
                marginRight: theme.spacing(1),
            },
        },
        mainContainer: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            maxWidth: '100vw',
            width: '100%',
            padding: 0,
        },
        menuItemsContainer: {
            display: 'flex',
            alignItems: 'center',
            gapX: '40px',
            padding: 0,
            gap: '40px',
        },
        profileContainer: {
            display: 'flex',
            justifyContent: 'end',
            alignItems: 'center',
            paddingRight: '24px',
        },
    }),
    { name: 'EvalMainStyles' }
)

/**
 * The main component for the evaluators. Handled evaluators dashboard and showing the open evaluation lists or capture
 * detail.
 */
export default function EvalMain() {
    const classes = useStyles()
    const history = useHistory()
    const location = useLocation()

    const isMobileOrTablet = useMediaQuery('(max-width: 435px)')

    const { isAdmin, multipleOrganizations, organizations } = getUserData()
    const { isZoomed } = get(location, 'location.state', {})

    const { loading, error, data } = useRequestForServer({
        uri: GET_USER_SESSION,
    })
    const [isReleaseNotesOpen, setReleaseNotesOpen] = useState(false)

    useEffect(() => {
        if (!isEmpty(data)) {
            setEstimateConfig(data)
            setDefaultValuationType(data)
        }
    }, [data])

    const handleVersionClick = (event) => {
        event.preventDefault()
        event.stopPropagation()
        setReleaseNotesOpen(true)
    }

    const handleCloseReleaseNotes = () => {
        setReleaseNotesOpen(false)
    }

    /**
     * Handle a change to the organization to display. Reload all the data.
     */
    const handleChangeOrganization = () => {
        history.push(DEFAULT_LOCATION)
        window.location.reload()
    }
    return (
        <div className={classes.root}>
            <Route exact path={EDIT_ITEM_PATH}>
                {({ location }) => (
                    <MainAppBar
                        display={
                            get(location, 'state.isZoomed', false)
                                ? 'none'
                                : undefined
                        }
                    >
                        <Hidden xsDown>
                            <Container className={classes.mainContainer}>
                                <Container
                                    className={classes.menuItemsContainer}
                                >
                                    <FTLink
                                        label="evalDashboard.heading"
                                        location={DEFAULT_LOCATION}
                                    />
                                    <FTLink
                                        label="nav.support.link"
                                        location={'/support'}
                                    />

                                    {isAdmin && (
                                        <FTLink
                                            label="nav.admin.link"
                                            location={'/admin'}
                                        />
                                    )}
                                    {isAdmin && (
                                        <OrganizationSelect
                                            onChange={handleChangeOrganization}
                                        />
                                    )}
                                    {!isAdmin && multipleOrganizations && (
                                        <OrganizationSelect
                                            onChange={handleChangeOrganization}
                                            userOrganizations={organizations}
                                        />
                                    )}
                                </Container>
                                <Container className={classes.profileContainer}>
                                    <UserMenu />
                                    <Typography
                                        className={classes.version}
                                        onClick={handleVersionClick}
                                    >
                                        v{process.env.REACT_APP_VERSION}
                                    </Typography>
                                </Container>
                            </Container>
                        </Hidden>
                        {/* <div style={{ display: 'flex', alignItems: 'center', marginLeft: '50px' }}>
                           {isAdmin && isMobileOrTablet && (
                              <Grid item>
                                 <OrganizationSelect onChange={handleChangeOrganization}/>
                              </Grid>
                           )}
                           {!isAdmin && multipleOrganizations && isMobileOrTablet && (
                              <Grid item>
                                 <OrganizationSelect onChange={handleChangeOrganization}
                                                     userOrganizations={organizations}/>
                              </Grid>
                           )}
                        </div> */}
                        {/* <Hidden smUp>
                     <NavigationMenu admin={isAdmin}/>
                  </Hidden> */}
                    </MainAppBar>
                )}
            </Route>
            <Grid
                container
                className={
                    isZoomed ? classes.mainGridFullScreen : classes.mainGrid
                }
            >
                <Switch>
                    <Redirect
                        from="/evaluations/owner/:owner?/id/:id?"
                        to="/evaluations/:owner%7C:id"
                    />
                    <PrivilegeRoute
                        hasPrivilege={isNormalUser}
                        exact
                        path="/evaluations/all/open"
                        component={EvalDashboard}
                    />
                    <PrivilegeRoute
                        hasPrivilege={isAdminOnly}
                        exact
                        path="/admin"
                        component={UsersGroupsMain}
                    />
                    <PrivilegeRoute
                        hasPrivilege={isNormalUser}
                        exact
                        path="/support"
                        component={Support}
                    />
                    <PrivilegeRoute
                        hasPrivilege={isNormalUser}
                        exact
                        path={[CATALOG_PATH, EDIT_ITEM_PATH, '/new']}
                        component={EquipmentListMain}
                    />
                    <PrivilegeRoute
                        hasPrivilege={isFilesOnly}
                        exact
                        path="/valuation"
                        component={ValuationMain}
                    />
                </Switch>
            </Grid>
            <ReleaseNotesModal
                open={isReleaseNotesOpen}
                onClose={handleCloseReleaseNotes}
            />
        </div>
    )
}
